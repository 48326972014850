import React, { Component, Fragment } from "react";
import {
  Row,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Input,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  InputGroup,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import { connect } from "react-redux";
import './outlet.css'
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { Colxx } from "../../../components/common/CustomBootstrap";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../helpers/IntlMessages";
import ImageUploader from 'react-images-upload';
import Resizer from 'react-image-file-resizer';

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { firestore, storage } from 'firebase'
import SearchBox from "react-google-maps/lib/components/places/SearchBox";
import Locations from './components/new_locations'
const refs = {
  map: undefined,
}
const MapWithAMarker = withScriptjs(
  withGoogleMap(props => {
    return (
      <GoogleMap
        ref={(ref) => refs.map = ref}
        defaultZoom={15}
        options={{ disableDefaultUI: true }}
        onCenterChanged={() =>
          props.updateLatLng({ lat: refs.map.getCenter().lat(), lng: refs.map.getCenter().lng() })}
        onClick={(e) => props.updateLatLng({ lat: e.latLng.lat(), lng: e.latLng.lng() })}
        center={props.latLng}
        defaultCenter={props.latLng}>
        <SearchBox
          style={{ left: '200px' }}
          ref={(ref) => refs.searchBox = ref}
          controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
          onPlacesChanged={props.onPlacesChanged}
        >
          <input
            type="text"
            placeholder="Search"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              marginTop: `27px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
            }}
          />
        </SearchBox>
        <Marker position={props.latLng} />

      </GoogleMap>
    )
  }

  )
);
const tabs = [
  { name: 'outlets.general-information', key: 1 },
  { name: 'outlets.address', key: 2 },
  { name: 'outlets.delivery-timings', key: 3 },
  { name: 'outlets.pickup-timings', key: 4 },
  { name: 'outlets.dinein-timings', key: 5 },
  { name: 'outlets.delivery-areas', key: 6 }]
class Outlets extends Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.state = {
      activeTab: 1,
      timings: {},
      name: '',
      name_ar: '',
      email: '',
      number: '',
      tax: {
        rate: '',
        type: '',
      },
      location: {},
      preparationTime: {},
      pictures: [],
      latLng: { lat: 25.20312907690573, lng: 55.27126858242784 },
      saveDefultTimings: false,
      defultTimingsType: '',
      storeItemObject: {}
    };

  }


  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  handleDateChange(date, selectedDay, index, type, timingsType) {
    let timings = JSON.parse(JSON.stringify(this.state.timings))
    let optimizedMoment = moment(date).set('year', 1955).set('month', 0).set('date', 5);
    switch (type) {
      case 'start':
        timings[timingsType][selectedDay].timings[index].startTime = optimizedMoment.toDate().getTime()
        this.setState({ timings });
        break;
      case 'end':
        timings[timingsType][selectedDay].timings[index].endTime = optimizedMoment.toDate().getTime()
        this.setState({ timings });
        break;

      default:
        break;
    }

  };
  handleSwitchChange = (selectedDay, switchState, type) => {
    let timings = JSON.parse(JSON.stringify(this.state.timings))
    timings[type][selectedDay].isOpen = switchState
    this.setState({ timings });
  };

  addTimings = (day, timingsType) => {
    let timings = JSON.parse(JSON.stringify(this.state.timings))
    let optimizedMoment = moment().set('year', 1955).set('month', 0).set('date', 5);
    timings[timingsType][day].timings.push({ startTime: optimizedMoment.startOf('day').toDate().getTime(), endTime: optimizedMoment.endOf('day').toDate().getTime() });
    this.setState({ timings });
  };


  removeTimings = (day, timeIndex, timingsType) => {
    let timings = JSON.parse(JSON.stringify(this.state.timings))
    timings[timingsType][day].timings.splice(timeIndex, 1);

    this.setState({ timings });
  };


  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  radioButtonSelect = i => {
    let { tax } = this.state
    tax.type = i
    this.setState({ tax });
  };
  componentWillMount() {
    let { addNewBranch, selectedOutlet } = this.props
    let { name, name_ar, email, number, tax, address, address_ar, area, area_ar, preparationTime, timings, pictures, latLng } = (addNewBranch ? this.state : selectedOutlet.value);
    let _this = this
    let key = localStorage.getItem('res')
    firestore().collection('res').where('id', '==', key).get().then(snapShot => {
      snapShot.forEach(resturant => {
        let storeItemObject = resturant.data().subscription.storeSubscriptions ? resturant.data().subscription.storeSubscriptions : {}
        _this.setState({ storeItemObject: storeItemObject })

      })
    })
    let optimizedMoment = moment().set('year', 1955).set('month', 0).set('date', 5);
    let initialTimings = [{ startTime: optimizedMoment.startOf('day').toDate().getTime(), endTime: optimizedMoment.endOf('day').toDate().getTime() }]
    let timingObjects = {
      Sunday: { timings: initialTimings, isOpen: false },
      Monday: { timings: initialTimings, isOpen: false },
      Tuesday: { timings: initialTimings, isOpen: false },
      Wednesday: { timings: initialTimings, isOpen: false },
      Thursday: { timings: initialTimings, isOpen: false },
      Friday: { timings: initialTimings, isOpen: false },
      Saturday: { timings: initialTimings, isOpen: false },
    }
    if (addNewBranch || !timings) {
      timings = {
        deliveryTimings: timingObjects,
        pickupTimings: timingObjects,
        dineinTimings: timingObjects
      }
    } else {
      if (!timings.deliveryTimings) {
        timings.deliveryTimings = timingObjects
      }
      if (!timings.pickupTimings) {
        timings.pickupTimings = timingObjects
      }
      if (!timings.dineinTimings) {
        timings.dineinTimings = timingObjects
      }
    }
    const sorter = {
      "saturday": 1,
      "sunday": 2,
      "monday": 3,
      "tuesday": 4,
      "wednesday": 5,
      "thursday": 6,
      "friday": 7,
    };

    let orderedTimings = {};
    Object.keys(timings).map((item) => {
      let tmp = []
      Object.keys(timings[item]).forEach(function (key) {
        let value = timings[item][key];
        let index = sorter[key.toLowerCase()];
        tmp[index] = {
          key: key,
          value: value
        };
      });
      orderedTimings[item] = tmp
    });


    let orderedData = {};
    Object.keys(orderedTimings).forEach(function (obj) {
      let daysObject = {};
      orderedTimings[obj].forEach(function (day) {
        daysObject[day.key] = day.value
      });
      orderedData[obj] = daysObject

    });

    this.setState({
      name: name ? name : '',
      name_ar: name_ar ? name_ar : '',
      email: email ? email : '',
      number: number ? number : '',
      tax: tax ? tax : this.state.tax,
      address: address ? address : '',
      address_ar: address_ar ? address_ar : '',
      area: area ? area : '',
      area_ar: area_ar ? area_ar : '',
      preparationTime: preparationTime && preparationTime.value ? preparationTime : preparationTime && !preparationTime.value ? { value: preparationTime, label: preparationTime.value } : false,
      timings: orderedData,
      pictures,
      latLng: latLng ? latLng : this.state.latLng
    })
  }
  submit() {
    let { addNewBranch, selectedOutlet, onClickItem } = this.props

    let { name, name_ar, email, number, tax, address, address_ar, area, area_ar, timings, preparationTime, pictures, latLng, storeItemObject } = this.state
    let key = localStorage.getItem('res')
    if (key) {

      if (pictures && pictures.length > 0 && pictures[0].name) {
        pictures.forEach(element => {
          var storageRef = storage().ref().child('resturant').child(key).child("outlet").child("img").put(element.data);
          storageRef.on('state_changed', function (snapshot) {
          }, function (error) {
            // Handle unsuccessful uploads
          }, function () {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            storageRef.snapshot.ref.getDownloadURL().then(function (downloadURL) {
              firestore().collection('resturant').doc(key).update({
                [`branches.${addNewBranch ? Date.now() : selectedOutlet.key}`]: {
                  name: name,
                  activeMenu: (selectedOutlet && selectedOutlet.value && selectedOutlet.value.activeMenu) ? selectedOutlet.value.activeMenu : [],
                  name_ar: name_ar,
                  email: email,
                  number: number,
                  tax: tax,
                  address: address,
                  address_ar: address_ar,
                  area: area,
                  area_ar: area_ar,
                  latLng: latLng ? latLng : false,
                  preparationTime: preparationTime,
                  timings,
                  pictures: [`https://img.food2order.io/${storageRef.snapshot.ref.fullPath}?dum=${Date.now()}`]
                }
              }).then(() => {
                firestore().collection('res').doc(localStorage.getItem('resID')).update({
                  [`subscription.storeSubscriptions`]: storeItemObject

                }).then(() => {
                  onClickItem(null)
                })
              })
            })
          })
        });
      } else {
        firestore().collection('resturant').doc(key).update({
          [`branches.${addNewBranch ? Date.now() : selectedOutlet.key}`]: {
            name, name_ar,
            activeMenu: (selectedOutlet && selectedOutlet.value && selectedOutlet.value.activeMenu) ? selectedOutlet.value.activeMenu : [],
            email,
            number,
            tax,
            address: address ? address : '',
            address_ar: address_ar ? address_ar : '',
            area: area ? area : '',
            area_ar: area_ar ? area_ar : '',
            latLng: latLng ? latLng : false,
            preparationTime,
            timings,
            pictures: pictures ? pictures : []
          }
        }).then(() => {

          firestore().collection('res').doc(localStorage.getItem('resID')).update({
            [`subscription.storeSubscriptions`]: storeItemObject

          }).then(() => {
            onClickItem(null)
          }).catch(err => {
            console.log("errerr", err);
          })
        })
      }
    }
  }
  onDrop(picture) {
    let _this = this;
    let pictures = []
    if (picture.length) {
      let lastPic = picture[picture.length - 1]
      Resizer.imageFileResizer(
        lastPic,
        1080,
        1920,
        'jpeg',
        100,
        0,
        uri600 => {
          pictures.push({
            name: '600',
            data: uri600
          });
          _this.setState({ pictures })
        },
        'blob'
      );

    }
  }

  render() {
    let { name, name_ar, email, number, tax, address, address_ar, area, area_ar, timings, preparationTime, saveDefultTimings, defultTimingsType, storeItemObject } = this.state
    let { addNewBranch, selectedOutlet, onClickItem, branches } = this.props

    console.log("Timings", timings)

    const CustomInput = React.forwardRef((props, ref) => {
      return (
        <input
          onClick={props.onClick}
          value={props.value}
          type="text"
          className="customDatePicker"
          readOnly={true}
          ref={ref}
        />
      )

    })
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <h1>{addNewBranch ? <IntlMessages id={"outlets.new-branch"} /> : selectedOutlet.value.name}</h1>
            <Nav tabs className="separator-tabs ml-0 mb-5">
              {
                tabs.map(item => {
                  return (
                    <NavItem key={item.key}>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === item.key,
                          "nav-link": true
                        })}
                        onClick={() => {
                          this.toggleTab(item.key);
                        }}
                        location={{}}
                        to="#">
                        <IntlMessages id={item.name} />
                      </NavLink>
                    </NavItem>
                  )
                })
              }
            </Nav>

            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId={1}>
                <Card className="rounded">
                  <CardBody>
                    <Row>
                      <Colxx xxs="12" className="mb-2">
                        <h4><IntlMessages id={"outlets.general-information"} /></h4>
                      </Colxx>
                    </Row>
                    <Row>
                      <Colxx xxs="6" className="mb-4">
                        <div className="mb-2" ><IntlMessages id={"outlets.cover-photo"} /></div>
                        <ImageUploader
                          className='w-100'
                          withIcon={true}
                          label={'Max file size: 15mb, accepted: jpg|gif|png'}
                          buttonText='Choose images'
                          onChange={this.onDrop}
                          imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                          maxFileSize={15728640}
                          withPreview={true}
                          defaultImages={selectedOutlet && selectedOutlet.value && selectedOutlet.value.pictures}
                        />
                        {/* <Dropzone ref={node => { this.dropzone = node }} /> */}
                      </Colxx>
                    </Row>
                    <Row>
                      <Colxx xxs="3" className="mb-4">
                        <div className="mb-2" >Outlet Name</div>
                        <Input type="text" autoCapitalize="characters" placeholder="e.g. Doughpamine" value={name} className="customInput" onChange={(e) => this.setState({ name: this.Capitalize(e.target.value) })} />
                      </Colxx>
                      <Colxx xxs="3" className="mb-4">
                        <div className="mb-2" style={{ textAlign: 'end' }} >اسم الفرع</div>
                        <Input type="text" placeholder="e.g. Doughpamine" style={{ textAlign: 'end' }} value={name_ar} className="customInput" onChange={(e) => this.setState({ name_ar: e.target.value })} />
                      </Colxx>
                    </Row>
                    <Row>
                      <Colxx xxs="12" className="mb-2 mt-4">
                        <h4><IntlMessages id={"outlets.contact-information"} /></h4>
                      </Colxx>
                    </Row>
                    <Row>
                      <Colxx xxs="6" className="mb-4">
                        <div className="mb-2" ><IntlMessages id={"outlets.store-email"} /></div>
                        <Input type="email" placeholder="e.g. xxxxx@gmail.com" value={email} className="customInput" onChange={(e) => this.setState({ email: e.target.value })} />
                      </Colxx>
                    </Row>
                    <Row>
                      <Colxx xxs="6" className="mb-4">
                        <div className="mb-2" ><IntlMessages id={"outlets.store-landline"} /></div>
                        <Input className="customInput" placeholder="e.g. +971xxxxxx" value={number} type="number" onChange={(e) => this.setState({ number: e.target.value })} />
                      </Colxx>
                    </Row>
                    <Row>
                      <Colxx xxs="12" className="mt-4" >
                        <h4><IntlMessages id={"outlets.operation-settings"} /></h4>
                        <p><IntlMessages id={"outlets.set--default-time"} /></p>
                      </Colxx>
                      <Colxx xxs="4" className="mb-4">
                        <div className="mb-2" ><IntlMessages id={"outlets.preparation-time"} /></div>
                        <InputGroup className='mb-4'>
                          <Input
                            placeholder={'10 min'}
                            type='number'
                            id='estimatedTime'
                            value={preparationTime && preparationTime.value}
                            onChange={(e) => {
                              if (preparationTime) {
                                preparationTime.value = e.target.value
                              }
                              else {
                                preparationTime = { value: e.target.value, label: 'min' }
                              }
                              this.setState({ preparationTime })
                            }}
                          />
                          <UncontrolledDropdown addonType="append">
                            <DropdownToggle caret outline color="primary">
                              {
                                preparationTime && preparationTime.label ? preparationTime.label : 'Select'
                              }
                            </DropdownToggle>
                            <DropdownMenu>
                              {
                                ['mins', 'hours'].map(item => {
                                  return (
                                    <DropdownItem key={item} onClick={() => {
                                      if (preparationTime) {
                                        preparationTime.label = item
                                      }
                                      else {
                                        preparationTime = { label: item }
                                      }
                                      this.setState({ preparationTime })
                                    }

                                    }>
                                      {item}
                                    </DropdownItem>
                                  )
                                })
                              }

                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </InputGroup>
                      </Colxx>

                    </Row>
                    {/* <Row>
                      <Colxx xxs="12" className="mb-2 mt-4">
                        <h4>Order Details</h4>
                      </Colxx>
                      <Colxx xxs="3" className="mb-4">
                        <div className="mb-2" >Tax Rate (%)</div>
                        <Input type="number" placeholder="e.g. 5%" className="customInput" value={tax.rate} onChange={(e) => {
                          tax.rate = e.target.value
                          this.setState({ tax })
                        }} />
                      </Colxx>

                      <Colxx xxs="3" className="mb-4">
                        <div className="mb-2" >Tax Inclusive</div>
                        <ButtonGroup>
                          <Button
                            outline={tax.type !== "Inclusive"}
                            color="primary"
                            onClick={() => this.radioButtonSelect("Inclusive")}
                            active={tax.type === "Inclusive"}
                          >
                            Inclusive
                          </Button>
                          <Button
                            outline={tax.type !== "Exclusive"}
                            color="primary"
                            onClick={() => this.radioButtonSelect("Exclusive")}
                            active={tax.type === "Exclusive"}
                          >
                            Exclusive
                          </Button>
                        </ButtonGroup>
                      </Colxx>
                    </Row> */}
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId={2}>
                <Card className="rounded">
                  <CardBody>
                    <Row>
                      <Colxx xxs="12" className="mb-2">
                        <h4><IntlMessages id={"outlets.address"} /></h4>
                      </Colxx>
                      <Colxx xxs="12" className="mb-4">
                        {
                          <MapWithAMarker
                            onPlacesChanged={() => {
                              let locations = refs.searchBox.getPlaces()[0]
                              let location = locations.geometry.location
                              let latLng = { lat: location.lat(), lng: location.lng() }
                              this.setState({ latLng })
                            }}
                            updateLatLng={(latLng) => this.setState({ latLng })}
                            latLng={this.state.latLng}
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAPe5JQQz0a1T7ytukrW3JLTapYdfRyuSE&v=3.exp&libraries=geometry,drawing,places&language=en"
                            loadingElement={<div className="map-item" />}
                            containerElement={<div className="map-item" />}
                            mapElement={<div className="map-item" />} />
                        }

                      </Colxx>
                    </Row>
                    <Row>
                      <Colxx xxs="6" className="mb-4">
                        <div className="mb-2" >Address</div>
                        <Input className="customInput" value={address} placeholder="e.g. building name, street" type="text" onChange={(e) => this.setState({ address: e.target.value })} />
                      </Colxx>
                      <Colxx xxs="6" className="mb-4">
                        <div className="mb-2" style={{ textAlign: 'end' }}>العنوان</div>
                        <Input className="customInput" style={{ textAlign: 'end' }} value={address_ar} placeholder="e.g. building name, street" type="text" onChange={(e) => this.setState({ address_ar: e.target.value })} />
                      </Colxx>
                    </Row>
                    <Row>
                      <Colxx xxs="6" className="mb-4">
                        <div className="mb-2" >Area</div>
                        <Input className="customInput" value={area} placeholder="e.g. Majaz 1" type="text" onChange={(e) => this.setState({ area: e.target.value })} />
                      </Colxx>
                      <Colxx xxs="6" className="mb-4">
                        <div className="mb-2" style={{ textAlign: 'end' }}>المنطقة</div>
                        <Input className="customInput" style={{ textAlign: 'end' }} value={area_ar} placeholder="e.g. Majaz 1" type="text" onChange={(e) => this.setState({ area_ar: e.target.value })} />
                      </Colxx>
                    </Row>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId={3}>
                <Card className="rounded">
                  <CardBody>
                    <Row>
                      <Colxx xxs="12" >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                          <h4><IntlMessages id={"outlets.delivery-schedule"} /></h4>
                          <Switch
                            style={{
                              marginBottom: '.5rem',
                              marginLeft: '18px',
                              marginRight: '18px',
                              padding: '2px 10px',
                            }}
                            className="custom-switch custom-switch-primary"
                            checked={storeItemObject && storeItemObject.delivery && storeItemObject.delivery.activated}
                            onChange={switchCheckedPrimary => {
                              if (storeItemObject && storeItemObject.delivery) {
                                storeItemObject.delivery.activated = switchCheckedPrimary
                              } else if (storeItemObject) {
                                storeItemObject.delivery = { activated: switchCheckedPrimary }
                              } else {
                                storeItemObject = { delivery: { activated: switchCheckedPrimary } }
                              }
                              this.setState({ storeItemObject })
                              // this.handleSwitchChange(item, switchCheckedPrimary, 'deliveryTimings')
                            }}
                          />
                          {branches.length > 1 && !addNewBranch && storeItemObject && storeItemObject.delivery && storeItemObject.delivery.activated &&
                            <div style={{
                              cursor: 'pointer',
                              fontSize: '12px',
                              marginBottom: '.5rem',
                              fontWeight: '600',
                              color: '#28a745',
                              marginLeft: '8px',
                              marginRight: '8px',
                            }}
                              onClick={() => this.setState({ saveDefultTimings: true, defultTimingsType: 'deliveryTimings' })}>
                              <IntlMessages id="outlets.apply-to-all-branches" />
                            </div>
                          }
                        </div>
                        <p><IntlMessages id={"outlets.set-delivery-hours"} /></p>
                      </Colxx>
                      <Colxx xxs="8" >
                        {storeItemObject && storeItemObject.delivery && storeItemObject.delivery.activated &&


                          Object.keys(timings.deliveryTimings).map((item, index) => {
                            return (
                              <div key={index} className="timingsParent" >
                                <div className="dayParent">
                                  <div className="day"><IntlMessages id={"outlets." + item} /></div>
                                  <Switch
                                    className="custom-switch custom-switch-primary"
                                    checked={timings.deliveryTimings[item].isOpen}
                                    onChange={switchCheckedPrimary => {
                                      this.handleSwitchChange(item, switchCheckedPrimary, 'deliveryTimings')
                                    }}
                                  />
                                </div>
                                {
                                  timings.deliveryTimings[item].isOpen &&
                                  <div className="dateContainer">
                                    {timings.deliveryTimings[item].timings.map((timing, index2) => {
                                      return (
                                        <div key={index2} className="date2Container">
                                          <DatePicker
                                            customInput={<CustomInput />}
                                            selected={(timing.startTime ? moment(timing.startTime) : moment().endOf('day'))}
                                            className="customDatePicker"
                                            showTimeSelectOnly
                                            onChange={date => this.handleDateChange(date, item, index2, 'start', 'deliveryTimings')}
                                            showTimeSelect
                                            showTimeInput
                                            dateFormat="hh:mm A"
                                            timeFormat="hh:mm A"
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            placeholderText={"Start"}
                                            minTime={index2 > 0 ? moment().hours(moment(timings.deliveryTimings[item].timings[index2 - 1].endTime).hours()).minutes(moment(timings.deliveryTimings[item].timings[index2 - 1].endTime).minutes()) : moment().hours(moment().startOf('day').hours()).minutes(moment().startOf('day').minutes())}
                                            maxTime={moment().hours(moment().endOf('day').hours()).minutes(moment().endOf('day').minutes())}
                                          />
                                          <DatePicker
                                            customInput={<CustomInput />}
                                            selected={(timing.endTime ? moment(timing.endTime) : moment().endOf('day'))}
                                            className="customDatePicker"
                                            showTimeSelectOnly
                                            onChange={date => { this.handleDateChange(date, item, index2, 'end', 'deliveryTimings') }}
                                            showTimeSelect
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="hh:mm A"
                                            timeFormat="hh:mm A"
                                            placeholderText={"End"}
                                            minTime={timing.startTime ? moment().hours(moment(timing.startTime).hours()).minutes(moment(timing.startTime).minutes()) : moment().startOf('day')}
                                            maxTime={moment().hours(moment().endOf('day').hours()).minutes(moment().endOf('day').minutes())}
                                          />
                                          {index2 === 0 ? <div className="addTimings" onClick={() => { this.addTimings(item, 'deliveryTimings') }}><i className="simple-icon-plus" /> <IntlMessages id={"outlets.add-new-timings"} /></div>
                                            : <div className="removeTimings" onClick={() => { this.removeTimings(item, index2, 'deliveryTimings') }}><i className="simple-icon-close" /></div>}
                                        </div>
                                      )
                                    })}
                                  </div>
                                }

                              </div>
                            )
                          })
                        }
                      </Colxx>

                    </Row>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId={4}>
                <Card className="rounded">
                  <CardBody>
                    <Row>
                      <Colxx xxs="12">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                          <h4><IntlMessages id={"outlets.pickup-schedule"} /></h4>
                          <Switch
                            style={{
                              marginBottom: '.5rem',
                              marginLeft: '18px',
                              marginRight: '18px',
                              padding: '2px 10px',
                            }}
                            className="custom-switch custom-switch-primary"
                            checked={storeItemObject && storeItemObject.pickup && storeItemObject.pickup.activated}
                            onChange={switchCheckedPrimary => {
                              if (storeItemObject && storeItemObject.pickup) {
                                storeItemObject.pickup.activated = switchCheckedPrimary
                              } else if (storeItemObject) {
                                storeItemObject.pickup = { activated: switchCheckedPrimary }
                              } else {
                                storeItemObject = { pickup: { activated: switchCheckedPrimary } }
                              }
                              this.setState({ storeItemObject })
                            }}
                          />
                          {branches.length > 1 && !addNewBranch && storeItemObject && storeItemObject.pickup && storeItemObject.pickup.activated &&
                            <div style={{
                              cursor: 'pointer',
                              fontSize: '12px',
                              marginBottom: '.5rem',
                              fontWeight: '600',
                              color: '#28a745',
                              marginLeft: '8px',
                              marginRight: '8px',
                            }}
                              onClick={() => this.setState({ saveDefultTimings: true, defultTimingsType: 'pickupTimings' })}>
                              <IntlMessages id="outlets.apply-to-all-branches" />
                            </div>}
                        </div>
                        <p><IntlMessages id={"outlets.set-pickup-hours"} /></p>
                      </Colxx>
                      <Colxx xxs="8" >
                        {storeItemObject && storeItemObject.pickup && storeItemObject.pickup.activated &&


                          Object.keys(timings.pickupTimings).map((item, index) => {
                            return (
                              <div key={index} className="timingsParent" >
                                <div className="dayParent">
                                  <div className="day"><IntlMessages id={"outlets." + item} /></div>
                                  <Switch
                                    className="custom-switch custom-switch-primary"
                                    checked={timings.pickupTimings[item].isOpen}
                                    onChange={switchCheckedPrimary => {
                                      this.handleSwitchChange(item, switchCheckedPrimary, 'pickupTimings')
                                    }}
                                  />
                                </div>
                                {
                                  timings.pickupTimings[item].isOpen &&
                                  <div className="dateContainer">
                                    {timings.pickupTimings[item].timings.map((timing, index2) => {
                                      return (
                                        <div key={index2} className="date2Container" >
                                          <DatePicker
                                            customInput={<CustomInput />}
                                            selected={(timing.startTime ? moment(timing.startTime) : moment().endOf('day'))}
                                            className="customDatePicker"
                                            showTimeSelectOnly
                                            onChange={date => this.handleDateChange(date, item, index2, 'start', 'pickupTimings')}
                                            showTimeSelect
                                            showTimeInput
                                            dateFormat="hh:mm A"
                                            timeFormat="hh:mm A"
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            placeholderText={"Start"}
                                            minTime={index2 > 0 ? moment().hours(moment(timings.pickupTimings[item].timings[index2 - 1].endTime).hours()).minutes(moment(timings.pickupTimings[item].timings[index2 - 1].endTime).minutes()) : moment().hours(moment().startOf('day').hours()).minutes(moment().startOf('day').minutes())}
                                            maxTime={moment().hours(moment().endOf('day').hours()).minutes(moment().endOf('day').minutes())}
                                          />
                                          <DatePicker
                                            customInput={<CustomInput />}
                                            selected={(timing.endTime ? moment(timing.endTime) : moment().endOf('day'))}
                                            className="customDatePicker"
                                            showTimeSelectOnly
                                            onChange={date => { this.handleDateChange(date, item, index2, 'end', 'pickupTimings') }}
                                            showTimeSelect
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="hh:mm A"
                                            timeFormat="hh:mm A"
                                            placeholderText={"End"}
                                            minTime={timing.startTime ? moment().hours(moment(timing.startTime).hours()).minutes(moment(timing.startTime).minutes()) : moment().startOf('day')}
                                            maxTime={moment().hours(moment().endOf('day').hours()).minutes(moment().endOf('day').minutes())} />
                                          {index2 === 0 ? <div className="addTimings" onClick={() => { this.addTimings(item, 'pickupTimings') }}><i className="simple-icon-plus" /> <IntlMessages id={"outlets.add-new-timings"} /></div>
                                            : <div className="removeTimings" onClick={() => { this.removeTimings(item, index2, 'pickupTimings') }}><i className="simple-icon-close" /></div>}
                                        </div>
                                      )
                                    })}
                                  </div>
                                }

                              </div>
                            )
                          })
                        }
                      </Colxx>
                    </Row>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId={5}>
                <Card className="rounded">
                  <CardBody>
                    <Row>
                      <Colxx xxs="12" >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                          <h4><IntlMessages id={"outlets.dinein-schedule"} /></h4>
                          <Switch
                            style={{
                              marginBottom: '.5rem',
                              marginLeft: '18px',
                              marginRight: '18px',
                              padding: '2px 10px',
                            }}
                            className="custom-switch custom-switch-primary"
                            checked={storeItemObject && storeItemObject.dineIn && storeItemObject.dineIn.activated}
                            onChange={switchCheckedPrimary => {
                              if (storeItemObject && storeItemObject.dineIn) {
                                storeItemObject.dineIn.activated = switchCheckedPrimary
                              } else if (storeItemObject) {
                                storeItemObject.dineIn = { activated: switchCheckedPrimary }
                              } else {
                                storeItemObject = { dineIn: { activated: switchCheckedPrimary } }
                              }
                              this.setState({ storeItemObject })
                            }}
                          />
                          {branches.length > 1 && !addNewBranch && storeItemObject && storeItemObject.dineIn && storeItemObject.dineIn.activated &&
                            <div style={{
                              cursor: 'pointer',
                              fontSize: '12px',
                              marginBottom: '.5rem',
                              fontWeight: '600',
                              color: '#28a745',
                              marginLeft: '8px',
                              marginRight: '8px',
                            }}
                              onClick={() => this.setState({ saveDefultTimings: true, defultTimingsType: 'dineinTimings' })}>
                              <IntlMessages id="outlets.apply-to-all-branches" />
                            </div>}
                        </div>
                        <p><IntlMessages id={"outlets.set-dinein-hours"} /></p>
                      </Colxx>
                      <Colxx xxs="8" >
                        {storeItemObject && storeItemObject.dineIn && storeItemObject.dineIn.activated &&

                          Object.keys(timings.dineinTimings).map((item, index) => {
                            return (
                              <div key={index} className="timingsParent" >
                                <div className="dayParent">
                                  <div className="day"><IntlMessages id={"outlets." + item} /></div>
                                  <Switch
                                    className="custom-switch custom-switch-primary"
                                    checked={timings.dineinTimings[item].isOpen}
                                    onChange={switchCheckedPrimary => {
                                      this.handleSwitchChange(item, switchCheckedPrimary, 'dineinTimings')
                                    }}
                                  />
                                </div>
                                {
                                  timings.dineinTimings[item].isOpen &&
                                  <div className="dateContainer">
                                    {timings.dineinTimings[item].timings.map((timing, index2) => {
                                      return (
                                        <div key={index2} className="date2Container">
                                          <DatePicker
                                            customInput={<CustomInput />}
                                            selected={(timing.startTime ? moment(timing.startTime) : moment().endOf('day'))}
                                            className="customDatePicker"
                                            showTimeSelectOnly
                                            onChange={date => this.handleDateChange(date, item, index2, 'start', 'dineinTimings')}
                                            showTimeSelect
                                            showTimeInput
                                            dateFormat="hh:mm A"
                                            timeFormat="hh:mm A"
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            placeholderText={"Start"}
                                            minTime={index2 > 0 ? moment().hours(moment(timings.dineinTimings[item].timings[index2 - 1].endTime).hours()).minutes(moment(timings.dineinTimings[item].timings[index2 - 1].endTime).minutes()) : moment().hours(moment().startOf('day').hours()).minutes(moment().startOf('day').minutes())}
                                            maxTime={moment().hours(moment().endOf('day').hours()).minutes(moment().endOf('day').minutes())}
                                          />
                                          <DatePicker
                                            customInput={<CustomInput />}
                                            selected={(timing.endTime ? moment(timing.endTime) : moment().endOf('day'))}
                                            className="customDatePicker"
                                            showTimeSelectOnly
                                            onChange={date => { this.handleDateChange(date, item, index2, 'end', 'dineinTimings') }}
                                            showTimeSelect
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="hh:mm A"
                                            timeFormat="hh:mm A"
                                            placeholderText={"End"}
                                            minTime={timing.startTime ? moment().hours(moment(timing.startTime).hours()).minutes(moment(timing.startTime).minutes()) : moment().startOf('day')}
                                            maxTime={moment().hours(moment().endOf('day').hours()).minutes(moment().endOf('day').minutes())} />
                                          {index2 === 0 ? <div className="addTimings" onClick={() => { this.addTimings(item, 'dineinTimings') }}><i className="simple-icon-plus" /> <IntlMessages id={"outlets.add-new-timings"} /></div>
                                            : <div className="removeTimings" onClick={() => { this.removeTimings(item, index2, 'dineinTimings') }}><i className="simple-icon-close" /></div>}
                                        </div>
                                      )
                                    })}
                                  </div>
                                }

                              </div>
                            )
                          })
                        }
                      </Colxx>
                    </Row>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId={6}>
                <Card className="rounded">
                  <CardBody>
                    <Locations branch={selectedOutlet} />
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>

            {
              this.state.activeTab !== 6 && <Button
                color="primary"
                className="float-right mt-4"
                size="lg"
                onClick={() => this.submit()}
              >
                <IntlMessages id={"outlets.save-changes"} />
              </Button>
            }
            <Button color='danger' className='m-4 float-right' outline onClick={() => onClickItem(null)}><IntlMessages id={"orders.dismiss"} /></Button>
            <Modal
              isOpen={saveDefultTimings}
              backdrop="static">
              <ModalHeader ><IntlMessages id="outlets.apply-to-all-branches" /></ModalHeader>
              <ModalBody>
                <Row>
                  <Colxx xxs="12" >
                    <Label ><IntlMessages id="outlets.apply-to-all-branches-msg" /></Label>
                  </Colxx>
                </Row>
              </ModalBody>
              <ModalFooter >
                <Button color="danger" className="top-right-button" onClick={() => this.setState({ saveDefultTimings: false })}><IntlMessages id="menus.cancel" /></Button>
                <Button color="primary" className="top-right-button" onClick={() => this.setState({ saveDefultTimings: false }, () => this.applyToOtherBranches(defultTimingsType))}><IntlMessages id="outlets.apply" /></Button>
              </ModalFooter>
            </Modal>
          </Colxx>
        </Row>
      </Fragment >
    );
  }
  applyToOtherBranches(type) {
    let { branches, selectedOutlet } = this.props
    let { timings } = this.state
    let resKey = localStorage.getItem('res')

    branches.map(branch => {
      return (
        (selectedOutlet.key !== branch.key) && (
          firestore().collection('resturant').doc(resKey).update({
            [`branches.${branch.key}.timings`]: {
              [type]: timings[type],
            }
          }).then(() => {
            this.setState({ defultTimingsType: '' }, () => {
              this.submit()
            })

          })
        )
      )

    })


  }

}
const mapStateToProps = ({ settings }) => {
  const { currency } = settings
  return {
    currency
  };
};
export default injectIntl(
  connect(
    mapStateToProps,
    {
    }
  )(Outlets)
);
