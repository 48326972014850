/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/


module.exports = {
  /* 01.General */
  "general.copyright": "Gogo React © 2018 All Rights Reserved.",

  /* 02.User Login, Logout, Register */
  "user.login-title": "Login",
  "user.register": "Register",
  "user.forgot-password": "Forgot Password",
  "user.email": "E-mail",
  "user.password": "Password",
  "user.forgot-password-question": "Forget password?",
  "user.fullname": "Full Name",
  "user.login-button": "LOGIN",
  "user.register-button": "REGISTER",
  "user.reset-password-button": "RESET",
  "user.buy": "BUY",
  "user.username": "Username",

  /* 03.Menu */
  "menu.dashboards": "Dashboards",
  "menu.menu-builder": "Menu Builder",
  "menu.items-and-modifiers": "Items and Modifiers",
  "menu.menus": "Menus",
  "menu.orders": "Orders",
  "menu.crm": "CRM",
  "menu.customers": "Customers",
  "menu.order-history": "Order History",
  "menu.store": "Market",
  "menu.outlets": "Outlets",
  "menu.marketing": "Marketing",
  "menu.promocodes": "Promocodes",
  "menu.super-admin": "Super Admin",
  "menu.all-restaurant": "All Restaurant",
  "menu.clients": "Clients",



  /* 04.Error Page */
  "pages.error-title": "Ooops... looks like an error occurred!",
  "pages.error-code": "Error code",
  "pages.go-back-home": "GO BACK HOME",

  /* 05.Dashboards */
  "dashboards.total-orders": "Total Orders",
  "dashboards.total-sales": "Total Sales",
  "dashboards.commission": "Saved Commission",
  "dashboards.avg-basket-value": "Avg. Basket Value",
  "dashboards.pending-orders": "Pending Orders",
  "dashboards.completed-orders": "Completed Orders",
  "dashboards.refund-requests": "Refund Requests",
  "dashboards.new-comments": "New Comments",
  "dashboards.recent-orders": "Recent Orders",
  "dashboards.sales": "Sales",
  "dashboards.orders": "Orders",
  "dashboards.refunds": "Refunds",
  "dashboards.product-categories": "Product Categories",
  "dashboards.cakes": "Cakes",
  "dashboards.tickets": "Tickets",
  "dashboards.calendar": "Calendar",
  "dashboards.best-sellers": "Best Sellers",
  "dashboards.website-visits": "Website Visits",
  "dashboards.unique-visitors": "Unique Visitors",
  "dashboards.this-week": "This Week",
  "dashboards.last-week": "Lat Week",
  "dashboards.this-month": "This Month",
  "dashboards.conversion-rates": "Conversion Rates",
  "dashboards.per-session": "Per Session",
  "dashboards.profile-status": "Profile Status",
  "dashboards.payment-status": "Payment Status",
  "dashboards.work-progress": "Work Progress",
  "dashboards.tasks-completed": "Tasks Completed",
  "dashboards.payments-done": "Payments Done",
  "dashboards.order-stock": "Order - Stock",
  "dashboards.categories": "Categories",
  "dashboards.quick-post": "Quick Post",
  "dashboards.title": "Title",
  "dashboards.content": "Content",
  "dashboards.category": "Category",
  "dashboards.save-and-publish": "Save and Publish",
  "dashboards.top-viewed-posts": "Top Viewed Posts",
  "dashboards.posts": "Posts",
  "dashboards.pending-for-publish": "Pending for publish",
  "dashboards.users": "Users",
  "dashboards.on-approval-process": "On approval process",
  "dashboards.alerts": "Alerts",
  "dashboards.waiting-for-notice": "Waiting for notice",
  "dashboards.files": "Files",
  "dashboards.pending-for-print": "Pending for print",
  "dashboards.logs": "Logs",
  "dashboards.gogo": "GOGO",
  "dashboards.magic-is-in-the-details": "MAGIC IS IN THE DETAILS",
  "dashboards.yes-it-is-indeed": "Yes, it is indeed!",
  "dashboards.advanced-search": "Advanced Search",
  "dashboards.toppings": "Toppings",
  "dashboards.type": "Type",
  "dashboards.keyword": "Keyword",
  "dashboards.search": "Search",
  "dashboards.top-rated-items": "Top Rated Items",

  /* 05.Menus */
  "menus.item-details": "Item Details",
  "menus.tutorial": "Tutorial",
  "menus.menu-items": "Menu Items",
  "menus.list-all-menu-items": "Add all your menu items to be added to your menus",
  "menus.filter-by-categoury": "Filter by category",
  "menus.reset": "Reset",
  "menus.import": "Import",
  "menus.create-new": "Create New",
  "menus.are-you-sure": "Are you sure you want to Delete",
  "menus.confirm": "Confirm",
  "menus.cancel": "Cancel",
  "menus.item-name": "Item Name",
  "menus.list-name": "Modifiers List Name",
  "menus.list-name_ar": "Modifiers List Arabic Name",
  "menus.item-arabic-name": "Item Arabic Name",
  "menus.include-vat": "Include vat in your Item price",
  "menus.dine-in-item-price": "'Dine-in' item price",
  "menus.delivery-item-price": "'Delivery' item price",
  "menus.pickup-item-price": "'Pickup' item price",
  "menus.item-price": "Item price",
  "menus.hide-adv-pricing": "Hide adv. pricing",
  "menus.show-adv-pricing": "Show adv. pricing",
  "menus.item-description": "Item Description",
  "menus.item-arabic-description": "Item Arabic Description",
  "menus.customizations": "Customizations",
  "menus.no-modifiers-added": "No modifiers added for this item",
  "menus.add-customization": "Add Customization",
  "menus.save": "Save",
  "menus.create-menu": "Create Menu",
  "menus.name": "Name",
  "menus.branches": "Branches",
  "menus.collapse-all": "Collapse All",
  "menus.new-category": "New Category",
  "menus.are-you-sure-you": "Are you sure you want to dismiss changes",
  "menus.menu-name": "Menu Name",
  "menus.active-branches": "Active Branches",
  "menus.activated-for": "Activated For",
  "menus.actions": "Actions",
  "menus.activate": "Activate",
  "menus.duplicate": "Duplicate",
  "menus.edit": "Edit",
  "menus.delete": "Delete",
  "menus.update-category": "Update Category",
  "menus.name*": "Name*",
  "menus.arabic-name*": "Arabic Name*",
  "menus.update": "Update",
  "menus.add-category": "Add Category",
  "menus.modifier-item": "Modifier Item",
  "menus.menu-item": "Menu Item",
  "menus.price-on-selection": "Price on Selection",
  "menus.out-of-stock": "Out of stock",
  "menus.view": "View",
  "menus.modifier-list": "Modifier List",
  "menus.selection-count": "Selection Count*",
  "menus.select-modifier-items": "Select Modifier Items",
  "menus.options": "Options",
  "menus.create-new-modifier": "Create new modifier item",
  "menus.create-modifier": "Create New Modifier",
  "menus.arabic-name": "Arabic Name",
  "menus.price": "Price",
  "menus.min-count": "Minimum Selection",
  "menus.max-count": "Maximum Selection",
  "menus.type": "Allow multiple selection?",
  "menus.modifiers-list": "Modifiers List",
  "menus.this-will-not": "This will not be showing on the app",

  /* 07.21.Orders */
  "orders.all-orders": "All Orders",
  "orders.new-orders": "New Orders",
  "orders.accepted-orders": "Accepted Orders",
  "orders.in-delivery-orders": "In Delivery Orders",
  "orders.delivered-orders": "Delivered Orders",
  "orders.canceled-orders": "Canceled Orders",

  "orders.estimated-preparation-time": "Estimated Preparation Time",
  "orders.estimated-pickup-time": "Estimated Pickup Time",
  "orders.request-jeebly": "Request Jeebly For Delivery",
  "orders.estimated-delivery-time": "Estimated Delivery Time",
  "orders.mins": "Mins",
  "orders.cancel": "Cancel",
  "orders.accept-order": "Accept Order",
  "orders.back": "Back",
  "orders.customer-name": "Customer Name",
  "orders.phone-number": "Phone Number",
  "orders.pickup-from": "Pickup From",
  "orders.dine-in": "Dine In",
  "orders.delivery-to": "Delivery To",
  "orders.branch": "Branch",
  "orders.payment-method": "Payment Method",
  "orders.loyalty": "Loyalty",
  "orders.points-balance": "Points Balance:",
  "orders.orders-notes": "Orders Notes",
  "orders.item-name": "Item Name",
  "orders.count": "Count",
  "orders.price": "Price",
  "orders.pickup": "Pickup:",
  "orders.delivery": "Delivery:",
  "orders.discount": "Discount",
  "orders.discount-loyalty": "Discount 'Loyalty'",
  "orders.total": "Total",
  "orders.subtotal": "Subtotal",
  "orders.vat": "VAT",
  "orders.accept": "Accept",
  "orders.ready-for-pickup": "Ready for pickup",
  "orders.send-to-table": "Send to table",
  "orders.send-for-delivery": "Send for delivery",
  "orders.confirm-pickup": "Confirm Pickup",
  "orders.confirm-delivery": "Confirm Delivery",
  "orders.canceled": "Canceled",
  "orders.reject-order": "Reject Order",
  "orders.dismiss": "Dismiss",
  "orders.msg-reject-order": "Are you sure you want to Reject this order?",
  "orders.status-filter": "Status Filter",
  "orders.to-crm": "Order History",

  /* 07.21.Customers */
  "customers.name": "Name",
  "customers.phone-number": "Phone  Number",
  "customers.orders-placed": "Total Orders",
  "customers.first-order-at": "First Order At",
  "customers.last-order-at": "Last Order At",
  "customers.total-revenue": "Total Revenue",
  "customers.no-customers-yet": "No Customers yet!",
  "customers.customers": "Customers",

  /* 07.21.OrderHistory */
  "orderhistory.ID": "ID",
  "orderhistory.customer": "Customer",
  "orderhistory.type": "Type",
  "orderhistory.status": "Status",
  "orderhistory.payment-method": "Payment Method",
  "orderhistory.total": "Total",
  "orderhistory.number": "Number",
  "orderhistory.ordered-at": "Ordered at",
  "orderhistory.pickup": "Pickup",
  "orderhistory.delivery": "Delivery",
  "orderhistory.dine-in": "Dine In",
  "orderhistory.new-orders": "New Orders",
  "orderhistory.accepted-orders": "Accepted Orders",
  "orderhistory.in-delivery-orders": "In Delivery Orders",
  "orderhistory.delivered-orders": "Delivered Orders",
  "orderhistory.canceled-orders": "Canceled Orders",
  "orderhistory.no-orders-yet": "No Orders Yet!",
  "orderhistory.order-history": "Order History",
  "orderhistory.revenue": "Revenue",
  "orderhistory.average-order-value": "Average Order Value",
  "orderhistory.total-orders": "Total Orders",

  /* 07.21.Store */
  "store.recommended": "Recommended",
  "store.expired": "Expired",
  "store.expiring": "Expiring",
  "store.coming-soon": "Coming Soon",
  "store.activated": "Activated",
  "store.get-started": "Get Started",
  "store.reactivate": "Reactivate",

  /* 07.21.Outlets */
  "outlets.outlets": "Outlets",
  "outlets.confim-deactivate": "Are you sure you want to Deactivate",
  "outlets.deactivate": "Deactivate",
  "outlets.new-branch": "New Branch",
  "outlets.general-information": "General Information",
  "outlets.cover-photo": "Cover Photo",
  "outlets.contact-information": "Contact Information",
  "outlets.store-email": "Store Email",
  "outlets.store-landline": "Store Landline",
  "outlets.operation-settings": "Operation Settings",
  "outlets.set--default-time": "Set the default time needed for preparing and delivering orders",
  "outlets.preparation-time": "Preparation Time",
  "outlets.address": "Address",
  "outlets.delivery-schedule": "Delivery Schedule",
  "outlets.set-delivery-hours": "Set Delivery hours for this outlet",
  "outlets.add-timings": "Add Timings",
  "outlets.preparation-minutes": "Preparation (minutes)",
  "outlets.pickup-schedule": "Pickup Schedule",
  "outlets.set-pickup-hours": "Set pickup hours for this outlet",
  "outlets.dinein-schedule": "Dine in Schedule",
  "outlets.set-dinein-hours": "Set Dine in hours for this outlet",
  "outlets.locations": "Locations",
  "outlets.import": "Import",
  "outlets.add-areas": "Add Areas",
  "outlets.areas-we-serve": "Areas we serve for",
  "outlets.confirm-delete-group": "Are you sure you want to delete this area group?",
  "outlets.add-new-areas": "Add new Areas",
  // "outlets.enter-delivery-fees": "Enter delivery fees for a specified group of areas:",
  "outlets.enter-delivery-fees": "Enter delivery fees:",
  "outlets.areas-you-deliver": "Areas that you deliver with fixed fees",
  "outlets.minimum-order-group": "Enter minimum order for this group:",
  "outlets.estimated-delivery-time": "Enter estimated delivery time for this group:",
  "outlets.delivery-areas": "Delivery Areas",
  "outlets.save-changes": "Save Changes",
  "outlets.delivery-timings": "Delivery Timings",
  "outlets.pickup-timings": "Pickup Timings",
  "outlets.dinein-timings": "Dine in Timings",
  "outlets.apply-to-all-branches": "Apply to All Branches",
  "outlets.Saturday": "Saturday",
  "outlets.Sunday": "Sunday",
  "outlets.Monday": "Monday",
  "outlets.Tuesday": "Tuesday",
  "outlets.Wednesday": "Wednesday",
  "outlets.Thursday": "Thursday",
  "outlets.Friday": "Friday",
  "outlets.apply-to-all-branches-msg": "Are you sure you want to apply this timings to all your branches?",
  "outlets.apply": "Apply",
  "outlets.add-new-timings": "Add New Timings",

  /* 07.21.Marketing */
  "marketing.code": "Code",
  "marketing.count": "Count",
  'marketing.limited-per-user':'Limited per user',
  "marketing.percent": "Percent",
  "marketing.maximum-value": "Maximum Value",
  "marketing.created": "Created",
  "marketing.auto-applied": "Auto Applied",
  "marketing.promo-codes": "Promo Codes",
  "marketing.no-promocodes": "No Promocodes",
  "marketing.create-promo-code": "Create New Promo Code",
  "marketing.edit-promo-code": "Edit Promo Code",
  "marketing.auto-applied2": "Auto Applied:",
  "marketing.enabling-this-will": "* Enabling this will disable it in all other promo codes",
  "marketing.promo-code": "Promo Code:",
  "marketing.number-of-promo-codes": "Number of promo codes:",
  "marketing.percent2": "Percent:",
  "marketing.maximum-value2": "Maximum Value (AED):",
  "marketing.available-in": "Available In:",
  "marketing.create": "Create",
  "marketing.delete-promo-code": "Delete Promo code",
  "marketing.confirm-delete-promocode": "Are you sure you want to delete this promo code?",
  "marketing.dismiss": "Dismiss",
  "marketing.endDate": "Expiration Date",
  "marketing.excludeItems": "Exclude items from this offer",
  "marketing.includeItems": "Include items to this offer",
  "marketing.excludeItemsLabel": "Include or Exclude items from this offer",
  "marketing.expired": "Expired",
  "marketing.expiredMsg": "This promocode is expired, kindly update the expiration date to activate this promo code again",
  /* 07.21.SetupList */
  "setuplist.steps-to-go-live": "Steps to go live",
  "setuplist.list-menu-items": "List your Menu Items",
  "setuplist.click-here": "Click here",
  "setuplist.add-your-menu-items": "to add your Menu Items.",
  "setuplist.to-create-your-menu": "to create your Menu.",
  "setuplist.to-set-your-delivery": "to set your Delivery Areas.",
  "setuplist.create-first-menu": "Create your 1st Menu",
  "setuplist.set-delivery-areas": "Set Delivery Areas",

  /* 07.21.Wizards */
  "wizard.step-name-1": "Admin Info",
  "wizard.step-name-2": "Store Info",
  "wizard.step-name-3": "Delivery Info",
  "wizard.step-desc-1": "Contact details of Admin",
  "wizard.step-desc-2": "Basic info of your Store",
  "wizard.step-desc-3": "Information needed for setting up your online order system",
  "wizard.content-1": "Step content for first step.",
  "wizard.content-2": "Step content for second step.",
  "wizard.content-3": "Last step content!",
  "wizard.content-thanks": "Thank You!",
  "wizard.next": "Next",
  "wizard.prev": "Back",
  "wizard.registered": "Your registration completed successfully!",
  "wizard.async": "Async save for 3 seconds!"


};

