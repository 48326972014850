import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, Modal, ModalBody, ModalFooter, } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import DataTablePagination from "../../../components/DatatablePagination";
import OrderModal from "./OrderModal";
import moment from "moment";
// import { DateRangePicker } from 'rsuite';
// import 'rsuite/dist/styles/rsuite-default.css';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import Select from 'react-select'
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import {
  getOrderList,
  getOrderListWithOrder,
  getOrderListSearch,
  selectedOrderItemsChange,
  deleteOrderItem,
  getOrderListWithFilter

} from "../../../redux/actions";

import IntlMessages from "../../../helpers/IntlMessages";
import { getDirection } from "../../../helpers/Utils";

const dataTableColumns = (currency) => [
  {
    Header: <IntlMessages id={"orderhistory.ID"} />,
    accessor: "id",
    width: 230,
    Cell: props => <p className="list-item-heading">{props.value}</p>
  },
  {
    Header: <IntlMessages id={"orderhistory.customer"} />,
    accessor: "customer",
    Cell: props => <p className="list-item-heading">{props.value}</p>
  },

  {
    Header: <IntlMessages id={"orderhistory.type"} />,
    accessor: "type",
    Cell: props => <p className="list-item-heading">{props.value}</p>
  },
  {
    Header: <IntlMessages id={"orderhistory.status"} />,
    accessor: "status",
    Cell: props => <p className="list-item-heading">{props.value}</p>
  },
  {
    Header: <IntlMessages id={"orderhistory.payment-method"} />,
    accessor: "paymentMethod",
    Cell: props => <p className="list-item-heading">{props.value}</p>
  },
  {
    Header: <IntlMessages id={"orderhistory.total"} />,
    accessor: "totalTag",
    Cell: props => <p className="list-item-heading">{currency + " " + props.value}</p>
  },
  {
    Header: <IntlMessages id={"orderhistory.number"} />,
    accessor: "number",
    Cell: props => <p className="list-item-heading">{props.value}</p>
  },
  {
    Header: <IntlMessages id={"orderhistory.ordered-at"} />,
    accessor: 'date',
    Cell: props => <div className="list-item-heading" >{moment(props.value).format("DD/MM/YYYY")}<div style={{ fontSize: '10px' }}>{moment(props.value).format("hh:mmA")}</div></div>
  }
];
const deliveryTypeOptions = [
  { value: 'pickup', label: <IntlMessages id={"orderhistory.pickup"} /> },
  { value: 'delivery', label: <IntlMessages id={"orderhistory.delivery"} /> },
  { value: 'dineIn', label: <IntlMessages id={"orderhistory.dine-in"} /> },
]
const statusOptions = [
  { label: <IntlMessages id={"orderhistory.new-orders"} />, value: 'new' },
  { label: <IntlMessages id={"orderhistory.accepted-orders"} />, value: 'accepted' },
  { label: <IntlMessages id={"orderhistory.in-delivery-orders"} />, value: 'delivery' },
  { label: <IntlMessages id={"orderhistory.delivered-orders"} />, value: 'delivered' },
  { label: <IntlMessages id={"orderhistory.canceled-orders"} />, value: 'canceled' },
]

class OrderHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      branches: [],
      selected: null,
      branchesSelected: null,
      statusSelected: null,
      typesSelected: null,
      dateRange: [moment().subtract(1, 'days').toDate(), moment().toDate()],
      allOrders: [],
      filteredOrders: null,
      sales: 0,
      avgSales: 0,
      modalBack: false,


    };
  }
  toggleBack = () => {
    this.setState(prevState => ({
      modalBack: !prevState.modalBack
    }));
  };


  componentWillReceiveProps(prop) {
    const { todoItems } = prop.orders;
    let { allBranches, currency } = prop
    let { allOrders, dateRange, filteredOrders, branches } = this.state

    if (todoItems && !allOrders.length && allBranches && currency) {
      let sales = 0
      let avgSales = 0
      todoItems.map(item => {
        if (item.status !== 'canceled') {
          sales += parseInt(item.receipt.total)
          avgSales = (sales / todoItems.length).toFixed(2)
        }

        allOrders.push({
          id: item.id,
          customer: item.user.name,
          number: item.user.number,
          type: item.receipt.type,
          status: item.status,
          paymentMethod: item.receipt.paymentType,
          total: (item.receipt.total),
          totalTag: (item.receipt.total * 1),
          date: item.created,
          branch: item.receipt.branch[0]
        })
      })
      branches = Object.keys(allBranches).map(item => {
        return ({ key: item, value: allBranches[item].name, label: allBranches[item].name })
      })
      // if (allOrders.length === todoItems.length) {
      let created = Math.max.apply(Math, allOrders.map(function (o) { return o.date; }))

      dateRange = [moment(created).subtract(1, 'days').endOf('day').toDate(), moment(created).endOf('day').toDate()]
      filteredOrders = allOrders
      this.setState({ allOrders, sales, avgSales, dateRange, filteredOrders, branches: branches }, () => {
        this.filterFunc()
      })
      // }
    }

  }
  filterFunc() {
    let { branchesSelected, statusSelected, typesSelected, filteredOrders, dateRange } = this.state

    if (branchesSelected && branchesSelected.length) {
      filteredOrders = filteredOrders.filter((item) => {
        return branchesSelected.filter(record => record.key === item.branch).length
      })
    }
    if (statusSelected && statusSelected.length) {
      filteredOrders = filteredOrders.filter((item) => {
        return statusSelected.includes(item.status)
      })
    }
    if (typesSelected && typesSelected.length) {
      filteredOrders = filteredOrders.filter((item) => {
        return typesSelected.includes(item.type)
      })
    }
    if (dateRange && dateRange.length) {
      filteredOrders = filteredOrders.filter((item) => {
        return moment(item.date).isAfter(moment(dateRange[0])) && moment(item.date).isBefore(moment(dateRange[1]))
      })
    }
    let sales = 0
    let avgSales = 0
    filteredOrders.map(item => {
      if (item.status !== 'canceled') {
        sales += parseInt(item.total)
        avgSales = (sales / filteredOrders.length).toFixed(2)
      }
    })

    this.setState({ filteredOrders, sales, avgSales }, () => {
      this.render()
    })

  }
  handleChange(value, type) {
    let { allOrders } = this.state

    switch (type.name) {
      case 'branch':
        let branchArray = []
        if (value) {
          value.map(item => {
            branchArray.push(item)
          })
        }
        this.setState({ branchesSelected: branchArray, filteredOrders: allOrders, sales: 0, avgSales: 0 }, () => {
          this.filterFunc()
        })
        break;
      case 'type':
        let typesArray = []
        if (value) {
          value.map(item => {
            typesArray.push(item.value)
          })
        }
        this.setState({ typesSelected: typesArray, filteredOrders: allOrders, sales: 0, avgSales: 0 }, () => {
          this.filterFunc()
        })
        break;
      case 'status':
        let statusArray = []
        if (value) {
          value.map(item => {
            statusArray.push(item.value)
          })
        }
        this.setState({ statusSelected: statusArray, filteredOrders: allOrders, sales: 0, avgSales: 0 }, () => {
          this.filterFunc()
        })
        break;

      default:
        break;
    }
  }

  render() {
    const { todoItems, loading } = this.props.orders;
    let { allOrders, filteredOrders, sales, avgSales, branches, modalBack } = this.state
    let { currency } = this.props
    const direction = getDirection();

    if (!allOrders.length) {
      return (
        <div>
          {
            (loading && !todoItems) ? <div className='loading'></div>
              :
              <div className="d-flex flex-row mb-3 pb-3 text-center justify-content-center ">
                <div className='font-weight-bold'><IntlMessages id={"orderhistory.no-orders-yet"} /></div>
              </div>
          }
        </div>
      )
    }



    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <div className="mb-2"><h1><IntlMessages id={"orderhistory.order-history"} /></h1></div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12">
            <Card className="mb-4 rounded">
              <CardBody>
                <Row>
                  <Colxx xxs="3">
                    <Select
                      placeholder={direction.isRtl ? "منافذ" : "Outlets"}
                      isMulti
                      name="branch"
                      options={branches}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={this.handleChange.bind(this)}
                    />
                  </Colxx>
                  <Colxx xxs="3">
                    <DateRangePicker
                      value={this.state.dateRange}
                      format="dd/MM/y"
                      locale="en"
                      clearIcon={<i className="iconsminds-close" />}
                      calendarIcon={<i className="iconsminds-calendar-4" />}
                      onChange={dateRange => {
                        this.setState({ dateRange, filteredOrders: allOrders, sales: 0, avgSales: 0 }, () => {
                          this.filterFunc()
                        })
                      }}
                    />
                  </Colxx>
                  <Colxx xxs="3">
                    <Select
                      placeholder={direction.isRtl ? "النوع" : "Type"}
                      isMulti
                      name="type"
                      options={deliveryTypeOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={this.handleChange.bind(this)}
                    />
                  </Colxx>
                  <Colxx xxs="3">
                    <Select
                      placeholder={direction.isRtl ? "الحالة" : "Status"}
                      isMulti
                      name="status"
                      options={statusOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={this.handleChange.bind(this)}
                    />
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card className="mb-4 rounded">
              <Row className="mt-4 pl-4 pr-4">
                <Colxx xxs="4" className='d-flex flex-column justify-content-center align-items-center'>
                  <div className="salesTitle"><IntlMessages id={"orderhistory.revenue"} /></div>
                  <div className="salesValue">{currency + ' ' + sales}</div>
                </Colxx>
                <Colxx xxs="4" className='d-flex flex-column justify-content-center align-items-center'>
                  <div className="salesTitle"><IntlMessages id={"orderhistory.average-order-value"} /></div>
                  <div className="salesValue">{currency + ' ' + avgSales}</div>
                </Colxx>
                <Colxx xxs="4" className='d-flex flex-column justify-content-center align-items-center'>
                  <div className="salesTitle"><IntlMessages id={"orderhistory.total-orders"} /></div>
                  <div className="salesValue">{filteredOrders ? filteredOrders.length : allOrders.length}</div>
                </Colxx>
              </Row>
              <Separator className="mb-4 mt-4" />
              <CardBody style={{ paddingTop: '0' }}>
                <ReactTable
                  data={filteredOrders ? filteredOrders : allOrders}
                  columns={dataTableColumns(currency)}
                  defaultPageSize={10}
                  showPageJump={true}
                  PaginationComponent={DataTablePagination}
                  showPageSizeOptions={true}
                  getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                      return {
                        onClick: (e) => {
                          let selectedItem = todoItems.filter((item) => {
                            return item.id === rowInfo.row.id
                          })

                          this.setState({
                            selectedItem: selectedItem[0],
                            selected: rowInfo.index,
                            modalBack: true
                          })
                        },
                        style: {
                          background: rowInfo.index === this.state.selected ? '#adadad30' : 'white',
                          // color: rowInfo.index === this.state.selected ? 'white' : 'black',
                          borderRadius: '4px'
                        }
                      }
                    } else {
                      return {}
                    }
                  }
                  }
                />
              </CardBody>
              {
                <Modal style={{ borderRadius: "16px", overflow: 'hidden' }} toggle={this.toggleBack} isOpen={this.state.modalBack}>
                  <ModalBody>
                    <OrderModal editItem={this.state.selectedItem} />
                  </ModalBody>
                </Modal>
              }
            </Card>
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}
const mapStateToProps = ({ orders, settings }) => {
  return {
    orders,
    allBranches: settings.allBranches,
    currency: settings.currency
  };
};
export default injectIntl(
  connect(
    mapStateToProps,
    {
      getOrderList,
      getOrderListWithOrder,
      getOrderListSearch,
      selectedOrderItemsChange,
      deleteOrderItem,
      getOrderListWithFilter
    }
  )(OrderHistory)
);
