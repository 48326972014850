import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import todoSagas from './todo/saga';
import offersSagas from './offers/saga';
import testmonials from './testmonials/saga';
import users from './users/saga';
import orders from './orders/saga';

import chatSagas from './chat/saga';


export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    todoSagas(),
    offersSagas(),
    testmonials(),
    users(),
    orders(),
    chatSagas()
  ]);
}
