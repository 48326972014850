import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import menuItems from "./menu-items";
import modifiersList from "./modifiers-list";
import menuBuilder from "./menu-builder";

const Users = ({ match }) => (
  <div className="dashboard-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}`} to={`${match.url}/menu-builder`} />
      <Route path={`${match.url}/menu-items`} component={menuItems} />
      <Route path={`${match.url}/modifiers-list`} component={modifiersList} />
      <Route path={`${match.url}/builder`} component={menuBuilder} />
      <Redirect to="/error" />
    </Switch>
  </div>
);

export default Users;
