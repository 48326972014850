const GET_FULL_LIST_ITEM = "GET_FULL_LIST_ITEM"
export const fetchItems = (items) => {
    return ({
        type: GET_FULL_LIST_ITEM,
        payload: items
    })
};


const INIT_STATE = {}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_FULL_LIST_ITEM:
            return { ...state, allItems: action.payload.allItems, allItemsMap: action.payload.allItemsMap, categouryMap: action.payload.categouryMap, loading: true };
        default: return { ...state };

    }

}