import appLocaleData from 'react-intl/locale-data/ar';
import esMessages from '../locales/ar_EG';

const ArLang = {
    messages: {
        ...esMessages
    },
    locale: 'ar-EG',
    data: appLocaleData
};
export default ArLang;