import {
    CHANGE_LOCALE
} from '../actions';


export const changeLocale = (locale) => {
    localStorage.setItem('currentLanguage', locale);
    return (
        {
            type: CHANGE_LOCALE,
            payload: locale
        }
    )
}

export const selectBranch = (bransh) => {
    return (
        {
            type: "CHANGE_BRANCH",
            payload: bransh
        }
    )
}
export const getAllBranches = (bransh) => {
    return (
        {
            type: "LIST_ALL_BRANCH",
            payload: bransh
        }
    )
}
export const setupResParam = (param) => {
    return (
        {
            type: "SET_RES_PARAM",
            payload: param
        }
    )
}

