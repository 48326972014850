import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Locations from "./locations.js";


const Users = ({ match }) => (
  <div className="dashboard-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}`} to={`${match.url}/settings`} />
      <Route path={`${match.url}/locations`} component={Locations} />

      <Redirect to="/error" />
    </Switch>
  </div>
);

export default Users;
