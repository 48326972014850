import React from "react";
import { Card, Button } from "reactstrap";
import classnames from "classnames";
import { ContextMenuTrigger } from "react-contextmenu";
import { Colxx } from "../../../../components/common/CustomBootstrap";
import "rc-switch/assets/index.css";
import IntlMessages from "../../../../helpers/IntlMessages";
import { getDirection } from "../../../../helpers/Utils";

const ModifierItem = ({ item, isSelected, rowClicked, deleteItem }) => {

    const direction = getDirection();

    return (
        <Colxx xxs="12" key={item.id} className="mb-3 ">
            <ContextMenuTrigger id="menu_id" data={item.id} className='overflow-hidden'>
                <Card
                    className={classnames("d-flex flex-row rounded ", {
                        active: isSelected
                    })}>

                    <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                        <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                            <p className="list-item-heading mb-1 truncate">
                                {direction.isRtl ? (item.name_ar ? item.name_ar : item.title_ar) : (item.name ? item.name : item.title)}
                            </p>
                        </div>

                        <div className="custom-control custom-checkbox align-self-center p-2">
                            {/* <Button outline color='danger' size='xs' onClick={() => remove(item)}>Delete</Button> */}
                            <Button outline color='danger' size='xs' onClick={() => deleteItem(item)}><IntlMessages id={"menus.delete"} /></Button>
                        </div>
                        <div className="custom-control custom-checkbox align-self-center p-2 pr-4">
                            <Button outline color="dark" size='xs' onClick={() => rowClicked(item)}><IntlMessages id={"menus.view"} /></Button>
                        </div>

                    </div>
                </Card>
            </ContextMenuTrigger>
        </Colxx>

    );
};

export default ModifierItem;
