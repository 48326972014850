import {
	ORDER_GET_LIST,
	ORDER_GET_LIST_SUCCESS,
	ORDER_GET_LIST_ERROR,
	ORDER_GET_LIST_WITH_FILTER,
	ORDER_GET_LIST_WITH_ORDER,
	ORDER_GET_LIST_SEARCH,
	ORDER_ADD_ITEM,
	ORDER_DELETE_ITEM,
	ORDER_ADD_ITEM_SUCCESS,
	ORDER_ADD_ITEM_ERROR,
	ORDER_SELECTED_ITEMS_CHANGE
} from '../actions';

const INIT_STATE = {
	allTodoItems: null,
	todoItems: null,
	error: '',
	filter: null,
	searchKeyword: '',
	orderColumn: null,
	loading: false,
	labels: [
		{ label: 'foodbutcher', color: "info" },
		{ label: "business", color: "info" },
		{ label: "table", color: "info" },
		{ label: "birthday", color: "info" }

	],
	orderColumns: [
		{ column: "title", label: "Title" },
		{ column: "category", label: "Category" },
		// { column: "status", label: "Status" },
		{ column: "created", label: "Creation Date" },
	],
	categories: ["breakfast", "soup", "cold appetizers", "hot appetizers", "main course", "tajin", "grills", "mix cairo", "fatayer", "sweet fatayer", "kids menu", "fruit salad", "desserts", "smoothies & milkshake", "fresh Juice & cocktail", "egyptian traditional drinks", "cold drinks"],
	selectedItems: []
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case ORDER_GET_LIST:
			return { ...state, loading: false };

		case ORDER_GET_LIST_SUCCESS:
			return { ...state, loading: true, allTodoItems: action.payload, todoItems: action.payload, activeOrders: action.payload.filter(item => ['new', 'accepted', 'delivery'].includes(item.status)) };

		case ORDER_GET_LIST_ERROR:
			return { ...state, loading: true, error: action.payload };

		case ORDER_GET_LIST_WITH_FILTER:
			if (action.payload.column === '' || action.payload.value === '') {
				return { ...state, loading: true, todoItems: state.allTodoItems, filter: null };
			} else {
				const filteredItems = state.allTodoItems.filter((item) =>
					action.payload.value.includes((item[action.payload.column].length === 2 ? item[action.payload.column].join('') : item[action.payload.column])));
				return {
					...state, loading: true, todoItems: filteredItems, filter: {
						column: action.payload.column,
						value: action.payload.value
					}
				}
			}

		case ORDER_GET_LIST_WITH_ORDER:
			if (action.payload === '') {
				return { ...state, loading: true, todoItems: state.todoItems, orderColumn: null };
			} else {
				const sortedItems = state.todoItems.sort((a, b) => {
					if (
						a[action.payload] <
						b[action.payload]
					)
						return -1;
					else if (
						a[action.payload] >
						b[action.payload]
					)
						return 1;
					return 0;
				})
				return { ...state, loading: true, todoItems: sortedItems, orderColumn: state.orderColumns.find(x => x.column === action.payload) }
			}

		case ORDER_GET_LIST_SEARCH:
			if (action.payload === '') {
				return { ...state, todoItems: state.allTodoItems };
			} else {
				const keyword = action.payload.toLowerCase();
				const searchItems = state.allTodoItems.filter((item) =>
					item.title.toLowerCase().indexOf(keyword) > -1 || (item.detail ? item.detail : '').toLowerCase().indexOf(keyword) > -1 || (item.status ? item.status : '').toLowerCase().indexOf(keyword) > -1 || (item.category ? item.category : '').toLowerCase().indexOf(keyword) > -1 || (item.label ? item.label : '').toLowerCase().indexOf(keyword) > -1);
				return { ...state, loading: true, todoItems: searchItems, searchKeyword: action.payload }
			}

		case ORDER_ADD_ITEM:
			return { ...state, loading: false };

		case ORDER_DELETE_ITEM:
			return { ...state, loading: false };

		case ORDER_ADD_ITEM_SUCCESS:
			return { ...state, loading: true, allTodoItems: action.payload, todoItems: action.payload };

		case ORDER_ADD_ITEM_ERROR:
			return { ...state, loading: true, error: action.payload };

		case ORDER_SELECTED_ITEMS_CHANGE:
			return { ...state, loading: true, selectedItems: action.payload };
		default: return { ...state };
	}
}
