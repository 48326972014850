import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { TODO_GET_LIST,TODO_GET_LIST_BUTCHER, TODO_ADD_ITEM, TODO_UPDATE_ITEM ,TODO_DELETE_ITEM} from "../actions";

import {
  getTodoListSuccess,
  getTodoListError,
  addTodoItemSuccess,
  addTodoItemError,
} from "./actions";
import { database, storage } from '../../helpers/Firebase';
const res = localStorage.getItem('res')
const getTodoListRequest = async () => {
  return await new Promise((success, fail) => {
    database.collection('menu').where('res','==',res).get().then(results => {
      let list = []
      results.forEach((item) => {
        let value = item.data();
        value.id = item.id
        list.push(value)
      })
      success(list);
    })
  })
    .then(response => response)
    .catch(error => error);
};
const getButcherListRequest = async () => {
  return await new Promise((success, fail) => {
    database.collection('menu').where('butcher','==',true).get().then(results => {
      let list = []
      results.forEach((item) => {
        let value = item.data();
        value.id = item.id
        list.push(value)
      })
      success(list);
    })
  })
    .then(response => response)
    .catch(error => error);
};

function* getTodoListItems() {
  try {
    const response = yield call(getTodoListRequest);
    yield put(getTodoListSuccess(response));
  } catch (error) {
    yield put(getTodoListError(error));
  }
}
function* getButcherListItems() {
  try {
    const response = yield call(getButcherListRequest);
    yield put(getTodoListSuccess(response));
  } catch (error) {
    yield put(getTodoListError(error));
  }
}
const deleteTodoItemRequest = async items => {
  return await new Promise((success, fail) => {
    let butcher = false;
    items.forEach(item=>{
      database.collection('menu').doc(item).delete();
      butcher = item.butcher
    })
    if (butcher){
      getButcherListRequest().then(results => {
        success(results)
      })
    }else{
      getTodoListRequest().then(results => {
        success(results)
      })
    }
  })

}

const addTodoItemRequest = async item => {

  return await new Promise((success, fail) => {

    let id = database.collection('menu').doc().id
    if (item.pictures && item.pictures.length) {
      item.pictures.forEach(picture => {
        var storageRef = storage.ref().child("menu").child(id).child("img").put(picture);
        storageRef.on('state_changed', function (snapshot) {
        }, function (error) {
          // Handle unsuccessful uploads
        }, function () {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          storageRef.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            database.collection('menu').doc(id).set({
              name: item.title,
              detail: item.detail ? item.detail : '',
              name_ar: item.title_ar ? item.title_ar : '',
              detail_ar: item.detail_ar ? item.detail_ar : '',
              category: item.category ? item.category : '',
              price: item.price ? item.price : '',
              topDish: item.topDish ? item.topDish : false,
              created: Date.now(),
              butcher:item.butcher,
              hide:item.hide?item.hide:false,
              extras:item.extras?item.extras:null,
              pictures: [downloadURL],
              index:item.index,
              res:res
            }).then(() => {
              if (item.butcher){
                getButcherListRequest().then(results => {
                  success(results)
                })
              }else{
                getTodoListRequest().then(results => {
                  success(results)
                })
              }
            })
          });
        });
      })
    } else {
      database.collection('menu').doc(id).set({
        name: item.title,
        detail: item.detail ? item.detail : '',
        name_ar: item.title_ar ? item.title_ar : '',
        detail_ar: item.detail_ar ? item.detail_ar : '',
        category: item.category ? item.category : '',
        price: item.price ? item.price : '',
        topDish: item.topDish ? item.topDish : false,
        created: Date.now(),
        hide:item.hide?item.hide:false,
        extras:item.extras?item.extras:null,
        butcher:item.butcher,
        index:item.index,
        res:res
      }).then(() => {
        if (item.butcher){
          getButcherListRequest().then(results => {
            success(results)
          })
        }else{
          getTodoListRequest().then(results => {
            success(results)
          })
        }
      })
    }
  })
    .then(response => response)
    .catch(error => error);
};

function* addTodoItem({ payload }) {
  try {
    const response = yield call(addTodoItemRequest, payload);
    yield put(addTodoItemSuccess(response));
  } catch (error) {
    yield put(addTodoItemError(error));
  }
}
function* updateTodoItem({ payload }) {
  try {
    const response = yield call(updateTodoItemRequest, payload);
    yield put(addTodoItemSuccess(response));
  } catch (error) {
    yield put(addTodoItemError(error));
  }
}
function* deleteTodoItem({ payload }) {
  try {
    const response = yield call(deleteTodoItemRequest, payload);
    yield put(addTodoItemSuccess(response));
  } catch (error) {
    yield put(addTodoItemError(error));
  }
}

const updateTodoItemRequest = async item => {
  return await new Promise((success, fail) => {
    if (item.pictures && item.pictures.length) {
      item.pictures.forEach(picture => {
        if (picture.name) {
          var storageRef = storage.ref().child("menu").child(item.id).child("img").put(picture);
          storageRef.on('state_changed', function (snapshot) {
          }, function (error) {
            // Handle unsuccessful uploads
          }, function () {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            storageRef.snapshot.ref.getDownloadURL().then(function (downloadURL) {
              console.log('File available at', downloadURL);
              let ref = database.collection('menu').doc(item.id)
              return ref.update({
                name: item.title ? item.title : '',
                detail: item.detail ? item.detail : '',
                name_ar: item.title_ar ? item.title_ar : '',
                detail_ar: item.detail_ar ? item.detail_ar : '',
                category: item.category ? item.category : '',
                price: item.price ? item.price : '',
                topDish: item.topDish ? item.topDish : false,
                pictures: [downloadURL],
                hide:item.hide?item.hide:false,
                butcher:item.butcher,
                extras:item.extras?item.extras:null,
                updated: Date.now(),
                index:item.index
              }).then(() => {
                if (item.butcher){
                  getButcherListRequest().then(results => {
                    success(results)
                  })
                }else{
                  getTodoListRequest().then(results => {
                    success(results)
                  })
                }
              }).catch(error => {
                console.log("errorerror", error)

              })
            });
          });
        } else {
          let ref = database.collection('menu').doc(item.id)
          return ref.update({
            name: item.title ? item.title : '',
            detail: item.detail ? item.detail : '',
            name_ar: item.title_ar ? item.title_ar : '',
            detail_ar: item.detail_ar ? item.detail_ar : '',
            category: item.category ? item.category : '',
            price: item.price ? item.price : '',
            topDish: item.topDish ? item.topDish : false,
            pictures: [picture],
            hide:item.hide?item.hide:false,
            butcher:item.butcher,
            extras:item.extras?item.extras:null,
            updated: Date.now(),
            index:item.index
          }).then(() => {
            if (item.butcher){
              getButcherListRequest().then(results => {
                success(results)
              })
            }else{
              getTodoListRequest().then(results => {
                success(results)
              })
            }
          }).catch(error => {
            console.log("errorerror", error)
          })
        }
      })
    }else{
      let ref = database.collection('menu').doc(item.id)
      return ref.update({
        name: item.title ? item.title : '',
        detail: item.detail ? item.detail : '',
        name_ar: item.title_ar ? item.title_ar : '',
        detail_ar: item.detail_ar ? item.detail_ar : '',
        category: item.category ? item.category : '',
        price: item.price ? item.price : '',
        topDish: item.topDish ? item.topDish : false,
        hide:item.hide?item.hide:false,
        butcher:item.butcher,
        extras:item.extras?item.extras:null,
        updated: Date.now(),
        index:item.index
      }).then(() => {
        if (item.butcher){
          getButcherListRequest().then(results => {
            success(results)
          })
        }else{
          getTodoListRequest().then(results => {
            success(results)
          })
        }
      }).catch(error => {
        console.log("errorerror", error)
      })
    }
  })
    .then(response => response)
    .catch(error => error);
};

export function* watchGetList() {
  yield takeEvery(TODO_GET_LIST, getTodoListItems);
}
export function* watchGetListButcher() {
  yield takeEvery(TODO_GET_LIST_BUTCHER, getButcherListItems);
}

export function* wathcAddItem() {
  yield takeEvery(TODO_ADD_ITEM, addTodoItem);
}
export function* wathcUpdateItem() {
  yield takeEvery(TODO_UPDATE_ITEM, updateTodoItem);
}
export function* wathcDeleteItems() {
  yield takeEvery(TODO_DELETE_ITEM, deleteTodoItem);
}
export default function* rootSaga() {
  yield all([fork(watchGetList),fork(watchGetListButcher), fork(wathcAddItem), fork(wathcUpdateItem),fork(wathcDeleteItems)]);
}
