import React, { Component } from "react";
import { connect } from "react-redux";
import { NavItem, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import classnames from "classnames";
import IntlMessages from "../../helpers/IntlMessages";
import { getDirection } from "../../helpers/Utils";

import ApplicationMenu from "../../components/common/ApplicationMenu";
import { getOrderListWithFilter } from "../../redux/actions";
class TodoApplicationMenu extends Component {
  constructor(props) {
    super();
  }

  addFilter = (column, value) => {
    this.props.getOrderListWithFilter(column, value);
  };

  render() {

    const {
      filter,
      allTodoItems,
      loading,
    } = this.props.orders;
    const direction = getDirection();

    return (
      <ApplicationMenu>
        <PerfectScrollbar
          option={{ suppressScrollX: true, wheelPropagation: false }}
        >
          <div className="p-4">
            <p className="text-muted text-small">
              <IntlMessages id={"orders.status-filter"} />
            </p>
            <ul className="list-unstyled mb-5">

              {
                [
                  { title: 'orders.all-orders', status: '', icon: 'simple-icon-layers' },
                  { title: 'orders.new-orders', status: 'new', icon: 'simple-icon-refresh' },
                  { title: 'orders.accepted-orders', status: 'accepted', icon: 'iconsminds-chef-hat' },
                  { title: 'orders.in-delivery-orders', status: 'delivery', icon: 'simple-icon-check' },
                  // { title: 'orders.delivered-orders', status: 'delivered', icon: 'iconsminds-scooter' },
                  // { title: 'orders.canceled-orders', status: 'canceled', icon: 'simple-icon-minus' },

                ].map((item, index) => {
                  return (
                    <NavItem
                      key={index}
                      className={classnames({
                        active:
                          filter &&
                          filter.column === "status" &&
                          filter.value === item.status
                      })}
                    >
                      <NavLink
                        to="#"
                        onTouchEnd={() => this.addFilter("status", item.status)}
                        onClick={() => this.addFilter("status", item.status)}
                      >
                        <i className={item.icon} style={direction.isRtl ? { marginLeft: '10px', marginRight: '0' } : { marginLeft: '0', marginRight: '10px' }} />
                        <IntlMessages id={item.title} />
                        <span className="float-right">
                          {loading &&
                            allTodoItems.filter(x => item.status ? x.status === item.status : item).length}
                        </span>
                      </NavLink>
                    </NavItem>
                  )
                })

              }

              {/* 
              <NavItem className={classnames({ active: !filter })}>
                <NavLink to="#" onClick={e => this.addFilter("", "")}>
                  <i className="simple-icon-reload" />
                  All Orders
                  <span className="float-right">
                    {loading && allTodoItems.length}
                  </span>
                </NavLink>
              </NavItem>



              <NavItem
                className={classnames({
                  active:
                    filter &&
                    filter.column === "status" &&
                    filter.value === "new"
                })}
              >
                <NavLink
                  to="#"
                  onClick={e => this.addFilter("status", "new")}
                >
                  <i className="simple-icon-refresh" />
                  New Orders
                  <span className="float-right">
                    {loading &&
                      allTodoItems.filter(x => x.status === "new").length}
                  </span>
                </NavLink>
              </NavItem>





              <NavItem
                className={classnames({
                  active:
                    filter &&
                    filter.column === "status" &&
                    filter.value === "accepted"
                })}
              >
                <NavLink
                  to="#"
                  onClick={e => this.addFilter("status", "accepted")}
                >
                  <i className="simple-icon-check" />
                  Accepted Orders
                  <span className="float-right">
                    {loading &&
                      allTodoItems.filter(x => x.status === "accepted").length}
                  </span>
                </NavLink>
              </NavItem>
              <NavItem
                className={classnames({
                  active:
                    filter &&
                    filter.column === "status" &&
                    filter.value === "canceled"
                })}
              >
                <NavLink
                  to="#"
                  onClick={e => this.addFilter("status", "canceled")}
                >
                  <i className="simple-icon-minus" />
                  Canceled Orders
                  <span className="float-right">
                    {loading &&
                      allTodoItems.filter(x => x.status === "canceled").length}
                  </span>
                </NavLink>
              </NavItem> */}
            </ul>
            {/* <p className="text-muted text-small">
              <IntlMessages id="todo.categories" />
            </p> */}
            {/* <ul className="list-unstyled mb-5">
              {categories.map((c, index) => {
                return (
                  <NavItem key={index}>
                    <div onClick={e => this.addFilter("category", c)}>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          defaultChecked={
                            filter &&
                            filter.column === "category" &&
                            filter.value === c
                          }
                        />
                        <label className="custom-control-label">{c}</label>
                      </div>
                    </div>
                  </NavItem>
                );
              })}
            </ul> */}
            {/* <p className="text-muted text-small">
              Category
            </p>
            <div>
              {labels.map((l, index) => {
                return (
                  <p className="d-sm-inline-block mb-1" key={index}>
                    <NavLink
                      to="#"
                      onClick={e => this.addFilter("category", l.label)}
                    >
                      <Badge
                        className="mb-1"
                        color={`${
                          filter &&
                            filter.column === "category" &&
                            filter.value === l.label
                            ? l.color
                            : "outline-" + l.color
                          }`}
                        pill
                      >
                        {l.label.toUpperCase()}
                      </Badge>
                    </NavLink>
                  </p>
                );
              })}
            </div> */}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <NavLink
                to="/app/crm/orderHistory"
              >
                <Button
                  color="primary"
                  size="md"                 
                >
                  <IntlMessages id="orders.to-crm" />
                </Button>
              </NavLink>
            </div>

          </div>
        </PerfectScrollbar>

      </ApplicationMenu>
    );
  }
}

const mapStateToProps = ({ orders, settings }) => {
  const { locale } = settings;
  return {
    orders, locale

  };
};
export default connect(
  mapStateToProps,
  {
    getOrderListWithFilter
  }
)(TodoApplicationMenu);
