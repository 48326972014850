import React, { Component } from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import AppLayout from "../../layout/AppLayout";
// import gogo from "./home/testmonials";
import Dashboard from "./dashboards";
import Offers from './offers.js'
import builder from './menu-builder'
import orders from "./orders"
import { fetchItems } from '../../redux/getItemsReducer'
import { firestore, auth } from 'firebase'
import Settings from './settings'
import SuperAdmin from './SuperAdmin'
import Store from './Store'
import Outlets from './outlets'
import CRM from './CRM'
import Marketing from './marketing'

class App extends Component {

  componentWillMount() {
    let key = localStorage.getItem('res')
    if (key) {
      firestore().collection('resturant').doc(key).collection('menuItems').onSnapshot(snapshot => {
        let allItems = []
        let allItemsMap = {}
        let { categouryMap } = this.props.allItems
        snapshot.forEach(function (change) {
          let data = change.data()
          data.id = change.id
          allItemsMap[change.id] = data
          allItems.push(data)
        });
        allItems = allItems.sort((a, b) => {
          if (a.name && b.name) {
            if (a.name.replace(' ', '').toLowerCase() > b.name.replace(' ', '').toLowerCase()) return 1;
            if (b.name.replace(' ', '').toLowerCase() > a.name.replace(' ', '').toLowerCase()) return -1;
            return 0;
          }
        })
        this.props.fetchItems({ categouryMap, allItems, allItemsMap })
      })
    } else {
      auth().signOut()
      localStorage.removeItem('user_id')
      window.location.href = '/'
    }
  }

  componentWillReceiveProps(prop) {
    let { branch } = this.props
    if (prop.branch && (branch !== prop.branch)) {
      this.getAllMenus(prop.branch).then(results => {
        let { allItems, allItemsMap } = this.props.allItems
        this.props.fetchItems({ categouryMap: results, allItems, allItemsMap })
      })
    }
  }


  getAllMenus = (branch) => new Promise((res, rej) => {
    let key = localStorage.getItem('res')
    if (key && branch) {
      firestore().collection('resturant').doc(key).collection('menus').where('branches', 'array-contains', branch.id).get().then(results => {
        let categouryObject = {}
        let filterArray = []
        results.forEach(item => {
          let data = item.data()
          let { categouries } = data
          if (categouries) {
            Object.keys(categouries).map(categouryKey => {
              let catObject = categouries[categouryKey]
              let { items, name } = catObject
              if (!filterArray.includes(name.trim())) {
                filterArray.push(name.trim())
              }
              items.map(itemKey => {
                categouryObject[itemKey] = name.replace(/\s+/g, '')
              })
            })
            filterArray.push('Out of stock')
          }
        })
        res({ categouryObject, filterArray })
      })
    }
  })
  render() {
    const { match } = this.props;
    return (
      <AppLayout>
        <Switch>
          <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
          <Route path={`${match.url}/dashboard`} component={Dashboard} />
          <Route path={`${match.url}/orders`} component={orders} />
          <Route path={`${match.url}/offers`} component={Offers} />
          <Route path={`${match.url}/settings`} component={Settings} />
          <Route path={`${match.url}/store`} component={Store} />
          <Route path={`${match.url}/outlets`} component={Outlets} />
          <Route path={`${match.url}/crm`} component={CRM} />
          <Route path={`${match.url}/menu-builder`} component={builder} />
          <Route path={`${match.url}/admin`} component={SuperAdmin} />
          <Route path={`${match.url}/marketing`} component={Marketing} />


          {/* <Route path={`${match.url}/blank-page`} component={blankPage} /> */}
          <Redirect to="/error" />
        </Switch>
      </AppLayout>
    );
  }
}
const mapStateToProps = ({ menu, allItems, settings }) => {
  const { containerClassnames } = menu;
  const { branch } = settings

  return { containerClassnames, allItems, branch };
};

export default withRouter(
  connect(
    mapStateToProps,
    { fetchItems }
  )(App)
);
