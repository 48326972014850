import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Label,
  Row, Card, CardBody, Table, ButtonGroup, CustomInput, ModalHeader
} from "reactstrap";
import { firestore, functions } from 'firebase';
import { Separator, Colxx } from "../../components/common/CustomBootstrap";
import { addOrderItem, updateOrderItem } from "../../redux/actions";
import { NotificationManager } from "../../components/common/react-notifications";
import Moment from 'moment'
import ReactToPrint from "react-to-print";
import IntlMessages from "../../helpers/IntlMessages";

function dateFormatter(timeStamp) {
  var date = new Date(timeStamp);
  return date.toLocaleDateString("en-US") + " " + date.toLocaleTimeString("en-US")
}
class ManageOrdersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      number: 0,
      address: '',
      area: '',
      id: '',
      status: '',
      category: '',
      items: [],
      preparationTime: 15,
      deliveryTime: 40,
      color: { new: '#dc3545', accepted: '#ffc107', canceled: '#8f8f8f', delivery: 'var(--theme-color-1)', delivered: '#28a745' }
    };
  }
  componentWillMount() {
    let { settings } = this.props
    let { preparationTime } = this.state
    let item = this.props.editItem
    preparationTime = settings.allBranches[settings.branch.id].preparationTime && settings.allBranches[settings.branch.id].preparationTime.value ? settings.allBranches[settings.branch.id].preparationTime : { value: settings.allBranches[settings.branch.id].preparationTime ? settings.allBranches[settings.branch.id].preparationTime : 15, label: 'mins' }

    if (!item) { return }
    this.setState({
      name: item.user.name,
      number: item.user.number,
      address: item.user.address,
      area: item.user && item.user.area ? item.user.area : '',
      id: item.id,
      status: item.status,
      category: item.category,
      items: item.items,
      loaded: true,
      preparationTime
    })
  }
  acceptOrder = (status) => {
    let { preparationTime, deliveryTime, requestDelivery } = this.state;
    let { loyalty, jeebly } = this.props;
    const item = this.props.editItem
    switch (status) {
      case 'new':
        item.status = "accepted"
        // if (this.state.address) {
        //   item.user.address = this.state.address
        // }
        item.accepted = Date.now()
        item.deliveryTime = deliveryTime
        item.preparationTime = preparationTime
        item.updated = Date.now()
        if (requestDelivery) {
          var requestDeliveryRequest = functions().httpsCallable('requestJeebly');
          requestDeliveryRequest({ item: item, restaurantInfo: jeebly }).then(function (result) {
            console.log('resultresult', result)
          })
        }

        this.props.updateOrderItem(item);
        this.setState({ acceptOrderModal: false })
        break;

      case 'accepted':
        if (item.receipt.type === 'dineIn') {
          item.status = "delivered"
          item.delivered = Date.now()
          item.updated = Date.now()
          if (loyalty) {
            let points = item.receipt.pointsPalance ? item.receipt.pointsPalance : 0;
            let deductedValue = item.receipt.deductedValue ? item.receipt.deductedValue : 0
            points = (((Number(points) ? Number(points) : 0) + ((item.receipt.subTotal - deductedValue) * (loyalty.percent / 100))).toFixed(2)) * 1;
            let exp = Moment().add('days', loyalty.expiration).format('x') * 1;
            let resKey = localStorage.getItem('res')
            firestore().collection('resturant').doc(resKey).collection('loyalty').doc(item.userUid).set({
              points,
              exp,
              lastOrder: item.id
            }, { merge: true })
          }
          this.props.updateOrderItem(item);
          break;
        } else {
          item.status = "delivery"
          item.deliveryStamp = Date.now()
          item.updated = Date.now()
          this.props.updateOrderItem(item);
          break;
        }
      case 'delivery':
        item.status = "delivered"
        item.delivered = Date.now()
        item.updated = Date.now()
        if (loyalty) {
          let points = item.receipt.pointsPalance ? item.receipt.pointsPalance : 0;
          let deductedValue = item.receipt.deductedValue && Number(item.receipt.deductedValue) ? Number(item.receipt.deductedValue) : 0
          points = (((Number(points) ? Number(points) : 0) + ((item.receipt.subTotal - deductedValue) * (loyalty.percent / 100))).toFixed(2)) * 1;
          let exp = Moment().add('days', loyalty.expiration).format('x') * 1;
          let resKey = localStorage.getItem('res')
          let referal = item.receipt.referal
          let cashBack = loyalty.cashBack
          if (referal && cashBack) {
            firestore().collection('resturant').doc(resKey).collection('loyalty').doc(referal).update({
              points: firestore.FieldValue.increment((cashBack * 1) || 0),
              notif: {
                from: item.user.name,
                id: item.userUid,
                valu: cashBack,
              }
            })
          }
          firestore().collection('resturant').doc(resKey).collection('loyalty').doc(item.userUid).set({
            points,
            exp,
            lastOrder: item.id
          }, { merge: true })
        }

        this.props.updateOrderItem(item);
        // if (item.receipt.paymentType === 'online') {
        //   var makeCharge = functions().httpsCallable('CreateCharge');
        //   let { receipt, user, authId } = item
        //   makeCharge({ receipt: receipt, user: user, authId: authId }).then(function (result) {
        //     console.log("resultresult1", result)
        //   })
        // }
        break;
      default: break;
    }

    NotificationManager.success(
      "",
      "order status changed to " + item.status,
      4000,
      null,
      null,
    )
  };
  cancelOrder = () => {
    const item = this.props.editItem
    item.status = "canceled"
    item.canceled = Date.now()
    item.updated = Date.now()
    this.props.updateOrderItem(item);
    // this.dismiss()
  };
  dismiss() {
    this.props.toggleModal();
  }

  toggleNested = () => {
    this.setState(prevState => ({ nestedModal: !prevState.nestedModal }));
  };
  updateAddress = () => {
    let { address } = this.state
    let { editItem } = this.props
    let userId = editItem.user.key
    let _this = this;
    firestore().collection('users').doc(userId).update({
      fullAddress: address
    }).then(() => {
      _this.setState({ nestedModal: false });
    })
  };

  renderSendToKitchen() {
    let { receipt } = this.props.editItem
    let { type } = receipt

    let { preparationTime, deliveryTime } = this.state


    return (
      <Modal isOpen={true}
        backdrop="static">
        <ModalBody>
          <Row className='justify-content-center mb-1'>
            <Label className='font-weight-bold'><IntlMessages id={"orders.estimated-preparation-time"} /></Label>

          </Row>
          <Row className="mb-5 justify-content-center">
            <ButtonGroup >
              <Button color="primary" style={{ fontSize: '24px' }} onClick={() => {
                preparationTime.value = preparationTime.label === 'mins' ? Number(preparationTime.value) - 5 : Number(preparationTime.value) - 1
                this.setState({ preparationTime })
              }}>-</Button>
              <Button outline color="primary">
                <div className='font-weight-bold'>
                  {preparationTime.value}
                </div>
                <div>{preparationTime.label}</div>
              </Button>
              <Button color="primary" style={{ fontSize: '24px' }} onClick={() => {
                preparationTime.value = preparationTime.label === 'mins' ? Number(preparationTime.value) + 5 : Number(preparationTime.value) + 1

                this.setState({ preparationTime })
              }}>+</Button>
            </ButtonGroup>
          </Row>


          {
            type !== 'dineIn' && type !== 'pickup' &&
            <>
              <Row className='justify-content-center mb-1' >
                <Label className='font-weight-bold'>{receipt.type && receipt.type === 'pickup' ? <IntlMessages id={"orders.estimated-pickup-time"} /> : <IntlMessages id={"orders.estimated-delivery-time"} />}</Label>

              </Row>
              <Row className="mb-4 justify-content-center">
                <ButtonGroup >
                  <Button color="primary" style={{ fontSize: '24px' }} onClick={() => this.setState({ deliveryTime: deliveryTime - 5 })}>-</Button>
                  <Button outline color="primary">
                    <div className='font-weight-bold'>
                      {deliveryTime}
                    </div>
                    <div> <IntlMessages id={"orders.mins"} /> </div>

                  </Button>
                  <Button color="primary" style={{ fontSize: '24px' }} onClick={() => this.setState({ deliveryTime: deliveryTime + 5 })}>+</Button>
                </ButtonGroup>
              </Row>
            </>
          }
          {
            type === 'delivery' && this.props.jeebly && <>
              <CustomInput
                className=" mb-0"
                type="checkbox"
                id='requestJeeply'
                checked={this.state.requestDelivery}
                onClick={() => {
                  this.setState({ requestDelivery: !this.state.requestDelivery })
                }}
                label={<IntlMessages id={"orders.request-jeebly"} />}
              />
            </>

          }



        </ModalBody>
        <ModalFooter>
          <Button outline color='danger' onClick={() => this.setState({ acceptOrderModal: false })}><IntlMessages id={"orders.cancel"} /></Button>

          <Button color='primary' onClick={() => this.acceptOrder('new')}><IntlMessages id={"orders.accept-order"} /></Button>
        </ModalFooter>
      </Modal>
    )
  }

  getModifierPrice(item) {

    if (item.extra && item.extra.length) {
      let price = item.price * 1

      item.extra.map((extraIndex, index) => {
        return (
          Object.keys(extraIndex).map((categoryKey, categoryIndex) => {
            return (
              extraIndex[categoryKey].map(innerItem => {
                return (
                  price = price + (innerItem.price * 1)
                )
              })

            )
          })
        )
      })

      return price
    } else {
      return item.totalPrice
    }
  }
  render() {
    let { color, acceptOrderModal } = this.state
    let { items, user, status, receipt, id, created, coupon } = this.props.editItem
    let { currency, loyalty } = this.props
    let direction = localStorage.getItem("languageDirection") === "rtl"
    return (
      <>
        <ReactToPrint
          trigger={() => <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '8px' }}><span className='iconsminds-printer' style={{ cursor: 'pointer', fontSize: '16px' }}></span></div>}
          content={() => this.componentRef}
        />
        <div ref={el => (this.componentRef = el)} >
          <Card className="rounded mt-4 ml-4 reciptMobileContainerCard"
            style={direction ? { direction: "rtl", textAlign: 'justify' } : {}}

          >
            <div>
              {
                this.props.editItem &&

                <Row className="invoice-react">
                  <div className="ordersBackButton no-print" onClick={this.props.dismiss}><IntlMessages id={"orders.back"} /></div>
                  <Colxx xxs="12" >
                    <div style={{ padding: '28px 0 0 ' }}>
                      <div className="d-flex flex-column flex-lg-row justify-content-between w-100 align-items-center" style={{ padding: '12px 30px' }}>
                        <div>
                          {/* <Label className="mb-0 text-semi-muted">{direction ? "فاتورة #" : "Invoice #: "}</Label> */}
                          <p className="font-weight-bold m-0 mb-2"> {id}</p>
                        </div>
                        <div >
                          {/* <Label className="mb-0 text-semi-muted">{direction ? "وقت الطلب: " : "Created On: "}</Label> */}
                          <p className="font-weight-bold m-0 mb-2">{dateFormatter(created)}</p>
                        </div>
                        <div className='badge badge-pill mb-2' style={{ backgroundColor: color[status], color: 'white' }} >{status}</div>
                      </div>
                    </div>
                    <Separator />
                    <Card className="invoice-contents" style={{ height: 'auto', background: 'transparent', boxShadow: 'none' }}>
                      <CardBody className="d-flex flex-column justify-content-between">
                        <div className="d-flex flex-column">
                          <Row className=" justify-content-between mb-5 ">
                            <Colxx sm={6}>
                              <div className="d-flex flex-column w-100 p-2  ">
                                <div >
                                  <Label className="mb-0  text-semi-muted" text-capitalize><IntlMessages id={"orders.customer-name"} />:</Label>
                                  <p className="font-weight-bold">{user.name}</p>
                                </div>
                                <div >
                                  <Label className="mb-0 text-semi-muted"><IntlMessages id={"orders.phone-number"} />:</Label>
                                  <p className="font-weight-bold">{user.number}</p>
                                </div>
                                <div >
                                  {
                                    receipt.type && receipt.type === 'pickup' ?
                                      <div>
                                        <Label className="mb-0 text-semi-muted"><IntlMessages id={"orders.pickup-from"} />:</Label>
                                        <p className="font-weight-bold">{receipt.selectedAddress && receipt.selectedAddress.area}</p>
                                      </div>
                                      : receipt.type === 'dineIn' ?
                                        <div>
                                          <Label className="mb-0 text-semi-muted"><IntlMessages id={"orders.dine-in"} />:</Label>
                                          <p className="font-weight-bold">{receipt.selectedAddress && "Table # : " + receipt.selectedAddress.location}</p>
                                        </div>
                                        :
                                        <div>
                                          <Label className="mb-0 text-semi-muted"><IntlMessages id={"orders.delivery-to"} />:</Label>
                                          <p className="font-weight-bold">{receipt.selectedAddress && [receipt.selectedAddress.fullAddress, (receipt.selectedAddress.area && receipt.selectedAddress.area.name ? receipt.selectedAddress.area.name : receipt.selectedAddress.area), receipt.selectedAddress.notes].join(' - ')}</p>
                                        </div>
                                  }
                                </div>
                                {/* <div>
                              <Button size='sm' color="info" onClick={this.toggleNested}>{direction ? "تعديل" : "Edit"}</Button>
                            </div> */}
                              </div>

                            </Colxx>

                            <Colxx sm={6}>
                              <div className="d-flex flex-column w-100 p-2  ">
                                <div >
                                  <Label className="mb-0 text-semi-muted" text-capitalize><IntlMessages id={"orders.branch"} />:</Label>
                                  <p className="font-weight-bold">{receipt.branch.map(item => window.branches[item].name).join(' - ')}</p>
                                </div>
                                <div >
                                  <Label className="mb-0 text-semi-muted" text-capitalize><IntlMessages id={"orders.payment-method"} />:</Label>
                                  <p className="font-weight-bold">{['online', 'applePay'].includes(receipt.paymentType) ? 'Online Payment' : (receipt.paymentType ? receipt.paymentType : 'cash ') + (receipt.type === 'dineIn' ? ' on leave' : ' on delivery')}</p>
                                </div>
                                {

                                  receipt && receipt.coponeType === 'loyalty' && <div >

                                    <Label className="mb-0 text-semi-muted" text-capitalize><IntlMessages id={"orders.loyalty"} />:</Label>
                                    <p className="font-weight-bold"><IntlMessages id={"orders.points-balance"} /> {receipt.pointsPalance}</p>
                                  </div>
                                }
                              </div>

                            </Colxx>

                          </Row>
                          {/* <Modal
                        isOpen={this.state.nestedModal}
                        toggle={this.toggleNested}
                      >
                        <ModalHeader>Edit Address</ModalHeader>
                        <Input value={this.state.address} onChange={(e) => this.setState({ address: e.target.value })} />
                        <ModalFooter>
                          <Button color="danger" onClick={this.toggleNested}>
                            Dismiss
                         </Button>{" "}
                          <Button color="primary" onClick={this.updateAddress}>
                            Done
                         </Button>{" "}
                        </ModalFooter>
                      </Modal> */}
                          {
                            receipt.specialNotes && <Row className='mb-5'>
                              <div className='col-sm-6'>
                                <div className='p-2'>
                                  <Label className="mb-0 text-semi-muted" text-capitalize><IntlMessages id={"orders.orders-notes"} />:</Label>
                                  <p className="font-weight-bold">{receipt.specialNotes}</p>
                                </div>

                              </div>

                            </Row>
                          }

                          <Table borderless>
                            <thead>
                              <tr>
                                <th className="text-muted text-extra-small mb-2">
                                  <IntlMessages id={"orders.item-name"} />
                                </th>
                                <th className="text-muted text-extra-small mb-2">
                                  <IntlMessages id={"orders.count"} />
                                </th>
                                <th className="text-right text-muted text-extra-small mb-2">
                                  <IntlMessages id={"orders.price"} />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                items.map((item, itemIndex) => {

                                  return (
                                    <tr key={itemIndex}>
                                      <td>
                                        <div style={{ fontSize: '14px' }}>
                                          {direction ? item.name_ar : item.name}
                                        </div>
                                        {
                                          item.extra && item.extra.map((extraIndex, index) => {
                                            return (
                                              Object.keys(extraIndex).map((categoryKey, categoryIndex) => {
                                                let duplicates = []
                                                return (
                                                  <div key={categoryIndex} style={{ padding: '2px 8px' }}>
                                                    <div style={{ color: 'gray', fontSize: '11px' }}>
                                                      {categoryKey + ":"}
                                                    </div>
                                                    <div style={{ color: 'black', fontSize: '12px', fontWeight: '500' }}>
                                                      {
                                                        extraIndex[categoryKey].map((innerItem, pos) => {
                                                          let extraCount = extraIndex[categoryKey].filter(rec => rec.name === innerItem.name).length
                                                          if (!duplicates.includes(innerItem.name)) {
                                                            duplicates.push(innerItem.name)
                                                            return (
                                                              direction ? <div><span style={{ fontWeight: '700' }}>{extraCount + 'x '}</span>{innerItem.name_ar}</div> : <div><span style={{ fontWeight: '700' }}>{extraCount + 'x '}</span>{innerItem.name}</div>
                                                            )
                                                          }
                                                          return false
                                                        })
                                                      }
                                                    </div>
                                                  </div>

                                                )
                                              })
                                            )
                                          })
                                        }
                                      </td>
                                      <td>{item.count}</td>


                                      <td className="text-right">
                                        {

                                          (item.price === "0" && item.extra) ?
                                            <div>
                                              {
                                                this.getModifierPrice(item).toFixed(2) + " " + currency
                                              }
                                            </div>
                                            :
                                            <div>
                                              <div className="text-right">{(item.price * item.count).toFixed(2) + " " + currency}</div>


                                              {
                                                item.extra && item.extra.map((extraIndex, index) => {
                                                  return (
                                                    Object.keys(extraIndex).map((categoryKey, categoryIndex) => {
                                                      let duplicates = []
                                                      return (
                                                        <div key={categoryIndex}>
                                                          <div>-</div>
                                                          <div style={{ color: 'black', fontSize: '12px', fontWeight: '500' }}>
                                                            {
                                                              extraIndex[categoryKey].map((innerItem, pos) => {
                                                                let extraCount = extraIndex[categoryKey].filter(rec => rec.name === innerItem.name).length
                                                                if (!duplicates.includes(innerItem.name)) {
                                                                  duplicates.push(innerItem.name)
                                                                  return (
                                                                    <div><span style={{ fontWeight: '700' }}></span>{innerItem.price !== "0" ? (Number(innerItem.price) * extraCount).toFixed(2) + " " + currency : "-"}</div>
                                                                  )
                                                                }
                                                                return false
                                                              })
                                                            }
                                                          </div>
                                                        </div>

                                                      )
                                                    })
                                                  )
                                                })
                                              }
                                            </div>

                                        }
                                      </td>
                                    </tr>
                                  )
                                })
                              }

                            </tbody>
                          </Table>
                        </div>
                        <div className="d-flex flex-column">
                          <div className="border-bottom pt-3 mb-5" />
                          <Table borderless className="d-flex justify-content-end">
                            <tbody>
                              <tr>
                                <td className="text-semi-muted"><IntlMessages id={"orders.subtotal"} />:</td>
                                <td className="text-right">{(receipt.subTotal - receipt.vat).toFixed(2) + ' ' + currency}</td>
                              </tr>
                              {
                                receipt.vat !== '0.00' ?
                                  <tr>

                                    <td className="text-semi-muted"><IntlMessages id={"orders.vat"} />:</td>
                                    <td className="text-right">{receipt.vat + ' ' + currency}</td>
                                  </tr>
                                  :
                                  <div />
                              }

                              {(receipt.type === 'pickup' || !receipt?.delivery ?
                                '' :
                                <tr>
                                  <td className="text-semi-muted">{(receipt.type === 'pickup' ?
                                    <IntlMessages id={"orders.pickup"} /> :
                                    <IntlMessages id={"orders.delivery"} />
                                  )
                                  }</td>
                                  <td className="text-right">{receipt.delivery + ' ' + currency}</td>
                                </tr>
                              )
                              }
                              {
                                coupon ?
                                  <tr>
                                    <td className="text-semi-muted"><IntlMessages id={"orders.discount"} />:</td>
                                    <td className="text-right">
                                      {(coupon.type === 'oneForOne') ?
                                        ('Free ' + coupon.value + ' -' + receipt.deductedValue + ' ' + currency)
                                        : (receipt.deductedValue + ' ' + currency)}
                                    </td>
                                  </tr>
                                  : receipt.coponeType === 'loyalty' ?
                                    <tr>
                                      <td className="text-semi-muted"><IntlMessages id={"orders.discount-loyalty"} />:</td>
                                      <td className="text-right">
                                        {(receipt.deductedValue + ' ' + currency)}
                                      </td>
                                    </tr>
                                    : ''
                              }
                              <tr className="font-weight-bold">
                                <td className="text-semi-muted"><IntlMessages id={"orders.total"} />:</td>
                                <td className="text-right">{receipt.total + ' ' + currency}</td>
                              </tr>
                            </tbody>
                          </Table>
                          <div className="border-bottom p-3 mb-5" />
                          <p className="text-muted text-small text-center no-print">

                            <Button disabled={status === 'delivered' || status === 'canceled'} className='m-1 float-right' color="primary"
                              onClick={() => {
                                status === 'new' ?
                                  this.setState({ acceptOrderModal: true })
                                  :
                                  this.acceptOrder(status)
                              }}>
                              {
                                status === 'new' ?
                                  <IntlMessages id={"orders.accept"} />
                                  : status === 'accepted' ?
                                    (receipt.type === 'pickup' ?
                                      <IntlMessages id={"orders.ready-for-pickup"} />
                                      : receipt.type === 'dineIn' ?
                                        <IntlMessages id={"orders.send-to-table"} />
                                        :
                                        <IntlMessages id={"orders.send-for-delivery"} />
                                    )
                                    : status === 'delivery' ?
                                      (receipt.type === 'pickup' ?
                                        <IntlMessages id={"orders.confirm-pickup"} />

                                        :
                                        <IntlMessages id={"orders.confirm-delivery"} />

                                      )
                                      : <IntlMessages id={"orders.canceled"} />
                              }
                              {/* {direction ? "قبول" : "Accept"} */}
                            </Button>
                            <Button disabled={status === 'delivered' || status === 'canceled'} className='m-1 float-left' color="danger" outline onClick={() =>
                              this.setState({ rejectModal: true })
                              // this.cancelOrder()
                            }>
                              <IntlMessages id={"orders.reject-order"} />
                            </Button>

                          </p>
                        </div>
                      </CardBody>
                    </Card>
                  </Colxx>
                </Row>
              }

            </div>
            {console.log("rejectModal", this.state.rejectModal)}
            {
              this.state.rejectModal && this.renderRejetModal()
            }

            {
              acceptOrderModal && this.renderSendToKitchen()
            }
          </Card>
        </div>
      </>
    );
  }
  renderRejetModal = () => {
    return (
      <Modal isOpen={true}>
        <ModalHeader>

        </ModalHeader>
        <ModalBody>
          <div><IntlMessages id={"orders.msg-reject-order"} /></div>

        </ModalBody>
        <ModalFooter>
          <Button color='danger' onClick={() => this.setState({ rejectModal: false })}><IntlMessages id={"orders.dismiss"} /></Button>
          <Button color='primary' onClick={() => this.setState({ rejectModal: false }, () => this.cancelOrder())}><IntlMessages id={"orders.reject-order"} /></Button>
        </ModalFooter>
      </Modal>
    )


  }
}


const mapStateToProps = ({ orders, settings }) => {
  return {
    orders,
    settings,
    currency: settings.currency ? settings.currency : 'AED',
    country: settings.country ? settings.country : 'UAE',
    loyalty: settings.loyalty ? settings.loyalty : false,
    jeebly: settings.jeebly ? settings.jeebly : false,
  };
};
export default connect(
  mapStateToProps,
  {
    addOrderItem,
    updateOrderItem
  }
)(ManageOrdersModal);
