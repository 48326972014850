import React, { Component, Fragment } from "react";
import { Row, Card, CardBody } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import DataTablePagination from "../../../components/DatatablePagination";
import moment from "moment";
import { firestore } from 'firebase';
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import {
  getOrderList,
  getOrderListWithOrder,
  getOrderListSearch,
  selectedOrderItemsChange,
  deleteOrderItem,
  getOrderListWithFilter

} from "../../../redux/actions";


const dataTableColumns = [
  {
    Header: "Name",
    accessor: "name",
    width: 230,
    Cell: props => <p className="list-item-heading">{props.value}</p>
  },
  {
    Header: "Vouchers",
    accessor: "offers",
    Cell: props => <p className="list-item-heading">{props.value}</p>
  },

  {
    Header: "Total Orders",
    accessor: "orders",
    Cell: props => <p className="list-item-heading">{props.value}</p>
  },
  {
    Header: "Total Sales",
    accessor: "sales",
    Cell: props => <p className="list-item-heading">{props.value}</p>
  },
  {
    Header: "Last Order",
    accessor: "lastOrder",
    Cell: props => <div className="list-item-heading" >{props.value && moment(props.value).format("DD/MM/YYYY")}{props.value && <div style={{ fontSize: '10px' }}>{moment(props.value).format("hh:mmA")}</div>}</div>
  },
  {
    Header: "Saved Commission",
    accessor: "commission",
    Cell: props => <div className="list-item-heading" >{props.value}</div>
  },
  {
    Header: "Last Session",
    accessor: "lastSession",
    Cell: props => <div className="list-item-heading" >{props.value && moment(props.value).format("DD/MM/YYYY")}{props.value && <div style={{ fontSize: '10px' }}>{moment(props.value).format("hh:mmA")}</div>}</div>
  },
  {
    Header: "Sessions Count",
    accessor: "sessionsCount",
    Cell: props => <div className="list-item-heading" >{props.value}</div>
  },
];

class OrderHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allRes: [],
      branches: [],
      selected: null,
      allOrders: [],
      sales: 0,
      avgSales: 0,
      modalBack: false,
    };
  }

  componentDidMount() {
    let { allRes } = this.state
    let _this = this
    firestore().collection('res').get().then(res => {
      res.forEach(result => {
        let resturantObject = { name: '', sales: 0, orders: 0, offers: 0 }
        resturantObject['name'] = result.data().title
        firestore().collection('admins').where('res', '==', result.data().id).get().then(results => {
          results.forEach(admin => {
            firestore().collection('users').doc(admin.id).get().then(user => {
              if (user && user.data() && user.data().lastSession) {
                resturantObject['lastSession'] = user.data().lastSession.date
                resturantObject['sessionsCount'] = user.data().lastSession.sessionsCount
              }
            })
          })
        }).then(() => {
          firestore().collection('resturant').doc(result.data().id).collection('orders').get().then(order => {
            let sales = 0
            let ordersArray = []
            order.forEach(orderResult => {
              if (orderResult.data().status !== 'canceled') {
                ordersArray.push(orderResult.data())
                sales += parseInt(orderResult.data().receipt.total)
                resturantObject['sales'] = sales
                resturantObject['commission'] = sales * 0.25
                resturantObject['lastOrder'] = Math.max.apply(Math, ordersArray.map(function (o) { return o.created; }))
              }
            })
            resturantObject['orders'] = order.size
            firestore().collection('resturant').doc(result.data().id).collection('offers').get().then(offer => {
              resturantObject['offers'] = offer.size
              allRes.push(resturantObject)
              if (res.size === allRes.length) {
                _this.setState({ allRes, loading: true })

              }

            })
          })

        })






      })
    })
  }


  render() {
    let { allRes, sales, avgSales } = this.state
    let { currency } = this.props
    let lastSessionArray = allRes.filter(item => { return item.lastSession })
    if (!allRes.length || !allRes[0].sales || !lastSessionArray.length) {
      return (
        <div>
          {

            <div className="d-flex flex-row mb-3 pb-3 text-center justify-content-center ">
              <div className='font-weight-bold'>No Resturants yet!</div>
            </div>
          }
        </div>
      )
    }

    sales = 0
    avgSales = 0
    let totalOrders = 0
    allRes.map(item => {
      totalOrders = totalOrders + item.orders;
      sales = sales + item.sales;
      if (item.sales > 0) {
        avgSales += (parseInt(item.sales) / parseInt(item.orders))
      }
    })
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <div className="mb-2"><h1>Clients</h1></div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12">
            <Card className="mb-4 rounded">
              <Row className="mt-4 pl-4 pr-4">
                <Colxx xxs="4" className='d-flex flex-column justify-content-center align-items-center'>
                  <div className="salesTitle">Revenue</div>
                  <div className="salesValue">{currency + ' ' + sales.toFixed(2)}</div>
                </Colxx>
                <Colxx xxs="4" className='d-flex flex-column justify-content-center align-items-center'>
                  <div className="salesTitle">Average order value</div>
                  <div className="salesValue">{currency + ' ' + ((avgSales / allRes.length).toFixed(2))}</div>
                </Colxx>
                <Colxx xxs="4" className='d-flex flex-column justify-content-center align-items-center'>
                  <div className="salesTitle">Total orders</div>
                  <div className="salesValue">{totalOrders}</div>
                </Colxx>
              </Row>
              <Separator className="mb-4 mt-4" />
              <CardBody style={{ paddingTop: '0' }}>
                <ReactTable
                  data={allRes}
                  columns={dataTableColumns}
                  defaultPageSize={10}
                  showPageJump={true}
                  PaginationComponent={DataTablePagination}
                  showPageSizeOptions={true}
                />
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}
const mapStateToProps = ({ orders, settings }) => {
  return {
    orders,
    allBranches: settings.allBranches,
    currency: settings.currency
  };
};
export default injectIntl(
  connect(
    mapStateToProps,
    {
      getOrderList,
      getOrderListWithOrder,
      getOrderListSearch,
      selectedOrderItemsChange,
      deleteOrderItem,
      getOrderListWithFilter
    }
  )(OrderHistory)
);
