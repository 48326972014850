import React, { useEffect, useState } from 'react';
import StoreDetails from './steps/storeDetails'
import AccountDetails from './steps/accountDetails'
import PlanDetails from './steps/planDetails'
import Checkout from './checkout.js'
import './index.css'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { functions } from 'firebase';
const stripePromise = loadStripe("pk_test_51KCVhHLUw2xbx9vJIuCWjLiU7CVKnswXAVvjs4owXLo0Gzvqd32qGyQRLyQyvEDQe4QceVru3mB3z7nKFB4Gf1kj00IC8llpCz");

const Index = () => {
    const [step, setStep] = useState(1)
    const [state, setState] = useState({});
    const [clientSecret, setClientSecret] = useState("");
    // useEffect(() => {
    //     // Create PaymentIntent as soon as the page loads
    //     var createRes = functions().httpsCallable('createPaymentSessionStripe');
    //     createRes({
    //         items: [
    //             {
    //                 price: "price_1KQpDrLUw2xbx9vJ2wjbHvhR",
    //             }, {
    //                 price: "price_1KU4vWLUw2xbx9vJVhrovu8X",
    //             }],
    //         customer: {
    //             id: 'cus_LAOU0LoJtuJpLZ'
    //         }
    //     })
    //         .then((result) => {
    //             let { data } = result
    //             console.log("result.data", result);
    //             setClientSecret(data.clientSecret)
    //         });
    // }, []);
    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };
    console.log("statestatestate", state);
    return (
        <div className='register'>
            {step === 1 ? <StoreDetails setStep={(e) => setStep(e)} state={state} setState={(newState) => setState({ ...state, ...newState })} /> :
                step === 2 ? <AccountDetails setStep={(e) => setStep(e)} state={state} setState={(newState) => setState({ ...state, ...newState })} /> :
                    ''}
            {/* <PlanDetails setStep={(e) => setStep(e)} /> */}
            {/* {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <Checkout />
                </Elements>
            )} */}
        </div>
    );
};

export default Index;