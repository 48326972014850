import React, { Component } from "react";
import { Card } from "reactstrap";

import { getDirection } from "../../../helpers/Utils";

class StoreItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }



    render() {
        let { storeItems, selectedIndex } = this.props
        let _this = this
        const direction = getDirection();

        return (
            <Card style={{ background: '#f8f8f8', boxShadow: 'none' }}>
                {storeItems.map((item, index) => {
                    return (
                        (selectedIndex === index) ? (
                            <div key={index} className="storeItemContainer storeItemActive mb-2" onClick={() => {
                                _this.props.selectItem(selectedIndex)
                            }} >{direction.isRtl ? item.title_ar : item.title}</div>

                        ) : (
                            <div key={index} className="storeItemContainer mb-2" onClick={() => {
                                _this.props.selectItem(index)
                            }}>{direction.isRtl ? item.title_ar : item.title}</div>
                        )
                    )
                })}
            </Card>
        );
    }
}

export default (StoreItems);
