import React, { useState } from "react";
import { Card, Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ContextMenuTrigger } from "react-contextmenu";
import { Colxx } from "../../../../components/common/CustomBootstrap";
import illustrationImage from '../../../../assets/css/img/outlet.png'
import { firestore, functions } from 'firebase'
import { NotificationManager } from "../../../../components/common/react-notifications";
import Switch from "rc-switch";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import './outletitem.css'
import IntlMessages from "../../../../helpers/IntlMessages";
import { getDirection } from "../../../../helpers/Utils";

const DatepickerInput = ({ ...props }) => (
  <input type="text" {...props} readOnly />
);
const OutletItemView = ({ branch, onClickItem, updateBranch, updateBranches }) => {
  const [confirmDeleteItem, setConfirmDeleteItem] = useState(false);
  const [activateBranch, setActivateBranch] = useState(false);
  const [deactivateDuration, setDuration] = useState(Date.now());
  let resKey = localStorage.getItem('res')
  const direction = getDirection();

  function removeItemFromList(branch) {
    firestore().collection('resturant').doc(resKey).update({
      [`branches.${branch.key}`]: firestore.FieldValue.delete()
    }).then(() => {
      NotificationManager.success(
        "",
        "Branch Deleted Successfully",
        3000,
        null,
        null,
      )
      setConfirmDeleteItem(false)
      updateBranches(branch.key)
    })
  }
  function deactivateBranch(branch) {
    branch.value.active = { active: false }
    firestore().collection('resturant').doc(resKey).update({
      [`branches.${branch.key}.active`]: { active: false, duration: deactivateDuration }
    }).then(() => {
      var deactivate = functions().httpsCallable('deactivateResturant');
      deactivate({ duration: deactivateDuration, resId: resKey, branch: branch.key })
        .then((result) => {
          var sanitizedMessage = result.data;
          console.log('sanitizedMessage', sanitizedMessage)
        });

      NotificationManager.success(
        "",
        "Branch Deactivated Successfully",
        3000,
        null,
        null,
      )
      setActivateBranch(false)
      updateBranch(branch.key, branch)
    })
  }
  return (
    <Colxx xxs="12" key={branch.id} className="mb-3" >
      <ContextMenuTrigger id="menu_id" data={branch.key}>
        <Card className="d-flex flex-row rounded overflow-hidden "
        // onClick={() => onClickItem(branch)}
        >

          <div className="d-flex flex-grow-1 min-width-zero align-items-center">
            <Switch
              className="custom-switch custom-switch-primary ml-4"
              checked={branch.value.active ? branch.value.active.active : true}
              onChange={switchCheckedPrimary => {
                console.log(switchCheckedPrimary)

                if (!switchCheckedPrimary) {
                  setActivateBranch(true)
                  console.log(activateBranch)
                } else {
                  branch.value.active = { active: true }
                  firestore().collection('resturant').doc(resKey).update({
                    [`branches.${branch.key}.active`]: { active: true }
                  }).then(() => {
                    NotificationManager.success(
                      "",
                      "Branch Activated Successfully",
                      3000,
                      null,
                      null,
                    )
                    updateBranch(branch.key, branch)
                  })
                }
              }}
            />
            <img
              alt={branch.value.name}
              src={illustrationImage}
              className="list-thumbnail responsive border-0 card-img-left m-4 "
              style={{ height: '45px' }}
            />
            <div className="pl-2 d-flex flex-grow-1 align-items-center min-width-zero p-2">
              <p className="list-item-heading truncate mb-0">
                {direction.isRtl && branch.value.name_ar !== '' ? branch.value.name_ar : branch.value.name}
              </p>

              <div className="w-15 w-sm-100">
                <Badge color={branch.statusColor} pill>
                  {branch.status}
                </Badge>
              </div>
            </div>
            <div className='d-flex justify-content-end p-3'>
              <Button outline color='danger' size='xs' className='m-1' onClick={() => { setConfirmDeleteItem(true) }}><IntlMessages id={"menus.delete"} /></Button>
              <Button outline color='dark' size='xs' className='m-1' onClick={() => { onClickItem(branch) }}><IntlMessages id={"menus.edit"} /></Button>
            </div>
          </div>
        </Card>
      </ContextMenuTrigger>
      {
        confirmDeleteItem && <Modal isOpen={true}>
          <ModalHeader><IntlMessages id={"menus.are-you-sure"} /> {branch.value.name}</ModalHeader>
          <ModalFooter>
            <Button outline color='danger' className='m-1' onClick={() => setConfirmDeleteItem(false)}><IntlMessages id={"menus.cancel"} /></Button>
            <Button color='primary ' className='m-1' onClick={() => removeItemFromList(branch)}><IntlMessages id={"menus.confirm"} /></Button>
          </ModalFooter>
        </Modal>
      }
      {
        activateBranch && <Modal isOpen={true}>
          <ModalHeader><IntlMessages id={"outlets.confim-deactivate"} /> {branch.value.name}</ModalHeader>
          <ModalBody>
            <DatePicker
              minDate={moment().toDate()}
              customInput={<DatepickerInput />}
              selected={moment(deactivateDuration)}
              onChange={date => {
                setDuration(moment(moment(date).format('lll')).toDate().getTime())
              }}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="DD/MM/YYYY hh:mm A"
            />
          </ModalBody>
          <ModalFooter>
            <Button outline color='danger' className='m-1' onClick={() => setActivateBranch(false)}><IntlMessages id={"menus.cancel"} /></Button>
            <Button color='primary ' className='m-1' onClick={() => deactivateBranch(branch)}><IntlMessages id={"outlets.deactivate"} /></Button>
          </ModalFooter>
        </Modal>
      }
    </Colxx >
  );
};
export default React.memo(OutletItemView);
