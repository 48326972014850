import React, { Component, Fragment } from "react";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import {
  Card,
  CardBody,
  ModalBody,
  Label,
  Table,
  Input,
  Modal,
  Button,
  ModalHeader,
  Collapse,
  Row,
  ModalFooter,
  CustomInput
} from "reactstrap";
import Creatable from 'react-select/creatable';
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { firestore, storage } from 'firebase'
import { ReactSortable as Sortable } from "react-sortablejs";
import {
  selectBranch,
} from "../../../redux/actions";
import AddEditItem from '../../../views/app/menu-builder/menu-item-editor.js'
import { NotificationManager } from "../../../components/common/react-notifications";
import Select from "react-select";
import ImageUploader from 'react-images-upload'
import Resizer from 'react-image-file-resizer';
import IntlMessages from "../../../helpers/IntlMessages";

class Builder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: {},
      loading: true,
      openVideo: false,
      checked: {}
    }
    this.onDropPic = this.onDropPic.bind(this);

  }

  getAllMenus(branch) {
    let key = localStorage.getItem('res')
    if (key && branch) {
      firestore().collection('resturant').doc(key).collection('menus').where('branches', 'array-contains', branch.id).get().then(results => {
        let allMenus = []
        results.forEach(item => {
          let data = item.data()
          data.id = item.id
          allMenus[item.id] = data
        })
        this.setState({ allMenus, menu: false, selectedMenu: false })
      })
    }

  }
  componentWillMount() {
    let key = localStorage.getItem('res')
    let { branch } = this.props
    if (key) {
      firestore().collection('resturant').doc(key).get().then(results => {
        let data = results.data()
        let branches = data.branches
        this.setState({ branches })
      })
      if (branch) {
        this.getAllMenus(branch)
      }
    }
  }

  componentWillReceiveProps(prop) {
    let { branch } = this.props
    if (prop.branch !== branch) {
      this.getAllMenus(prop.branch)
    }
  }
  handleArrange(e) {
    let { menu } = this.state
    let newMenu = []
    e.forEach(element => {
      newMenu.push(menu.filter(item => item.id === element)[0])
    })
    this.setState({ menu: newMenu })
  }
  handleInnnerItems(e, category) {
    let { menu } = this.state
    let items = category.items
    let newItems = []
    e.forEach(element => {
      newItems.push(items.filter(item => item.id === element)[0])
    })

    let index = menu.indexOf(category)
    menu[index].items = newItems
    this.setState({ menu })
  }
  updateMenu() {
    let { branch } = this.props
    let { menu, selectedMenu } = this.state
    let updateMenuObject = { categouries: {} }
    let id = selectedMenu && selectedMenu.id ? selectedMenu.id : firestore().collection('menus').doc().id;

    if (menu.length === 0) {
      return (
        NotificationManager.info(
          "warning",
          "Add at least one category to save this Menu",
          6000,
          null,
          null,
        )

      )
    }
    // eslint-disable-next-line
    menu.map((category, index) => {
      let key = category.key
      let optimizedItems = category.items.map(item => item.key)
      updateMenuObject.name = selectedMenu.name
      updateMenuObject.branches = selectedMenu.branches ? selectedMenu.branches.map(item => item.id) : []
      updateMenuObject.categouries[key] = {
        name: category.name ? category.name : '',
        name_ar: category.name_ar ? category.name_ar : '',
        id: String(index + 1),
        items: optimizedItems ? optimizedItems : [],
        pic: category.pic ? category.pic : ''
      }
    })

    let reskey = localStorage.getItem('res')

    firestore().collection('resturant').doc(reskey).collection('menus').doc(id).set(updateMenuObject).then(() => {
      this.getAllMenus(branch)
      NotificationManager.success(
        "",
        "Menu Updated successfully",
        3000,
        null,
        null,
      )
    }).catch(error => {
      NotificationManager.error(
        "",
        "Something went wrong",
        3000,
        null,
        null,
      )
    })
  }

  addToList(item) {
    const { menu, modalOpen, addNew } = this.state
    let index = Number(modalOpen)
    let { items } = menu[index]
    if (addNew) {
      if (items) {
        items.push({ id: String(menu[modalOpen].items.length), key: item.id, name: item.name })
      } else {
        items = [{ id: String(menu[modalOpen].items.length), key: item.id, name: item.name }]
      }
    } else {
      let oldIndex = items.findIndex(existedItem => existedItem.key === item.id)
      let oldItem = items.filter(existedItem => existedItem.key === item.id)
      items[oldIndex] = { id: oldItem[0].id, key: item.id, name: item.name }
    }
    menu[index].items = items
    this.setState({ modalOpen: false, menu, addNew: false })

  }
  onMenuSelect(selectedMenu) {
    let { branches } = this.state
    let { allItemsMap } = this.props.allItems

    let selectedMenuCopy = JSON.parse(JSON.stringify(selectedMenu))
    const categouries = selectedMenuCopy.categouries

    let menu = Object.keys(categouries).map(categouryKey => {
      let categoury = Object.assign({}, categouries[categouryKey])
      categoury.key = categouryKey
      let optimizedItems = categoury.items.map((key, index) => {
        let menuItem = allItemsMap[key]
        if (menuItem) {
          return { id: String(index), key: key, name: menuItem ? menuItem.name : '' }
        }
        return false
      })
      optimizedItems = optimizedItems.filter(item => item !== false)
      categoury.items = optimizedItems
      return categoury
    })
    menu = menu.sort((a, b) => a.id - b.id)
    if (selectedMenu.branches) {
      selectedMenuCopy.branches = selectedMenu.branches.map(key => {
        return { id: key, label: branches[key].name, value: branches[key].name }
      })
    }
    this.setState({ menu, selectedMenu: selectedMenuCopy })
  }
  onMenuRemove(selectedMenu) {
    let reskey = localStorage.getItem('res')
    let { branch } = this.props

    firestore().collection('resturant').doc(reskey).collection('menus').doc(selectedMenu.id).delete().then(() => {
      this.setState({ confirmDeleteItem: false }, () => {
        NotificationManager.success(
          "",
          "Menu DELETED successfully",
          3000,
          null,
          null,
        )
        if (branch) {
          this.getAllMenus(branch)
        }
      })
    })
  }
  toggleBack = () => {
    this.setState(prevState => ({
      openVideo: !prevState.openVideo
    }));
  };
  render() {
    const { menu } = this.state
    return (
      <Fragment>
        <Row className="survey-app">
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>
                <IntlMessages id={"menu.menu-builder"} />  <Button size="xs" outline color='primary' onClick={this.toggleBack}><IntlMessages id={"menus.tutorial"} /></Button>

              </h1>
              {
                !menu && <div className="text-zero top-right-button-container">
                  <Button
                    color="primary"
                    size="lg"
                    className="top-right-button"
                    onClick={() => this.setState({ menu: [], selectedMenu: {} })}
                  > <IntlMessages id={"menus.create-menu"} />
                  </Button>
                </div>

              }

            </div>

            <Separator className="mb-5" />
            <Row>
              {
                this.renderMenuBuilder()
              }
            </Row>
          </Colxx>
        </Row>
        {
          <Modal isOpen={this.state.openVideo}
            toggle={this.toggleBack} style={{ maxWidth: 'max-content' }}  ><iframe width="806" height="465" src="https://www.youtube.com/embed/ds2tAJzX5m0?list=PL7VkScEgCZACVDFdNQ1w5trBq2c2odQc9" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </Modal>
        }
      </Fragment>
    )
  }
  removeItem(item, category) {
    let { menu } = this.state


    let index = menu.indexOf(category)
    if (index > -1) {
      menu[index].items = menu[index].items.filter(menuItem => menuItem.key !== item.id)
      this.setState({ menu })
    }
  }
  removeCategory(category) {
    let { menu } = this.state
    menu = menu.filter(item => item !== category)
    this.setState({ menu })

  }

  getCategouryOptions(allItemsFiltered, menuItems) {
    if (allItemsFiltered) {
      let optimizedMenu = allItemsFiltered.map((item, i) => {
        if (menuItems && menuItems.items) {
          let filtered = menuItems.items.filter(innerItem => innerItem.key === item.id)
          if (filtered.length > 0) {
            return false
          }
          return { label: item.name, value: item.name, key: item.id }
        }
        return { label: item.name, value: item.name, key: item.id };
      })
      return optimizedMenu.filter(item => item !== false)
    }
    return []
  }

  renderMenuBuilder() {
    let { allItems, allItemsMap } = this.props.allItems
    let { branch } = this.props

    let { menu, expand, modalOpen, allMenus, selectedMenu, branches, editItem, addNew, checked } = this.state
    let allItemsFiltered = []
    if (allItems) {
      allItemsFiltered = allItems.filter(item => !item.type || (item.type !== "modifier"))
    }
    if (modalOpen) {
      return (
        <Colxx>
          <AddEditItem addNew={addNew} addToList={(item) => this.addToList(item)} edit={editItem} allItems={allItems} dismiss={() => this.setState({ modalOpen: false, addNew: false })} />
        </Colxx>
      )
    }
    if (menu) {
      return (
        <Colxx xxs="12" className="mb-3">
          {/* <Row className='justify-content-between'>
            <h5 className="mb-4">{selectedMenu.name ? selectedMenu.name : 'New Menu'}</h5>
          </Row> */}
          <div>

            <Card className="mb-4 rounded">

              <CardBody>
                <Row>
                  <Colxx sm={6} className='mb-4'>
                    <Label for="name">
                      <IntlMessages id={"menus.name"} />
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="name"
                      defaultValue={selectedMenu.name}
                      value={selectedMenu.name}
                      onChange={event => {
                        selectedMenu.name = event.target.value
                        this.setState({ selectedMenu })
                      }}
                    />
                  </Colxx>
                  <Colxx sm={6} className='mb-4'>
                    <Label for="name">
                      <IntlMessages id={"menus.branches"} />
                    </Label>
                    <Select
                      type="text"
                      name="name"
                      id="name"
                      options={branches && Object.keys(branches).map(key => {
                        return { id: key, label: branches[key].name, value: branches[key].name }
                      })}
                      isMulti
                      placeholder="name"
                      defaultValue={selectedMenu.branches}
                      value={selectedMenu.branches}
                      onChange={event => {
                        selectedMenu.branches = event
                        this.setState({ selectedMenu })
                      }}
                    />
                  </Colxx>
                </Row>

                <Separator className='mb-4'></Separator>
                {
                  menu && menu.length > 1 && expand && Object.keys(expand).length > 0 && <Button color='link' className='mb-3' size='xs' onClick={() => this.setState({ expand: {} })}><IntlMessages id={"menus.collapse-all"} /></Button>

                }
                <Sortable
                  // key={Date.now()}
                  list={menu}
                  tag="ul"
                  className="list-unstyled"
                  options={{
                    handle: ".handle",
                    animation: 150
                  }}
                  setList={(newState) => this.setState({ menu: newState })}

                // onChange={(e) => this.handleArrange(e)}
                >
                  {
                    menu.map((category, index) => {
                      return (
                        <li key={category.id} data-id={category.id}>
                          <div className='d-flex justify-content-between'>
                            <p >
                              <span className=" badgeing badge-pill mr-1" onClick={() => { expand[category.name] = !expand[category.name]; this.setState({ expand }) }}>
                                <i className={expand[category.name] ? "simple-icon-arrow-down" : "simple-icon-arrow-right"} />
                              </span>
                              <span className="badgeing badge-pill handle mr-1">
                                <i className="simple-icon-cursor-move" />
                              </span>
                              <span>{category.name}</span>
                              <span className="badgeing">
                                <i className="simple-icon-pencil" onClick={() => this.setState({ category })} />
                              </span>
                              <span className="badgeing">
                                <i className="iconsminds-remove" onClick={() => this.removeCategory(category)} />
                              </span>
                            </p>
                            <Button  onClick={() => this.setState({ addItem: true })} disabled={category.items.length > 0} color="link">{category.items.length ? "items(" + category.items.length + ")" : "Add item"}</Button>
                          </div>
                          <Collapse isOpen={expand[category.name]} className='ml-5'>
                            <Sortable
                              className='ml-3'
                              setList={(newState) => {
                                let { menu } = this.state
                                let index = menu.indexOf(category)
                                menu[index].items = newState
                                this.setState({ menu })
                              }}
                              list={category.items}
                            // onChange={(e) => this.handleInnnerItems(e, category)}
                            >
                              {
                                category.items.map(item => {
                                  let index = menu.indexOf(category)
                                  return (
                                    <div key={item.id} data-id={item.id} className='d-flex justify-content-between'>
                                      <p>

                                        <span className=" badgeing badge-pill handle mr-1">
                                          <i className="simple-icon-cursor-move" />
                                        </span>
                                        <span>{item.name}</span>
                                        <span className="badgeing" onClick={() => { this.setState({ modalOpen: String(index), editItem: JSON.parse(JSON.stringify(allItemsMap[item.key])) }) }}>
                                          <i className="simple-icon-pencil" />
                                        </span>
                                        <span className="badgeing" onClick={() => this.removeItem(allItemsMap[item.key], category)}>
                                          <i className="iconsminds-remove" />
                                        </span>
                                      </p>
                                    </div>
                                  )
                                })

                              }

                            </Sortable>
                            <Creatable
                              isClearable
                              maxMenuHeight={150}
                              pageSize={5}
                              components={{ Input: CustomSelectInput }}
                              className="react-select text-capitalize mb-4 w-30"
                              classNamePrefix="react-select"
                              name="form-field-name"
                              options={this.getCategouryOptions(allItemsFiltered, menu[index])}
                              value={''}
                              placeholder="Add New Item"
                              // value={this.state.val}
                              onCreateOption={(value) => {
                                let editItem = {}
                                editItem.name = value
                                this.setState({ modalOpen: String(index), editItem, addNew: true })
                              }}
                              onChange={val => {
                                if (val.__isNew__) {
                                  let editItem = {}
                                  editItem.name = val.value
                                  this.setState({ modalOpen: String(index), editItem, addNew: true })
                                } else {
                                  let index = menu.indexOf(category)
                                  menu[index].items.push({ id: String(category.items.length), key: val.key, name: val.value })
                                  this.setState({ menu })
                                }

                              }}
                            />
                          </Collapse>

                        </li>
                      )
                    })
                  }
                </Sortable>
                <div>
                  <Button size='xs' className='pill' onClick={() => this.setState({ category: {} })}><IntlMessages id={"menus.new-category"} /></Button>
                  <div style={{ marginTop: '70px' }} className='float-right'>
                    <Button outline color='danger' className='m-1' onClick={() => this.setState({ confirmDismiss: true })}><IntlMessages id={"menus.cancel"} /></Button>
                    <Button color='primary ' className='m-1' onClick={() => this.updateMenu()}><IntlMessages id={"menus.save"} /></Button>
                  </div>
                </div>


              </CardBody>
            </Card>

          </div>

          {
            this.state.confirmDismiss && <Modal isOpen={true}>
              <ModalBody><IntlMessages id={"menus.are-you-sure-you"} /></ModalBody>
              <ModalFooter>
                <Button outline color='danger' className='m-1' onClick={() => this.setState({ confirmDismiss: false })}><IntlMessages id={"menus.cancel"} /></Button>
                <Button color='primary ' className='m-1' onClick={() => this.setState({ menu: false, selectedMenu: false, confirmDismiss: false })}><IntlMessages id={"menus.confirm"} /></Button>

              </ModalFooter>
            </Modal>
          }
          {
            this.state.category && this.renderAddnewCategouey()
          }

        </Colxx>

      )
    }
    return (
      <Colxx xxs="12" className="mb-3">

        <div>
          <Card className="mb-4 rounded">
            <CardBody>
              <Table responsive className=''>
                <thead>
                  <tr>
                    <th className='w-15'><IntlMessages id={"menus.menu-name"} /></th>
                    <th className='w-15 '>
                      <div className='d-flex justify-content-center'>
                        <IntlMessages id={"menus.active-branches"} />
                      </div>
                    </th>
                    <th className='w-20 '>
                      <div className='d-flex justify-content-center'>
                        <IntlMessages id={"menus.activated-for"} />
                      </div>
                    </th>
                    <th className='w-30'>
                      <div className='d-flex justify-content-end m-2'><IntlMessages id={"menus.actions"} /></div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    allMenus && Object.keys(allMenus).map((key, index) => {
                      return (
                        <tr key={index}>
                          <td className='list-item-heading w-15 '>{allMenus[key].name}</td>
                          <td className='w-20 '>
                            <div className='d-flex justify-content-center'>
                              {allMenus[key].branches.length}
                            </div>

                          </td>
                          <td className='w-15'>
                            <div className='d-flex justify-content-center text-capitalize'>
                              {

                                branch.activeMenu && Object.keys(branch.activeMenu).map(item => {
                                  if (branch.activeMenu[item] === key) {
                                    return (item)
                                  }
                                  return false

                                }
                                ).filter(item => !!item).join(', ')
                              }
                            </div>

                          </td>
                          <td className='w-30'>
                            <div className='d-flex justify-content-end w-100'>
                              <Button
                                // disabled={branch.activeMenu === key} 
                                outline color='dark' size='xs' className='m-1' onClick={() => { this.setState({ confirmActivate: allMenus[key] }) }}><IntlMessages id={"menus.activate"} /></Button>
                              <Button outline color='dark' size='xs' className='m-1' onClick={() => {
                                let resKey = localStorage.getItem('res')
                                let newData = JSON.parse(JSON.stringify(allMenus[key]))
                                newData.name = newData.name + ' Copy'
                                delete newData.id
                                firestore().collection('resturant').doc(resKey).collection('menus').add(newData).then(result => {
                                  let id = result.id
                                  newData.id = id
                                  allMenus[id] = newData
                                  this.setState({ allMenus })
                                })
                                // this.setState({ confirmActivate: allMenus[key] }) 
                              }}><IntlMessages id={"menus.duplicate"} /></Button>

                              <Button outline color='dark' size='xs' className='m-1' onClick={() => { this.onMenuSelect(allMenus[key]) }}><IntlMessages id={"menus.edit"} /></Button>
                              {/* <Button outline color='danger' size='xs' className='m-1' onClick={() => { this.onMenuRemove(allMenus[key]) }}> Delete</Button> */}
                              <Button outline color='danger' size='xs' className='m-1' onClick={() => { this.setState({ confirmDeleteItem: allMenus[key] }) }}><IntlMessages id={"menus.delete"} /></Button>
                            </div>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </div>

        {
          this.state.confirmDeleteItem && <Modal isOpen={true}><ModalBody>{"Are you sure you want to Delete  " + this.state.confirmDeleteItem.name}</ModalBody>
            <ModalFooter>
              <Button outline color='danger' className='m-1' onClick={() => this.setState({ confirmDeleteItem: false })}><IntlMessages id={"menus.cancel"} /></Button>
              <Button color='primary ' className='m-1' onClick={() => this.onMenuRemove(this.state.confirmDeleteItem)}><IntlMessages id={"menus.confirm"} /></Button>
            </ModalFooter>
          </Modal>
        }
        {
          this.state.confirmActivate && <Modal isOpen={true}>
            <ModalBody>
              <div className='mb-2'>
                {"Are you sure you want to Activate  " + this.state.confirmActivate.name + ' for this branch ,Please choose activation options below:'}
              </div>
              <div>
                <CustomInput
                  className=" mb-0"
                  type="checkbox"
                  id={`check_2`}
                  checked={checked.delivery}
                  onClick={() => {
                    checked.delivery = !checked.delivery
                    this.setState({ checked })
                  }}
                  label="Activate For Delivery"
                />
                <CustomInput
                  className=" mb-0"
                  type="checkbox"
                  id={`check_3`}
                  checked={checked.pickup}
                  onClick={() => {
                    let { checked } = this.state
                    checked.pickup = !checked.pickup
                    this.setState({ checked })
                  }}
                  label="Activate For Pickup"
                />
                <CustomInput
                  className=" mb-0"
                  type="checkbox"
                  id={`check_4`}
                  checked={checked.dineIn}
                  onClick={() => {
                    let { checked } = this.state
                    checked.dineIn = !checked.dineIn
                    this.setState({ checked })
                  }}
                  label="Activate For Dine in"
                />

              </div>
            </ModalBody>
            <ModalFooter>
              <Button outline color='danger' className='m-1' onClick={() => this.setState({ confirmActivate: false })}><IntlMessages id={"menus.cancel"} /></Button>
              <Button color='primary ' className='m-1' onClick={() => this.activateMenu(this.state.confirmActivate)}><IntlMessages id={"menus.confirm"} /></Button>
            </ModalFooter>
          </Modal>
        }

      </Colxx>
    )

  }
  activateMenu(menu) {
    let { branch, selectBranch } = this.props
    let { checked } = this.state
    let reskey = localStorage.getItem('res')
    if (reskey && branch && branch.id) {
      Object.keys(checked).map(key => {
        if (checked[key]) {
          if (!branch.activeMenu || (typeof branch.activeMenu === 'string')) {
            branch.activeMenu = { [key]: menu.id }
          } else {
            branch.activeMenu[key] = menu.id
          }
          return firestore().collection('resturant').doc(reskey).update({
            [`branches.${branch.id}.activeMenu.${key}`]: menu.id
          })
        }
      })

      this.setState({ confirmActivate: false, checked: {} }, () => {
        selectBranch(branch)
        NotificationManager.success(
          "",
          "Menu Activated successfully",
          3000,
          null,
          null,
        )
      })
      // })
    }
  }
  onDropPic(pic) {
    let _this = this
    console.log('picpic', pic)
    let pictures = []
    if (pic.length) {
      let lastPic = pic[pic.length - 1];
      let splitter = lastPic.type.split('/')
      let type = 'jpeg'
      if (splitter && splitter.length > 0) {
        type = splitter[splitter.length - 1]
      }
      Resizer.imageFileResizer(
        lastPic,
        1080,
        1080,
        type,
        100,
        0,
        uri600 => {
          var fr = new FileReader();
          fr.onload = function () {

            pictures.push({
              name: 'img',
              data: uri600,
              base64: fr.result
            });
            _this.setState({ pictures })
          }
          fr.readAsDataURL(pic[0]);
        },
        'blob'
      );
    }
  }
  renderAddnewCategouey() {
    const { category, menu, expand, pictures } = this.state
    return (
      <Modal isOpen={true}>

        <ModalHeader>{category.id ? <IntlMessages id={"menus.update-category"} /> : <IntlMessages id={"menus.new-category"} />}</ModalHeader>
        <ModalBody>

          {
            category.pic || pictures ?
              <div style={{ height: '150px', display: 'flex', justifyContent: 'center' }} >
                <img style={{ width: '150px', objectFit: 'contain' }} src={category.pic || pictures[0].base64} />
                <div style={{ fontSize: '28px', color: 'red', cursor: 'pointer' }} onClick={() => {
                  category.pic = false;
                  this.setState({ category, pictures: false })
                }}>x</div>
              </div>
              :
              <ImageUploader
                buttonText='Attach Picture'
                onChange={this.onDropPic}
                withLabel={false}
                withIcon={true}
                className="many"
                withPreview={false}
                defaultImages={category.pic}

              />

          }
          <Label >
            <IntlMessages id={"menus.name*"} />
          </Label>
          <Input
            type="text"
            defaultValue={category.name}
            id='categouryname'
            onChange={event => {
              category.name = event.target.value
              this.setState({ category });
            }}
          />
          <Label className="mt-4">
            <IntlMessages id={"menus.arabic-name*"} />
          </Label>
          <Input
            type="text"
            defaultValue={category.name_ar}
            id='categouryname_ar'
            onChange={event => {
              category.name_ar = event.target.value
              this.setState({ category });
            }}
          />


        </ModalBody>
        <ModalFooter>
          <Button color='danger' onClick={() => this.setState({ category: false })}><IntlMessages id={"menus.cancel"} /></Button>
          <Button color="secondary" onClick={() => {
            let uploadKey = category.key ? category.key : firestore().collection('categouries').doc().id
            let upload = new Promise((resolve, reject) => {
              let pic = pictures && pictures.length > 0 && pictures[0]
              if (!pic) {
                resolve(category.pic)
              }
              var storageRef = storage().ref().child("categouries").child(uploadKey).child("img").put(pic.data, {
                cacheControl: 'public,max-age=300'
              });
              storageRef.on('state_changed', function (snapshot) {
              }, function (error) {
                resolve('')
                // Handle unsuccessful uploads
              }, function () {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                console.log("storageRef.snapshot.ref.fullPath", storageRef.snapshot.ref.fullPath);
                resolve(`https://img.food2order.io/${storageRef.snapshot.ref.fullPath}?dum=${Date.now()}`)

                // storageRef.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                //   console.log("downloadURL", downloadURL)

                //   resolve(downloadURL)
                // })
              })
            })
            upload.then((picUrl) => {
              console.log("picUrl", picUrl)

              if (category.id) {
                category.pic = picUrl
                menu[category.id - 1] = category
              } else {
                category.id = String(menu.length + 1)
                category.pic = picUrl
                category.items = []
                category.key = uploadKey
                menu.push(category)
                expand[category.name] = true
              }
              this.setState({ menu, category: false, expand, pictures: false })
            })
          }}>{category.id ? <IntlMessages id={"menus.update"} /> : <IntlMessages id={"menus.add-category"} />}</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

const mapStateToProps = ({ allItems, settings }) => {
  const { branch } = settings

  return {
    allItems,
    branch
  };
};
const mapActionsToProps = (dispatch) => ({
  selectBranch: (bransh) => dispatch(selectBranch(bransh)),
});
export default injectIntl(
  connect(
    mapStateToProps,
    mapActionsToProps
  )(Builder)
);