import React, { useState } from 'react';

import './storeDetails.css'
import Food2orderLogo from '../../../assets/css/img/footerLogo.png'
import Step1Img from '../../../assets/css/img/step1Img.png'
import { Button, Input } from 'reactstrap';
import Select from "react-select";

const StoreDetails = ({ setStep, state, setState }) => {
    return (
        <div className='storeDetails'>
            <div className='storeDetailsLeftSide'>
                <img style={{ position: 'absolute', top: '41px', left: '26px', width: '206px' }} src={Food2orderLogo} />
                <img src={Step1Img} style={{ width: '60%' }} />
            </div>
            <div className='storeDetailsRightSide'>
                <div className='stepTitle'>Step 1 of 3</div>
                <div className='welcomeTitle'>Welcome!</div>
                <div className='desc'>Enter your store details to continue</div>
                <div className='detailsTitle'>Store Details</div>
                <div className='inputsContainer'>
                    <div className='inputsContainer2'>
                        <div className='inputsTitle'>Name of restaurant <span>*</span></div>
                        <Input className='inputStyle' onChange={(e) => setState({ ...state, storeName: e.target.value })} />
                    </div>
                    <div className='inputsContainer2'>
                        <div className='inputsTitle'>Total outlets <span>*</span></div>
                        <Input key='oultels' type='number' className='inputStyle' onChange={(e) => setState({ ...state, branchesCount: e.target.value })} />
                    </div>
                    <div className='inputsContainer2'>
                        <div className='inputsTitle'>Country <span>*</span></div>
                        <Select
                            className='inputStyle'
                            options={[
                                { key: 'UAE', value: 'UAE', label: 'UAE', code: '971', currency: 'AED', vat: '0.05' },
                                { key: 'Egypt', value: 'Egypt', label: 'Egypt', code: '20', currency: 'EGP', vat: '0.14' },
                            ]}
                            type="text"
                            name="language"
                            id="language"
                            placeholder="Choose from dropdown"
                            // value={settings && settings.direction ? { key: settings.direction, value: settings.direction === 'ltr' ? 'English' : 'Arabic', label: settings.direction === 'ltr' ? 'English' : 'Arabic' } : {}}
                            onChange={event => {
                                setState({ ...state, country: event })
                            }}

                        />
                    </div>
                    <div className='inputsContainer2'>
                        <div className='inputsTitle'>How did you hear? <span>*</span></div>
                        <Select
                            className='inputStyle'
                            options={[
                                { key: 'ltr', value: 'English', label: 'English' },
                                { key: 'rtl', value: 'Arabic', label: 'Arabic' },

                            ]}
                            type="text"
                            name="language"
                            id="language"
                            placeholder="Choose from dropdown"
                            // value={settings && settings.direction ? { key: settings.direction, value: settings.direction === 'ltr' ? 'English' : 'Arabic', label: settings.direction === 'ltr' ? 'English' : 'Arabic' } : {}}
                            onChange={event => {
                                // settings.direction = event.key
                                // this.setState({ settings })

                            }}

                        />
                    </div>
                </div>
                <Button
                    color="primary"
                    // className="btn-shadow"
                    size="lg"
                    style={{ width: '100%', marginTop: '60px', fontSize: '15px', fontWeight: '600' }}
                    onClick={() => {
                        setStep(2)
                    }}
                >
                    Continue
                </Button>
            </div>
        </div>
    );
};

export default StoreDetails;