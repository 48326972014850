

import {
    TEST_GET_LIST,
    TEST_GET_LIST_SUCCESS,
    TEST_GET_LIST_ERROR,
    TEST_GET_LIST_WITH_FILTER,
    TEST_GET_LIST_WITH_ORDER,
    TEST_GET_LIST_SEARCH,
    TEST_ADD_ITEM,
    TEST_DELETE_ITEM,
    TEST_UPDATE_ITEM,
    TEST_ADD_ITEM_SUCCESS,
    TEST_ADD_ITEM_ERROR,
    TEST_SELECTED_ITEMS_CHANGE
} from '../actions';


export const getTestList = () => ({
    type: TEST_GET_LIST
});

export const getTestListSuccess = (items) => ({
    type: TEST_GET_LIST_SUCCESS,
    payload: items
});

export const getTestListError = (error) => ({
    type: TEST_GET_LIST_ERROR,
    payload: error
});

export const getTestListWithFilter = (column, value) => ({
    type: TEST_GET_LIST_WITH_FILTER,
    payload: { column, value }
});

export const getTestListWithOrder = (column) => ({
    type: TEST_GET_LIST_WITH_ORDER,
    payload: column
});

export const getTestListSearch = (keyword) => ({
    type: TEST_GET_LIST_SEARCH,
    payload: keyword
});

export const addTestItem = (item) => ({
    type: TEST_ADD_ITEM,
    payload: item
});
export const updateTestItem = (item) => ({
    type: TEST_UPDATE_ITEM,
    payload: item
});
export const deleteTestItem = (item) => ({
    type: TEST_DELETE_ITEM,
    payload: item
});
export const addTestItemSuccess = (items) => ({
    type: TEST_ADD_ITEM_SUCCESS,
    payload: items
});

export const addTestItemError = (error) => ({
    type: TEST_ADD_ITEM_ERROR,
    payload: error
});

export const selectedTestItemsChange = (selectedItems) => ({
    type: TEST_SELECTED_ITEMS_CHANGE,
    payload: selectedItems
});
