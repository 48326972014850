

import {
    ORDER_GET_LIST,
    ORDER_GET_LIST_SUCCESS,
    ORDER_GET_LIST_ERROR,
    ORDER_GET_LIST_WITH_FILTER,
    ORDER_GET_LIST_WITH_ORDER,
    ORDER_GET_LIST_SEARCH,
    ORDER_ADD_ITEM,
    ORDER_DELETE_ITEM,
    ORDER_UPDATE_ITEM,
    ORDER_ADD_ITEM_SUCCESS,
    ORDER_ADD_ITEM_ERROR,
    ORDER_SELECTED_ITEMS_CHANGE
} from '../actions';


export const getOrderList = () => ({
    type: ORDER_GET_LIST
});

export const getOrderListSuccess = (items) => {
    return ({
    type: ORDER_GET_LIST_SUCCESS,
    payload: items
})};

export const getOrderListError = (error) => ({
    type: ORDER_GET_LIST_ERROR,
    payload: error
});

export const getOrderListWithFilter = (column, value) => ({
    type: ORDER_GET_LIST_WITH_FILTER,
    payload: { column, value }
});

export const getOrderListWithOrder = (column) => ({
    type: ORDER_GET_LIST_WITH_ORDER,
    payload: column
});

export const getOrderListSearch = (keyword) => ({
    type: ORDER_GET_LIST_SEARCH,
    payload: keyword
});

export const addOrderItem = (item) => ({
    type: ORDER_ADD_ITEM,
    payload: item
});
export const updateOrderItem = (item) => ({
    type: ORDER_UPDATE_ITEM,
    payload: item
});
export const deleteOrderItem = (item) => ({
    type: ORDER_DELETE_ITEM,
    payload: item
});
export const addOrderItemSuccess = (items) => ({
    type: ORDER_ADD_ITEM_SUCCESS,
    payload: items
});

export const addOrderItemError = (error) => ({
    type: ORDER_ADD_ITEM_ERROR,
    payload: error
});

export const selectedOrderItemsChange = (selectedItems) => ({
    type: ORDER_SELECTED_ITEMS_CHANGE,
    payload: selectedItems
});
