import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";
import { Colxx } from '../../../components/common/CustomBootstrap';


import ImageListView from "../../../containers/pages/ImageListView";
import { firestore } from 'firebase'
import StoreItems from './storeItems.js';
import './store.css'
import { getDirection } from "../../../helpers/Utils";


class ThumbListPages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      storeItems: [],
      selectedIndex: 0
    };
  }
  componentWillMount() {
    let _this = this
    let store = []
    let key = localStorage.getItem('res')
    firestore().collection('store').get().then(function (snap) {
      snap.forEach(function (record) {
        let data = record.data()
        store.push(data)
      })

      let activatedItemsArray = []
      firestore().collection('res').where('id', '==', key).get().then(snapShot => {
        snapShot.forEach(resturant => {
          let storeItemObject = resturant.data().subscription.storeSubscriptions
          if (storeItemObject) {
            Object.keys(storeItemObject).forEach(powerUpId => {
              let powerUp = storeItemObject[powerUpId]
              store.map(storeCategory => {
                let { storeItems } = storeCategory
                storeItems.map(item => {
                  if (item.id === powerUpId) {
                    item["activated"] = powerUp.activated ? powerUp.activated : (powerUp.endDate > Date.now())
                    item["startDate"] = powerUp.startDate
                    item["endDate"] = powerUp.endDate
                    activatedItemsArray.push(item)
                  }
                })
              })
            })
          }

          if (activatedItemsArray.length) {
            let activatedItem = {
              title: 'Activated plugins',
              title_ar: 'الملحقات النشطة',
              description: '',
              description_ar: '',
              storeItems: activatedItemsArray,
              index: 0.5
            }
            store.splice(0, 0, activatedItem)

          }

          let allItems = []
          store.map(item => {
            return (
              item.storeItems.map(storeItem => {
                return (
                  allItems.push(storeItem)
                )
              })
            )
          })


          // eslint-disable-next-line
          let unique = allItems.filter((set => f => !set.has(f.title) && set.add(f.title))(new Set));

          let AllItem = {
            title: 'All',
            title_ar: 'الكل',
            description_ar: '',
            description: 'Enrich your channel with a selection of PowerUp targeting different needs',
            storeItems: unique,
            index: 0
          }
          store.splice(0, 0, AllItem)

          store = store.sort((a, b) => a.index > b.index ? 1 : -1)
          store = store.map((record) => {
            record.storeItems = record.storeItems.sort((a, b) => a.comingSoon > (b.comingSoon ? b.comingSoon : 0) ? 1 : -1);
            record.storeItems = record.storeItems.sort((a, b) => a.activated > (b.activated ? b.activated : 0) ? -1 : 1);

            return record

          })
          _this.setState({ storeItems: store, isLoading: false })

        })
      })
    })
  }
  selectedItemFunc(selectedIndex) {
    this.setState({ selectedIndex: selectedIndex })
  }
  render() {
    let { storeItems, selectedIndex } = this.state
    const direction = getDirection();

    // storeItems.sort(compare);

    return this.state.isLoading ? (
      <div className="loading" />
    ) : (
      <Fragment>

        <Row className="disable-text-selection">
          <Colxx lg="2.5" xl="2.5" style={{ maxWidth: '200px' }} >
            <StoreItems storeItems={storeItems} selectedIndex={selectedIndex} selectItem={(selectedIndex) => (this.selectedItemFunc(selectedIndex))}></StoreItems>
          </Colxx>

          <Colxx lg="8" xl="8" xxl='8' className="ml-5" style={{ maxWidth: '770px' }}>
            <Row className="ml-3 mr-3">
              <div>
                <h1>{direction.isRtl ? storeItems[selectedIndex].title_ar : storeItems[selectedIndex].title}
                  <p className='text-muted'>{direction.isRtl ? storeItems[selectedIndex].description_ar : storeItems[selectedIndex].description}</p>
                </h1>
              </div>
            </Row>
            {storeItems[selectedIndex].storeItems.map((product, index) => {
              return (
                <ImageListView
                  direction={direction}
                  key={index}
                  product={product}
                />
              );
            })}{" "}
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}
export default ThumbListPages;
