import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';


import {firebaseConfig} from '../constants/defaultValues'

firebase.initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig,'userCreator');

const auth = firebase.auth();
const database = firebase.firestore();
const storage = firebase.storage();

export {
   auth,
   database,
   storage
};
