import React, { Component } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Card,
    Form,
    Row,
    FormGroup,
    CustomInput,

} from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";

import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import { NotificationManager } from "../../components/common/react-notifications";
import { ReactSortable as Sortable } from "react-sortablejs";
import IntlMessages from "../../helpers/IntlMessages";
const typeOptions = [{ key: 'single', value: "Single", label: 'Single' }, { key: 'multiple', value: "Multiple", label: 'Multiple' }]
class AddNewModifierList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: this.props.editList ? this.props.editList : false,
            modification: {},
            items: []
        };
    }
    componentWillMount() {
        let { editList } = this.props
        if (editList) {
            // eslint-disable-next-line
            Object.keys(editList).map(key => {
                this.setState({ [key]: editList[key] })
            })
        }
    }

    addNetItem = () => {
        let { name, name_ar, items, min_count, max_count, type } = this.state
        if (!name || !name_ar || !items || !min_count || !max_count) {
            return NotificationManager.error(
                "",
                "Please fill all required fields",
                3000,
                null,
                null,
            );
        }
        const newItem = {
            name,
            name_ar,
            // detail: detail ? detail : '',
            // detail_ar: detail_ar ? detail_ar : '',
            min_count: min_count ? min_count : '',
            max_count: max_count ? max_count : '',
            type: max_count && max_count > 0 ? (type ? type : '') : "single",
            items: items
        };

        if (this.props.editList) {
            // eslint-disable-next-line
            Object.keys(newItem).map(key => {
                this.props.editList[key] = newItem[key]
            })
            this.props.UpdateList(this.props.editList);
        } else {
            this.props.addNewList(newItem);
        }
        this.dismiss()
    };
    dismiss() {
        this.props.toggleModal();
    }
    handleInnnerItems(e, items) {
        let newItems = []
        e.forEach((element, index) => {
            let item = items.filter(item => item.id === element)[0]
            item.id = String(element)
            item.key = String(index)
            newItems.push(item)
        })
        this.setState({ items: newItems })
    }
    render() {
        const { modalOpen, modifiersOptions } = this.props;
        let { items, edit, modification, max_count, type } = this.state
        return (
            <Modal
                isOpen={modalOpen}
                wrapClassName="modal-right"
                backdrop="static"
            >
                <ModalHeader>
                    <IntlMessages id={"menus.modifier-list"} />
                </ModalHeader>
                <ModalBody>
                    <Label >
                        <IntlMessages id={"menus.name*"} />
                    </Label>
                    <Input
                        type="text"
                        defaultValue={edit.name}
                        id='listname'
                        onChange={event => {
                            this.setState({ name: event.target.value });
                        }}
                    />
                    <Label className="mt-4">
                        <IntlMessages id={"menus.arabic-name*"} />
                    </Label>
                    <Input
                        type="text"
                        defaultValue={edit.name_ar}
                        id='listname_ar'
                        onChange={event => {
                            this.setState({ name_ar: event.target.value });
                        }}
                    />
                    {/* <Label className="mt-4">
                        <IntlMessages id={"menus.selection-count"} />
                    </Label>
                    <Input
                        type="number"
                        defaultValue={edit.count}
                        id='listdetail_ar'
                        onChange={event => {
                            this.setState({ count: event.target.value });
                        }}
                    /> */}


                    <Label className="mt-4">
                        <IntlMessages id={"menus.min-count"} />
                    </Label>
                    <Input
                        type="number"
                        defaultValue={edit.min_count}
                        id='listdetail_ar'
                        onChange={event => {
                            this.setState({ min_count: event.target.value });
                        }}
                    />
                    <Label className="mt-4">
                        <IntlMessages id={"menus.max-count"} />
                    </Label>
                    <Input
                        type="number"
                        defaultValue={edit.max_count}
                        id='listdetail_ar'
                        onChange={event => {
                            this.setState({ max_count: event.target.value });
                        }}
                    />
                    <CustomInput
                        className="mt-2"
                        type="checkbox"
                        id='requestJeeply'
                        checked={edit.type && edit.type === "multiple"}
                        disabled={!max_count || max_count === "" || Number(max_count) < 2}
                        onChange={(e) => {
                            if (e.target.checked) {
                                edit.type = "multiple"
                                this.setState({ edit, type: "multiple" })

                            } else {
                                edit.type = "single"
                                this.setState({ edit, type: "single" })

                            }
                        }}
                        label={<IntlMessages id={"menus.type"} />}
                    />



                    <Label className="mt-4">
                        <IntlMessages id={"menus.select-modifier-items"} />
                    </Label>
                    <Select
                        components={{ Input: CustomSelectInput }}
                        className="react-select text-capitalize mb-4"
                        classNamePrefix="react-select"
                        name="form-field-name"
                        options={modifiersOptions.map((x, i) => {
                            return { label: x.name + ' "' + window.currency + " " + x.price + '"', value: x.name, key: i, data: x };
                        })}
                        defaultValue={edit.items}
                        placeholder={items}
                        value={items}
                        isMulti={true}
                        onChange={val => {
                            if (val) {
                                let newitemsOptions = val.map((item, index) => {
                                    item.id = String(index)
                                    return item
                                })
                                this.setState({ items: newitemsOptions });
                            } else {
                                this.setState({ items: [] });
                            }

                        }}
                    />

                    {
                        items && items.length > 0 && <div className='mt-4'>
                            <Label>
                                <IntlMessages id={"menus.options"} />
                            </Label>

                            <Sortable
                                list={items}
                                setList={(newState) => this.setState({ items: newState })}
                                tag="ul"
                                className="list-unstyled"
                                options={{
                                    handle: ".handle",
                                    animation: 150
                                }}
                            >
                                {
                                    items.map((item) => {
                                        return (
                                            <li key={item.id} id={item.id} data-id={item.id} className='handle'>
                                                <Card >
                                                    <Row className='p-2'>
                                                        <span className=" badge badge-pill w-5 d-flex align-items-center">
                                                            <i className='simple-icon-cursor-move' />
                                                        </span>
                                                        <div>{item.value}</div>
                                                    </Row>
                                                </Card>
                                            </li>
                                        )
                                    })
                                }
                            </Sortable>
                        </div>
                    }
                    <Button size='xs' onClick={() => this.setState({ newModifierModal: true })}><IntlMessages id={"menus.create-new-modifier"} /></Button>

                </ModalBody>
                <ModalFooter>
                    <Button color="danger" outline onClick={() => this.dismiss()}>
                        <IntlMessages id={"menus.cancel"} />
                    </Button>
                    <Button color="primary" onClick={() => this.addNetItem()}>
                        <IntlMessages id={"menus.save"} />
                    </Button>{" "}
                </ModalFooter>
                {
                    this.state.newModifierModal &&
                    <Modal isOpen={true}>

                        <ModalHeader><IntlMessages id={"menus.create-modifier"} /></ModalHeader>
                        <ModalBody>
                            <Form>
                                <FormGroup row>
                                    <Colxx sm={4}>
                                        <Label for="name">
                                            <IntlMessages id={"menus.name"} />
                                        </Label>
                                        <Input
                                            type="text"
                                            name="name"
                                            id="newmodifiername"
                                            defaultValue={modification.name_ar}
                                            placeholder="English Name"
                                            value={modification.name}
                                            onChange={event => {
                                                modification.name = event.target.value
                                                this.setState({ modification })
                                            }}
                                        />
                                    </Colxx>
                                    <Colxx sm={4}>
                                        <Label for="arabic">
                                            <IntlMessages id={"menus.arabic-name"} />
                                        </Label>
                                        <Input
                                            type="text"
                                            name="arabic"
                                            id="newmodifiernamearabic"
                                            placeholder="Arabic Name"
                                            defaultValue={modification.name_ar}
                                            value={modification.name_ar}
                                            onChange={event => {
                                                modification.name_ar = event.target.value
                                                this.setState({ modification })
                                            }}
                                        />

                                    </Colxx>
                                </FormGroup>
                                <FormGroup row>
                                    <Colxx sm={3}>
                                        <Label for="price">
                                            <IntlMessages id={"menus.price"} /> {window.currency}
                                        </Label>
                                        <Input
                                            type="number"
                                            name="price"
                                            id="newmodifiernameprice"
                                            placeholder="20"
                                            value={modification.price}
                                            onChange={event => {
                                                modification.price = event.target.value
                                                this.setState({ modification })
                                            }}
                                        />
                                    </Colxx>
                                </FormGroup>
                                <Button color='danger' className="mt-4 mr-2" outline onClick={() => this.setState({ modification: {}, newModifierModal: false })}><IntlMessages id={"menus.cancel"} /></Button>
                                <Button disabled={!modification.name || !modification.name_ar} color="primary" className="mt-4"
                                    onClick={() => {
                                        items.push({ value: modification.name, label: modification.name, key: items.length, id: String(items.length), newData: modification });
                                        this.setState({ modification: {}, newModifierModal: false })
                                    }}>
                                    <IntlMessages id={"menus.save"} />
                                </Button>
                            </Form>
                        </ModalBody>


                    </Modal>
                }
            </Modal>
        );
    }
}

export default AddNewModifierList;
