/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-sub-hidden";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "ar", name: "Arabic - RTL", direction: "rtl" },
  // { id: "es", name: "Español", direction: "ltr" },
  // { id: "enrtl", name: "English - RTL", direction: "rtl" }
];

// export const firebaseConfig = {
//   apiKey: "AIzaSyCnL8ZbanUNt3edWy52ACb75XIzWG1DXes",
//   authDomain: "cairo-gourmet.firebaseapp.com",
//   databaseURL: "https://cairo-gourmet.firebaseio.com",
//   projectId: "cairo-gourmet",
//   storageBucket: "cairo-gourmet.appspot.com",
//   messagingSenderId: "831057236631",
//   appId: "1:831057236631:web:fb7ffe1f768ddcee"
// };
export const firebaseConfig = {
  apiKey: "AIzaSyAPe5JQQz0a1T7ytukrW3JLTapYdfRyuSE",
  authDomain: "food2order-33f23.firebaseapp.com",
  databaseURL: "https://food2order-33f23.firebaseio.com",
  projectId: "food2order-33f23",
  storageBucket: "img.food2order.io",
  messagingSenderId: "1031783764410",
  appId: "1:1031783764410:web:5a15589c3f345e7d1fcd83",
  measurementId: "G-TCEEZR3TLT"
};

export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";

/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = true;
export const defaultColor = "light.purple";
export const defaultDirection = "ltr";
export const isDarkSwitchActive = false;
export const themeColorStorageKey="__theme_color";