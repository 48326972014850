import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AllRes from "./createRes.js";
import clientsData from "./clientsData.js";


const SuperAdmin = ({ match }) => (
  <div className="dashboard-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}`} to={`${match.url}/admin`} />
      <Route path={`${match.url}/all`} component={AllRes} />
      <Route path={`${match.url}/clients`} component={clientsData} />
      {/* <Route path={`${match.url}/allPaymentList`} component={AllRes} /> */}
      <Redirect to="/error" />
    </Switch>
  </div>
);

export default SuperAdmin;
