import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
// import PerfectScrollbar from "react-perfect-scrollbar";
import { Card, Modal } from "reactstrap";

import {
    getOrderList,
    getOrderListWithOrder,
    getOrderListSearch,
    selectedOrderItemsChange,
    deleteOrderItem,
} from "../../../redux/actions";
import moment from 'moment'
import Countdown from 'react-countdown';

function dateFormatter(timeStamp) {

    return moment(timeStamp).fromNow()
}
class ChatApplicationMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchKey: "",
            color: { new: '#dc3545', accepted: '#ffc107', canceled: '#8f8f8f', delivery: 'var(--theme-color-1)', delivered: '#28a745' }

        };
    }

    handleSearchContact = keyword => {
        let { orders } = this.props

        if (keyword) {
            let filteredOrders = orders.filter(item => {
                let { user } = item
                return (user && user.name.toLowerCase().replace(" ", "").includes(keyword)) || (user.number && user.number.includes(keyword))

            })
            this.setState({ filteredOrders, searchKey: keyword })
        } else {
            this.setState({
                filteredOrders: false,
                searchKey: keyword
            });
        }

    };

    handleConversationClick = (selectedUserId) => {
        this.props.selectItem(selectedUserId)
    }


    renderer = ({ days, hours, minutes, seconds, completed }) => {
        // if (completed) {
        //     // Render a completed state
        //     return <span style={{ padding: '8px' }}>0</span>;
        // } else {


        if (hours < 2) {
            minutes = minutes + (60 * hours)
        }
        if (days > 0) {
            hours = hours + (24 * days)
        }


        // Render a countdown
        return <span className="font-weight-bold" style={{ padding: '8px' }}>{hours > 2 ? (hours + ":" + minutes) : minutes}</span>;
        // }
    };

    render() {
        let { orders, upgrade } = this.props
        let { filteredOrders, color } = this.state
        const { messages } = this.props.intl;
        let ordersArray = filteredOrders ? filteredOrders : orders
        return (
            <Card style={{ height: 'calc(100vh - 55px)' }} className="scrollOrderListMobileContainerCard">
                <div className="pt-4 pr-4 pl-4 pb-0">
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control  css-yk16xz-control"
                            placeholder={messages["menu.search"]}
                            value={this.state.searchKey}
                            onChange={e => this.handleSearchContact(e.target.value)}
                        />
                    </div>
                </div>

                <div className="scroll dashboard-list-with-thumbs scrollOrderListMobile" style={{ height: 'calc(100% - 85px)', opacity: upgrade ? '0.5' : '1' }}>
                    <div className="scrollbar-container ps ps--active-y newPerfectScroll">
                        <div className="pt-2 pb-2" >
                            {
                                ordersArray && ordersArray.length > 0 ? ordersArray.map((item, index) => {

                                    return (
                                        <div
                                            key={index}
                                            onClick={() => {
                                                if (!upgrade) {
                                                    this.handleConversationClick(item)
                                                }
                                            }}
                                            className="d-flex flex-row border-bottom pt-3 pb-3 "
                                            style={this.props.selectedItem === item ? { background: 'lightGray', cursor: 'pointer' } : { cursor: 'pointer' }}
                                        >
                                            <div className="d-flex w-100">
                                                <div className="d-flex flex-grow-1 min-width-zero">
                                                    <div className="pl-4 pr-4 align-items-center d-flex flex-lg-row justify-content-between min-width-zero w-100">
                                                        <div className="min-width-zero">
                                                            <p className=" mb-0 truncate text-capitalize font-weight-bold">
                                                                {item.user.name}
                                                            </p>
                                                            <p className="mb-1 text-muted text-small" style={{ fontWeight: '400' }}>
                                                                {dateFormatter(item.created)}
                                                            </p>
                                                        </div>

                                                        {
                                                            item.status === 'accepted' ?
                                                                <div className='d-flex flex-column align-items-center'>
                                                                    <div className="font-weight-bold">ready In</div>
                                                                    <div className='rounded-circle d-flex align-items-center' style={{ width: '45px', height: '45px', justifyContent: "center", lineHeight: '10px', border: `3px solid ${color[item.status]}` }}>
                                                                        <Countdown renderer={this.renderer} date={moment(item.accepted).add((item.preparationTime && item.preparationTime.value ? Number(item.preparationTime.value) : item.preparationTime), (item.preparationTime && item.preparationTime.label ? (item.preparationTime.label === 'mins' ? 'minutes' : 'hours') : 'minutes'))}></Countdown>
                                                                    </div>
                                                                </div>

                                                                : item.status === 'delivery' ?
                                                                    <div className='d-flex flex-column align-items-center'>
                                                                        <div className='font-weight-bold'>{item.receipt.type === 'pickup' ? 'pickup in' : 'deliver In'}</div>
                                                                        <div className='rounded-circle d-flex align-items-center' style={{ width: '40px', height: '40px', justifyContent: "center", lineHeight: '10px', border: `3px solid ${color[item.status]}` }}>
                                                                            <Countdown renderer={this.renderer} date={moment(item.deliveryStamp).add(item.deliveryTime, 'minutes')}></Countdown>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div style={{ padding: '8px', color: color[item.status], fontWeight: '400' }} >{item.status}</div>

                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                                    :
                                    <div className='d-flex flex-column align-items-center'>
                                        <div className="font-weight-bold">No Orders yet</div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </Card>
        );
    }
}

const mapStateToProps = ({ chatApp, settings }) => {
    let upgrade;
    if (settings.type === 'free_trial' && settings.remainigOrders <= 0) {
        upgrade = true
    }
    return { chatApp, upgrade };
};
export default injectIntl(
    connect(
        mapStateToProps,
        {
            getOrderList,
            getOrderListWithOrder,
            getOrderListSearch,
            selectedOrderItemsChange,
            deleteOrderItem,
        }
    )(ChatApplicationMenu)
);
