import React, { Component, Fragment } from "react";
import {
  Row,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  ModalBody,
  ModalFooter,
  Collapse,
  Modal,
  Input,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { firestore } from 'firebase';
import {
  getTodoList,
  getTodoListWithOrder,
  getTodoListSearch,
  selectedTodoItemsChange,
  deleteTodoItem,
  updateTodoItem

} from "../../../redux/actions";
import ModifierItem from "./components/modifierItem";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import AddEditItem from './components/modifier';
import { NotificationManager } from "../../../components/common/react-notifications";
import IntlMessages from "../../../helpers/IntlMessages";



class ModifiersList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openVideo: false,
      displayOptionsIsOpen: false,
      loading: false,
      editList: false,
      modifierList: []

    };
  }


  handleKeyPress = e => {
    if (e.key === "Enter") {
      this.setState({ searchKeyword: e.target.value })
    }
  };


  itemClickd(item) {
    this.setState({ edit: JSON.parse(JSON.stringify(item)), editList: true })
  }

  removeItemFromList = (item) => {
    let { modifierList } = this.state

    let key = localStorage.getItem('res')
    firestore().collection('resturant').doc(key).collection('modifiersList').doc(item.id).delete().then(() => {
      let newList = modifierList.filter(list => {
        return list.id !== item.id
      })
      this.setState({ confirmDeleteItem: false, modifierList: newList }, () => {
        NotificationManager.success(
          "",
          "Item Deleted successfully",
          3000,
          null,
          null,
        )
      })
    })
  }
  // componentWillReceiveProps() {
  //   let { allItems } = Object.assign([], this.props.allItems)
  //   let { modifierList } = this.state
  //   allItems && allItems.map((item, index) => (
  //     item.modifierList && item.modifierList.map(modifier => {
  //       modifierList.push(modifier)
  //     })
  //   ))
  //   const uniqueAddresses = Array.from(new Set(modifierList.map(a => a.name)))
  //     .map(name => {
  //       return modifierList.find(a => a.name === name)
  //     })
  //   this.setState({ modifierList: uniqueAddresses })
  //   console.log("itemitemitem", uniqueAddresses)

  // }

  componentDidMount() {
    let _this = this
    let reskey = localStorage.getItem('res')
    firestore().collection('resturant').doc(reskey).collection('modifiersList').onSnapshot(function (snap) {
      let modifierList = []
      snap.forEach(doc => {
        modifierList.push(doc.data())
      })


      _this.setState({ modifierList })
    })

  }
  toggleBack = () => {
    this.setState(prevState => ({
      openVideo: !prevState.openVideo
    }));
  };
  render() {
    const {
      selectedItems
    } = this.props.todoApp;
    let { edit, searchKeyword, modifierList, editList } = this.state;
    const { messages } = this.props.intl;
    let { allItems } = Object.assign([], this.props.allItems)
    let { categouryMap } = this.props.allItems
    let loading = this.props.allItems && this.props.allItems.loading
    let { currency } = this.props
    let { categouryObject, filterArray } = categouryMap ? categouryMap : { categouryObject: {}, filterArray: [] }
    if (searchKeyword) {
      let filteredArray = Object.keys(categouryObject).map(item => {
        if (categouryObject[item].toLowerCase() === searchKeyword) {
          return item
        }
        return false
      })
      filteredArray = filteredArray.filter(item => item !== false)

      if (filteredArray && filteredArray.length > 0) {
        allItems = allItems.filter(item => filteredArray.includes(item.id))

      } else {
        allItems = allItems.filter(item => item.name.toLowerCase().includes(searchKeyword.toLowerCase()))

      }

    }
    if (edit) {
      return (
        <Fragment>
          <Row className="survey-app">
            <Colxx xxs="12">
              <div className="mb-2">
                <h1>
                  <IntlMessages id={"menus.item-details"} /> 
                  {/* <Button size="xs" outline color='primary' onClick={this.toggleBack}><IntlMessages id={"menus.tutorial"} /></Button> */}
                </h1>
              </div>
              <Separator className="mb-5" />
              <AddEditItem editList={editList} edit={edit} allItems={this.props.allItems ? this.props.allItems.allItems : []} dismiss={() => this.setState({ edit: null, editList: false })}></AddEditItem>
            </Colxx>
          </Row>
        </Fragment>
      )
    }
    return (
      <Fragment>
        <Row className="survey-app">
          <Colxx xxs="12">
            <div>
              <h1>
                <IntlMessages id={"menus.modifiers-list"} /> 
                {/* <Button size="xs" outline color='primary' onClick={this.toggleBack}><IntlMessages id={"menus.tutorial"} /></Button> */}
              </h1>
            </div>
            <div className="mb-2 d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
              <Collapse
                id="displayOptions"
                className="d-md-block"
                isOpen={this.state.displayOptionsIsOpen}
              >
                <div className="d-block mb-2 d-md-inline-block">
                  <div className="d-flex">
                    <div className="search-sm d-inline-block float-md-left mr-1 mb-1 align-top" >
                      <Input
                        type="text"
                        name="keyword"
                        id="search"
                        placeholder={messages["menu.search"]}
                        defaultValue={searchKeyword}
                        value={searchKeyword}
                        onKeyPress={e => this.handleKeyPress(e)}
                        onChange={(e) => {
                          this.setState({ searchKeyword: e.target.value })
                        }}
                      />
                    </div>
                    {
                      searchKeyword && <div>
                        <Button size="xs" color='primary' style={{ borderColor: 'var(--theme-color-1)', color: 'white' }} onClick={() => this.setState({ searchKeyword: '' })}><IntlMessages id={"menus.reset"} /></Button>
                      </div>
                    }


                  </div>
                </div>
              </Collapse>
              <div className="float-right" >
                {loading && (
                  <div className="text-zero top-right-button-container">
                    <Button
                      color="primary"
                      size="lg"
                      className="top-right-button"
                      onClick={() => this.setState({ edit: {}, editList: false })}
                    >
                      <IntlMessages id={"menus.create-new"} />

                    </Button>
                  </div>
                )}

              </div>

            </div>
            <Separator className="mb-5" />
            <Row>
              {loading ? (
                modifierList.map((item, index) => (
                  <ModifierItem
                    currency={currency}
                    key={`todo_item_${index}`}
                    item={item}
                    handleCheckChange={this.handleCheckChange}
                    isSelected={
                      loading ? selectedItems.includes(item.id) : false
                    }
                    deleteItem={(item) => this.setState({ confirmDeleteItem: item })}
                    rowClicked={(selectedItem) => { this.itemClickd(selectedItem) }}
                    categouryMap={categouryObject}
                  />
                ))
              ) : (
                <div className="loading" />
              )}
            </Row>
          </Colxx>
        </Row>
        {
          this.state.confirmDeleteItem && <Modal isOpen={true}><ModalBody><IntlMessages id={"menus.are-you-sure"} /> {this.state.confirmDeleteItem.name}</ModalBody>
            <ModalFooter>
              <Button outline color='danger' className='m-1' onClick={() => this.setState({ confirmDeleteItem: false })}><IntlMessages id={"menus.cancel"} /></Button>
              <Button color='primary ' className='m-1' onClick={() => this.removeItemFromList(this.state.confirmDeleteItem)}><IntlMessages id={"menus.confirm"} /></Button>
            </ModalFooter>
          </Modal>
        }

        <Modal isOpen={this.state.openVideo}
          toggle={this.toggleBack} style={{ maxWidth: 'max-content' }}  ><iframe width="806" height="465" src="https://www.youtube.com/embed/30JTHFBWzp4?list=PL7VkScEgCZACVDFdNQ1w5trBq2c2odQc9" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </Modal>

      </Fragment>
    )
  }
}

const mapStateToProps = ({ todoApp, allItems, settings }) => {
  return {
    todoApp,
    allItems,
    currency: settings.currency

  };
};
export default injectIntl(
  connect(
    mapStateToProps,
    {
      getTodoList,
      getTodoListWithOrder,
      getTodoListSearch,
      selectedTodoItemsChange,
      deleteTodoItem,
      updateTodoItem
    }
  )(ModifiersList)
);