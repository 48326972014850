import React, { Component, Fragment } from "react";
import {
    Row,
    Button,
    Collapse,
    Input,
    Label,
    Card,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormText,
    InputGroup,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    CustomInput,
    UncontrolledDropdown
} from "reactstrap";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { NotificationManager } from "../../../../components/common/react-notifications";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import { firestore, functions } from 'firebase'

import * as typeformEmbed from '@typeform/embed'
import { getDirection } from "../../../../helpers/Utils";
function openTypeForm(typeformUrl) {
    typeformEmbed.makePopup(
        typeformUrl,
        // 'https://food2order.typeform.com/to/foutcT',
        {
            mode: 'popup',
            autoOpen: true,
            autoClose: 3,
            hideScrollbars: true,
            style: { width: "50%" },
            onSubmit: function () {
                console.log('Typeform successfully submitted')
            },
            onReady: function () {
                console.log('Typeform is ready')
            },
            onClose: function () {
                console.log('Typeform is closed')
            }
        }
    )
}
class Start extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collaps: {},
            openVideo: false,
            allLocations: {},
            search: '',
        };

    }
    componentWillReceiveProps(prop) {
        if (prop.branch !== this.props.branch) {
            this.getList(prop.branch)
        }
    }
    componentWillMount() {
        let { branch } = this.props
        // if (branch && branch.key) {
        this.getList(branch)
        // }
    }
    getList(branch) {
        let key = localStorage.getItem('res')
        if (branch.key) {
            firestore().collection('resturant').doc(key).collection('locations').where('branches', 'array-contains', branch.key).get().then(results => {
                let locations = {}
                results.forEach(list => {
                    let data = list.data()
                    data.id = list.id
                    locations[list.id] = data
                })
                this.setState({ locations })
            })
        }
        firestore().collection('locations').doc(localStorage.getItem('country').toLowerCase()).collection('cities').get().then(results => {
            let allLocations = {}
            results.forEach(list => {
                let data = list.data()
                data.id = list.id
                allLocations[list.id] = data
            })
            this.setState({ allLocations })
        })
    }

    toggleBack = () => {
        this.setState(prevState => ({
            openVideo: !prevState.openVideo
        }));
    };

    render() {
        let { locations, list } = this.state
        let { currency } = this.props
        const direction = getDirection();

        return (
            <Fragment>
                <Row className="survey-app">
                    <Colxx xxs="12">
                        <div className="mb-2">
                            <h1>
                                {/* <IntlMessages id={"outlets.locations"} /> <Button size="xs" outline color='primary' onClick={this.toggleBack}>Tutorial</Button> */}
                                <IntlMessages id={"outlets.locations"} />
                            </h1>
                            <div className="text-zero top-right-button-container">
                                <Button
                                    color="light"
                                    style={{ margin: '0 8px', background: '#DEEBF4', borderColor: '#DEEBF4', color: '#303030' }}
                                    size="lg"
                                    className="top-right-button"
                                    onClick={() => openTypeForm("https://food2order.typeform.com/to/nzJsmR")}
                                ><IntlMessages id={"outlets.import"} /></Button>
                                <Button
                                    color="primary"
                                    size="lg"
                                    className="top-right-button"
                                    onClick={() => this.setState({ list: {} })}
                                ><IntlMessages id={"outlets.add-areas"} />
                                </Button>
                            </div>

                        </div>
                        <Separator className="mb-5" />
                        <Row>
                            <Colxx xxs="12" className="mb-3">
                                {
                                    locations && Object.keys(locations).map(key => {
                                        let item = locations[key]

                                        return (
                                            <div key={key} className='rounded mb-4' style={{ boxShadow: 'none' }}>
                                                <div className='d-flex justify-content-between m-4'>
                                                    <h3 >
                                                        <IntlMessages id={"outlets.areas-we-serve"} /> {item.fees + ' ' + currency}
                                                    </h3>

                                                    <div>
                                                        <Button style={{ margin: '4px' }} outline color='danger' onClick={() => this.setState({ confirmDelete: key })} size='xs'><IntlMessages id={"menus.delete"} /></Button>

                                                        <Button outline color='dark' onClick={() => this.setState({ list: JSON.parse(JSON.stringify(item)) })} size='xs'><IntlMessages id={"menus.edit"} /></Button>
                                                    </div>
                                                </div>
                                                {
                                                    item.areas && Object.keys(item.areas).map(areaTitle => {
                                                        let areas = item.areas[areaTitle].list ? item.areas[areaTitle].list : item.areas[areaTitle]
                                                        return (
                                                            <div key={areaTitle} className="mb-2">
                                                                <Row className='ml-5'>
                                                                    <h4>{direction.isRtl ? item.areas[areaTitle].name_ar : item.areas[areaTitle].name}</h4>
                                                                </Row>
                                                                <Collapse isOpen={true} className='ml-5'>
                                                                    {
                                                                        areas.map((area, i) => {
                                                                            return (
                                                                                <div key={i} className='ml-2 d-flex'>
                                                                                    <span className="badgeing badge-pill mr-1 ">
                                                                                        <i className="simple-icon-location-pin" />
                                                                                    </span>
                                                                                    <p > {direction.isRtl ? area.name_ar : area.name}</p>
                                                                                    {/* {area.name ? area.name : area} */}

                                                                                </div>
                                                                            )
                                                                        })
                                                                    }

                                                                </Collapse>
                                                            </div>

                                                        )
                                                    })
                                                }
                                                <Separator />
                                            </div>
                                        )
                                    })
                                }
                            </Colxx>
                            {list && this.renderAddNew()}
                        </Row>
                        {
                            this.state.confirmDelete && <Modal isOpen={true}>
                                <ModalBody><IntlMessages id={"outlets.confirm-delete-group"} /></ModalBody>
                                <ModalFooter>
                                    <Button color='danger' onClick={() => this.setState({ confirmDelete: false })}><IntlMessages id={"orders.cancel"} /></Button>
                                    <Button onClick={() => this.deleteLoaction(this.state.confirmDelete)}><IntlMessages id={"menus.confirm"} /></Button>
                                </ModalFooter>

                            </Modal>
                        }
                    </Colxx>
                </Row>
                {
                    <Modal isOpen={this.state.openVideo}
                        toggle={this.toggleBack} style={{ maxWidth: 'max-content' }}  ><iframe width="806" height="465" src="https://www.youtube.com/embed/pIbogUndZWk?list=PL7VkScEgCZACVDFdNQ1w5trBq2c2odQc9" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Modal>
                }
            </Fragment>
        )
    }
    deleteLoaction(locationsKey) {
        let { locations } = this.state
        let key = localStorage.getItem('res')

        firestore().collection('resturant').doc(key).collection('locations').doc(locationsKey).delete().then(() => {
            delete locations[locationsKey]
            this.setState({ locations, confirmDelete: false }, () => {

                NotificationManager.success(
                    "",
                    "Area group deleted successfully",
                    4000,
                    null,
                    null,
                )
            })
        })
    }

    search() {
        let { search } = this.state
        let filteredAreas = {}
        let allLocations = JSON.parse(JSON.stringify(this.state.allLocations))

        Object.values(allLocations).map(areaTitle => {

            let key = areaTitle.name
            if ((areaTitle.name).toLowerCase().includes(search) || (areaTitle.name_ar).toLowerCase().includes(search)) {
                return filteredAreas[key] = areaTitle
            }
            this.setState({ filteredAreas })

            let areas = areaTitle.areas.filter(area => {
                if (area.name.toLowerCase().includes(search) || area.name_ar.toLowerCase().includes(search)) {
                    return (area.name).toLowerCase().includes(search) || area.name_ar.toLowerCase().includes(search)
                }
                this.setState({ filteredAreas })

                return false
            })
            areaTitle.areas = areas
            if ((areaTitle.name).toLowerCase().includes(search) || (areaTitle.name_ar).toLowerCase().includes(search) || (areas && areas.length !== 0)) {
                return filteredAreas[key] = areaTitle
            }
            this.setState({ filteredAreas })

            return false

        })
    }
    renderAddNew() {
        let { list, collaps, search } = this.state
        let { currency } = this.props
        const direction = getDirection();

        let filteredAreas = this.state.filteredAreas && search !== "" ? this.state.filteredAreas : this.state.allLocations
        return (
            <Modal backdrop="static" size="lg" isOpen={true}>
                <ModalHeader>
                    {/* <IntlMessages id={"outlets.add-new-areas"} /> <Button size="xs" style={{ marginLeft: "10px" }} outline color='primary' className="float-right" onClick={this.toggleBack}>Tutorial</Button> */}
                    <IntlMessages id={"outlets.add-new-areas"} />
                </ModalHeader>
                <ModalBody>
                    <Row className="mb-4">
                        <Colxx>
                            <Label><IntlMessages id={"outlets.enter-delivery-fees"} /></Label>
                            {/* <FormText color="muted">
                                <IntlMessages id={"outlets.areas-you-deliver"} />
                            </FormText> */}
                            <Input
                                placeholder={'10 ' + currency}
                                type='number'
                                id='fees'
                                defaultValue={list.fees}
                                onChange={(e) => {
                                    list.fees = e.target.value
                                    this.setState({ list })
                                }}
                            />
                        </Colxx>
                        <Colxx>
                            <Label><IntlMessages id={"outlets.minimum-order-group"} /></Label>
                            <Input
                                placeholder={'10 ' + currency}
                                type='number'
                                id='minOrder'
                                defaultValue={list.minOrder}
                                onChange={(e) => {
                                    list.minOrder = e.target.value
                                    this.setState({ list })
                                }}
                            />

                        </Colxx>
                    </Row>
                    <Row>
                        <Colxx>
                            <Card className="rounded pb-3">
                                <Input
                                    style={{ margin: '16px', width: 'auto' }}
                                    id='autocomplete'
                                    type="text"
                                    placeholder={direction.isRtl ? "ابحث عن مناطق" : "Search for areas"}
                                    value={this.state.search}
                                    onChange={event => {
                                        this.setState({ search: event.target.value }, () => {
                                            this.search()
                                        });
                                    }}
                                />
                                <div style={{ maxHeight: '300px', overflow: "auto" }}>
                                    {
                                        Object.keys(filteredAreas).map((areaTitle) => {
                                            let key = filteredAreas[areaTitle].name
                                            return (
                                                <div id={areaTitle} key={areaTitle}>
                                                    <div className='p-2'>
                                                        <div className='d-flex' style={{ justifyContent: 'space-between' }}>
                                                            <div className='d-flex'>
                                                                <span onClick={() => {
                                                                    collaps[filteredAreas[areaTitle].name] = !collaps[areaTitle]
                                                                    this.setState({ collaps })
                                                                }
                                                                } className=" badgeing badge-pill handle mr-1">
                                                                    <i className={!collaps[areaTitle] ? "simple-icon-arrow-down" : "simple-icon-arrow-right"} />
                                                                </span>
                                                                <div>{direction.isRtl ? filteredAreas[areaTitle].name_ar : key}</div>
                                                            </div>
                                                            <div className='pr-3 pl-3'>
                                                                <CustomInput
                                                                    type="checkbox"
                                                                    id={`check_${areaTitle}`}
                                                                    onChange={(event) => {
                                                                        if (event.target.checked) {
                                                                            this.handleSelectAll(areaTitle)
                                                                        } else {
                                                                            list.areas[key].list = []
                                                                            delete list.areas[key]
                                                                            this.setState({ list })
                                                                        }
                                                                    }}
                                                                    checked={(list.areas || false) && (list.areas[key] || false) && (list.areas[key].list || false) && (list.areas[key].list).length === (filteredAreas[areaTitle].areas).length}
                                                                />
                                                            </div>

                                                        </div>
                                                        <Collapse isOpen={!collaps[areaTitle]} className='mt-2'>
                                                            {
                                                                filteredAreas[areaTitle].areas && filteredAreas[areaTitle].areas.map((area, index2) => {
                                                                    return (
                                                                        <div id={area.name} key={area.name} className='d-flex justify-content-between pl-5 pr-3 pb-1'>
                                                                            <div>{direction.isRtl ? area.name_ar : area.name}</div>
                                                                            <CustomInput
                                                                                type="checkbox"
                                                                                id={`check_${area.name}`}
                                                                                onChange={(event) => {
                                                                                    if (event.target.checked) {
                                                                                        this.handlePlaceSelect(filteredAreas[areaTitle], area)
                                                                                    } else {
                                                                                        let newList = []
                                                                                        if (list.areas[key] && list.areas[key].list && (list.areas[key].list).length > 1) {
                                                                                            newList = (list.areas[key].list).filter(listItem => {
                                                                                                return listItem.name !== area.name
                                                                                            })
                                                                                            list.areas[key].list = newList
                                                                                        } else {
                                                                                            delete list.areas[key]
                                                                                        }
                                                                                        this.setState({ list })
                                                                                    }
                                                                                }}
                                                                                checked={(list.areas || false) && (list.areas[key] || false) && (list.areas[key].list || false) && ((list.areas[key].list).find(item => {
                                                                                    return (item.name === area.name)
                                                                                }) || false)
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </Collapse>

                                                    </div>

                                                </div>

                                            )
                                        })
                                    }
                                </div>

                            </Card>
                        </Colxx>
                    </Row>

                    <Row>
                        <Colxx>
                            {
                                list && <div className='mt-4'>
                                    <Label><IntlMessages id={"outlets.estimated-delivery-time"} /></Label>
                                    <InputGroup className='mb-4'>
                                        <Input
                                            placeholder={'10 min'}
                                            type='number'
                                            id='estimatedTime'
                                            value={list.deliveryTime && list.deliveryTime.value}
                                            onChange={(e) => {
                                                if (list.deliveryTime) {
                                                    list.deliveryTime.value = e.target.value
                                                }
                                                else {
                                                    list.deliveryTime = { value: e.target.value, label: 'min' }
                                                }
                                                this.setState({ list })
                                            }}
                                        />
                                        <UncontrolledDropdown addonType="append">
                                            <DropdownToggle caret outline color="primary">
                                                {
                                                    list.deliveryTime && list.deliveryTime.label ? list.deliveryTime.label : 'Select'
                                                }
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {
                                                    ['min', 'hour', 'day'].map(item => {
                                                        return (
                                                            <DropdownItem key={item} onClick={() => {
                                                                if (list.deliveryTime) {
                                                                    list.deliveryTime.label = item
                                                                }
                                                                else {
                                                                    list.deliveryTime = { label: item }
                                                                }
                                                                this.setState({ list })
                                                            }

                                                            }>
                                                                {item}
                                                            </DropdownItem>
                                                        )
                                                    })
                                                }

                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </InputGroup>

                                </div>
                            }
                        </Colxx>
                    </Row>


                </ModalBody>
                <ModalFooter>
                    <Button color='danger' onClick={() => this.setState({ list: false })}><IntlMessages id={"menus.cancel"} /></Button>
                    <Button disabled={!list || !list.areas} onClick={() => this.addList()}><IntlMessages id={"menus.save"} /></Button>
                </ModalFooter>


            </Modal>
        )
    }
    addList() {
        let { list, locations } = this.state
        let { branch } = this.props
        let post = {
            fees: list.fees ? list.fees : '',
            areas: list.areas,
            minOrder: list.minOrder ? Number(list.minOrder) : 0,
            branches: branch.key ? [branch.key] : [],
            deliveryTime: list.deliveryTime ? list.deliveryTime : false
        }
        let key = localStorage.getItem('res')

        // return 
        if (list.id) {
            firestore().collection('resturant').doc(key).collection('locations').doc(list.id).update(post).then((result) => {
                post.id = list.id
                locations[list.id] = post
                this.setState({ list: false, locations })
            })
        } else {
            firestore().collection('resturant').doc(key).collection('locations').add(post).then((result) => {
                post.id = result.id
                locations[result.id] = post
                this.setState({ list: false, locations })
            })
        }
    }
    handleSelectAll = (areaTitle) => {
        let _this = this
        let { list } = this.state
        let allLocations = this.state.filteredAreas ? this.state.filteredAreas : this.state.allLocations
        let allAreas = allLocations[areaTitle].areas
        if (list.areas && list.areas[areaTitle.name] && list.areas[areaTitle.name].list) {
            list.areas[allLocations[areaTitle].name].list = allAreas
        } else if (list.areas) {
            list.areas[allLocations[areaTitle].name] = { list: allAreas, name: allLocations[areaTitle].name, name_ar: allLocations[areaTitle].name_ar }
        } else {
            list.areas = { [allLocations[areaTitle].name]: { list: allAreas, name: allLocations[areaTitle].name, name_ar: allLocations[areaTitle].name_ar } }
        }

        _this.setState({ list });

    };
    handlePlaceSelect(areaTitle, area) {
        let _this = this
        let { list } = this.state

        if (list.areas && list.areas[areaTitle.name] && list.areas[areaTitle.name].list) {
            list.areas[areaTitle.name].list.push({ name: area.name, name_ar: area.name_ar, location: area.location })
        } else if (list.areas) {

            list.areas[areaTitle.name] = { list: [{ name: area.name, name_ar: area.name_ar, location: area.location }], name_ar: areaTitle.name_ar, name: areaTitle.name }
        } else {
            list.areas = { [areaTitle.name]: { list: [{ name: area.name, name_ar: area.name_ar, location: area.location }], name: areaTitle.name, name_ar: areaTitle.name_ar } }
        }
        _this.setState({ list });
    }

}

const mapStateToProps = ({ settings }) => {

    const { currency } = settings
    return {
        currency
    };
};
export default injectIntl(
    connect(
        mapStateToProps,
        {
        }
    )(Start)
);