import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import chatApp from './chat/reducer';
import authUser from './auth/reducer';
import todoApp from './todo/reducer';
import offers from './offers/reducer';
import testmonials from './testmonials/reducer';
import usersReducer from './users/reducer';
import orders from './orders/reducer';
import allItems from './getItemsReducer.js';



const reducers = combineReducers({
  menu,
  settings,
  authUser,
  todoApp,
  offers,
  testmonials,
  usersReducer,
  orders,
  allItems,
  chatApp
});

export default reducers;