import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { IntlProvider } from "react-intl";
import "./helpers/Firebase";
import AppLocale from "./lang";
import NotificationContainer from "./components/common/react-notifications/NotificationContainer";
import main from "./views";
import app from "./views/app";
import user from "./views/user";
import error from "./views/error";
import { getDirection } from "./helpers/Utils";
// import Onboarding from './views/app/wizard/wizard.js'
import {
  getOrderListSuccess

} from "./redux/actions";
import Onboarding from './views/onboard/index'
import Register from './views/register/index'
import PlanDetails from "./views/register/steps/planDetails";
import { Card, CardBody, CardHeader, Modal, ModalBody } from "reactstrap";
import Button from "./components/common/Button";
const AuthRoute = ({ component: Component, authUser, ...rest }) => (

  <Route
    {...rest}
    render={props =>

      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/user/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

class App extends Component {
  constructor(prop) {
    super(prop)
    this.state = {

    }
  }
  componentWillMount() {
    const direction = getDirection();
    if (direction.isRtl) {
      document.body.classList.add("rtl");
      document.body.classList.remove("ltr");
    } else {
      document.body.classList.add("ltr");
      document.body.classList.remove("rtl");
    }

  }
  render() {
    const { locale, loginUser, subscription, remainigOrders } = this.props;
    let { upgradePlan } = this.state
    const currentAppLocale = AppLocale[locale];
    return (
      <div className="h-100">
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <React.Fragment>
            <NotificationContainer />
            {
              remainigOrders && <div style={{
                display: 'flex',
                justifyContent: 'center',
                position: 'fixed',
                top: '0',
                right: 0,
                left: 0,
                alignItems: 'center',
                backgroundColor: '#f9ebd5',
                padding: '8px',
                fontSize: '16px',
                zIndex: 99
              }}>
                <span style={{ fontSize: '18px', fontWeight: 'bold', padding: '0 4px' }}>{remainigOrders}</span>
                Orders remaining, Keep spreading the channel with your customers
                <span style={{ padding: '0 8px' }}>
                  <Button
                    onClick={() => {
                      this.setState({ upgradePlan: true })
                    }}
                    size='xs' color="primary" style={{ fontSize: '16px', fontWeight: 'bold', padding: '0 8px' }}>Upgrade Now</Button>
                </span>
              </div>
            }

            {/* {
              orderExcceded &&
              <Modal style={{ borderRadius: "16px", overflow: 'hidden' }} isOpen={true}>
                <ModalBody>
                  <Card>
                    <div style={{ padding: '8px', textAlign: 'center' }}>
                      <div>
                        You have reached maximum orders for this month,your new 10 orders will be topped up on 22/10/22
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'center' ,padding:'16px'}}>
                        <Button color='primary'>
                          subscribe
                        </Button>
                      </div>

                    </div>

                  </Card>

                </ModalBody>
              </Modal>

            } */}

            {/* subscriptions */}
            {/* {loginUser && (subscription === 'subscripe' || upgradePlan) &&
              <PlanDetails dismissable={upgradePlan} dismiss={() => this.setState({ upgradePlan: false })} />
            } */}
            <Router>
              <Switch>
                <AuthRoute path="/app" authUser={loginUser} component={app} />
                <Route path="/user" component={user} />
                <Route path='/get-started' component={Onboarding} />
                <Route path='/register' component={Register} />
                <Route path="/error" exact component={error} />

                <Route path="/" exact component={main} />
                {/* <Redirect to="/error" /> */}
              </Switch>
            </Router>
          </React.Fragment>
        </IntlProvider>
        {/* {
          loaded && <Modal isOpen={true}>
            <ModalBody>
              {"You have " + activateSound + " new orders"}
            </ModalBody>

            <ModalFooter >
              <Button color='primary' onClick={() => { beep.play(); this.setState({ activateSound: false, loaded: false }) }}>Dismiss</Button>
            </ModalFooter>
          </Modal>
        } */}

      </div>
    );
  }
}

const mapStateToProps = ({ authUser, settings, orders, usersReducer }) => {
  const { user: loginUser } = authUser;
  let subscription = usersReducer.subscription;
  let remainigOrders;
  if (settings.type === 'free_trial') {
    document.documentElement.style.setProperty('--navbar-height', '110px');
    subscription = "free_trial"
    remainigOrders = settings.remainigOrders
  }

  const { locale } = settings;
  return { loginUser, locale, orders, subscription, remainigOrders };
};
const mapActionsToProps = (dispatch) => ({
  getOrderListSuccess: (updatedList) => dispatch(getOrderListSuccess(updatedList)),
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(App);
