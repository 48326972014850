import React, { useState } from 'react';

import './storeDetails.css'
import Food2orderLogo from '../../../assets/css/img/footerLogo.png'
import Step2Img from '../../../assets/css/img/step2Img.png'
import { Button, Input } from 'reactstrap';
import { auth, functions } from 'firebase';
const AccountDetails = ({ setStep, state, setState }) => {
    return (
        <div className='storeDetails'>
            <div className='storeDetailsLeftSide'>
                <img style={{ position: 'absolute', top: '41px', left: '26px', width: '206px' }} src={Food2orderLogo} />
                <img src={Step2Img} style={{ width: '60%' }} />
            </div>
            <div className='storeDetailsRightSide'>
                <div className='stepTitle'>Step 2 of 3</div>
                <div className='welcomeTitle'>Account infromation</div>
                <div className='desc'>Enter your personal information to create account</div>
                <div className='detailsTitle'>Your Account Details</div>
                <div className='inputsContainer'>
                    <div className='inputsContainer2'>
                        <div className='inputsTitle'>Your Name <span>*</span></div>
                        <Input className='inputStyle' onChange={(e) => setState({ ...state, name: e.target.value })} />
                    </div>
                    <div className='inputsContainer2'>
                        <div className='inputsTitle'>Mobile number <span>*</span></div>
                        <Input type='number' className='inputStyle' onChange={(e) => setState({ ...state, number: e.target.value })} />
                    </div>
                    <div className='inputsContainer2'>
                        <div className='inputsTitle'>Email <span>*</span></div>
                        <Input className='inputStyle' type='email' onChange={(e) => setState({ ...state, email: e.target.value })} />
                    </div>
                    <div className='inputsContainer2'>
                        <div className='inputsTitle'>Password <span>*</span></div>
                        <Input className='inputStyle' type='password' onChange={(e) => setState({ ...state, password: e.target.value })} />
                    </div>
                </div>
                <Button
                    color="primary"
                    // className="btn-shadow"
                    size="lg"
                    style={{ width: '100%', marginTop: '60px', fontSize: '15px', fontWeight: '600' }}
                    onClick={async () => {
                        let user = await auth().createUserWithEmailAndPassword(state.email, state.password);
                        var createRes = functions().httpsCallable('createNewRes');
                        createRes(state)
                            .then((result) => {
                                console.log("result.data", result);
                            });
                    }}
                >
                    Continue
                </Button>
            </div>
        </div>
    );
};

export default AccountDetails;