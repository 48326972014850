import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { USER_GET_LIST, USER_ADD_ITEM, USER_UPDATE_ITEM ,USER_DELETE_ITEM} from "../actions";

import {
  getUserListSuccess,
  getUserListError,
  addUserItemSuccess,
  addUserItemError,
} from "./actions";

import { database, storage } from '../../helpers/Firebase';
const getUserListRequest = async () => {
  return await new Promise((success, fail) => {
    database.collection('users').get().then(results => {
      console.log("users",results)
      let list = []
      results.forEach((item) => {
        let value = item.data();
        value.id = item.id
        list.push(value)
      })
      success(list);
    })
  })
    .then(response => response)
    .catch(error => error);
};

function* getUserListItems() {
  try {
    const response = yield call(getUserListRequest);
    yield put(getUserListSuccess(response));
  } catch (error) {
    yield put(getUserListError(error));
  }
}

const addUserItemRequest = async item => {
  return await new Promise((success, fail) => {
    let id = database.collection('users').doc().id

    if (item.pictures && item.pictures.length) {
      item.pictures.forEach(picture => {
        var storageRef = storage.ref().child("users").child(id).child("img").put(picture);
        storageRef.on('state_changed', function (snapshot) {
        }, function (error) {
          // Handle unsuccessful uploads
        }, function () {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          storageRef.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            database.collection('users').doc(id).set({
              title: item.title,
              detail: item.detail,
              category: item.category,
              price: item.price,
              code: item.code,
              link: item.link,
              created: Date.now(),
              pictures: [downloadURL]
            }).then(() => {
              getUserListRequest().then(results => {
                success(results)
              })
            })
          });
        });
      })
    } else {
      database.collection('users').doc(id).set({
        title: item.title,
        detail: item.detail,
        category: item.category,
        price: item.price,
        code: item.code,
        link: item.link,
        created: Date.now(),
      }).then(() => {
        getUserListRequest().then(results => {
          success(results)
        })
      })
    }
  })
    .then(response => response)
    .catch(error => error);
};
const deleteUserItemRequest = async items => {
  console.log("deleteUserItemRequest",items)
  return await new Promise((success, fail) => {
    items.forEach(item=>{
      database.collection('users').doc(item).delete()
    })
    getUserListRequest().then(results => {
        success(results)
      })
  })

}
const updateUserItemRequest = async item => {
  return await new Promise((success, fail) => {


    if (item.pictures && item.pictures.length) {
      item.pictures.forEach(picture => {
        if (picture.name) {
          var storageRef = storage.ref().child("users").child(item.id).child("img").put(picture);
          storageRef.on('state_changed', function (snapshot) {
          }, function (error) {
            // Handle unsuccessful uploads
          }, function () {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            storageRef.snapshot.ref.getDownloadURL().then(function (downloadURL) {
              console.log('File available at', downloadURL);
              let ref = database.collection('users').doc(item.id)
              return ref.update({
                title: item.title,
                detail: item.detail ? item.detail : '',
                category: item.category ? item.category : '',
                price: item.price ? item.price : '',
                code: item.code ? item.code : '',
                link: item.link ? item.link : '',
                updated: Date.now(),
                pictures: [downloadURL],
              }).then(() => {
                getUserListRequest().then(results => {
                  success(results)
                })
              }).catch(error => {
                console.log("errorerror", error)

              })
            });
          });
        } else {
          let ref = database.collection('users').doc(item.id)
          return ref.update({
            title: item.title,
            detail: item.detail ? item.detail : '',
            category: item.category ? item.category : '',
            price: item.price ? item.price : '',
            code: item.code ? item.code : '',
            link: item.link ? item.link : '',
            updated: Date.now()
          }).then(() => {

            getUserListRequest().then(results => {
              success(results)
            })
          }).catch(error => {
            console.log("errorerror", error)

          })

        }
      })
    }
  })
    .then(response => response)
    .catch(error => error);
};

function* addUserItem({ payload }) {
  try {
    const response = yield call(addUserItemRequest, payload);
    yield put(addUserItemSuccess(response));
  } catch (error) {
    yield put(addUserItemError(error));
  }
}
function* updateUserItem({ payload }) {
  try {
    const response = yield call(updateUserItemRequest, payload);
    yield put(addUserItemSuccess(response));
  } catch (error) {
    yield put(addUserItemError(error));
  }
}
function* deleteUserItem({ payload }) {
  try {
    const response = yield call(deleteUserItemRequest, payload);
    yield put(addUserItemSuccess(response));
  } catch (error) {
    yield put(addUserItemError(error));
  }
}
export function* watchGetList() {
  yield takeEvery(USER_GET_LIST, getUserListItems);
}

export function* wathcAddItem() {
  yield takeEvery(USER_ADD_ITEM, addUserItem);
}
export function* wathcUpdateItem() {
  yield takeEvery(USER_UPDATE_ITEM, updateUserItem);
}
export function* wathcDeleteItems() {
  yield takeEvery(USER_DELETE_ITEM, deleteUserItem);
}
export default function* rootSaga() {
  yield all([fork(watchGetList), fork(wathcAddItem), fork(wathcUpdateItem),fork(wathcDeleteItems)]);
}
