import React, { Component } from 'react';
import { Modal, ModalBody, Input, Button } from 'reactstrap';
import './onboardModal.css'
class OnboardModal extends Component {
    state = {
        activeTap: 'unlock'
    }
    render() {
        let { activeTap, activeStep } = this.state
        let steps = [
            { key: 'info', value: "Store Information" },
            { key: 'menu', value: "Create your 1st item" },
            { key: 'onlineOrder', value: "Activate online ordering" }


        ]
        return (
            <div >
                <Modal isOpen={true} >
                    <ModalBody>
                        <div className='onboardModal'>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div className='header_tap'>Unlock 2 weeks trial</div>
                                <div className='header_tap'>Skip and get help now</div>
                            </div>
                            <div>
                                {
                                    activeTap === 'unlock' ?
                                        <div style={{ padding: '12px 0' }}>
                                            <div>Complete below steps to unlock your Subscription</div>

                                            <div>
                                                {
                                                    steps.map(rec => {
                                                        return (
                                                            <div key={rec.key} className='step_card' onClick={() => this.setState({ activeStep: rec.key })} >
                                                                <div>
                                                                    {rec.value}
                                                                </div>
                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '25%' }}>
                                                                    <div>credit:4</div>
                                                                    <div style={{ width: '40px', height: '40px', borderRadius: '50%', background: '#fff' }}></div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        : ''
                                }
                            </div>
                        </div>
                    </ModalBody>
                    {
                        activeStep ?
                            this.renderNestedModal(activeStep)
                            : ''
                    }

                </Modal>

            </div>
        );
    }
    renderNestedModal(flag) {
        return (
            <Modal isOpen={true}>
                <ModalBody>
                    {
                        flag === 'info' ?
                            this.renderAddInfo()
                            : flag === 'menu' ?
                                this.renderCreateItem()
                                : ''
                    }
                </ModalBody>
            </Modal>
        )

    }
    renderAddInfo() {
        return (
            <div className='onboardModal'>
                <div className='row_group'>
                    <div>Store Address</div>
                    <Input />
                </div>
                <div className='row_group'>
                    <div>Store phone number</div>
                    <Input />
                </div>
                <div className='row_group'>
                    <div>Work hours</div>
                    <Input />
                </div>
                <div className='row_group'>
                    <div>Logo</div>
                    <Input />
                </div>
                <div className='row_group'>
                    <div>brand color</div>
                    <Input />
                </div>
                <div className='row_group'>
                    <Button style={{ width: '100%' }} onClick={() => this.setState({ activeStep: false })}>Submit</Button>

                </div>
            </div>
        )
    }
    renderCreateItem() {
        return (
            <div className='onboardModal'>

            </div>
        )

    }
}

export default OnboardModal;