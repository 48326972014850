import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';


import store from "./store";

const PagesProduct = ({ match }) => (
  // <div className="dashboard-wrapper orderListCustom" >
  <div >
    <Switch>
      {/* <Redirect exact from={`${match.url}`} to={`${match.url}/store`} /> */}
      <Route path={`${match.url}`} component={store} />
      <Redirect to="/error" />
    </Switch>
  </div>
);
export default PagesProduct;
