import {
    OFFER_GET_LIST,
    OFFER_GET_LIST_SUCCESS,
    OFFER_GET_LIST_ERROR,
    OFFER_GET_LIST_WITH_FILTER,
    OFFER_GET_LIST_WITH_ORDER,
    OFFER_GET_LIST_SEARCH,
    OFFER_ADD_ITEM,
    OFFER_DELETE_ITEM,
    OFFER_UPDATE_ITEM,
    OFFER_ADD_ITEM_SUCCESS,
    OFFER_ADD_ITEM_ERROR,
    OFFER_SELECTED_ITEMS_CHANGE
} from '../actions';


export const getOfferList = () => ({
    type: OFFER_GET_LIST
});

export const getOfferListSuccess = (items) => ({
    type: OFFER_GET_LIST_SUCCESS,
    payload: items
});

export const getOfferListError = (error) => ({
    type: OFFER_GET_LIST_ERROR,
    payload: error
});

export const getOfferListWithFilter = (column, value) => ({
    type: OFFER_GET_LIST_WITH_FILTER,
    payload: { column, value }
});

export const getOfferListWithOrder = (column) => ({
    type: OFFER_GET_LIST_WITH_ORDER,
    payload: column
});

export const getOfferListSearch = (keyword) => ({
    type: OFFER_GET_LIST_SEARCH,
    payload: keyword
});

export const addOfferItem = (item) => ({
    type: OFFER_ADD_ITEM,
    payload: item
});
export const updateOfferItem = (item) => ({
    type: OFFER_UPDATE_ITEM,
    payload: item
});
export const deleteOfferItem = (item) => ({
    type: OFFER_DELETE_ITEM,
    payload: item
});
export const addOfferItemSuccess = (items) => ({
    type: OFFER_ADD_ITEM_SUCCESS,
    payload: items
});

export const addOfferItemError = (error) => ({
    type: OFFER_ADD_ITEM_ERROR,
    payload: error
});

export const selectedOfferItemsChange = (selectedItems) => ({
    type: OFFER_SELECTED_ITEMS_CHANGE,
    payload: selectedItems
});
