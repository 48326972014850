import React from "react";
import { Button, Spinner } from "reactstrap";
import classnames from "classnames";

export default ({ children, loading, block, ...rest }) => (
    <Button {...rest} block={block} style={{ ...rest.style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {
            loading ?
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                :
                <span
                    className={classnames({
                        invisible: loading,
                        visible: !loading,
                        display: 'none',
                        "span-style": !block
                    })}
                >
                    {children}
                </span>
        }
    </Button>
);
