import React, { Component, Fragment } from "react";
import {
  Row,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  ModalBody,
  ModalFooter,
  Collapse,
  Modal,
  Input,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { firestore } from 'firebase';
import {
  getTodoList,
  getTodoListWithOrder,
  getTodoListSearch,
  selectedTodoItemsChange,
  deleteTodoItem,
  updateTodoItem

} from "../../../redux/actions";
import TodoListItem from "../../../components/applications/TodoListItem";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import AddEditItem from './menu-item-editor.js';
import { NotificationManager } from "../../../components/common/react-notifications";
import * as typeformEmbed from '@typeform/embed'
import IntlMessages from "../../../helpers/IntlMessages";

function openTypeForm(typeformUrl) {
  typeformEmbed.makePopup(
    typeformUrl,
    {
      mode: 'popup',
      autoOpen: true,
      autoClose: 3,
      hideScrollbars: true,
      style: { width: "50%" },
      onSubmit: function () {
        console.log('Typeform successfully submitted')
      },
      onReady: function () {
        console.log('Typeform is ready')
      },
      onClose: function () {
        console.log('Typeform is closed')
      }
    }
  )
}

class MenuItems extends Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);

    this.state = {
      loaded: true,
      dropdownSplitOpen: false,
      modalOpen: false,
      openVideo: false,
      lastChecked: null,
      expand: {},
      displayOptionsIsOpen: false,
      modification: {
        modifierList: []
      },
      loading: false
    };
  }

  toggleSplit = () => {
    this.setState(prevState => ({
      dropdownSplitOpen: !prevState.dropdownSplitOpen
    }));
  };


  handleKeyPress = e => {
    if (e.key === "Enter") {
      this.setState({ searchKeyword: e.target.value })
    }
  };
  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
      edit: null
    });
  };

  updateItem() {
    let reskey = localStorage.getItem('res')

    let { modification, edit } = this.state

    let updateItem = Object.assign({}, edit);
    Object.keys(modification).map(key => {
      updateItem[key] = modification[key]
    })
    const modifierList = updateItem.modifierList
    if (modifierList) {
      delete updateItem.modifierList
    }

    let id = updateItem.id ? updateItem.id : firestore().collection('resturant').doc(reskey).collection('menuItems').doc().id;

    let ref = firestore().collection('resturant').doc(reskey).collection('menuItems').doc(id);
    ref.set(updateItem, { merge: true }).then(() => {
      if (modifierList) {
        ref.update({
          modifierList: modifierList
        })
      }
      this.setState({ edit: null, modification: { modifierList: [] } })
    }).catch(error => {
      console.log("error", error)
    })

  }
  itemClickd(item) {
    let modification = {
      modifierList: item.modifierList ? item.modifierList : [],
      hide: item.hide
    }
    // console.log("itemitemitem", JSON.parse(JSON.stringify(item)));
    this.setState({ edit: item, modification })
  }
  removeItemFromList = (item) => {
    let key = localStorage.getItem('res')
    firestore().collection('resturant').doc(key).collection('menuItems').doc(item.id).delete().then(() => {
      this.setState({ confirmDeleteItem: false }, () => {
        NotificationManager.success(
          "",
          "Item Deleted successfully",
          3000,
          null,
          null,
        )
      })
    })
  }
  toggleBack = () => {
    this.setState(prevState => ({
      openVideo: !prevState.openVideo
    }));
  };
  render() {
    const {
      selectedItems
    } = this.props.todoApp;
    const { edit, searchKeyword } = this.state;
    const { messages } = this.props.intl;
    let { allItems } = Object.assign([], this.props.allItems)
    let { categouryMap } = this.props.allItems
    let loading = this.props.allItems && this.props.allItems.loading
    let { currency } = this.props
    let { categouryObject, filterArray } = categouryMap ? categouryMap : { categouryObject: {}, filterArray: [] }
    if (searchKeyword) {
      if (searchKeyword === 'out of stock') {
        allItems = allItems.filter(item => item.hide === true)
      } else {
        let filteredArray = Object.keys(categouryObject).map(item => {
          if (categouryObject?.[item]?.toLowerCase() === searchKeyword?.replace(/\s+/g, '')) {
            return item
          }
          return false
        })
        filteredArray = filteredArray.filter(item => item !== false)

        if (filteredArray && filteredArray.length > 0) {
          allItems = allItems?.filter(item => filteredArray.includes(item.id))

        } else {
          allItems = allItems?.filter(item => item?.name?.toLowerCase()?.includes(searchKeyword.toLowerCase()))

        }
      }

    }
    if (edit) {
      return (
        <Fragment>
          <Row className="survey-app">
            <Colxx xxs="12">
              <div className="mb-2">
                <h1>
                  <IntlMessages id={"menus.item-details"} /> <Button size="xs" outline color='primary' onClick={this.toggleBack}><IntlMessages id={"menus.tutorial"} /></Button>
                </h1>
              </div>
              <Separator className="mb-5" />
              <AddEditItem edit={edit} allItems={this.props.allItems ? this.props.allItems.allItems : []} dismiss={() => this.setState({ edit: null })}></AddEditItem>
            </Colxx>
          </Row>
        </Fragment>
      )
    }
    return (
      <Fragment>
        <Row className="survey-app">
          <Colxx xxs="12">
            <div>
              <h1>
                <IntlMessages id={"menus.menu-items"} /> <Button size="xs" outline color='primary' onClick={this.toggleBack}><IntlMessages id={"menus.tutorial"} /></Button>
                <p><IntlMessages id={"menus.list-all-menu-items"} /></p>

              </h1>
            </div>
            <div className="mb-2 d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
              <Collapse
                id="displayOptions"
                className="d-md-block"
                isOpen={this.state.displayOptionsIsOpen}
              >
                <div className="d-block mb-2 d-md-inline-block">
                  {
                    filterArray && filterArray.length > 0 && <UncontrolledDropdown className="mr-1 float-md-left btn-group mb-1">
                      <DropdownToggle caret color="outline-dark" size="xs">
                        <IntlMessages id={"menus.filter-by-categoury"} />
                      </DropdownToggle>
                      <DropdownMenu
                        modifiers={{
                          setMaxHeight: {
                            enabled: true,
                            order: 890,
                            fn: (data) => {
                              return {
                                ...data,
                                styles: {
                                  ...data.styles,
                                  overflow: 'auto',
                                  maxHeight: '300px',
                                },
                              };
                            },
                          },
                        }}
                      >
                        {filterArray.map((o, index) => {
                          return (
                            <DropdownItem
                              key={index}
                              onClick={() => this.setState({ searchKeyword: o.toLowerCase() })}
                            >
                              {o}
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  }

                  <div className="d-flex">
                    <div className="search-sm d-inline-block float-md-left mr-1 mb-1 align-top" >
                      <Input
                        type="text"
                        name="keyword"
                        id="search"
                        placeholder={messages["menu.search"]}
                        // defaultValue={searchKeyword}
                        value={searchKeyword}
                        onKeyPress={e => this.handleKeyPress(e)}
                        onChange={(e) => {
                          this.setState({ searchKeyword: e.target.value })
                        }}
                      />
                    </div>
                    {
                      searchKeyword && <div>
                        <Button size="xs" color='primary' style={{ borderColor: 'var(--theme-color-1)', color: 'white' }} onClick={() => this.setState({ searchKeyword: '' })}><IntlMessages id={"menus.reset"} /></Button>
                      </div>
                    }


                  </div>
                </div>
              </Collapse>
              <div className="float-right" >
                {loading && (
                  <div className="text-zero top-right-button-container">
                    <Button
                      color="light"
                      style={{ margin: '0 8px', background: '#DEEBF4', borderColor: '#DEEBF4', color: '#303030' }}
                      size="lg"
                      className="top-right-button"
                      onClick={() => openTypeForm("https://food2order.typeform.com/to/s5L1yF")}
                    >
                      <IntlMessages id={"menus.import"} />

                    </Button>
                    <Button
                      color="primary"
                      size="lg"
                      className="top-right-button"
                      onClick={() => {
                        let { productsLimit } = this.props;
                        if (allItems?.length >= productsLimit) {
                          return NotificationManager.error(
                            "",
                            "You exceeded maximum number of products",
                            3000,
                            null,
                            null,
                          );
                        }
                        this.setState({ edit: {} })
                      }}
                    >
                      <IntlMessages id={"menus.create-new"} />

                    </Button>
                  </div>
                )}

              </div>

            </div>
            <Separator className="mb-5" />
            <Row>
              {loading ? (
                allItems.map((item, index) => (
                  <TodoListItem
                    currency={currency}
                    key={`todo_item_${index}`}
                    item={item}
                    handleCheckChange={this.handleCheckChange}
                    isSelected={
                      loading ? selectedItems.includes(item.id) : false
                    }
                    deleteItem={(item) => this.setState({ confirmDeleteItem: item })}
                    rowClicked={(selectedItem) => { this.itemClickd(selectedItem) }}
                    categouryMap={categouryObject}
                  />
                ))
              ) : (
                <div className="loading" />
              )}
            </Row>
          </Colxx>
        </Row>
        {
          this.state.confirmDeleteItem && <Modal isOpen={true}><ModalBody><IntlMessages id={"menus.are-you-sure"} /> {this.state.confirmDeleteItem.name}</ModalBody>
            <ModalFooter>
              <Button outline color='danger' className='m-1' onClick={() => this.setState({ confirmDeleteItem: false })}><IntlMessages id={"menus.cancel"} /></Button>
              <Button color='primary ' className='m-1' onClick={() => this.removeItemFromList(this.state.confirmDeleteItem)}><IntlMessages id={"menus.confirm"} /></Button>
            </ModalFooter>
          </Modal>
        }

        <Modal isOpen={this.state.openVideo}
          toggle={this.toggleBack} style={{ maxWidth: 'max-content' }}  ><iframe width="806" height="465" src="https://www.youtube.com/embed/30JTHFBWzp4?list=PL7VkScEgCZACVDFdNQ1w5trBq2c2odQc9" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </Modal>

      </Fragment>
    )
  }
  onDrop(picture) {
    let { modification } = this.state
    if (picture.length) {
      let lastPic = picture[picture.length - 1]

      const reader = new FileReader();
      reader.readAsDataURL(lastPic);
      reader.onload = event => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const elem = document.createElement('canvas');
          let width = 400
          elem.width = width;
          const scaleFactor = width / img.width;
          elem.height = img.height * scaleFactor;
          const ctx = elem.getContext('2d');
          // img.width and img.height will contain the original dimensions
          ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
          ctx.canvas.toBlob((blob) => {
            const file = new File([blob], picture.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            });

            modification.startupPictures = [event.target.result]
            this.setState({
              pictures: [file],
              modification
            })
          }, 'image/jpeg', 1);

        }

        reader.onerror = (error) => console.log(error);
      };

    }
  }
  handleArrange(e) {
    let { modification } = this.state
    let list = modification.modifierList
    let newList = []
    e.forEach((element, index) => {
      let item = list.filter(item => Number(item.id) === Number(element))[0]
      if (item) {
        let itemCopy = JSON.parse(JSON.stringify(item))
        itemCopy.id = String(index + 1)
        newList.push(itemCopy)
      }
    })
    modification.modifierList = newList
    this.setState({ modification })
  }

  removeList(item) {
    let { modification } = this.state
    let id = item.id

    let newArray = modification.modifierList.filter(item => item.id !== id)
    modification.modifierList = newArray.map((item, i) => {
      item.id = String(i)
      return item
    })

    this.setState({ modification })
  }
}
const mapStateToProps = ({ todoApp, allItems, settings }) => {
  return {
    todoApp,
    allItems,
    currency: settings.currency,
    productsLimit: settings?.productsLimit || 250

  };
};
export default injectIntl(
  connect(
    mapStateToProps,
    {
      getTodoList,
      getTodoListWithOrder,
      getTodoListSearch,
      selectedTodoItemsChange,
      deleteTodoItem,
      updateTodoItem
    }
  )(MenuItems)
);