const data = [
  {
    id: "gogo",
    icon: "iconsminds-shop-4",
    label: "menu.dashboards",
    to: "/app/dashboard",
    role: ["admin"]
    // subs: [
    //   {
    //     icon: "iconsminds-testimonal",
    //     label: "Testimonials",
    //     to: "/app/home/testmonials"
    //   }
    // ]
  },
  {
    id: "menu-builder",
    icon: "iconsminds-receipt-4",
    label: "menu.menu-builder",
    to: "/app/menu-builder",
    role: ["admin"],
    subs: [
      {
        icon: "iconsminds-receipt-4",
        label: "menu.items-and-modifiers",
        to: "/app/menu-builder/menu-items"
      },
      {
        icon: "iconsminds-receipt-4",
        label: "menus.modifiers-list",
        to: "/app/menu-builder/modifiers-list"
      },

      {
        icon: "iconsminds-receipt-4",
        label: "menu.menus",
        to: "/app/menu-builder/builder"
      },

    ]
  },
  {
    id: "orders",
    icon: "iconsminds-headset",
    label: "menu.orders",
    to: "/app/orders",
    role: ["admin", "callCenter"]
  },
  // {
  //   id: "Offers",
  //   icon: "iconsminds-money-bag",
  //   label: "Offers",
  //   to: "/app/offers",
  // },


  {
    id: "CRMmenu",
    icon: "iconsminds-monitor-analytics",
    label: "menu.crm",
    to: "/app/crm",
    role: ["admin"],
    subs: [
      {
        icon: "simple-icon-people",
        label: "menu.customers",
        to: "/app/crm/customers"
      },
      {
        icon: "simple-icon-clock",
        label: "menu.order-history",
        to: "/app/crm/orderHistory"
      }
    ]
  },
  {
    id: "StoreMenu",
    icon: "iconsminds-shopping-cart",
    label: "menu.store",
    to: "/app/store",
    role: ["admin"],

  },
  {
    id: "OutletsMenu",
    icon: "iconsminds-home-4",
    label: "menu.outlets",
    to: "/app/outlets",
    role: ["admin"],

  },
  {
    id: "marketing",
    icon: "iconsminds-loudspeaker",
    label: "menu.marketing",
    to: "/app/marketing",
    role: ["superAdmin", "admin"],
    subs: [
      {
        icon: "iconsminds-qr-code",
        label: "menu.promocodes",
        to: "/app/marketing/promocodes"
      }
    ]
  },
  // {
  //   id: "secondmenu",
  //   icon: "iconsminds-gear",
  //   label: "Settings",
  //   to: "/app/settings",
  //   role: ["admin"],
  //   subs: [
  //     {
  //       icon: "iconsminds-map-marker-2",
  //       label: "Delivery Areas",
  //       to: "/app/settings/locations"
  //     }
  //   ]
  // },
  {
    id: "superAdmin",
    icon: "iconsminds-business-man",
    label: "menu.super-admin",
    to: "/app/admin",
    role: [],
    subs: [
      {
        icon: "iconsminds-map-marker-2",
        label: "menu.all-restaurant",
        to: "/app/admin/all"
      },
      {
        icon: "iconsminds-map-marker-2",
        label: "menu.clients",
        to: "/app/admin/clients"
      }
    ]
  },

];
export default data;
