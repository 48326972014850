import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { OFFER_GET_LIST, OFFER_ADD_ITEM, OFFER_UPDATE_ITEM ,OFFER_DELETE_ITEM} from "../actions";
import {
  getOfferListSuccess,
  getOfferListError,
  addOfferItemSuccess,
  addOfferItemError,
} from "./actions";

import { database, storage } from '../../helpers/Firebase';
const res = localStorage.getItem('res')

const getOfferListRequest = async () => {
  return await new Promise((success, fail) => {
    database.collection('resturant').doc(res).collection('offers').where('res','==',res).get().then(results => {
      let list = []
      results.forEach((item) => {
        let value = item.data();
        value.id = item.id
        list.push(value)
      })
      success(list);
    })
  })
    .then(response => response)
    .catch(error => error);
};

function* getOfferListItems() {
  try {
    const response = yield call(getOfferListRequest);
    yield put(getOfferListSuccess(response));
  } catch (error) {
    yield put(getOfferListError(error));
  }
}

const addOfferItemRequest = async item => {
  return await new Promise((success, fail) => {
    let id = database.collection('resturant').doc(res).collection('offers').doc().id

    if (item.pictures && item.pictures.length) {
      item.pictures.forEach(picture => {
        var storageRef = storage.ref().child('resturant').child(res).child("offers").child(id).child("img").put(picture);
        storageRef.on('state_changed', function (snapshot) {
        }, function (error) {
          // Handle unsuccessful uploads
        }, function () {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          storageRef.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            database.collection('resturant').doc(res).collection('offers').doc(id).set({
              title: item.title,
              detail: item.detail ? item.detail:null,
              title_ar: item.title_ar?item.title_ar:null,
              detail_ar: item.detail_ar?item.detail_ar:null,
              category: item.category?item.category:null,
              cap: item.cap?item.cap:null,
              code: item.code,
              active: item.active?item.active:false,
              home: item.home?item.home:false,
              type: item.type?item.type:null,
              percent:item.percent?item.percent:null,
              link: item.link?item.link:null,
              created: Date.now(),
              pictures: [downloadURL],
              itemId:item.itemId?item.itemId:null,
              area:item.area?item.area:[],
              res:res

            }).then(() => {
              getOfferListRequest().then(results => {
                success(results)
              })
            })
          });
        });
      })
    } else {
      database.collection('resturant').doc(res).collection('offers').doc(id).set({
        title: item.title,
        detail: item.detail ? item.detail:null,
        title_ar: item.title_ar?item.title_ar:null,
        detail_ar: item.detail_ar?item.detail_ar:null,
        category: item.category?item.category:null,
        cap: item.cap?item.cap:null,
        code: item.code,
        active: item.active?item.active:false,
        home: item.home?item.home:false,
        type: item.type?item.type:null,
        percent:item.percent?item.percent:null,
        link: item.link?item.link:null,
        created: Date.now(),
        itemId:item.itemId?item.itemId:null,
        area:item.area?item.area:[],
        res:res
      }).then(() => {
        getOfferListRequest().then(results => {
          success(results)
        })
      })
    }
  })
    .then(response => response)
    .catch(error => error);
};
const deleteOfferItemRequest = async items => {
  return await new Promise((success, fail) => {
    items.forEach(item=>{
      database.collection('resturant').doc(res).collection('offers').doc(item).delete()
    })
    getOfferListRequest().then(results => {
        success(results)
      })
  })

}
const updateOfferItemRequest = async item => {

  return await new Promise((success, fail) => {
    if (item.pictures && item.pictures.length) {
      item.pictures.forEach(picture => {
        if (picture.name) {
          var storageRef = storage.ref().child('resturant').child(res).child("offers").child(item.id).child("img").put(picture);
          storageRef.on('state_changed', function (snapshot) {
          }, function (error) {
            // Handle unsuccessful uploads
          }, function () {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            storageRef.snapshot.ref.getDownloadURL().then(function (downloadURL) {
              let ref = database.collection('resturant').doc(res).collection('offers').doc(item.id)
              return ref.update({
                title: item.title,
                detail: item.detail ? item.detail:null,
                title_ar: item.title_ar?item.title_ar:null,
                detail_ar: item.detail_ar?item.detail_ar:null,
                category: item.category?item.category:null,
                cap: item.cap?item.cap:null,
                code: item.code,
                active: item.active?item.active:false,
                home: item.home?item.home:false,
                type: item.type?item.type:null,
                percent:item.percent?item.percent:null,
                link: item.link?item.link:null,
                updated: Date.now(),
                pictures: [downloadURL],
                itemId:item.itemId?item.itemId:null,
                area:item.area?item.area:[],
                res:res

              }).then(() => {
                getOfferListRequest().then(results => {
                  success(results)
                })
              }).catch(error => {
                console.log("errorerror", error)

              })
            });
          });
        } else {
          let ref = database.collection('resturant').doc(res).collection('offers').doc(item.id)
          return ref.update({
            title: item.title,
            detail: item.detail ? item.detail:null,
            title_ar: item.title_ar?item.title_ar:null,
            detail_ar: item.detail_ar?item.detail_ar:null,
            category: item.category?item.category:null,
            cap: item.cap?item.cap:null,
            code: item.code,
            active: item.active?item.active:false,
            home: item.home?item.home:false,
            type: item.type?item.type:null,
            percent:item.percent?item.percent:null,
            link: item.link?item.link:null,
            updated: Date.now(),
            itemId:item.itemId?item.itemId:null,
            area:item.area?item.area:[],
            res:res
          }).then(() => {

            getOfferListRequest().then(results => {
              success(results)
            })
          }).catch(error => {
            console.log("errorerror", error)

          })

        }
      })
    }else{
      let ref = database.collection('resturant').doc(res).collection('offers').doc(item.id)
      return ref.update({
        title: item.title,
        detail: item.detail ? item.detail:null,
        title_ar: item.title_ar?item.title_ar:null,
        detail_ar: item.detail_ar?item.detail_ar:null,
        category: item.category?item.category:null,
        cap: item.cap?item.cap:null,
        code: item.code,
        active: item.active?item.active:false,
        home: item.home?item.home:false,
        type: item.type?item.type:null,
        percent:item.percent?item.percent:null,
        link: item.link?item.link:null,
        updated: Date.now(),
        itemId:item.itemId?item.itemId:null,
        area:item.area?item.area:[],
        res:res

      }).then(() => {

        getOfferListRequest().then(results => {
          success(results)
        })
      }).catch(error => {
        console.log("errorerror", error)

      })
    }
  })
    .then(response => response)
    .catch(error => error);
};

function* addOfferItem({ payload }) {
  try {
    const response = yield call(addOfferItemRequest, payload);
    yield put(addOfferItemSuccess(response));
  } catch (error) {
    yield put(addOfferItemError(error));
  }
}
function* updateOfferItem({ payload }) {
  try {
    const response = yield call(updateOfferItemRequest, payload);
    yield put(addOfferItemSuccess(response));
  } catch (error) {
    yield put(addOfferItemError(error));
  }
}
function* deleteOfferItem({ payload }) {
  try {
    const response = yield call(deleteOfferItemRequest, payload);
    yield put(addOfferItemSuccess(response));
  } catch (error) {
    yield put(addOfferItemError(error));
  }
}
export function* watchGetList() {
  yield takeEvery(OFFER_GET_LIST, getOfferListItems);
}

export function* wathcAddItem() {
  yield takeEvery(OFFER_ADD_ITEM, addOfferItem);
}
export function* wathcUpdateItem() {
  yield takeEvery(OFFER_UPDATE_ITEM, updateOfferItem);
}
export function* wathcDeleteItems() {
  yield takeEvery(OFFER_DELETE_ITEM, deleteOfferItem);
}
export default function* rootSaga() {
  yield all([fork(watchGetList), fork(wathcAddItem), fork(wathcUpdateItem),fork(wathcDeleteItems)]);
}
