import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Label,
  Row, Card, CardBody, Table
} from "reactstrap";
import { Separator, Colxx } from "../../../components/common/CustomBootstrap";
import { addOrderItem, updateOrderItem } from "../../../redux/actions";
import IntlMessages from "../../../helpers/IntlMessages";

function dateFormatter(timeStamp) {
  var date = new Date(timeStamp);
  return date.toLocaleDateString("en-US") + " " + date.toLocaleTimeString("en-US")
}
class ManageOrdersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      number: 0,
      address: '',
      area: '',
      id: '',
      status: '',
      category: '',
      items: [],
      color: { new: '#dc3545', accepted: '#ffc107', canceled: '#8f8f8f', delivery: 'var(--theme-color-1)', delivered: '#28a745' }
    };
  }
  componentWillMount() {
    let item = this.props.editItem
    if (!item) { return }

    this.setState({
      name: item.user.name,
      number: item.user.number,
      address: item.user.address,
      area: item.user && item.user.area ? item.user.area : '',
      id: item.id,
      status: item.status,
      category: item.category,
      items: item.items,
      loaded: true,
    })
  }


  getModifierPrice(item) {

    if (item.extra && item.extra.length) {
      let price = item.price * 1

      item.extra.map((extraIndex, index) => {
        return (
          Object.keys(extraIndex).map((categoryKey, categoryIndex) => {
            return (
              extraIndex[categoryKey].map(innerItem => {
                return (
                  price = price + (innerItem.price * 1)
                )
              })

            )
          })
        )
      })

      return price
    } else {
      return item.totalPrice
    }
  }
  render() {
    let { color } = this.state
    let { items, user, status, receipt, id, created, coupon } = this.props.editItem
    let { currency } = this.props
    let direction = localStorage.getItem("languageDirection") === "rtl"
    return (

      <div>
        {
          this.props.editItem &&

          <Row className="invoice-react">
            <Colxx xxs="12" >
              <div style={{ padding: '28px 0 0 ' }}>
                <div className="d-flex flex-column flex-lg-row justify-content-between w-100 align-items-center" style={{ padding: '12px 30px' }}>
                  <div>
                    <p className="font-weight-bold m-0"> {id}</p>
                  </div>
                  <div >
                    <p className="font-weight-bold m-0">{dateFormatter(created)}</p>
                  </div>
                  <div className='badge badge-pill' style={{ backgroundColor: color[status], color: 'white' }} >{status}</div>
                </div>
              </div>
              <Separator />
              <Card className="invoice-contents" style={{ height: 'auto', background: 'transparent', boxShadow: 'none' }}>
                <CardBody className="d-flex flex-column justify-content-between">
                  <div className="d-flex flex-column">
                    <Row className=" justify-content-between mb-5 ">
                      <Colxx sm={6}>
                        <div className="d-flex flex-column w-100 p-2  ">
                          <div >
                            <Label className="mb-0  text-semi-muted" text-capitalize><IntlMessages id={"orders.customer-name"} />:</Label>
                            <p className="font-weight-bold">{user.name}</p>
                          </div>
                          <div >
                            <Label className="mb-0 text-semi-muted"><IntlMessages id={"orders.phone-number"} />:</Label>
                            <p className="font-weight-bold">{user.number}</p>
                          </div>

                        </div>

                      </Colxx>

                      <Colxx sm={6}>
                        <div className="d-flex flex-column w-100 p-2  ">
                          <div >
                            <Label className="mb-0 text-semi-muted"><IntlMessages id={"orders.branch"} />:</Label>

                            <p className="font-weight-bold">{receipt.branch.map(item => window.branches[item].name).join(' - ')}</p>
                          </div>
                          <div >
                            <Label className="mb-0  text-semi-muted" text-capitalize><IntlMessages id={"orders.payment-method"} />:</Label>
                            <p className="font-weight-bold">{['online', 'applePay'].includes(receipt.paymentType) ? 'Online Payment' : receipt.paymentType + ' on delivery'}</p>
                          </div>
                        </div>

                      </Colxx>

                      <Colxx sm={12}>
                        <div className="d-flex flex-column w-100 p-2  ">
                          {
                            receipt.type && receipt.type === 'pickup' ?
                              <div>
                                <Label className="mb-0 text-semi-muted"><IntlMessages id={"orders.pickup-from"} />:</Label>
                                <p className="font-weight-bold">{receipt.selectedAddress && [receipt.selectedAddress.area].join(' - ')}</p>
                              </div>
                              :
                              <div>
                                <Label className="mb-0 text-semi-muted"><IntlMessages id={"orders.delivery-to"} />:</Label>
                                <p className="font-weight-bold">{receipt.selectedAddress && [receipt.selectedAddress.fullAddress, receipt.selectedAddress.area, receipt.selectedAddress.notes].join(' - ')}</p>
                              </div>
                          }
                        </div>
                      </Colxx>

                    </Row>

                    {
                      receipt.specialNotes && <Row className='mb-5'>
                        <div className='col-sm-6'>
                          <div className='p-2'>
                            <Label className="mb-0  text-semi-muted" text-capitalize><IntlMessages id={"orders.orders-notes"} />:</Label>
                            <p className="font-weight-bold">{receipt.specialNotes}</p>
                          </div>

                        </div>

                      </Row>
                    }

                    <Table borderless>
                      <thead>
                        <tr>
                          <th className="text-muted text-extra-small mb-2">
                            <IntlMessages id={"orders.item-name"} />
                          </th>
                          <th className="text-muted text-extra-small mb-2">
                            <IntlMessages id={"orders.count"} />
                          </th>
                          <th className="text-right text-muted text-extra-small mb-2">
                            <IntlMessages id={"orders.price"} />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          items.map((item, itemIndex) => {

                            return (
                              <tr key={itemIndex}>
                                <td>
                                  <div style={{ fontSize: '14px' }}>
                                    {direction ? item.name_ar : item.name}
                                  </div>
                                  {
                                    item.extra && item.extra.map((extraIndex, index) => {
                                      return (
                                        Object.keys(extraIndex).map((categoryKey, categoryIndex) => {
                                          let duplicates = []
                                          return (
                                            <div key={categoryIndex} style={{ padding: '2px 8px' }}>
                                              <div style={{ color: 'gray', fontSize: '11px' }}>
                                                {categoryKey + ":"}
                                              </div>
                                              <div style={{ color: 'black', fontSize: '12px', fontWeight: '500' }}>
                                                {
                                                  extraIndex[categoryKey].map((innerItem, pos) => {
                                                    let extraCount = extraIndex[categoryKey].filter(rec => rec.name === innerItem.name).length
                                                    if (!duplicates.includes(innerItem.name)) {
                                                      duplicates.push(innerItem.name)
                                                      return (
                                                        direction ? <div><span style={{ fontWeight: '700' }}>{extraCount + 'x '}</span>{innerItem.name_ar}</div> : <div><span style={{ fontWeight: '700' }}>{extraCount + 'x '}</span>{innerItem.name}</div>
                                                      )
                                                    }
                                                    return false
                                                  })
                                                }
                                              </div>
                                            </div>

                                          )
                                        })
                                      )
                                    })
                                  }
                                </td>
                                <td>{item.count}</td>


                                <td className="text-right">
                                  {

                                    (item.price === "0" && item.extra) ?
                                      <div>
                                        {
                                          this.getModifierPrice(item) + " " + currency

                                        }
                                      </div>
                                      :
                                      <div>
                                        <div className="text-right">{(item.price * item.count).toFixed(2) + " " + currency}</div>
                                        {
                                          item.extra && item.extra.map((extraIndex, index) => {
                                            return (
                                              Object.keys(extraIndex).map((categoryKey, categoryIndex) => {
                                                let duplicates = []
                                                return (
                                                  <div key={categoryIndex}>
                                                    <div>-</div>
                                                    <div style={{ color: 'black', fontSize: '12px', fontWeight: '500' }}>
                                                      {
                                                        extraIndex[categoryKey].map((innerItem, pos) => {
                                                          let extraCount = extraIndex[categoryKey].filter(rec => rec.name === innerItem.name).length
                                                          if (!duplicates.includes(innerItem.name)) {
                                                            duplicates.push(innerItem.name)
                                                            return (
                                                              <div><span style={{ fontWeight: '700' }}></span>{innerItem.price !== "0" ? (Number(innerItem.price) * extraCount).toFixed(2) + " " + currency : "-"}</div>
                                                            )
                                                          }
                                                          return false
                                                        })
                                                      }
                                                    </div>
                                                  </div>

                                                )
                                              })
                                            )
                                          })
                                        }
                                      </div>
                                  }
                                </td>
                              </tr>
                            )
                          })
                        }

                      </tbody>
                    </Table>
                  </div>
                  <div className="d-flex flex-column">
                    <div className="border-bottom pt-3 mb-5" />
                    <Table borderless className="d-flex justify-content-end">
                      <tbody>
                        <tr>
                          <td className="text-semi-muted"><IntlMessages id={"orders.subtotal"} />:</td>
                          <td className="text-right">{(receipt.subTotal - receipt.vat).toFixed(2) + ' ' + currency}</td>
                        </tr>
                        {
                          receipt?.vat !== '0.00' ?
                            <tr>
                              <td className="text-semi-muted"><IntlMessages id={"orders.vat"} />:</td>
                              <td className="text-right">{receipt.vat + ' ' + currency}</td>
                            </tr>
                            : <div />
                        }
                        {
                          receipt?.delivery ?
                            <tr>

                              <td className="text-semi-muted"><IntlMessages id={"orders.delivery"} /></td>
                              <td className="text-right">{receipt.delivery + ' ' + currency}</td>
                            </tr>
                            : <div />
                        }

                        {
                          coupon ?
                            <tr>
                              <td className="text-semi-muted"><IntlMessages id={"orders.discount"} />:</td>
                              <td className="text-right">
                                {(coupon.type === 'oneForOne') ?
                                  ('Free ' + coupon.value + ' -' + receipt.deductedValue + ' ' + currency)
                                  : (receipt.deductedValue + ' ' + currency)}
                              </td>
                            </tr>
                            : ''
                        }
                        <tr className="font-weight-bold">
                          <td className="text-semi-muted"><IntlMessages id={"orders.total"} />:</td>
                          <td className="text-right">{receipt.total + ' ' + currency}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        }

      </div>
    );
  }
}

const mapStateToProps = ({ orders, settings }) => {
  return {
    orders,
    currency: settings.currency ? settings.currency : 'AED',
    country: settings.country ? settings.country : 'UAE'
  };
};
export default connect(
  mapStateToProps,
  {
    addOrderItem,
    updateOrderItem
  }
)(ManageOrdersModal);
