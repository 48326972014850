import {
    USER_GET_LIST,
    USER_GET_LIST_SUCCESS,
    USER_GET_LIST_ERROR,
    USER_GET_LIST_WITH_FILTER,
    USER_GET_LIST_WITH_ORDER,
    USER_GET_LIST_SEARCH,
    USER_ADD_ITEM,
    USER_DELETE_ITEM,
    USER_UPDATE_ITEM,
    USER_ADD_ITEM_SUCCESS,
    USER_ADD_ITEM_ERROR,
    USER_SELECTED_ITEMS_CHANGE,
    USER_GET_SUSCRIPTION
} from '../actions';


export const getUserList = () => ({
    type: USER_GET_LIST
});

export const getUserListSuccess = (items) => ({
    type: USER_GET_LIST_SUCCESS,
    payload: items
});

export const getUserListError = (error) => ({
    type: USER_GET_LIST_ERROR,
    payload: error
});

export const getUserListWithFilter = (column, value) => ({
    type: USER_GET_LIST_WITH_FILTER,
    payload: { column, value }
});

export const getUserListWithOrder = (column) => ({
    type: USER_GET_LIST_WITH_ORDER,
    payload: column
});

export const getUserListSearch = (keyword) => ({
    type: USER_GET_LIST_SEARCH,
    payload: keyword
});

export const addUserItem = (item) => ({
    type: USER_ADD_ITEM,
    payload: item
});
export const updateUserItem = (item) => ({
    type: USER_UPDATE_ITEM,
    payload: item
});
export const deleteUserItem = (item) => ({
    type: USER_DELETE_ITEM,
    payload: item
});
export const addUserItemSuccess = (items) => ({
    type: USER_ADD_ITEM_SUCCESS,
    payload: items
});

export const addUserItemError = (error) => ({
    type: USER_ADD_ITEM_ERROR,
    payload: error
});

export const selectedUserItemsChange = (selectedItems) => ({
    type: USER_SELECTED_ITEMS_CHANGE,
    payload: selectedItems
});

export const GetSubscriptionStatus = (data) => ({
    type: USER_GET_SUSCRIPTION,
    payload: data
})
