import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";
import { firestore } from 'firebase'
import Pagination from "../../../containers/pages/Pagination";
import ListPageHeading from "../../../containers/pages/ListPageHeading";
import OutletItemView from "./components/outletItemView";

import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import Outlet from "./outlet";
import IntlMessages from "../../../helpers/IntlMessages";

import {

  getAllBranches,
} from "../../../redux/actions";
import { NotificationManager } from "../../../components/common/react-notifications";
class OutletsList extends Component {
  constructor(props) {
    super(props);
    this.mouseTrap = require('mousetrap');
    this.state = {
      displayMode: "thumblist",

      selectedPageSize: 8,

      pageSizes: [8, 12, 24],


      currentPage: 1,
      totalItemCount: 0,
      totalPage: 1,
      search: "",
      isLoading: false,
      selectOutlet: false,
      selectedOutlet: {},
      branches: [],
      addNewBranch: false

    };
  }
  componentWillReceiveProps(prop) {
    let { allBranches, } = prop
    let { branches } = this.state

    console.log(allBranches, "allBranches")
    if (allBranches) {
      branches = Object.keys(allBranches).map(item => {
        return ({ key: item, value: allBranches[item] })
      })

      this.setState({
        branches: branches, isLoading: true
      })
    }

  }


  toggleModal = () => {
    let { selectOutlet } = this.state
    let { branchesLimit, allBranches } = this.props
    if (Object.keys(allBranches)?.length >= branchesLimit) {
      return NotificationManager.error(
        "",
        "You exceeded maximum number of outlets",
        3000,
        null,
        null,
    );
    }
    this.setState({ selectOutlet: !selectOutlet, selectedOutlet: {}, addNewBranch: true })
  };

  changePageSize = size => {
    this.setState(
      {
        selectedPageSize: size,
        currentPage: 1
      },
      // () => this.dataListRender()
    );
  };
  changeDisplayMode = mode => {
    this.setState({
      displayMode: mode
    });
    return false;
  };
  onChangePage = page => {
    this.setState(
      {
        currentPage: page
      },
      // () => this.dataListRender()
    );
  };

  onSearchKey = e => {
    this.setState(
      {
        search: e.target.value.toLowerCase()
      }
    );
  };

  onClickItem = (branch) => {
    let { branches } = this.state;

    let _this = this
    let { selectOutlet } = this.state
    let resKey = localStorage.getItem('res')

    if (selectOutlet) {
      firestore().collection('resturant').doc(resKey).get().then(resultData => {
        let data = resultData.data()
        _this.props.getAllBranches(data.branches)
        window.branches = data.branches

        branches = Object.keys(data.branches).map(item => {
          return ({ key: item, value: data.branches[item] })
        })


        this.setState({ selectOutlet: !selectOutlet, selectedOutlet: branch, branches: branches, isLoading: true, addNewBranch: false })

      })
    } else {
      this.setState({ selectOutlet: !selectOutlet, selectedOutlet: branch, addNewBranch: false })
    }
  };


  render() {
    const {
      currentPage,
      selectedPageSize,
      totalItemCount,
      pageSizes,
      branches,
      selectOutlet,
      selectedOutlet,
      addNewBranch,
      search
    } = this.state;
    const startIndex = (currentPage - 1) * selectedPageSize;
    const endIndex = currentPage * selectedPageSize;

    let filteredBranches = branches.filter(item => {
      return (item.value.name).toLowerCase().includes(search.toLowerCase())
    })
    filteredBranches.sort(function (a, b) {
      if (a.value.name < b.value.name) { return -1; }
      if (a.value.name > b.value.name) { return 1; }
      return 0;
    })
    return (selectOutlet ? <Outlet branches={branches} selectedOutlet={selectedOutlet} onClickItem={this.onClickItem} addNewBranch={addNewBranch} /> : (
      !this.state.isLoading ? (
        <div className="loading" />
      ) : (
        <Fragment>
          <div className="disable-text-selection">
            <ListPageHeading
              heading={"outlets.outlets"}
              changePageSize={this.changePageSize}
              selectedPageSize={selectedPageSize}
              totalItemCount={totalItemCount}
              startIndex={startIndex}
              endIndex={endIndex}
              itemsLength={branches ? branches.length : 0}
              onSearchKey={this.onSearchKey}
              pageSizes={pageSizes}
              toggleModal={this.toggleModal}
            />

            <Row>
              {filteredBranches.map((branch) => {
                return (
                  <OutletItemView
                    key={branch.key}
                    branch={branch}
                    onClickItem={this.onClickItem}
                    updateBranch={(id, branch) => {
                      let updatedBranches = branches.filter(item => item.key !== id);
                      updatedBranches.push(branch)
                      updatedBranches.sort(function (a, b) {
                        if (a.value.name < b.value.name) { return -1; }
                        if (a.value.name > b.value.name) { return 1; }
                        return 0;
                      })
                      this.setState({ branches: updatedBranches })
                    }}
                    updateBranches={(id) => {
                      let updatedBranches = branches.filter(item => item.key !== id);
                      updatedBranches.sort(function (a, b) {
                        if (a.value.name < b.value.name) { return -1; }
                        if (a.value.name > b.value.name) { return 1; }
                        return 0;
                      })
                      this.setState({ branches: updatedBranches })
                    }}
                  />
                );
              })}{" "}
              <Pagination
                currentPage={this.state.currentPage}
                totalPage={this.state.totalPage}
                onChangePage={i => this.onChangePage(i)}
              />

            </Row>
          </div>
        </Fragment>
      )
    ))

  }
}
const mapStateToProps = ({ settings }) => {
  console.log("settingssettings", settings)
  return {
    allBranches: settings.allBranches,
    currency: settings.currency,
    branchesLimit: settings?.branchesLimit || 1
  };
};
const mapActionsToProps = (dispatch) => ({

  getAllBranches: (bransh) => dispatch(getAllBranches(bransh)),

});
export default injectIntl(
  connect(
    mapStateToProps,
    mapActionsToProps
  )(OutletsList)
);

