/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/


module.exports = {
  /* 01.General */
  "general.copyright": "Gogo React © 2018 All Rights Reserved.",

  /* 02.User Login, Logout, Register */
  "user.login-title": "تسجيل الدخول",
  "user.register": "التسجيل",
  "user.forgot-password": "نسيت كلمة المرور",
  "user.email": "البريد إلكتروني",
  "user.password": "كلمة المرور",
  "user.forgot-password-question": "نسيت كلمة المرور؟",
  "user.fullname": "الاسم بالكامل",
  "user.login-button": "تسجيل الدخول",
  "user.register-button": "تسجيل",
  "user.reset-password-button": "إعادة تعيين",
  "user.buy": "شراء",
  "user.username": "اسم المستخدم",


  /* 03.Menu */
  "menu.dashboards": "لوحة القيادة",
  "menu.menu-builder": "قائمة طعامك",
  "menu.items-and-modifiers": "العناصر والمعدلات",
  "menu.menus": "القوائم",
  "menu.orders": "الطلبات",
  "menu.crm": "إدارة العملاء",
  "menu.customers": "العملاء",
  "menu.order-history": "الطلبات السابقة",
  "menu.store": "سوق F2O",
  "menu.outlets": "المنافذ",
  "menu.marketing": "تسويق",
  "menu.promocodes": "الرموز الترويجية",
  "menu.super-admin": "المشرف",
  "menu.all-restaurant": "كل المطاعم",
  "menu.clients": "العملاء",

  /* 04.Error Page */
  "pages.error-title": "عفوًا ... يبدو أنه حدث خطأ!",
  "pages.error-code": "رمز الخطأ",
  "pages.go-back-home": "العودة الى الصفحة الرئيسية",

  /* 05.Dashboards */
  "dashboards.total-orders": "إجمالي الطلبات",
  "dashboards.total-sales": "إجمالي المبيعات",
  "dashboards.commission": "العمولة التي تم توفيرها",
  "dashboards.avg-basket-value": "متوسط. قيمة السلة",
  "dashboards.pending-orders": "الطلبات المعلقة",
  "dashboards.completed-orders": "الطلبات المكتملة",
  "dashboards.refund-requests": "طلبات استرداد",
  "dashboards.new-comments": "تعليقات جديدة",
  "dashboards.recent-orders": "الطلبيات الأخيرة",
  "dashboards.sales": "Sales",
  "dashboards.orders": "Orders",
  "dashboards.refunds": "Refunds",
  "dashboards.product-categories": "Product Categories",
  "dashboards.cakes": "Cakes",
  "dashboards.tickets": "Tickets",
  "dashboards.calendar": "Calendar",
  "dashboards.best-sellers": "Best Sellers",
  "dashboards.website-visits": "Website Visits",
  "dashboards.unique-visitors": "Unique Visitors",
  "dashboards.this-week": "This Week",
  "dashboards.last-week": "Lat Week",
  "dashboards.this-month": "This Month",
  "dashboards.conversion-rates": "Conversion Rates",
  "dashboards.per-session": "Per Session",
  "dashboards.profile-status": "Profile Status",
  "dashboards.payment-status": "Payment Status",
  "dashboards.work-progress": "Work Progress",
  "dashboards.tasks-completed": "Tasks Completed",
  "dashboards.payments-done": "Payments Done",
  "dashboards.order-stock": "Order - Stock",
  "dashboards.categories": "Categories",
  "dashboards.quick-post": "Quick Post",
  "dashboards.title": "Title",
  "dashboards.content": "Content",
  "dashboards.category": "Category",
  "dashboards.save-and-publish": "Save and Publish",
  "dashboards.top-viewed-posts": "Top Viewed Posts",
  "dashboards.posts": "Posts",
  "dashboards.pending-for-publish": "Pending for publish",
  "dashboards.users": "Users",
  "dashboards.on-approval-process": "On approval process",
  "dashboards.alerts": "Alerts",
  "dashboards.waiting-for-notice": "Waiting for notice",
  "dashboards.files": "Files",
  "dashboards.pending-for-print": "Pending for print",
  "dashboards.logs": "Logs",
  "dashboards.gogo": "GOGO",
  "dashboards.magic-is-in-the-details": "MAGIC IS IN THE DETAILS",
  "dashboards.yes-it-is-indeed": "Yes, it is indeed!",
  "dashboards.advanced-search": "Advanced Search",
  "dashboards.toppings": "Toppings",
  "dashboards.type": "Type",
  "dashboards.keyword": "Keyword",
  "dashboards.search": "Search",
  "dashboards.top-rated-items": "Top Rated Items",

  /* 05.Menus */
  "menus.item-details": "تفاصيل العنصر",
  "menus.tutorial": "تعلم",
  "menus.menu-items": "عناصر القائمة",
  "menus.list-all-menu-items": "أضف جميع عناصر القائمة الخاصة بك لإضافتها إلى قوائمك",
  "menus.filter-by-categoury": "تصفية حسب الفئة",
  "menus.reset": "إعادة ضبط",
  "menus.import": "استيراد",
  "menus.create-new": "إنشاء",
  "menus.are-you-sure": "هل أنت متأكد أنك تريد حذف",
  "menus.confirm": "تأكيد",
  "menus.cancel": "إلغاء",
  "menus.item-name": "اسم العنصر",
  "menus.list-name": "اسم قائمة المعدلات",
  "menus.list-name_ar": "الاسم العربي لقائمة المعدلات",
  "menus.item-arabic-name": "اسم العنصر بالعربية",
  "menus.include-vat": "قم بتضمين ضريبة القيمة المضافة في سعر السلعة",
  "menus.dine-in-item-price": "سعر العنصر في حالة التناول داخل المطعم",
  "menus.delivery-item-price": "سعر العنصر في حالة التوصيل",
  "menus.pickup-item-price": "سعر العنصر في حالة الاستلام من المطعم'",
  "menus.item-price": "سعر العنصر",
  "menus.hide-adv-pricing": "إخفاء تفاصيل التسعير",
  "menus.show-adv-pricing": "إظهار تفاصيل التسعير",
  "menus.item-description": "وصف العنصر",
  "menus.item-arabic-description": "وصف العنصر بالعربية",
  "menus.customizations": "التخصيصات",
  "menus.no-modifiers-added": "لم يتم إضافة أي معدلات لهذا العنصر",
  "menus.add-customization": "أضف التخصيص",
  "menus.save": "حفظ",
  "menus.create-menu": "إنشاء قائمة طعام",
  "menus.name": "الاسم",
  "menus.branches": "الفروع",
  "menus.collapse-all": "تصغير الكل",
  "menus.new-category": "فئة جديدة",
  "menus.are-you-sure-you": "هل أنت متأكد أنك تريد عدم حفظ التغييرات",
  "menus.menu-name": "اسم القائمة",
  "menus.active-branches": "الفروع المفعلة",
  "menus.activated-for": "مفعل ل",
  "menus.actions": "أجراءات",
  "menus.activate": "تفعيل",
  "menus.duplicate": "ينسخ",
  "menus.edit": "تعديل",
  "menus.delete": "حذف",
  "menus.update-category": "تحديث الفئة",
  "menus.name*": "اسم*",
  "menus.arabic-name*": "الاسم العربي *",
  "menus.update": "تحديث",
  "menus.add-category": "إضافة فئة",
  "menus.modifier-item": "عنصر معدل",
  "menus.menu-item": "عنصر القائمة",
  "menus.price-on-selection": "السعر عند الاختيار",
  "menus.out-of-stock": "إنتهى من المخزن",
  "menus.view": "عرض",
  "menus.modifier-list": "قائمة المعدلات",
  "menus.selection-count": "تحديد العدد  *",
  "menus.select-modifier-items": "حدد عناصر التعديل",
  "menus.options": "خيارات",
  "menus.create-new-modifier": "إنشاء عنصر تعديل جديد",
  "menus.create-modifier": "إنشاء معدل جديد",
  "menus.arabic-name": "الاسم العربي",
  "menus.price": "السعر",
  "menus.min-count": "الحد الأدنى للاختيارات",
  "menus.max-count": "الحد الأقصى للاختيارات",
  "menus.type": "السماح باختيارات متعددة؟",
  "menus.modifiers-list": "قائمة المعدلات",
  "menus.this-will-not":"لن يظهر هذا في التطبيق",

  /* 07.21.Orders */
  "orders.all-orders": "جميع الطلبات",
  "orders.new-orders": "طلبات جديدة",
  "orders.accepted-orders": "الطلبات المقبولة",
  "orders.in-delivery-orders": "طلبات في الطريق للتسليم",
  "orders.delivered-orders": "طلبات تم تسليمها",
  "orders.canceled-orders": "الطلبات الملغاة",

  "orders.estimated-preparation-time": "وقت التحضير المقدر",
  "orders.estimated-pickup-time": "وقت الاستلام المقدر",
  "orders.estimated-delivery-time": "وقت التوصيل المقدر",
  "orders.request-jeebly": "طلب Jeebly للتسليم",
  "orders.mins": "دقيقة",
  "orders.cancel": "إلغاء",
  "orders.accept-order": "قبول الطلب",
  "orders.back": "عودة",
  "orders.customer-name": "اسم العميل",
  "orders.phone-number": "رقم الهاتف",
  "orders.pickup-from": "استلام من",
  "orders.dine-in": "تناول الطعام في",
  "orders.delivery-to": "تسليم الى",
  "orders.branch": "فرع",
  "orders.payment-method": "طريقة الدفع",
  "orders.loyalty": "الولاء",
  "orders.points-balance": "رصيد النقاط:",
  "orders.orders-notes": "ملاحظات الطلب",
  "orders.item-name": "اسم العنصر",
  "orders.count": "العدد",
  "orders.price": "السعر",
  "orders.pickup": "استلام:",
  "orders.delivery": "توصيل:",
  "orders.discount": "خصم",
  "orders.discount-loyalty": "خصم 'الولاء'",
  "orders.total": "المجموع النهائي",
  "orders.subtotal": "حاصل الجمع",
  "orders.vat": "ضريبة القيمة المضافة",
  "orders.accept": "قبول",
  "orders.ready-for-pickup": "جاهز للإستلام",
  "orders.send-to-table": "ارسل الى الطاولة",
  "orders.send-for-delivery": "أرسل للتوصيل",
  "orders.confirm-pickup": "تأكيد الاستلام",
  "orders.confirm-delivery": "تأكيد التوصيل",
  "orders.canceled": "تم الغاء",
  "orders.reject-order": "رفض الطلب",
  "orders.dismiss": "إلغاء",
  "orders.msg-reject-order": "هل أنت متأكد أنك تريد رفض هذا الطلب؟",
  "orders.status-filter": "تصفية حسب حالة الطلب",
  "orders.to-crm": "تاريخ الطلبات",

  /* 07.21.Customers */
  "customers.name": "الاسم",
  "customers.phone-number": "رقم الهاتف",
  "customers.orders-placed": "اجمالي الطلبات",
  "customers.first-order-at": "أول طلب في",
  "customers.last-order-at": "آخر طلب في",
  "customers.total-revenue": "إجمالي الإيرادات",
  "customers.no-customers-yet": "لا يوجد عملاء حتى الآن!",
  "customers.customers": "الزبائن",

  /* 07.21.OrderHistory */
  "orderhistory.ID": "رقم الطلب",
  "orderhistory.customer": "الزبون",
  "orderhistory.type": "نوع الطلب",
  "orderhistory.status": "الحالة",
  "orderhistory.payment-method": "طريقة الدفع",
  "orderhistory.total": "الإجمالي",
  "orderhistory.number": "رقم الهاتف",
  "orderhistory.ordered-at": "طلب في",
  "orderhistory.pickup": "استلام",
  "orderhistory.delivery": "توصيل",
  "orderhistory.dine-in": "تناول الطعام داخل المطعم",
  "orderhistory.new-orders": "طلبات جديدة",
  "orderhistory.accepted-orders": "الطلبات المقبولة",
  "orderhistory.in-delivery-orders": "طلبات في الطريق للتسليم",
  "orderhistory.delivered-orders": "الطلبات المسلمة",
  "orderhistory.canceled-orders": "الطلبات الملغاة",
  "orderhistory.no-orders-yet": "لا توجد طلبات حتى الآن!",
  "orderhistory.order-history": "تاريخ الطلبات",
  "orderhistory.revenue": "الإيرادات",
  "orderhistory.average-order-value": "متوسط قيمة الطلب",
  "orderhistory.total-orders": "إجمالي الطلبات",

  /* 07.21.Store */
  "store.recommended": "موصى به",
  "store.expired": "منتهي الصلاحية",
  "store.expiring": "تنتهي",
  "store.coming-soon": "قريبا",
  "store.activated": "مفعل",
  "store.get-started": "البدء",
  "store.reactivate": "اعادة تفعيل",


  /* 07.21.Outlets */
  "outlets.outlets": "منافذ",
  "outlets.confim-deactivate": "هل أنت متأكد أنك تريد تعطيل",
  "outlets.deactivate": "تعطيل",
  "outlets.new-branch": "منفذ جديد",
  "outlets.general-information": "معلومات عامة",
  "outlets.cover-photo": "صورة الغلاف",
  "outlets.contact-information": "معلومات الاتصال",
  "outlets.store-email": "البريد الإلكتروني الخاص بالمتجر",
  "outlets.store-landline": "هاتف أرضي",
  "outlets.operation-settings": "إعدادات العملية",
  "outlets.set--default-time": "حدد الوقت الافتراضي اللازم لإعداد الطلبات وتسليمها",
  "outlets.preparation-time": "وقت التحضير",
  "outlets.address": "العنوان",
  "outlets.delivery-schedule": "مواعيد للتوصيل",
  "outlets.set-delivery-hours": "حدد ساعات التوصيل لهذا المنفذ",
  "outlets.add-timings": "أضف مواعيد",
  "outlets.preparation-minutes": "التحضير (بالدقائق)",
  "outlets.pickup-schedule": "مواعيد الاستلام من المنفذ",
  "outlets.set-pickup-hours": "حدد ساعات الاستلام لهذا المنفذ",
  "outlets.dinein-schedule": "الجدول الزمني للاكل داخل المطعم",
  "outlets.set-dinein-hours": "حدد ساعات الاكل داخل المطعم لهذا المنفذ",
  "outlets.locations": " مناطق التوصيل",
  "outlets.import": "استيراد",
  "outlets.add-areas": "أضف مناطق",
  "outlets.areas-we-serve": "المناطق التي نخدمها ",
  "outlets.confirm-delete-group": "هل أنت متأكد أنك تريد حذف هذه المنطقة؟",
  "outlets.add-new-areas": "أضف مناطق جديدة",
  "outlets.enter-delivery-fees": "أدخل رسوم التوصيل لمجموعة محددة من المناطق:",
  "outlets.areas-you-deliver": "المناطق التي تقوم بالتوصيل لها برسوم ثابتة",
  "outlets.minimum-order-group": "أدخل الحد الأدنى للطلب لهذه المجموعة:",
  "outlets.estimated-delivery-time": "أدخل وقت التسليم المقدر لهذه المجموعة:",
  "outlets.delivery-areas": "مناطق التوصيل",
  "outlets.save-changes": "حفظ التغييرات",
  "outlets.delivery-timings": "مواعيد التوصيل",
  "outlets.pickup-timings": "مواعيد الاستلام من المنفذ",
  "outlets.dinein-timings": "مواعيد الاكل داخل المطعم",
  "outlets.apply-to-all-branches": "تطبيق على جميع الفروع",
  "outlets.Saturday": "السبت",
  "outlets.Sunday": "الأحد",
  "outlets.Monday": "الاثنين",
  "outlets.Tuesday": "الثلاثاء",
  "outlets.Wednesday": "الأربعاء",
  "outlets.Thursday": "الخميس",
  "outlets.Friday": "الجمعة",
  "outlets.apply-to-all-branches-msg": "هل أنت متأكد أنك تريد تطبيق هذه المواعيد على جميع فروعك؟",
  "outlets.apply": "تطبيق",
  "outlets.add-new-timings": "إضافة توقيتات جديدة",

  /* 07.21.Marketing */
  "marketing.code": "الرمز",
  "marketing.count": "العدد",
  'marketing.limited-per-user':'الحد للرمز الترويجي للفرد',
  "marketing.percent": "نسبه مئويه",
  "marketing.maximum-value": "القيمة القصوى",
  "marketing.created": "تم إنشاؤه",
  "marketing.auto-applied": "التطبيق تلقائيًا",
  "marketing.promo-codes": "الرموز الترويجية",
  "marketing.no-promocodes": "لا يوجد رموز ترويجية",
  "marketing.create-promo-code": "إنشاء رمز ترويجي جديد",
  "marketing.edit-promo-code": "تعديل الرمز الترويجي",
  "marketing.auto-applied2": "تطبيقه تلقائيًا:",
  "marketing.enabling-this-will": "* سيؤدي تمكين هذا إلى تعطيل جميع الرموز الترويجية الأخرى",
  "marketing.promo-code": "الرمز ترويجي:",
  "marketing.number-of-promo-codes": "عدد الرموز الترويجية:",
  "marketing.percent2": "نسبه مئويه:",
  "marketing.maximum-value2": "القيمة القصوى (درهم):",
  "marketing.available-in": "متوفر في:",
  "marketing.create": "إنشاء",
  "marketing.delete-promo-code": "حذف الرمز الترويجي",
  "marketing.confirm-delete-promocode": "هل أنت متأكد أنك تريد حذف هذا الرمز الترويجي؟",
  "marketing.dismiss": "رجوع",
  "marketing.endDate": "تاريخ الإنتهاء",
  "marketing.excludeItems": "استبعاد عناصر من هذا العرض",
  "marketing.includeItems": "ضم عناصر لهذا العرض",
  "marketing.excludeItemsLabel": "ضم او استبعد العنصر نم العرض",
  "marketing.expired": "منتهي الصلاحية",
  "marketing.expiredMsg": "هذا الرمز الترويجي منتهي ، يرجى تحديث تاريخ انتهاء لتفعيل هذا الرمز الترويجي مرة أخرى",
 
  /* 07.21.SetupList */
  "setuplist.steps-to-go-live": "خطوات للذهاب مباشرة",
  "setuplist.list-menu-items": "أدخل عناصر القائمة الخاصة بك",
  "setuplist.click-here": "انقر هنا",
  "setuplist.add-your-menu-items": "لإضافة عناصر القائمة الخاصة بك.",
  "setuplist.to-create-your-menu": "لإنشاء قائمتك.",
  "setuplist.to-set-your-delivery": "لتعيين مناطق التسليم الخاصة بك.",
  "setuplist.create-first-menu": "قم بإنشاء القائمة الأولى الخاصة بك",
  "setuplist.set-delivery-areas": "تحديد مناطق التسليم",


  /* 07.21.Wizards */
  "wizard.step-name-1": "Admin Info",
  "wizard.step-name-2": "Store Info",
  "wizard.step-name-3": "Delivery Info",
  "wizard.step-desc-1": "Contact details of Admin",
  "wizard.step-desc-2": "Basic info of your Store",
  "wizard.step-desc-3": "Information needed for setting up your online order system",
  "wizard.content-1": "Step content for first step.",
  "wizard.content-2": "Step content for second step.",
  "wizard.content-3": "Last step content!",
  "wizard.content-thanks": "Thank You!",
  "wizard.next": "Next",
  "wizard.prev": "Back",
  "wizard.registered": "Your registration completed successfully!",
  "wizard.async": "Async save for 3 seconds!"


};

