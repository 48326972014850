import React, { Component, Fragment } from "react";
import './index.css'
import {
    Row,
} from "reactstrap";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import {
    getOrderList,
    getOrderListWithOrder,
    getOrderListSearch,
    selectedOrderItemsChange,
    deleteOrderItem,
    getOrderListWithFilter

} from "../../../redux/actions";
import { Colxx } from '../../../components/common/CustomBootstrap';

import ManageOrdersModal from "../../../containers/applications/ManageOrdersModal";
import TodoApplicationMenu from "../../../containers/applications/TodoApplicationMenu";
import OrderList from './orderList.js';
import { getDirection } from "../../../helpers/Utils";

class ManageOrders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dropdownSplitOpen: false,
            modalOpen: false,
            lastChecked: null,
            displayOptionsIsOpen: false
        };
    }

    toggleSplit = () => {
        this.setState(prevState => ({
            dropdownSplitOpen: !prevState.dropdownSplitOpen
        }));
    };

    changeOrderBy = column => {
        this.props.getOrderListWithOrder(column);
    };

    handleKeyPress = e => {
        if (e.key === "Enter") {
            this.props.getOrderListSearch(e.target.value);
        }
    };
    toggleModal = () => {
        this.setState({
            modalOpen: !this.state.modalOpen,
            edit: null
        });
    };
    handleCheckChange = (event, id) => {
        if (this.state.lastChecked == null) {
            this.setState({
                lastChecked: id
            });
        }

        let selectedItems = Object.assign([], this.props.orders.selectedItems);
        if (selectedItems.includes(id)) {
            selectedItems = selectedItems.filter(x => x !== id);
        } else {
            selectedItems.push(id);
        }
        this.props.selectedOrderItemsChange(selectedItems);

        if (event.shiftKey) {
            var items = this.props.orders.activeOrders;
            var start = this.getIndex(id, items, "id");
            var end = this.getIndex(this.state.lastChecked, items, "id");
            items = items.slice(Math.min(start, end), Math.max(start, end) + 1);
            selectedItems.push(
                ...items.map(item => {
                    return item.id;
                })
            );
            selectedItems = Array.from(new Set(selectedItems));
            this.props.selectedOrderItemsChange(selectedItems);
        }
        return;
    };
    handleChangeSelectAll = () => {
        if (this.props.orders.loading) {
            if (
                this.props.orders.selectedItems.length >=
                this.props.orders.activeOrders.length
            ) {
                this.props.selectedOrderItemsChange([]);
            } else {
                this.props.selectedOrderItemsChange(
                    this.props.orders.activeOrders.map(x => x.id)
                );
            }
        }
    };
    removeItem() {
        const { selectedItems } = this.props.orders;
        this.props.deleteOrderItem(selectedItems)

    }
    getIndex(value, arr, prop) {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i][prop] === value) {
                return i;
            }
        }
        return -1;
    }
    dismiss() {
        this.setState({ selectedItem: false })
    }
    render() {
        const { activeOrders, loading } = this.props.orders;
        let { selectedItem } = this.state
        const direction = getDirection();

        return (
            loading ?
                <Fragment >
                    <Row>
                        <Colxx lg="3" xl="3" className="scrollOrderListMobileContainer" style={direction.isRtl ? { borderRight: '1px solid #f3f3f3' } : { borderLeft: '1px solid #f3f3f3' }} >
                            <OrderList orders={activeOrders} selectedItem={selectedItem} selectItem={(item) => this.setState({ selectedItem: item })}></OrderList>
                        </Colxx>
                        {
                            selectedItem && <Colxx lg="6" xl="6" className="reciptMobileContainer" style={{ height: 'calc(100vh - 85px', overflow: 'auto' }}>
                                <ManageOrdersModal editItem={this.state.selectedItem} dismiss={() => this.dismiss()} />
                            </Colxx>
                        }
                        <Colxx lg="3" xl="3" >
                            <TodoApplicationMenu />
                        </Colxx>
                    </Row>
                </Fragment>
                :
                <div className='loading' />
        )
    }
}
const mapStateToProps = ({ orders }) => {
    return {
        orders
    };
};
export default injectIntl(
    connect(
        mapStateToProps,
        {
            getOrderList,
            getOrderListWithOrder,
            getOrderListSearch,
            selectedOrderItemsChange,
            deleteOrderItem,
            getOrderListWithFilter
        }
    )(ManageOrders)
);