import React from "react";
import {
  Button,
  Row,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Badge
} from "reactstrap";
import { ContextMenuTrigger } from "react-contextmenu";
import { Colxx } from "../../components/common/CustomBootstrap";
import * as typeformEmbed from '@typeform/embed'

import moment from 'moment'
import IntlMessages from "../../helpers/IntlMessages";

function dateFormatter(timeStamp) {
  return moment(timeStamp).format("YYYY-MM-DD")
}



function openTypeForm(typeformUrl) {
  typeformEmbed.makePopup(
    typeformUrl,
    // 'https://food2order.typeform.com/to/foutcT',
    {
      mode: 'popup',
      autoOpen: true,
      autoClose: 3,
      hideScrollbars: true,
      style: { width: "50%" },
      onSubmit: function () {
        console.log('Typeform successfully submitted')
      },
      onReady: function () {
        console.log('Typeform is ready')
      },
      onClose: function () {
        console.log('Typeform is closed')
      }
    }
  )
}

const ImageListView = ({ product, direction }) => {

  return (
    <Colxx sm="16" lg="12" xl="12" className="mb-5" key={product.id}>
      <ContextMenuTrigger id="menu_id" data={product.id}>
        <Card className="rounded">
          <CardBody style={{ padding: '30px' }}>
            <Row className="mb-2">
              {/* <Colxx xxs="2">
                <CardImg style={{ height: '81.55px', borderRadius: '8px', boxShadow: '0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04)' }} top alt={product.title} src={product.img} />
              </Colxx> */}
              <Colxx xxs="10" >
                <div className='d-flex'>
                  <CardSubtitle style={{ fontWeight: '600', fontSize: '1.2rem', marginBottom: '1rem' }}>{direction.isRtl && product.title_ar !== '' ? product.title_ar : product.title}</CardSubtitle>
                  {
                    product.recommended && <div className='ml-2'>
                      <Badge pill style={{ backgroundColor: 'var(--theme-color-1)', color: 'white' }} color=''><IntlMessages id={"store.recommended"} /></Badge>
                    </div>
                  }
                </div>
                <CardText className="text-muted" style={{ fontSize: '0.9rem', fontWeight: '400' }}>{direction.isRtl && product.description_ar !== '' ? product.description_ar : product.description}</CardText>
                {product.endDate &&
                  (<div style={{ fontSize: '12px', color: (moment(product.endDate).add(-3, 'days').valueOf() < Date.now()) ? 'rgb(255,82,69)' : '#909090', fontWeight: '400' }}>{(product.endDate < Date.now()) ? <IntlMessages id={"store.expired"} /> : <IntlMessages id={"store.expiring"} />} on {dateFormatter(product.endDate)}</div>)
                }
              </Colxx>
              <Colxx xxs="2">
                {product.comingSoon ?
                  (<Button size="xs" disabled color='warning' style={{ color: 'white', opacity: '1', borderRadius: '4px', padding: '8px 12px', width: '100px', textAlign: 'center' }} className="float-right"><IntlMessages id={"store.coming-soon"} /></Button>
                  ) : product.activated ? (
                    <Button size="xs" style={{ opacity: '1', borderRadius: '4px', padding: '8px 12px', backgroundColor: '#00bd44', width: '100px', borderColor: '#00bd44', textAlign: 'center' }} disabled className="float-right"><IntlMessages id={"store.activated"} /></Button>
                  ) : (
                    !product.endDate || (moment(product.endDate).add(-3, 'days').valueOf() < Date.now()) ?
                      <Button outline size="xs" color='primary' style={{ borderRadius: '4px', padding: '8px 12px', width: '100px', textAlign: 'center' }} onClick={() => openTypeForm(!product.endDate ? product.typeFormUrl : product.reactivateUrl)} className="float-right">{!product.endDate ? <IntlMessages id={"store.get-started"} /> : <IntlMessages id={"store.reactivate"} />}</Button>
                      : (
                        <Button size="xs" style={{ opacity: '1', borderRadius: '4px', padding: '8px 12px', backgroundColor: '#00bd44', width: '100px', borderColor: '#00bd44', textAlign: 'center' }} disabled className="float-right"><IntlMessages id={"store.activated"} /></Button>
                      )
                  )
                }

              </Colxx>
            </Row>
          </CardBody>
        </Card>
      </ContextMenuTrigger>
    </Colxx>
  );
};

export default React.memo(ImageListView);
