import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import './index.css'
import orders from "./orders";

const Users = ({ match }) => (
  <div className="dashboard-wrapper orderListCustom" >
    <Switch>
      {/* <Redirect exact from={`${match.url}`} to={`${match.url}/orders`} /> */}
      <Route path={`${match.url}`} component={orders} />
      <Redirect to="/error" />
    </Switch>
  </div>
);
export default Users;
