import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, Input } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import DataTablePagination from "../../../components/DatatablePagination";
import moment from "moment";

import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import {
  getOrderList,
  getOrderListWithOrder,
  getOrderListSearch,
  selectedOrderItemsChange,
  deleteOrderItem,
  getOrderListWithFilter

} from "../../../redux/actions";
import IntlMessages from "../../../helpers/IntlMessages";
import { getDirection } from "../../../helpers/Utils";

const dataTableColumns = (currency) => [
  {
    Header: <IntlMessages id={"customers.name"} />,
    accessor: "name",
    // width: 230,
    Cell: props => <p className="list-item-heading">{props.value}</p>
  },
  {
    Header: <IntlMessages id={"customers.phone-number"} />,
    accessor: "number",
    Cell: props => <p className="list-item-heading">{props.value}</p>
  },

  {
    Header: <IntlMessages id={"customers.orders-placed"} />,
    accessor: "ordersPlaced",
    Cell: props => <p className="list-item-heading">{props.value}</p>
  },
  {
    Header: <IntlMessages id={"customers.first-order-at"} />,
    accessor: "firstOrder",
    Cell: props => <div className="list-item-heading" >{moment(props.value).format("DD/MM/YYYY")}<div style={{ fontSize: '10px' }}>{moment(props.value).format("hh:mmA")}</div></div>
  },
  {
    Header: <IntlMessages id={"customers.last-order-at"} />,
    accessor: "lastOrder",
    Cell: props => <div className="list-item-heading" >{moment(props.value).format("DD/MM/YYYY")}<div style={{ fontSize: '10px' }}>{moment(props.value).format("hh:mmA")}</div></div>
  },
  {
    Header: <IntlMessages id={"customers.total-revenue"} />,
    accessor: "revenue",
    Cell: props => <p className="list-item-heading">{currency + " " + props.value}</p>
  },

];

class Customers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nameSearch: '',
      emailSearch: '',
      numberSearch: '',
      allUsers: [],
      filteredUsers: null,
    };
  }

  componentWillReceiveProps(prop) {
    const { todoItems } = prop.orders;
    let { allUsers } = this.state
    let { currency } = prop
    if (todoItems && !allUsers.length && currency) {

      let counter = {}
      todoItems.forEach((item) => {
        counter[item.user.key] = (counter[item.user.key] || 0) + 1
      })

      let createdObject = {}
      Object.keys(counter).map(count => {
        let createdArray = []
        let totalRevenue = 0
        todoItems.map(item => {
          if (item.user.key === count) {
            if (item.status !== 'canceled') {
              totalRevenue += parseInt(item.receipt.total)
            }
            createdArray.push(item.created)
            createdObject[item.user.key] = {
              name: item.user.name,
              email: item.user.email,
              number: item.user.number,
              ordersPlaced: createdArray.length,
              firstOrder: Math.min.apply(null, createdArray),
              lastOrder: Math.max.apply(null, createdArray),
              revenue: (totalRevenue),
            }
          }
        })
      })


      Object.keys(createdObject).map(item => {
        allUsers.push(createdObject[item])
      })
      if (allUsers.length === todoItems.length) {
        this.setState({ allUsers }, () => {
          this.filterFunc()
        })
      }
    }

  }
  filterFunc() {
    let { nameSearch, emailSearch, numberSearch, filteredUsers } = this.state

    if (nameSearch) {
      filteredUsers = filteredUsers.filter((item) => {
        return (item.name).toLowerCase().includes(nameSearch)
      })
    }
    if (emailSearch) {
      filteredUsers = filteredUsers.filter((item) => {
        return (item.email).toLowerCase().includes(emailSearch)
      })
    }
    if (numberSearch) {
      filteredUsers = filteredUsers.filter((item) => {
        return (item.number).includes(numberSearch)

      })
    }


    this.setState({ filteredUsers })

  }
  handleChange(event) {
    let { allUsers } = this.state

    switch (event.target.name) {
      case 'name':
        this.setState({ nameSearch: event.target.value, filteredUsers: allUsers }, () => {
          this.filterFunc()
        })
        break;
      case 'email':

        this.setState({ emailSearch: event.target.value, filteredUsers: allUsers }, () => {
          this.filterFunc()
        })
        break;
      case 'number':

        this.setState({ numberSearch: event.target.value, filteredUsers: allUsers }, () => {
          this.filterFunc()
        })
        break;

      default:
        break;
    }
  }

  render() {
    const { todoItems, loading } = this.props.orders;
    let { allUsers, filteredUsers } = this.state
    let { currency } = this.props
    const direction = getDirection();

    if (!allUsers.length) {
      return (
        <div>
          {
            (loading && !todoItems) ? <div className='loading'></div>
              : <div className="d-flex flex-row mb-3 pb-3 text-center justify-content-center ">
                <div className='font-weight-bold'><IntlMessages id={"customers.no-customers-yet"} /></div>
              </div>
          }
        </div>
      )
    }

    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <div className="mb-2"><h1><IntlMessages id={"customers.customers"} /></h1></div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12">
            <Card className="mb-4 rounded">
              <CardBody>
                <Row>
                  <Colxx xxs="4">
                    <Input type="text" style={{ borderRadius: '4px' }} placeholder={direction.isRtl ? "الاسم" : "Name"} name="name" onChange={this.handleChange.bind(this)} />
                  </Colxx>
                  <Colxx xxs="4">
                    <Input type="text" style={{ borderRadius: '4px' }} placeholder={direction.isRtl ? "البريد الإلكتروني" : "Email"} name="email" onChange={this.handleChange.bind(this)} />
                  </Colxx>
                  <Colxx xxs="4">
                    <Input type="number" style={{ borderRadius: '4px' }} placeholder={direction.isRtl ? "رقم الهاتف" : "Number"} name="number" onChange={this.handleChange.bind(this)} />
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card className="mb-4 rounded">
              <CardBody>
                <ReactTable
                  data={filteredUsers ? filteredUsers : allUsers}
                  columns={dataTableColumns(currency)}
                  defaultPageSize={10}
                  showPageJump={true}
                  PaginationComponent={DataTablePagination}
                  showPageSizeOptions={true}
                // pageSizeOptions={[1,2,3,filteredUsers?filteredUsers.length:allUsers.length+1]}
                />
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}
const mapStateToProps = ({ orders, settings }) => {
  return {
    orders,
    currency: settings.currency
  };
};
export default injectIntl(
  connect(
    mapStateToProps,
    {
      getOrderList,
      getOrderListWithOrder,
      getOrderListSearch,
      selectedOrderItemsChange,
      deleteOrderItem,
      getOrderListWithFilter
    }
  )(Customers)
);
