import React, { Component } from "react";
import { connect } from "react-redux";
import {
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label
} from "reactstrap";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import Script from 'react-load-script';
import { NotificationManager } from "../../components/common/react-notifications";
import { addOfferItem, updateOfferItem, getTodoList } from "../../redux/actions";
import ImageUploader from 'react-images-upload';
class AddNewOfferModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      detail: "",
      title_ar: "",
      detail_ar: "",
      label: {},
      labelColor: "",
      category: '',
      cap: '',
      code: '',
      link: '',
      loaded: false,
      pictures: [],
      active: false,
      percent: '',
      area: [],
      home: false

    };
    this.onDrop = this.onDrop.bind(this);
    this.handleScriptLoad = this.handleScriptLoad.bind(this);
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
  }

  componentWillMount() {
    this.props.getTodoList();
  }
  componentWillReceiveProps(props) {
    if (props.editItem && !this.state.loaded) {
      let item = props.editItem

      this.setState({
        title: item.title,
        detail: item.detail,
        title_ar: item.title_ar,
        detail_ar: item.detail_ar,
        label: item.label ? item.label : {},
        labelColor: "",
        category: item.category ? item.category : '',
        cap: item.cap ? item.cap : 0,
        code: item.code ? item.code : '',
        link: item.link ? item.link : '',
        id: item.id,
        loaded: true,
        pictures: item.pictures ? item.pictures : [],
        startupPictures: item.pictures ? item.pictures : [],
        type: item.type ? item.type : '',
        active: item.active ? item.active : false,
        percent: item.percent ? item.percent : '',
        area: item.area ? item.area : [],
        home: item.home ? item.home : false,


      })
    }
  }
  handleScriptLoad() {
    // Declare Options For Autocomplete 
    // var circle = new google.maps.Circle(
    //     { center: { lat: 25.1874756, lng: 55.2529987 }, radius: 20000 });
    console.log("handleScriptLoad")
    var options = {
      types: ['geocode'],
      componentRestrictions: { country: 'ae' }
    };

    // Initialize Google Autocomplete 
    /*global google*/
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      options);
    // this.autocomplete.setBounds(circle.getBounds());

    // Avoid paying for data that you don't need by restricting the 
    // set of place fields that are returned to just the address
    // components and formatted address
    this.autocomplete.setFields(['address_components',
      'formatted_address', 'geometry', 'type', 'name', 'vicinity']);
    // Fire Event when a suggested name is selected
    this.autocomplete.addListener('place_changed',
      this.handlePlaceSelect);
  }
  handlePlaceSelect() {
    let { area } = this.state
    // Extract City From Address Object
    let addressObject = this.autocomplete.getPlace();
    let mapArea = addressObject.name
    // Check if address is valid
    if (mapArea) {
      // Set State
      area.push(mapArea)
      this.setState(
        {
          area,
          search: '',
        }
      );
    }
  }
  addNetItem = () => {
    if (this.state.home) {
      if (!this.state.title || !this.state.code || !this.state.detail || !this.state.detail_ar) {
        return NotificationManager.error(
          "",
          "Please fill all required fields",
          3000,
          null,
          null,
        );
      }
    }
    if (!this.state.title || !this.state.code) {
      return NotificationManager.error(
        "",
        "Please fill all required fields",
        3000,

        null,
      );
    }

    const newItem = {
      title: this.state.title,
      detail: this.state.detail,
      title_ar: this.state.title_ar,
      detail_ar: this.state.detail_ar,
      label: this.state.label.value,
      labelColor: this.state.label.color,
      category: this.state.category,
      cap: this.state.cap,
      code: this.state.code,
      link: this.state.link,
      id: this.state.id ? this.state.id : '',
      pictures: this.state.pictures ? this.state.pictures : [],
      type: this.state.type ? this.state.type : '',
      active: this.state.active ? this.state.active : false,
      percent: this.state.percent ? this.state.percent : '',
      itemId: this.state.offerItem ? this.state.offerItem.key : '',
      area: this.state.area ? this.state.area : [],
      home: this.state.home ? this.state.home : false,

    };
    if (this.props.editItem) {
      this.props.updateOfferItem(newItem)
    } else {
      this.props.addOfferItem(newItem);
    }
    this.setState({
      title: "",
      detail: "",
      title_ar: "",
      detail_ar: "",
      label: {},
      category: '',
      cap: '',
      code: '',
      link: '',
      loaded: null,
      pictures: [],
      type: '',
      active: false,
      percent: 0,
      area: [],
      home: false,


    }, () => {
      this.props.toggleModal();
    });
  };
  dismiss() {
    this.setState({
      title: "",
      detail: "",
      title_ar: "",
      detail_ar: "",
      label: {},
      category: '',
      cap: '',
      code: '',
      link: '',
      loaded: null,
      pictures: [],
      type: '',
      active: false,
      percent: 0,
      area: [],
      home: false,
    }, () => {
      this.props.toggleModal();

    });
  }
  onDrop(picture) {
    if (picture.length) {
      let lastPic = picture[picture.length - 1]

      const reader = new FileReader();
      reader.readAsDataURL(lastPic);
      reader.onload = () => {
        this.setState({
          pictures: [lastPic],
          startupPictures: [reader.result]
        })
      };
    }
  }
  render() {
    const { type } = this.props.offers;
    const { modalOpen, todoApp } = this.props;

    return (
      <Modal
        isOpen={modalOpen}
        toggle={() => this.dismiss()}
        wrapClassName="modal-right"
        backdrop="static"
      >
        <ModalHeader toggle={() => this.dismiss()}>
          Add new item
          {/* <IntlMessages id="todo.add-new-title" /> */}
        </ModalHeader>
        <ModalBody>
          <Label className="mt-4">
            Code*
          </Label>
          <Input
            type="text"
            defaultValue={this.state.code}
            onChange={event => {
              this.setState({ code: event.target.value.toLowerCase() });
            }}
          />
          <Label className="mt-4">
            {/* <IntlMessages id="todo.title" /> */}
            Title*
          </Label>
          <Input
            type="text"
            defaultValue={this.state.title}
            onChange={event => {
              this.setState({ title: event.target.value });
            }}
          />
          <Label className="mt-4">
            Arabic Title*
          </Label>
          <Input
            type="text"
            defaultValue={this.state.title_ar}
            onChange={event => {
              this.setState({ title_ar: event.target.value });
            }}
          />

          {/* 
          <Label className="mt-4">
            Category
          </Label>
          <Select
            components={{ Input: CustomSelectInput }}
            className="react-select"
            classNamePrefix="react-select"
            name="form-field-name"
            options={categories.map((x, i) => {
              return { label: x, value: x, key: i };
            })}
            defaultValue={this.state.category}
            placeholder={this.state.category}
            value={this.state.category}
            onChange={val => {
              this.setState({ category: val.value });
            }}
          /> */}

          <Label className="mt-4">
            Type
          </Label>
          <Select
            components={{ Input: CustomSelectInput }}
            className="react-select"
            classNamePrefix="react-select"
            name="form-field-name"
            options={type.map((x, i) => {
              return { label: x, value: x, key: i };
            })}
            defaultValue={this.state.type}
            placeholder={this.state.type}
            value={this.state.type}
            onChange={val => {
              this.setState({ type: val.value });
            }}
          />
          {
            this.state.type === 'discount' ?
              <div>
                <Label className="mt-4">
                  Discount Percentage
                </Label>
                <Input
                  type="number"
                  defaultValue={this.state.percent}
                  onChange={event => {
                    this.setState({ percent: event.target.value });
                  }}
                />
                <Label className="mt-4">
                  Maximum Discount Cap 'AED'
                </Label>
                <Input
                  type="number"
                  defaultValue={this.state.cap}
                  onChange={event => {
                    this.setState({ cap: event.target.value });
                  }}
                />
              </div>
              : this.state.type === 'oneForOne' ?
                <div>
                  <Label className="mt-4">
                    Discounted Item
                    </Label>
                  <Select
                    components={{ Input: CustomSelectInput }}
                    className="react-select"
                    classNamePrefix="react-select"
                    name="form-field-name"
                    options={todoApp && todoApp.todoItems.map((x, i) => {
                      return { label: x.name, value: x.name, key: x.id };
                    })}
                    defaultValue={this.state.offerItem ? this.state.offerItem.name : ''}
                    placeholder={this.state.offerItem ? this.state.offerItem.name : ''}
                    value={this.state.offerItem ? this.state.offerItem.name : ''}
                    onChange={val => {
                      this.setState({ offerItem: val });
                    }}
                  />
                </div>
                : ''

          }
          <Label className="mt-4">
            Area
          </Label>

          <Script url="https://maps.googleapis.com/maps/api/js?key=AIzaSyAPe5JQQz0a1T7ytukrW3JLTapYdfRyuSE&libraries=places"
            onLoad={this.handleScriptLoad}
          />
          <Input
            id='autocomplete'
            type="text"
            value={this.state.search}
            onChange={event => {
              this.setState({ search: [event.target.value] });
            }}

          />
          <div>
            {
              this.state.area && this.state.area.map((item, index) => {
                return (
                  <Button color="primary" style={{ margin: '8px' }} outline key={index} onClick={() => {
                    let area = this.state.area;
                    area.splice(index, 1);
                    this.setState({ area })
                  }}>
                    {item + ' X'}
                  </Button>
                )
              })
            }
          </div>

          <Label className="mt-4">
            Link
          </Label>
          <Input
            type="text"
            defaultValue={this.state.link}
            onChange={event => {
              this.setState({ link: event.target.value });
            }}
          />
          <CustomInput
            type="radio"
            id="exCustomRadioHome"
            name="exCustomRadioHome"
            label="Show on Home Screen"
            checked={this.state.home === true}
            onClick={(event) => {
              this.setState({
                home: !this.state.home
              });
            }}
          />
          {
            this.state.home ?
              <div>
                <Label className="mt-4">
                  Detail*
            {/* <IntlMessages id="todo.detail" /> */}
                </Label>
                <Input
                  type="textarea"
                  defaultValue={this.state.detail}
                  onChange={event => {
                    this.setState({ detail: event.target.value });
                  }}
                />
                <Label className="mt-4">
                  Arabic Detail*
            {/* <IntlMessages id="todo.detail" /> */}
                </Label>
                <Input
                  type="textarea"
                  defaultValue={this.state.detail_ar}
                  onChange={event => {
                    this.setState({ detail_ar: event.target.value });
                  }}
                />
                <ImageUploader
                  withIcon={true}
                  buttonText='Choose images'
                  onChange={this.onDrop}
                  imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                  maxFileSize={5242880}
                  withPreview={true}
                  defaultImages={this.state.startupPictures}
                />

              </div>

              : ''

          }
          <CustomInput
            type="radio"
            id="exCustomRadioActive"
            name="exCustomRadioActive"
            label="Activated"
            checked={this.state.active === true}
            onClick={(event) => {
              this.setState({
                active: !this.state.active
              });
            }}
          />

        </ModalBody>
        <ModalFooter>
          <Button color="secondary" outline onClick={() => this.dismiss()}>
            Cancel
            {/* <IntlMessages id="todo.cancel" /> */}
          </Button>
          <Button color="primary" onClick={() => this.addNetItem()}>
            Submit
            {/* <IntlMessages id="todo.submit" /> */}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = ({ offers, todoApp }) => {
  return {
    offers,
    todoApp
  };
};
export default connect(
  mapStateToProps,
  {
    getTodoList,
    addOfferItem,
    updateOfferItem
  }
)(AddNewOfferModal);
