import React, { Component, Fragment } from "react";
import {
  Row, Button, Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Label,
} from "reactstrap";
import './prmodcodes.css'
import { Colxx } from "../../../components/common/CustomBootstrap";
import { firestore } from 'firebase';
import ReactTable from "react-table";
import DataTablePagination from "../../../components/DatatablePagination";
import ListPageHeading from "../../../containers/pages/ListPageHeading";
import moment from 'moment';
import { NotificationManager } from "../../../components/common/react-notifications";
import Switch from "rc-switch";
import Select from 'react-select'
import IntlMessages from "../../../helpers/IntlMessages";
import { getDirection } from "../../../helpers/Utils";

import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const allowOptions = [
  { label: 'Delivery', value: 'delivery' },
  { label: 'Pickup', value: 'pickup' },
  { label: 'Dine in', value: 'dinein' },
]
class PromoCodes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addNewPromocode: false,
      code: '',
      endDate: '',
      count: 1,
      limited: '',
      percent: '',
      cap: '',
      allow: [],
      global: false,
      standalone: false,
      isLoading: true,
      selectedPromocode: {},
      promocodes: [],
      updatePromocode: false,
      search: "",
      deletePromocode: false,
      allowValues: [],
      excludeItems: [],
      flag: 'exclude'
    };
  }


  dataTableColumns() {
    let _this = this
    let resKey = localStorage.getItem('res')

    return ([
      {
        accessor: "active",
        width: 80,
        Cell: props => <div className="list-item-heading">
          <Switch
            className="custom-switch custom-switch-primary"
            checked={props.value && !moment(props.original.endDate).isBefore(moment())}
            onChange={switchCheckedPrimary => {
              if (moment(props.original.endDate).isBefore(moment())) {
                _this.setState({ expiredPromo: true })
              } else {
                this.setState({ isLoading: true })
                firestore().collection('resturant').doc(resKey).collection('offers').doc(props.original.key).update({ active: switchCheckedPrimary, updated: Date.now() }).then(function () {
                  _this.getPromocodesList()
                });
              }

            }}
          />
        </div>
      },
      {
        Header: <IntlMessages id={"marketing.code"} />,
        accessor: "code",
        width: 100,
        Cell: props => <div className="providerfieldText">{props.value}</div>
      },
      {
        Header: <IntlMessages id={"marketing.count"} />,
        accessor: "count",
        width: 100,
        Cell: props => <div className="providerfieldText">{props.value}</div>
      },

      {
        Header: <IntlMessages id={"marketing.percent"} />,
        accessor: "percent",
        width: 100,
        Cell: props => <div className="providerfieldText">{props.value}%</div>
      },
      {
        Header: <IntlMessages id={"marketing.maximum-value"} />,
        accessor: "cap",
        width: 100,
        Cell: props => <div className="providerfieldText">{props.value} {window.currency}</div>
      },
      {
        Header: <IntlMessages id={"marketing.created"} />,
        accessor: "created",
        width: 200,
        Cell: props => <div className="providerfieldText">{moment(props.value).format('DD/MM/YYYY HH:mm A')}</div>
      },
      {
        Header: <IntlMessages id={"marketing.endDate"} />,
        accessor: "endDate",
        width: 200,
        Cell: props => <div className="providerfieldText">{props.value ? moment(props.value).format('DD/MM/YYYY') : ''}</div>
      },
      {
        Header: <IntlMessages id={"marketing.auto-applied"} />,
        accessor: "global",
        // width: 150,
        Cell: props => <div className="providerfieldText">{props.value ? 'True' : 'False'}</div>
      },

      // {
      //   Header: "Stand Alone",
      //   accessor: "standalone",
      //   // width:100,
      //   Cell: props => <div className="providerfieldText">{props.value ? 'True' : 'False'}</div>
      // },
      {
        width: 80,
        accessor: "delete",
        Cell: props => <div className="providerfieldText">
          <Button
            color="danger"
            size="sm"
            outline
            onClick={() => this.setState({ deletePromocode: true, selectedPromocode: props.original })}>
            <IntlMessages id={"menus.delete"} />
          </Button>
        </div>
      },
      {
        width: 70,
        accessor: "edit",
        Cell: props => <div className="providerfieldText">
          <Button
            color="primary"
            size="sm"
            outline
            className="top-right-button"
            onClick={() => this.onClickItem(props.original)}
          >
            <IntlMessages id={"menus.edit"} />
          </Button>
        </div>
      }
    ])
  }

  deletePromocode(PromoCode) {
    let _this = this
    let resKey = localStorage.getItem('res')

    firestore().collection('resturant').doc(resKey).collection('offers').doc(PromoCode.key).delete().then(function () {
      _this.setState({ deletePromocode: false, selectedPromocode: {} }, () => { _this.getPromocodesList() })
    }).catch(function (error) {
      _this.setState({ deletePromocode: false, selectedPromocode: {} }, () => { _this.getPromocodesList() })
    });
  }

  componentDidMount() {
    this.getPromocodesList()
  }
  getPromocodesList() {
    let { promocodes } = this.state
    let _this = this
    let resKey = localStorage.getItem('res')

    promocodes = [];

    firestore().collection('resturant').doc(resKey).collection('offers').get().then(function (promoCodesSnapshot) {
      promoCodesSnapshot.forEach(function (doc) {
        let promocode = {}
        promocode = doc.data()
        promocode.key = doc.id
        promocodes.push(promocode);
      });
      _this.setState({ promocodes, isLoading: false })
    });

  }
  createNotification = (className) => {
    let cName = className || "";
    return (
      NotificationManager.error(
        "Please fill all information required!",
        "Error",
        2000,
        null,
        cName
      )
    )
  };

  submit() {
    let { code, limited, count, percent, cap, updatePromocode, selectedPromocode, global, standalone, allow, endDate, excludeItems, flag } = this.state
    let _this = this

    let resKey = localStorage.getItem('res')
    let key = selectedPromocode.key ? selectedPromocode.key : firestore().collection('resturant').doc(resKey).collection('offers').doc().id
    let { allItems } = this.props.allItems
    let items = allItems.filter(rec => rec.type !== 'modifier')
    excludeItems = flag === 'exclude' ? excludeItems : (items.filter(rec => !excludeItems.includes(rec.id)).map(rec => rec.id))
    if (key) {
      if (!updatePromocode) {
        firestore().collection('resturant').doc(resKey).collection('offers').doc(key).set({
          active: true,
          code: code.toLowerCase(),
          id: code.toLowerCase(),
          global,
          count: count * 1,
          allow,
          excludeItems,
          standalone: standalone ? standalone : false,
          limited: limited ? limited : 'unlimited',
          cap: Number(cap),
          percent: Number(percent),
          created: Date.now(),
          endDate: moment(endDate).toDate().getTime(),
          type: 'discount',
          flag
        }).then(() => {
          items.map(item => {
            firestore().collection('resturant').doc(resKey).collection('menuItems').doc(item.id).update({
              [`excluded.${[code.toLowerCase()]}`]: excludeItems.includes(item.id) ? true : false
            })
          })
          _this.updateGlobalPromocode()
        })
      } else {
        firestore().collection('resturant').doc(resKey).collection('offers').doc(key).update({
          code: code.toLowerCase(),
          id: code.toLowerCase(),
          global,
          count: count * 1,
          allow,
          excludeItems,
          standalone: standalone ? standalone : false,
          limited: limited ? limited : 'unlimited',
          cap: Number(cap),
          percent: Number(percent),
          updated: Date.now(),
          endDate: moment(endDate).toDate().getTime(),
          type: 'discount',
          flag
        }).then(() => {
          items.map(item => {
            firestore().collection('resturant').doc(resKey).collection('menuItems').doc(item.id).update({
              [`excluded.${[code.toLowerCase()]}`]: excludeItems.includes(item.id) ? true : false
            })
          })
          _this.updateGlobalPromocode()

        })
      }
    }

  }
  updateGlobalPromocode = () => {
    let { selectedPromocode, promocodes, global } = this.state
    let _this = this
    let resKey = localStorage.getItem('res')
    let key = selectedPromocode.key ? selectedPromocode.key : firestore().collection('resturant').doc(resKey).collection('offers').doc().id

    if (global) {
      promocodes.map(item => {
        return (
          item.key !== key && (
            firestore().collection('resturant').doc(resKey).collection('offers').doc(item.key).update({
              global: false,
              updated: Date.now(),
            }).then(() => {
              _this.getPromocodesList()
              _this.setState({ updatePromocode: false, addNewPromocode: false, promocodes, selectedPromocode: {}, code: '', endDate: '', limited: '', count: 1, percent: '', cap: '', global: false, standalone: false, allow: [] })
            })
          )
        )
      })

    } else {
      _this.getPromocodesList()
      _this.setState({ updatePromocode: false, addNewPromocode: false, promocodes, selectedPromocode: {}, code: '', endDate: '', limited: '', count: 1, percent: '', cap: '', global: false, standalone: false, allow: [] })
    }
  };
  toggleModal = () => {
    this.setState({ addNewPromocode: true })
  };
  onSearchKey = e => {
    this.setState(
      {
        search: e.target.value.toLowerCase()
      }
    );
  };
  onClickItem = (promoCode) => {
    let { promocodes, allowValues, selectedItems, excludeItems } = this.state
    let { allItems } = Object.assign([], this.props.allItems)
    allItems = allItems.filter(rec => rec.type !== 'modifier')
    allowValues = []
    if (promoCode.allow) {
      promoCode.allow.filter(item => {
        return allowValues.push({ label: item === 'delivery' ? 'Delivery' : item === 'dinein' ? 'Dine in' : 'Pickup', value: item })
      })

    }
    selectedItems = []
    let flag = promoCode.flag || 'exclude'

    if (promoCode.excludeItems) {
      if (flag === 'exclude') {
        excludeItems = promoCode.excludeItems

      } else {
        excludeItems = allItems.filter(rec => !promoCode.excludeItems.includes(rec.id)).map(rec => rec.id)

      }

      allItems && allItems.filter(item => {
        item.label = item.name
        item.value = item.name
        item.key = item.id
        if (flag === 'exclude') {
          return promoCode.excludeItems.includes(item.id) && selectedItems.push(item)
        }
        return !promoCode.excludeItems.includes(item.id) && selectedItems.push(item)

      })
    }
    this.setState({
      updatePromocode: true,
      selectedPromocode: promoCode,
      code: promoCode.code,
      endDate: promoCode.endDate ? moment(promoCode.endDate) : '',
      limited: promoCode.limited,
      count: promoCode.count,
      percent: promoCode.percent,
      cap: promoCode.cap,
      global: promoCode.global,
      allow: promoCode.allow ? promoCode.allow : [],
      standalone: promoCode.standalone,
      promocodes: promocodes,
      allowValues,
      selectedItems: selectedItems,
      excludeItems: excludeItems,
      allExcludeItems: excludeItems,
      flag
    })
  };




  render() {
    const direction = getDirection();
    let { code, endDate, limited, count, cap, percent, promocodes, updatePromocode, addNewPromocode, search, selectedPromocode, deletePromocode, global, standalone, allow, allowValues, selectedItems, excludeItems, flag } = this.state
    let filteredPromocodes = promocodes.filter(item => {
      return (item.code).toLowerCase().includes(search.toLowerCase())
    })
    let { allItems } = Object.assign([], this.props.allItems)
    console.log("allItemsallItemsallItems", JSON.stringify(excludeItems))
    let menuItems = []
    allItems && allItems.map(item => {
      let menuItem = item
      menuItem.value = item.name
      menuItem.label = item.name
      menuItem.key = item.id
      menuItems.push(menuItem)
    })
    return (

      <Fragment>
        {this.state.isLoading && (
          <div className="loading" />
        )
        }

        <div className="disable-text-selection">

          <ListPageHeading
            heading={"marketing.promo-codes"}
            onSearchKey={this.onSearchKey}
            toggleModal={this.toggleModal}
          />

          <Row className="survey-app">
            <Colxx xxs="12">
              {
                filteredPromocodes && filteredPromocodes.length ?

                  <ReactTable
                    data={filteredPromocodes}
                    columns={this.dataTableColumns()}
                    defaultPageSize={10}
                    pageSize={filteredPromocodes.length < 10 ? filteredPromocodes.length : 10}
                    showPageJump={false}
                    showPageSizeOptions={false}
                    PaginationComponent={DataTablePagination}
                    className='custuomReactTable' />
                  :

                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><IntlMessages id={"marketing.no-promocodes"} /></div>
              }
            </Colxx>
          </Row>
          <Modal wrapClassName="modal-right" backdrop="static" isOpen={addNewPromocode || updatePromocode}>
            <ModalHeader >
              {addNewPromocode ?
                <IntlMessages id={"marketing.create-promo-code"} />
                :
                <IntlMessages id={"marketing.edit-promo-code"} />

              }
            </ModalHeader>
            <ModalBody>
              <Colxx xxs="12" className="mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                <div style={{ width: '65%' }}>
                  <Label style={{ marginBottom: '0' }}><IntlMessages id={"marketing.auto-applied2"} /></Label>
                  <p style={{ fontSize: '10px', color: 'gray', lineHeight: '14px' }}>
                    <IntlMessages id={"marketing.enabling-this-will"} />
                  </p>
                </div>
                <Switch
                  className="custom-switch custom-switch-primary"
                  checked={global}
                  onChange={switchCheckedPrimary => {
                    this.setState({ global: switchCheckedPrimary })
                  }}
                />
              </Colxx>
              {/* <Colxx xxs="12" className="mb-4" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Label>Stand Alone:</Label>
                <Switch
                  className="custom-switch custom-switch-primary"
                  checked={standalone}
                  onChange={switchCheckedPrimary => {
                    this.setState({ standalone: switchCheckedPrimary })
                  }}
                />
              </Colxx> */}
              <Colxx xxs="12" className="mb-4">
                <Label className="mb-2"><IntlMessages id={"marketing.promo-code"} /></Label>
                <Input
                  className='mb-4'
                  placeholder={direction.isRtl ? 'أدخل الرمز الترويجي' : 'Enter Promo code'}
                  type='text'
                  id='code'
                  value={code}
                  onChange={(e) => {
                    code = e.target.value
                    this.setState({ code })
                  }}
                />
              </Colxx>

              <Colxx xxs="12" className="mb-4">
                <Label className="mb-2"><IntlMessages id={"marketing.number-of-promo-codes"} /></Label>
                <Input
                  className='mb-4'
                  placeholder={direction.isRtl ? 'الحد للرمز الترويجي' : 'Promo code limit'}
                  type='number'
                  id='count'
                  value={count}
                  onChange={(e) => {
                    count = e.target.value
                    this.setState({ count })
                  }}
                />
              </Colxx>
              <Colxx xxs="12" className="mb-4">
                <Label className="mb-2"><IntlMessages id={"marketing.limited-per-user"} /></Label>
                <Input
                  className='mb-4'
                  placeholder={direction.isRtl ? 'الحد للرمز الترويجي للفرد' : 'Limited per user'}
                  type='number'
                  id='limited'
                  value={limited}
                  onChange={(e) => {
                    limited = e.target.value
                    this.setState({ limited })
                  }}
                />
              </Colxx>
              <Colxx xxs="12" className="mb-4">
                <Label className="mb-2"><IntlMessages id={"marketing.endDate"} />:</Label>
                <DatePicker
                  selected={endDate}
                  placeholderText={direction.isRtl ? 'حدد تاريخ' : 'Select date'}
                  className="customDatePicker"
                  onChange={date => {
                    console.log('endDate', endDate)
                    this.setState({ endDate: date })
                  }}
                  dateFormat="DD/MM/YYYY"
                />
              </Colxx>
              <Colxx xxs="12" className="mb-4">
                <Label className="mb-2"><IntlMessages id={"marketing.percent2"} /></Label>
                <Input
                  className='mb-4'
                  placeholder={direction.isRtl ? 'قيمة الرمز الترويجي' : 'Promo code value'}
                  type='number'
                  id='percent'
                  value={percent}
                  onChange={(e) => {
                    percent = e.target.value
                    this.setState({ percent })
                  }}
                />
              </Colxx>
              <Colxx xxs="12" className="mb-4">
                <Label className="mb-2"><IntlMessages id={"marketing.maximum-value2"} /></Label>
                <Input
                  className='mb-4'
                  placeholder={direction.isRtl ? 'الحد الاقصى للرمز الترويجي' : 'Promo code limit'}
                  type='number'
                  id='cap'
                  value={cap}
                  onChange={(e) => {
                    cap = e.target.value
                    this.setState({ cap })
                  }}
                />
              </Colxx>
              <Colxx xxs="12" className="mb-4">
                <Label className="mb-2"><IntlMessages id={"marketing.available-in"} /></Label>
                <Select
                  placeholder={direction.isRtl ? 'اختار' : 'Select'}
                  isMulti
                  name="allow"
                  options={allowOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  defaultValue={allowValues}
                  onChange={this.handleChangeType}
                />
              </Colxx>

              <Colxx xxs="12" className="mb-4">
                <Label className="mb-2"><IntlMessages id={"marketing.excludeItemsLabel"} /></Label>

                <div className='d-flex pb-2' >
                  <Button onClick={() => this.setState({ flag: 'exclude' })} color={flag === 'exclude' ? 'primary' : 'gray'} size='xs' >Exclude</Button>
                  <Button onClick={() => this.setState({ flag: 'include' })} color={flag === 'include' ? 'primary' : 'gray'} size='xs' className='mx-2'>Include</Button>

                </div>
                <Label className="mb-2"><IntlMessages id={flag === 'exclude' ? "marketing.excludeItems" : "marketing.includeItems"} /></Label>
                <Select
                  placeholder={direction.isRtl ? 'اختار' : 'Select'}
                  isMulti
                  name="allow"
                  options={menuItems.filter(rec => rec.type !== 'modifier')}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  defaultValue={selectedItems}
                  onChange={this.handleChangeItems}
                />
              </Colxx>
            </ModalBody>
            <ModalFooter>
              <Button color='danger' onClick={() => this.setState({ addNewPromocode: false, updatePromocode: false, selectedPromocode: {}, code: '', limited: '', count: 1, percent: '', cap: '', global: false, standalone: false, allow: [] }, () => { this.getPromocodesList() })}><IntlMessages id={"menus.cancel"} /></Button>
              <Button onClick={() =>
                (code !== "" && count && count !== 0 && count !== "" && percent !== "" && cap !== "" && endDate !== "" && allow.length !== 0) ?
                  this.setState({ isLoading: true }, () => this.submit()) : this.createNotification("error")}>{updatePromocode ? <IntlMessages id={"menus.save"} /> : <IntlMessages id={"marketing.create"} />}</Button>

            </ModalFooter>

          </Modal>

          <Modal backdrop="static" isOpen={deletePromocode}>
            <ModalHeader >
              <IntlMessages id={"marketing.delete-promo-code"} />

            </ModalHeader>
            <ModalBody>
              <Row>
                <Colxx xxs="12" className="mb-4">
                  <Label className="mb-2"> <IntlMessages id={"marketing.confirm-delete-promocode"} /></Label>
                </Colxx>
              </Row>

            </ModalBody>
            <ModalFooter>
              <Button onClick={() => this.setState({ deletePromocode: false, selectedPromocode: {} })}><IntlMessages id={"marketing.dismiss"} /></Button>
              <Button color='danger' onClick={() => this.setState({ isLoading: true }, () => { this.deletePromocode(selectedPromocode) })}><IntlMessages id={"marketing.delete-promo-code"} /></Button>
            </ModalFooter>

          </Modal>
          <Modal backdrop="static" isOpen={this.state.expiredPromo}>
            <ModalHeader >
              <IntlMessages id={"marketing.expired"} />
            </ModalHeader>
            <ModalBody>
              <Row>
                <Colxx xxs="12">
                  <Label> <IntlMessages id={"marketing.expiredMsg"} /></Label>
                </Colxx>
              </Row>

            </ModalBody>
            <ModalFooter>
              <Button onClick={() => this.setState({ expiredPromo: false })}><IntlMessages id={"marketing.dismiss"} /></Button>
            </ModalFooter>

          </Modal>
        </div>
      </Fragment>
    )

  }
  handleChangeType = selectedOptionsType => {
    let allow = []
    if (selectedOptionsType && selectedOptionsType.length) {
      selectedOptionsType.map(item => {
        allow.push(item.value)
      })
    }
    this.setState({ allowValues: selectedOptionsType, allow });
  };
  handleChangeItems = value => {
    let excludeItems = []
    if (value && value.length) {
      value.map(item => {
        excludeItems.push(item.id)
      })
    }
    this.setState({ selectedItems: value, excludeItems });
  };
}
const mapStateToProps = ({ allItems }) => {
  return {
    allItems,
  };
};
export default injectIntl(
  connect(
    mapStateToProps
  )(PromoCodes)
);



