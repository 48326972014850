import React, { Component } from "react";
import {
  Row,
  Button,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Collapse
} from "reactstrap";
import { injectIntl } from "react-intl";

import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";

class ListPageHeading extends Component {
  constructor(props) {
    super();
    this.state = {
      dropdownSplitOpen: false,
    };
  }


  render() {
    const {
      changePageSize,
      selectedPageSize,
      totalItemCount,
      startIndex,
      endIndex,
      onSearchKey,
      pageSizes,
      toggleModal,
      heading
    } = this.props;

    return (
      <Row>
        <Colxx xxs="12">
          <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>

            <div className="d-flex align-items-center">
              <h1 style={{ paddingBottom: '0' }}>
                <IntlMessages id={heading} />
              </h1>
              <div style={{ marginLeft: '12px' }} className="search-sm d-inline-block float-md-left mr-1 ml-1 mb-1 align-top">
                <input
                  type="text"
                  name="keyword"
                  id="search"
                  placeholder={"Search"}
                  onChange={e => onSearchKey(e)}
                />
              </div>
            </div>
            <div className="text-zero top-right-button-container">
              <Button
                color="primary"
                size="lg"
                className="top-right-button"
                onClick={() => toggleModal()}
              >
                <IntlMessages id="menus.create-new" />
              </Button>
              {"  "}
            </div>
          </div>

          <Separator className="mb-5" />
        </Colxx>
      </Row>
    );
  }
}

export default injectIntl(ListPageHeading);
