import React, { useEffect, useState } from 'react';
import './storeDetails.css'
import checked from '../../../assets/css/img/checked.png'
import { Input, Modal, ModalBody } from 'reactstrap';
import Switch from "rc-switch";
import { auth, firestore, functions } from 'firebase';
import CheckoutForm from '../checkout';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import classNames from 'classnames';
import Button from '../../../components/common/Button';
import { useSelector } from 'react-redux';
import plansJSON from './plans.json'
const stripePromise = loadStripe("pk_test_51JzDloC0MBUkl1rrNFFW6jYXPY8up08X47LOaTgrsLODamM5dGIyZg8ZeYltczkcB6Ze4zYQY4z3ZlOoWwoJkmRR004Y659Lob");

const generateRecipt = (state) => {
    let { annual, selectedPlan } = state
    let planFlag = annual ? "yearly" : 'monthly';
    let total = selectedPlan?.addons?.filter(r => r.acive)?.reduce((val, currentVal) => {
        let count = currentVal?.count || 1;
        val += (currentVal.price[planFlag].val * count)
        return val
    }, (selectedPlan.price[planFlag].val * 1) || 0) || selectedPlan.price[planFlag].val;
    if (annual) {
        total = total * 12
    }
    let vat = total * 0.05;
    return { total: total.toFixed(2), vat: vat.toFixed(2), subtotal: (total - vat).toFixed(2) }
}
const PlanDetails = ({ dismissable, dismiss }) => {
    let { country } = useSelector(state => state.settings);
    const [clientSecret, setClientSecret] = useState("");
    const [loading, setLoading] = useState(false);
    const { type, stripeId } = useSelector(state => state.settings);
    const [plans, setPlans] = useState(plansJSON[`${["Egypt", "UAE"].includes(country) ? country : 'other'}`])
    const [state, setState] = useState({
        selectedPlan: plans[1],
        annual: true
    })
    let planFlag = state?.annual ? 'yearly' : 'monthly';
    useEffect(() => {
        if (country) {
            let plan = plansJSON[`${["Egypt", "UAE"].includes(country) ? country : 'other'}`]
            setPlans(plan)
            setState({
                ...state,
                selectedPlan: plan[1],
                annual: true
            })
        }
    }, country)

    let recipt = generateRecipt(state);
    const options = {
        clientSecret,
        appearance: {
            theme: 'stripe',
        },
    };
    return (
        <Modal isOpen={true} size='lg' style={{ maxWidth: 'none', margin: '50px', borderRadius: '16px', overflow: 'hidden' }} >
            <div
                onClick={dismiss}
                style={{
                    position: 'absolute',
                    right: '0',
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    padding: '8px 12px',
                    fontSize: '22px',
                    fontWeight: '900',
                    cursor: 'pointer',
                    zIndex: 9,
                    color: 'var(--theme-color-1)'
                }} >
                X
            </div>
            <ModalBody className='storeDetails p-0' style={{ height: '100%' }}>
                {clientSecret ? (
                    <div className='storeDetailsRightSide' style={{ padding: "31px 73px 105px", flex: '1' }}>
                        <Elements options={options} stripe={stripePromise}>
                            <CheckoutForm />
                        </Elements>
                    </div>)

                    :

                    <div className='storeDetailsRightSide' style={{ padding: "31px 73px 105px", flex: '1' }}>
                        <div className='stepTitle'>Step 3 of 3</div>
                        <div className='welcomeTitle'>Subscription Plan</div>
                        <div className='desc'>Select your plan and start earning!</div>
                        <div className='detailsTitle' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <div className='mr-4' style={{ fontWeight: '700', fontSize: '18px' }}>Choose Plan</div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ color: '#3B3B3B', fontSize: '16px' }} >Monthly</div>
                                <Switch
                                    // id={"Tooltip-" + item.id}
                                    className="custom-switch custom-switch-primary custom-switch-small m-0 mr-2 ml-2"
                                    checked={state.annual}
                                    onChange={(e) => {
                                        console.log("eeeee", e)
                                        setState({ ...state, annual: !state.annual })
                                    }}
                                />
                                <div style={{ color: '#3B3B3B', fontSize: '16px' }}  >Yearly <span style={{ color:'var(--theme-color-1)' }}>(Save 30%)</span></div>
                            </div>
                        </div>

                        <div className='plansContainer'>
                            {
                                plans.map(rec => {
                                    let planPrice = rec.price?.[planFlag];
                                    return (
                                        <div key={rec.title} className={`${state.selectedPlan?.title === rec.title ? 'activePlan' : 'notActivePlan'} mr-2`} onClick={() => setState({ ...state, selectedPlan: rec })}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <div className="custom-control custom-radio">
                                                    <input
                                                        style={{ cursor: 'pointer' }}
                                                        type="radio"
                                                        className="custom-control-input"
                                                        checked={true}
                                                    />
                                                </div>
                                                <div>
                                                    <div className='head' >{rec.title}</div>
                                                    <div className='tail' >{rec.desc}</div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='head' >{planPrice.desc}</div>
                                                <div className='tail' style={{ fontSize: '10px', textAlign: 'end' }} >/Month</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            state.selectedPlan?.addons && <div className='plansContainer' style={{ display: 'block' }}>
                                <div className='detailsTitle' style={{ fontWeight: '700', fontSize: '18px' }}>Addons</div>
                                {
                                    state.selectedPlan?.addons?.map((rec, index) => {
                                        let { type, price } = rec;
                                        let addonPrice = price?.[planFlag];

                                        return (
                                            <div key={rec.title} className={`${rec.acive ? 'activePlan' : 'notActivePlan'}`} style={{ marginTop: '17px' }} onClick={() => {
                                                if (type !== 'incremnt') {
                                                    let addons = state.selectedPlan?.addons;
                                                    addons[index].acive = !rec.acive;
                                                    setState({ ...state, [state.selectedPlan.addons]: addons })
                                                }

                                            }}>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <img src={checked} style={{ width: '22px', height: '22px', marginRight: '8px' }} />

                                                    <div>
                                                        <div className='head' >{rec.title}</div>
                                                        <div className='tail' >{rec.desc}</div>
                                                    </div>
                                                </div>
                                                {
                                                    type === 'incremnt' ?
                                                        <div>
                                                            <div className='incrementCard'>
                                                                <div
                                                                    className='btns'
                                                                    onClick={() => {
                                                                        let addons = state.selectedPlan?.addons;
                                                                        addons[index].count = (addons[index].count > 0) ? addons[index].count - 1 : 0;
                                                                        addons[index].acive = addons[index].count !== 0;

                                                                        setState({ ...state, [state.selectedPlan.addons]: addons })
                                                                    }
                                                                    }
                                                                >
                                                                    -
                                                                </div>
                                                                <div className='btns' style={{ backgroundColor: 'transparent', fontSize: '18px' }} >{rec.count || 0}</div>
                                                                <div className='btns'
                                                                    onClick={() => {
                                                                        let addons = state.selectedPlan?.addons;
                                                                        addons[index].acive = true;
                                                                        addons[index].count = (addons[index].count || 0) + 1;
                                                                        setState({ ...state, [state.selectedPlan.addons]: addons })
                                                                    }
                                                                    } >+</div>
                                                            </div>
                                                            <div>
                                                                <div className='head' >{addonPrice.desc}</div>
                                                                <div className='tail' style={{ fontSize: '10px', textAlign: 'end' }} >/Month</div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div>
                                                            <div className='head' >{addonPrice.desc}</div>
                                                            <div className='tail' style={{ fontSize: '10px', textAlign: 'end' }} >/Month</div>
                                                        </div>
                                                }

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }

                    </div>
                }

                <div className='receipt'>
                    <div>
                        <div className='head'>
                            Billing Details
                        </div>
                        <div style={{ paddingTop: '20px' }}>
                            <div className='header'>
                                Plan
                            </div>
                            <div className='details'>
                                <div>
                                    {state.selectedPlan?.title}
                                </div>
                                <div style={{ fontWeight: 800 }}>
                                    {state.selectedPlan?.price[planFlag].desc}
                                </div>

                            </div>
                        </div>
                        {
                            state.selectedPlan?.addons?.filter(r => r.acive)?.length > 0 &&
                            <div style={{ paddingTop: '18px' }}>
                                <div className='header'>
                                    Addons
                                </div>
                                {state.selectedPlan?.addons?.filter(r => r.acive)?.map(rec => {
                                    return (<div
                                        key={rec.title}
                                        className='details'>
                                        <div>
                                            {rec.title}
                                        </div>
                                        <div style={{ fontWeight: 800 }}>
                                            {rec.price[planFlag].desc} {rec.count && `x ${rec.count}`}
                                        </div>

                                    </div>)
                                })}
                            </div>
                        }
                        <div style={{ borderTop: '1px solid', borderBottom: '1px solid', marginTop: '20px', padding: '10px 0' }}>
                            <div className='details  muted'>
                                <div>
                                    Subtotal
                                </div>
                                <div>
                                    {"AED"} {recipt.subtotal}
                                </div>
                            </div>
                            <div className='details muted'>
                                <div>
                                    Taxes
                                </div>
                                <div>
                                    {"AED"} {recipt.vat}
                                </div>
                            </div>

                        </div>
                        <div className='details muted'>
                            <div className='head'>
                                Total
                            </div>
                            <div>
                                {"AED"} {recipt.total}
                            </div>
                        </div>

                    </div>
                    {
                        !clientSecret && <Button
                            disabled={(type === "free_trial") && state?.selectedPlan?.id === 'free'}
                            color="primary"
                            size="lg"
                            loading={loading}
                            style={{ width: '100%', marginTop: '60px', fontSize: '15px', fontWeight: '600' }}
                            onClick={() => {
                                let resKey = localStorage.getItem('resKey')
                                if (!clientSecret) {
                                    setLoading(true);
                                    if (state?.selectedPlan?.id === 'free') {
                                        firestore().collection('res').doc(resKey).update({
                                            'subscription.status': 'active',
                                            'subscription.type': 'free_trial',
                                            'subscription.remainigOrders': 10
                                        })
                                        return
                                    }
                                    var createRes = functions().httpsCallable('createPaymentSessionStripe');
                                    let prices = [{ price: state.selectedPlan.price[planFlag].id }]
                                    state.selectedPlan.addons.forEach(rec => {
                                        if (rec.acive) {
                                            let post = { price: rec.price[planFlag].id };
                                            if (rec.count) {
                                                post.quantity = rec.count
                                            }
                                            prices.push(post)
                                        }
                                    })
                                    let post = {
                                        items: prices,
                                        type: planFlag,
                                        resID: resKey
                                    }
                                    if (stripeId) {
                                        post.customer = { id: stripeId }
                                    } else {
                                        post.email = auth().currentUser.email
                                    }
                                    createRes(post)
                                        .then((result) => {
                                            let { data } = result
                                            setClientSecret(data.clientSecret);
                                            setLoading(false)
                                        }).catch(error => {
                                            setLoading(false)
                                        });;
                                }
                            }}
                        >
                            Continue


                        </Button>
                    }

                </div>
            </ModalBody>

        </Modal >
    );
};

export default PlanDetails;