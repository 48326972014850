import React, { Component, Fragment } from "react";
import {
    Row,
    Button,
    Collapse,
    Input,
    Label,
    Card,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormText,
    InputGroup,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown
} from "reactstrap";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import Script from 'react-load-script';
import Select from 'react-select';
import { NotificationManager } from "../../../components/common/react-notifications";

import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import { firestore } from 'firebase'

import * as typeformEmbed from '@typeform/embed'

function openTypeForm(typeformUrl) {
    typeformEmbed.makePopup(
        typeformUrl,
        // 'https://food2order.typeform.com/to/foutcT',
        {
            mode: 'popup',
            autoOpen: true,
            autoClose: 3,
            hideScrollbars: true,
            style: { width: "50%" },
            onSubmit: function () {
                console.log('Typeform successfully submitted')
            },
            onReady: function () {
                console.log('Typeform is ready')
            },
            onClose: function () {
                console.log('Typeform is closed')
            }
        }
    )
}
class Start extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collaps: {},
            openVideo: false,

        };
        this.handleScriptLoad = this.handleScriptLoad.bind(this);
        this.handlePlaceSelect = this.handlePlaceSelect.bind(this);

    }
    componentWillReceiveProps(prop) {
        if (prop.branch !== this.props.branch) {
            this.getList(prop.branch)
        }
    }
    componentWillMount() {
        let { branch } = this.props
        if (branch) {
            this.getList(branch)
        }
    }
    getList(branch) {
        let key = localStorage.getItem('res')

        firestore().collection('resturant').doc(key).collection('locations').where('branches', 'array-contains', branch.id).get().then(results => {
            let locations = {}
            results.forEach(list => {

                let data = list.data()
                data.id = list.id
                locations[list.id] = data
            })
            this.setState({ locations })

        })
    }

    toggleBack = () => {
        this.setState(prevState => ({
            openVideo: !prevState.openVideo
        }));
    };

    render() {
        let { locations, list } = this.state
        let { currency } = this.props
        return (
            <Fragment>
                <Row className="survey-app">
                    <Colxx xxs="12">
                        <div className="mb-2">
                            <h1>
                                Locations <Button size="xs" outline color='primary' onClick={this.toggleBack}>Tutorial</Button>
                            </h1>
                            <div className="text-zero top-right-button-container">
                                <Button
                                    color="light"
                                    style={{ margin: '0 8px', background: '#DEEBF4', borderColor: '#DEEBF4', color: '#303030' }}
                                    size="lg"
                                    className="top-right-button"
                                    onClick={() => openTypeForm("https://food2order.typeform.com/to/nzJsmR")}
                                >Import</Button>
                                <Button
                                    color="primary"
                                    size="lg"
                                    className="top-right-button"
                                    onClick={() => this.setState({ list: {} })}
                                >Add Areas
                                    </Button>
                            </div>

                        </div>
                        <Separator className="mb-5" />
                        <Row>
                            <Colxx xxs="12" className="mb-3">
                                {
                                    locations && Object.keys(locations).map(key => {
                                        let item = locations[key]

                                        return (
                                            <Card key={key} className='rounded mb-4'>
                                                <div className='d-flex justify-content-between m-4'>
                                                    <h3 >
                                                        {"Areas we serve for " + item.fees + ' ' + currency}
                                                    </h3>

                                                    <div>
                                                        <Button style={{ margin: '4px' }} outline color='danger' onClick={() => this.setState({ confirmDelete: key })} size='xs'>Delete</Button>

                                                        <Button outline color='dark' onClick={() => this.setState({ list: JSON.parse(JSON.stringify(item)) })} size='xs'>Edit</Button>
                                                    </div>
                                                </div>
                                                {
                                                    item.areas && Object.keys(item.areas).map(areaTitle => {
                                                        let areas = item.areas[areaTitle]
                                                        return (
                                                            <div key={areaTitle} className="mb-2">
                                                                <Row className='ml-5'>
                                                                    <h4>{areaTitle}</h4>
                                                                </Row>
                                                                <Collapse isOpen={true} className='ml-5'>
                                                                    {
                                                                        areas.map((area, i) => {
                                                                            return (
                                                                                <Row key={i} className='ml-2'>
                                                                                    <span className="badgeing badge-pill mr-1">
                                                                                        <i className="simple-icon-location-pin" />
                                                                                    </span>
                                                                                    <p>{area}</p>

                                                                                </Row>
                                                                            )
                                                                        })
                                                                    }

                                                                </Collapse>
                                                            </div>

                                                        )
                                                    })
                                                }
                                            </Card>
                                        )
                                    })
                                }
                            </Colxx>
                            {list && this.renderAddNew()}
                        </Row>
                        {
                            this.state.confirmDelete && <Modal isOpen={true}>
                                <ModalBody>Are you sure you want to delete this area group?</ModalBody>
                                <ModalFooter>
                                    <Button color='danger' onClick={() => this.setState({ confirmDelete: false })}>Cancel</Button>
                                    <Button onClick={() => this.deleteLoaction(this.state.confirmDelete)}>Confirm</Button>
                                </ModalFooter>

                            </Modal>
                        }
                    </Colxx>
                </Row>
                {
                    <Modal isOpen={this.state.openVideo}
                        toggle={this.toggleBack} style={{ maxWidth: 'max-content' }}  ><iframe width="806" height="465" src="https://www.youtube.com/embed/pIbogUndZWk?list=PL7VkScEgCZACVDFdNQ1w5trBq2c2odQc9" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Modal>
                }
            </Fragment>
        )
    }
    deleteLoaction(locationsKey) {
        let { locations } = this.state
        let key = localStorage.getItem('res')

        firestore().collection('resturant').doc(key).collection('locations').doc(locationsKey).delete().then(() => {
            delete locations[locationsKey]
            this.setState({ locations, confirmDelete: false }, () => {

                NotificationManager.success(
                    "",
                    "Area group deleted successfully",
                    4000,
                    null,
                    null,
                )
            })
        })
    }
    renderAddNew() {
        let { list, collaps } = this.state
        let { currency } = this.props

        return (
            <Modal wrapClassName="modal-right"
                backdrop="static" isOpen={true}>
                <ModalHeader>
                    Add new Areas <Button size="xs" style={{ marginLeft: "10px" }} outline color='primary' className="float-right" onClick={this.toggleBack}>Tutorial</Button>
                </ModalHeader>
                <ModalBody>
                    <Label>Enter delivery fees for a specified group of areas:</Label>
                    <FormText color="muted">
                        "Areas that you deliver with fixed fees"
                    </FormText>
                    <Input
                        className='mb-4'
                        placeholder={'10 ' + currency}
                        type='number'
                        id='fees'
                        defaultValue={list.fees}
                        onChange={(e) => {
                            list.fees = e.target.value
                            this.setState({ list })
                        }}
                    />
                    <Label>Enter minimum order for this group:</Label>
                    {/* <FormText color="muted">
                        "Enter 0 if not"
                    </FormText> */}
                    <Input
                        className='mb-4'
                        placeholder={'10 ' + currency}
                        type='number'
                        id='minOrder'
                        defaultValue={list.minOrder}
                        onChange={(e) => {
                            list.minOrder = e.target.value
                            this.setState({ list })
                        }}
                    />

                    {
                        list.fees && <div className='mb-4'>
                            <Label>
                                {"Select areas you serve with "}<span className='font-weight-bold'>{list.fees + " " + currency}</span>
                            </Label>
                            <Script url="https://maps.googleapis.com/maps/api/js?key=AIzaSyAPe5JQQz0a1T7ytukrW3JLTapYdfRyuSE&libraries=places"
                                onLoad={this.handleScriptLoad}
                            />
                            <Input
                                id='autocomplete'
                                type="text"
                                value={this.state.search}
                                onChange={event => {
                                    this.setState({ search: [event.target.value] });
                                }}

                            />
                        </div>
                    }
                    {
                        list && list.areas && Object.keys(list.areas).map(areaTitle => {
                            return (
                                <div id={areaTitle} key={areaTitle}>
                                    <Card >
                                        <Row className='p-3 ' onClick={() => this.setState({ collaps: { [areaTitle]: !collaps[areaTitle] } })}>
                                            <span className=" badgeing badge-pill handle mr-1">
                                                <i className={collaps[areaTitle] ? "simple-icon-arrow-down" : "simple-icon-arrow-right"} />
                                            </span>
                                            <div>{areaTitle}</div>


                                        </Row>
                                        <Collapse isOpen={collaps[areaTitle]}>
                                            {
                                                list.areas[areaTitle].map((area, index) => {
                                                    return (
                                                        <div id={index} key={index} className='pl-3 pr-3'>
                                                            <Row className='justify-content-between pl-5 pr-2 pb-1'>
                                                                {/* <span className=" badge badge-pill handle mr-1 ml-3">
                                                                    <i className="simple-icon-cursor-move" />
                                                                </span> */}
                                                                {area}

                                                                <span className=" badgeing badge-pill mr-1 ml-3" onClick={() => { list.areas[areaTitle].splice(index, 1); this.setState({ list }) }}>
                                                                    <i className="simple-icon-trash" />
                                                                </span>
                                                            </Row>
                                                        </div>

                                                    )
                                                })
                                            }
                                        </Collapse>

                                    </Card>

                                </div>

                            )
                        })
                    }
                    {
                        list && list && <div className='mt-4'>
                            <Label>Enter estimated delivery time for this group:</Label>
                            {/* <FormText color="muted">
                        "Enter 0 if not"
                    </FormText> */}
                            <InputGroup className='mb-4'>
                                <Input
                                    placeholder={'10 min'}
                                    type='number'
                                    id='estimatedTime'
                                    value={list.deliveryTime && list.deliveryTime.value}
                                    onChange={(e) => {
                                        if (list.deliveryTime) {
                                            list.deliveryTime.value = e.target.value
                                        }
                                        else {
                                            list.deliveryTime = { value: e.target.value }
                                        }
                                        this.setState({ list })
                                    }}
                                />
                                <UncontrolledDropdown addonType="append">
                                    <DropdownToggle caret outline color="primary">
                                        {
                                            list.deliveryTime && list.deliveryTime.label ? list.deliveryTime.label : 'Select'
                                        }
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {
                                            ['min', 'hour', 'day'].map(item => {
                                                return (
                                                    <DropdownItem key={item} onClick={() => {
                                                        if (list.deliveryTime) {
                                                            list.deliveryTime.label = item
                                                        }
                                                        else {
                                                            list.deliveryTime = { label: item }
                                                        }
                                                        this.setState({ list })
                                                    }

                                                    }>
                                                        {item}
                                                    </DropdownItem>
                                                )
                                            })
                                        }

                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </InputGroup>

                        </div>
                    }

                </ModalBody>
                <ModalFooter>
                    <Button color='danger' onClick={() => this.setState({ list: false })}>Cancel</Button>
                    <Button disabled={!list || !list.areas} onClick={() => this.addList()}>Save</Button>
                </ModalFooter>


            </Modal>
        )
    }
    addList() {
        let { list, locations } = this.state
        let { branch } = this.props
        let post = {
            fees: list.fees,
            areas: list.areas,
            minOrder: list.minOrder ? Number(list.minOrder) : 0,
            branches: branch.id ? [branch.id] : [],
            deliveryTime: list.deliveryTime ? list.deliveryTime : false
        }
        let key = localStorage.getItem('res')

        // return 
        if (list.id) {
            firestore().collection('resturant').doc(key).collection('locations').doc(list.id).update(post).then((result) => {
                post.id = list.id
                locations[list.id] = post
                this.setState({ list: false, locations })
            })
        } else {
            firestore().collection('resturant').doc(key).collection('locations').add(post).then((result) => {
                post.id = result.id
                locations[result.id] = post
                this.setState({ list: false, locations })
            })
        }
    }
    handleScriptLoad() {
        // Declare Options For Autocomplete 
        // var circle = new google.maps.Circle(
        //     { center: { lat: 25.1874756, lng: 55.2529987 }, radius: 20000 });
        let country = localStorage.getItem("country")
        var options = {
            types: ['geocode'],
            componentRestrictions: { country: country === "Egypt" ? 'eg' : 'ae' },
            language:'ar'
        };

        // Initialize Google Autocomplete 
        /*global google*/
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('autocomplete'),
            options);
        // this.autocomplete.setBounds(circle.getBounds());

        // Avoid paying for data that you don't need by restricting the 
        // set of place fields that are returned to just the address
        // components and formatted address
        this.autocomplete.setFields(['address_components',
            'formatted_address', 'geometry', 'type', 'name', 'vicinity']);
        // Fire Event when a suggested name is selected
        this.autocomplete.addListener('place_changed',
            this.handlePlaceSelect);
    }
    handlePlaceSelect() {
        let country = localStorage.getItem("country")

        let { list } = this.state
        // Extract City From Address Object
        let addressObject = this.autocomplete.getPlace();
        // console.log('addressObject',addressObject,addressObject.geometry.location.lat(),addressObject.geometry.location.lng())
        let mapArea = addressObject.name
        // Check if address is valid
        let { formatted_address } = addressObject
        let paramArray = formatted_address.split((country === 'Egypt') ? ', ' : ' - ')

        if (paramArray.length > 1) {
            let areaTitle = paramArray[paramArray.length - 2]

            if (mapArea) {
                // Set State
                if (list.areas && list.areas[areaTitle]) {
                    list.areas[areaTitle].push(mapArea)
                } else if (list.areas) {
                    list.areas[areaTitle] = [mapArea]
                } else {
                    list.areas = { [areaTitle]: [mapArea] }
                }
                this.setState(
                    {
                        list,
                        search: '',
                        collaps: { [areaTitle]: true }
                    }
                );
            }
        }
    }
}

const mapStateToProps = ({ settings }) => {

    const { branch, currency } = settings
    return {
        branch,
        currency
    };
};
export default injectIntl(
    connect(
        mapStateToProps,
        {
        }
    )(Start)
);