import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { TEST_GET_LIST, TEST_ADD_ITEM, TEST_UPDATE_ITEM ,TEST_DELETE_ITEM} from "../actions";

import {
  getTestListSuccess,
  getTestListError,
  addTestItemSuccess,
  addTestItemError,
} from "./actions";

import { database, storage } from '../../helpers/Firebase';
const res = localStorage.getItem('res')

const getTestListRequest = async () => {
  return await new Promise((success, fail) => {
    database.collection('testimonials').where('res','==',res).get().then(results => {
      let list = []
      results.forEach((item) => {
        let value = item.data();
        value.id = item.id
        list.push(value)
      })
      success(list);
    }).catch(error=>{
      console.log("error",error)
      // fail()
    })
  })
    .then(response => response)
    .catch(error => error);
};

function* getTestListItems() {
  try {
    const response = yield call(getTestListRequest);
    yield put(getTestListSuccess(response));
  } catch (error) {
    yield put(getTestListError(error));
  }
}

const addTestItemRequest = async item => {
  return await new Promise((success, fail) => {
    let id = database.collection('testimonials').doc().id

    if (item.pictures && item.pictures.length) {
      item.pictures.forEach(picture => {
        var storageRef = storage.ref().child("testimonials").child(id).child("img").put(picture);
        storageRef.on('state_changed', function (snapshot) {
        }, function (error) {
          // Handle unsuccessful uploads
        }, function () {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          storageRef.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            database.collection('testimonials').doc(id).set({
              title: item.title,
              detail: item.detail,
              created: Date.now(),
              active:item.active,
              pictures: [downloadURL],
              res:res
            }).then(() => {
              getTestListRequest().then(results => {
                success(results)
              })
            })
          });
        });
      })
    } else {
      database.collection('testimonials').doc(id).set({
        title: item.title,
        detail: item.detail,
        active: item.active,
        created: Date.now(),
        res:res
      }).then(() => {
        getTestListRequest().then(results => {
          success(results)
        })
      })
    }
  })
    .then(response => response)
    .catch(error => error);
};
const deleteTestItemRequest = async items => {
  return await new Promise((success, fail) => {
    items.forEach(item=>{
      database.collection('testimonials').doc(item).delete()
    })
    getTestListRequest().then(results => {
        success(results)
      })
  })

}
const updateTestItemRequest = async item => {
  return await new Promise((success, fail) => {


    if (item.pictures && item.pictures.length) {
      item.pictures.forEach(picture => {
        if (picture.name) {
          var storageRef = storage.ref().child("testimonials").child(item.id).child("img").put(picture);
          storageRef.on('state_changed', function (snapshot) {
          }, function (error) {
            // Handle unsuccessful uploads
          }, function () {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            storageRef.snapshot.ref.getDownloadURL().then(function (downloadURL) {
              let ref = database.collection('testimonials').doc(item.id)
              return ref.update({
                title: item.title,
                detail: item.detail ? item.detail : '',
                active: item.active ? item.active : '',
                updated: Date.now(),
                pictures: [downloadURL],
                res:res
              }).then(() => {
                getTestListRequest().then(results => {
                  success(results)
                })
              }).catch(error => {
                console.log("errorerror", error)

              })
            });
          });
        } else {
          let ref = database.collection('testimonials').doc(item.id)
          return ref.update({
            title: item.title,
            detail: item.detail ? item.detail : '',
            active: item.active ? item.active : '',
            updated: Date.now()
          }).then(() => {

            getTestListRequest().then(results => {
              success(results)
            })
          }).catch(error => {
            console.log("errorerror", error)

          })

        }
      })
    }
  })
    .then(response => response)
    .catch(error => error);
};

function* addTestItem({ payload }) {
  try {
    const response = yield call(addTestItemRequest, payload);
    yield put(addTestItemSuccess(response));
  } catch (error) {
    yield put(addTestItemError(error));
  }
}
function* updateTestItem({ payload }) {
  try {
    const response = yield call(updateTestItemRequest, payload);
    yield put(addTestItemSuccess(response));
  } catch (error) {
    yield put(addTestItemError(error));
  }
}
function* deleteTestItem({ payload }) {
  try {
    const response = yield call(deleteTestItemRequest, payload);
    yield put(addTestItemSuccess(response));
  } catch (error) {
    yield put(addTestItemError(error));
  }
}
export function* watchGetList() {
  yield takeEvery(TEST_GET_LIST, getTestListItems);
}

export function* wathcAddItem() {
  yield takeEvery(TEST_ADD_ITEM, addTestItem);
}
export function* wathcUpdateItem() {
  yield takeEvery(TEST_UPDATE_ITEM, updateTestItem);
}
export function* wathcDeleteItems() {
  yield takeEvery(TEST_DELETE_ITEM, deleteTestItem);
}
export default function* rootSaga() {
  yield all([fork(watchGetList), fork(wathcAddItem), fork(wathcUpdateItem),fork(wathcDeleteItems)]);
}
