import React, { Component, Fragment } from "react";
import {
    Row,
    Button,
    FormGroup,
    Label,
    Input,
    Card,
    CardBody,
    Form,
    Modal,
    ModalHeader,
    ModalBody,
    CustomInput,
} from "reactstrap";

import { firestore } from 'firebase';
import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { getDirection } from "../../../../helpers/Utils";
import Select from "react-select";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";
import { NotificationManager } from "../../../../components/common/react-notifications";

class Modifier extends Component {
    constructor(props) {
        super(props);

        this.state = {
            edit: this.props.edit ? Object.assign({}, this.props.edit) : {},
            allItems: this.props.allItems ? this.props.allItems : [],
            modalOpen: false,
            expand: {},
            items: [],
            modification: {},
            loading: false
        };
    }

    componentWillMount() {

        let item = Object.assign({}, this.props.edit)
        let { allItems } = this.props
        let { items } = this.state
        let allItemsCopy = Object.assign([], allItems)
        let modifiersOptions = allItemsCopy.filter(item => item.type === 'modifier')

        if (item.items) {
            items = modifiersOptions.filter(list => {
                let itemData = list
                itemData.key = list.id
                itemData.value = list.name
                itemData.data = list
                itemData.label = list.name + ' "' + window.currency + " " + list.price + '"'
                if ((item.items).includes(list.id)) {
                    return (items).push(itemData)
                }

            })
        }

        this.setState({ modifiersOptions, items })
    }

    updateItem() {
        let { edit, items } = this.state
        let { editList } = this.props

        let _this = this


        let reskey = localStorage.getItem('res')

        let id = editList ? edit.id : firestore().collection('resturant').doc(reskey).collection('modifiersList').doc().id;

        let ref = firestore().collection('resturant').doc(reskey).collection('modifiersList').doc(id);

        edit.id = id
        if (edit.displayName && edit.name && edit.name_ar && edit.min_count && edit.max_count && items) {
            console.log("editediteditedit", edit)
            ref.set(edit, { merge: true }).then(() => {
                if (items.length) {
                    items.map(modifier => {
                        let modifierId = modifier.data ? modifier.id : modifier.newData.id;
                        let modifierData = {}
                        let batch = firestore().batch();
                        if (modifier.data) {
                            modifierData.name = modifier.name
                            modifierData.name_ar = modifier.name_ar
                            modifierData.price = modifier.price
                            modifierData.type = modifier.type
                            modifierData.id = modifier.id
                            var nycRef = firestore().collection('resturant').doc(reskey).collection('menuItems').doc(modifierId);
                            batch.update(nycRef, modifierData);
                            // batch.set(nycRef, modifierData, { merge: true });


                        } else {
                            modifierData = modifier.newData
                            modifierData.type = "modifier"
                            modifierData.id = modifierId
                            var sfRef = firestore().collection('resturant').doc(reskey).collection('menuItems').doc(modifierId);
                            batch.set(sfRef, modifierData);

                        }
                        batch.commit().then(() => {
                            _this.props.dismiss()
                        });
                    })
                } else {
                    this.props.dismiss()
                }

            }).catch(error => {
                this.setState({ saveDisable: false })
                console.log("error", error)
            })
        } else {
            NotificationManager.error(
                "",
                "Please fill all required data",
                4000,
                null,
                null,
            )
            this.setState({ saveDisable: false })

        }


    }


    render() {
        let { edit, modification, modifiersOptions, items } = this.state
        let direction = getDirection();
        let reskey = localStorage.getItem('res')
        return (
            <Fragment>
                <Row className="mb-4">
                    <Colxx xxs="12">
                        <Card>
                            <CardBody>
                                <Form>
                                    <FormGroup row>

                                        <Colxx sm={8}>
                                            <Row className="mb-4">
                                                <Colxx sm={6}>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <Label for="name">
                                                            <IntlMessages id={"menus.name"} />
                                                        </Label>
                                                        <Label style={{ fontSize: '11px' }}>
                                                            <IntlMessages id={"menus.this-will-not"} />
                                                        </Label>
                                                    </div>

                                                    <Input
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        placeholder={direction.isRtl ? "الاسم" : "Name"}
                                                        defaultValue={edit.displayName}
                                                        value={edit.displayName}
                                                        onChange={event => {
                                                            edit.displayName = event.target.value
                                                            this.setState({ edit })
                                                        }}
                                                    />
                                                </Colxx>
                                            </Row>
                                            <Row >
                                                <Colxx sm={6}>

                                                    <Label for="name">
                                                        <IntlMessages id={"menus.list-name"} />

                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        placeholder={direction.isRtl ? "الاسم الظاهر" : "Display Name"}
                                                        defaultValue={edit.name}
                                                        value={edit.name}
                                                        onChange={event => {
                                                            edit.name = event.target.value
                                                            this.setState({ edit })
                                                        }}
                                                    />
                                                </Colxx>
                                                <Colxx sm={6}>
                                                    <Label for="arabic">
                                                        <IntlMessages id={"menus.list-name_ar"} />
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        name="arabic"
                                                        id="arabic"
                                                        placeholder={direction.isRtl ? "الاسم بالعربية" : "Arabic Name"}
                                                        defaultValue={edit.name_ar}
                                                        value={edit.name_ar}
                                                        onChange={event => {
                                                            edit.name_ar = event.target.value
                                                            this.setState({ edit })

                                                        }}
                                                    />
                                                </Colxx>

                                            </Row>

                                            {/* <Label className="mt-4">
                                                <IntlMessages id={"menus.selection-count"} />
                                            </Label>
                                            <Input
                                                type="number"
                                                defaultValue={edit.count}
                                                id='listdetail_ar'
                                                onChange={event => {
                                                    edit.count = event.target.value
                                                    this.setState({ edit })
                                                }}
                                            /> */}

                                            <Row>
                                                <Colxx sm={6}>
                                                    <Label className="mt-4">
                                                        <IntlMessages id={"menus.min-count"} />
                                                    </Label>
                                                    <Input
                                                        type="number"
                                                        defaultValue={edit.min_count}
                                                        id='listdetail_ar'
                                                        onChange={event => {
                                                            edit.min_count = event.target.value
                                                            this.setState({ min_count: event.target.value, edit });
                                                        }}
                                                    />
                                                </Colxx>
                                                <Colxx sm={6}>
                                                    <Label className="mt-4">
                                                        <IntlMessages id={"menus.max-count"} />
                                                    </Label>
                                                    <Input
                                                        type="number"
                                                        defaultValue={edit.max_count}
                                                        id='listdetail_ar'
                                                        onChange={event => {
                                                            edit.max_count = event.target.value
                                                            this.setState({ max_count: event.target.value, edit });
                                                        }}
                                                    />

                                                </Colxx>

                                            </Row>
                                            <CustomInput
                                                className="mt-2"
                                                type="checkbox"
                                                id='requestJeeply'
                                                checked={edit.type && edit.type === "multiple"}
                                                disabled={!edit.max_count || edit.max_count === "" || Number(edit.max_count) < 2}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        edit.type = "multiple"
                                                        this.setState({ edit })

                                                    } else {
                                                        edit.type = "single"
                                                        this.setState({ edit })

                                                    }
                                                }}
                                                label={<IntlMessages id={"menus.type"} />}
                                            />

                                            <Label className="mt-4">
                                                <IntlMessages id={"menus.select-modifier-items"} />
                                            </Label>
                                            <Select
                                                components={{ Input: CustomSelectInput }}
                                                className="react-select text-capitalize mb-4"
                                                classNamePrefix="react-select"
                                                name="form-field-name"
                                                options={modifiersOptions.map((x, i) => {
                                                    return { name: x.name, name_ar: x.name_ar, type: x.type, price: x.price, label: x.name + ' "' + window.currency + " " + x.price + '"', value: x.name, key: i, id: x.id, data: x };
                                                })}
                                                defaultValue={edit.items}
                                                placeholder={items}
                                                value={items}
                                                isMulti={true}
                                                onChange={val => {
                                                    if (val) {
                                                        edit.items = []
                                                        let newitemsOptions = val.map((item, index) => {
                                                            (edit.items).push(item.data.id)
                                                            item.id = item.data.id
                                                            return item
                                                        })
                                                        this.setState({ items: newitemsOptions, edit });
                                                    } else {
                                                        edit.items = []
                                                        this.setState({ items: [], edit });
                                                    }

                                                }}
                                            />

                                            {
                                                items && items.length > 0 && <div className='mt-4'>
                                                    <Label>
                                                        <IntlMessages id={"menus.options"} />
                                                    </Label>
                                                    {
                                                        items.map((item) => {
                                                            return (
                                                                <Card className="mb-2" key={item.id} id={item.id} data-id={item.id} >
                                                                    <Row className='p-3'>
                                                                        <div className='w-90 d-flex align-items-center'>

                                                                            <span className=" badgeing badge-pill w-5 d-flex align-items-center">
                                                                                <i className='simple-icon-cursor-move' />
                                                                            </span>
                                                                            <div>{item.value}</div>
                                                                        </div>

                                                                        <div className="badgeing badge-pill handle w-10 d-flex align-items-center btn" onClick={() => { this.setState({ modification: item, newModifierModal: true, editModifier: true }) }}>
                                                                            <i className='simple-icon-pencil' />
                                                                        </div>

                                                                    </Row>
                                                                </Card>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                            <Button size='xs' onClick={() => this.setState({ newModifierModal: true })}><IntlMessages id={"menus.create-new-modifier"} /></Button>
                                        </Colxx>
                                    </FormGroup>
                                    <FormGroup >
                                        <div className="mb-4  float-right">
                                            <Button color='danger' className='m-1' outline onClick={() => this.props.dismiss()}><IntlMessages id={"menus.cancel"} /></Button>
                                            <Button disabled={this.state.saveDisable} color="primary" className='m-1' onClick={() => this.setState({ saveDisable: true }, () => this.updateItem())}>
                                                <IntlMessages id={"menus.save"} />
                                            </Button>
                                        </div>
                                    </FormGroup>


                                </Form>
                            </CardBody>
                        </Card>
                    </Colxx>
                </Row>
                {
                    this.state.newModifierModal &&
                    <Modal isOpen={true}>

                        <ModalHeader><IntlMessages id={"menus.create-modifier"} /></ModalHeader>
                        <ModalBody>
                            <Form>
                                <FormGroup row>
                                    <Colxx sm={4}>
                                        <Label for="name">
                                            <IntlMessages id={"menus.name"} />
                                        </Label>
                                        <Input
                                            type="text"
                                            name="name"
                                            id="newmodifiername"
                                            defaultValue={modification.name_ar}
                                            placeholder="English Name"
                                            value={modification.name}
                                            onChange={event => {
                                                modification.name = event.target.value
                                                this.setState({ modification })
                                            }}
                                        />
                                    </Colxx>
                                    <Colxx sm={4}>
                                        <Label for="arabic">
                                            <IntlMessages id={"menus.arabic-name"} />
                                        </Label>
                                        <Input
                                            type="text"
                                            name="arabic"
                                            id="newmodifiernamearabic"
                                            placeholder="Arabic Name"
                                            defaultValue={modification.name_ar}
                                            value={modification.name_ar}
                                            onChange={event => {
                                                modification.name_ar = event.target.value
                                                this.setState({ modification })
                                            }}
                                        />

                                    </Colxx>
                                </FormGroup>
                                <FormGroup row>
                                    <Colxx sm={3}>
                                        <Label for="price">
                                            <IntlMessages id={"menus.price"} /> {window.currency}
                                        </Label>
                                        <Input
                                            type="number"
                                            name="price"
                                            id="newmodifiernameprice"
                                            placeholder="20"
                                            value={modification.price}
                                            onChange={event => {
                                                modification.price = event.target.value
                                                this.setState({ modification })
                                            }}
                                        />
                                    </Colxx>
                                </FormGroup>
                                <Button color='danger' className="mt-4 mr-2" outline onClick={() => this.setState({ modification: {}, newModifierModal: false })}><IntlMessages id={"menus.cancel"} /></Button>
                                <Button disabled={!modification.name || !modification.name_ar} color="primary" className="mt-4"
                                    onClick={() => {
                                        if (this.state.editModifier) {
                                            items = items.filter(modif => {
                                                return modif.id !== modification.id
                                            })
                                            items.push({
                                                name: modification.name,
                                                name_ar: modification.name_ar,
                                                price: modification.price,
                                                type: modification.type, value: modification.name, label: modification.name + ' "' + window.currency + " " + modification.price + '"', key: modification.id, id: modification.id, data: modification
                                            });
                                            this.setState({ items, modification: {}, newModifierModal: false, edit, editModifier: false })

                                            console.log("itemsitemsitems", items)
                                        } else {
                                            modification.id = firestore().collection('resturant').doc(reskey).collection('menuItems').doc().id
                                            edit.items = edit.items ? edit.items : []
                                            edit.items.push(modification.id)
                                            items.push({ value: modification.name, label: modification.name, key: modification.id, id: modification.id, newData: modification });
                                            this.setState({ items, modification: {}, newModifierModal: false, edit, editModifier: false })

                                        }
                                    }}>
                                    <IntlMessages id={"menus.save"} />
                                </Button>
                            </Form>
                        </ModalBody>


                    </Modal>
                }
            </Fragment>
        )

    }
}
export default Modifier
