import React, { useState } from "react";
import { Card, Badge, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { ContextMenuTrigger } from "react-contextmenu";
import { Colxx } from "../common/CustomBootstrap";
// let currency = localStorage.getItem('currency')
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { Tooltip } from "reactstrap";
import { firestore } from "firebase";
import IntlMessages from "../../helpers/IntlMessages";
import { getDirection } from "../../helpers/Utils";

const TodoListItem = ({ item, isSelected, rowClicked, deleteItem, categouryMap, currency }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const direction = getDirection();

  return (
    <Colxx xxs="12" key={item.id} className="mb-3 ">
      <ContextMenuTrigger id="menu_id" data={item.id} className='overflow-hidden'>
        <Card
          className={classnames("d-flex flex-row rounded ", {
            active: isSelected
          })}
        >
          <div to={`?p=${item.id}`} className="d-flex w-10 " >
            {
              item.pictures && item.pictures.length && <img
                alt={item.name}
                src={item.pictures && item.pictures.length ? item.pictures[0] : ''}
                className="list-thumbnail w-100 responsive "
                style={{ minWidth: '100%', borderRadius: '16px  0px 0 16px' }}
              />
            }
            <span className='position-absolute badge-top-left badge badge-secondary badge-pill '>
              {categouryMap && categouryMap[item.id]}
            </span>
          </div>
          <div className="pl-2 d-flex flex-grow-1 min-width-zero">
            <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
              <NavLink to={`?p=${item.id}`} onClick={() => rowClicked(item)} className="w-20 w-sm-100">
                <p className="list-item-heading mb-1 truncate">
                  {direction.isRtl ? (item.name_ar ? item.name_ar : item.title_ar) : (item.name ? item.name : item.title)}
                </p>
                <p className=" mb-1 truncate muted" style={{ color: '#adadad', fontSize: '10px' }}>
                  {direction.isRtl ? item.name : item.name_ar}
                </p>
              </NavLink>
              <p className="mb-1 text-muted text-small w-30 w-sm-100 truncate">
                {direction.isRtl && item.detail_ar !== '' ? item.detail_ar : item.detail}
              </p>

              <div className=" w-sm-100 w-10 mb-2">
                <Badge className={item.type === 'modifier' ? "badge-modifier" : 'badge-item'} pill>
                  {(item.type && item.type === 'modifier') ? <IntlMessages id={"menus.modifier-item"} /> : <IntlMessages id={"menus.menu-item"} />}
                </Badge>
              </div>
              <p className="mb-1 text-muted text-small w-10 w-sm-100">
                {console.log('item', item)}

                {
                  item?.modifierList?.length ?
                    <IntlMessages id={"menus.price-on-selection"} />
                    :
                    `${item?.price && item?.price !== '0' ? item?.price : item?.advPrice?.delivery || '0'}  ${currency}`
                }
              </p>
            </div>
            <div className="custom-control custom-checkbox align-self-center p-2">
              <Switch
                id={"Tooltip-" + item.id}
                className="custom-switch custom-switch-primary custom-switch-small"
                checked={!item.hide}
                onChange={() => {
                  let key = localStorage.getItem('res')
                  firestore().collection('resturant').doc(key).collection('menuItems').doc(item.id).update({
                    hide: item.hide ? false : true
                  })
                }}
              />
              <Tooltip
                id={"Tooltip-" + item.id}
                placement="left"
                isOpen={tooltipOpen}
                target={"Tooltip-" + item.id}
                toggle={toggle}
              >
                <IntlMessages id={"menus.out-of-stock"} />
              </Tooltip>
            </div>
            <div className="custom-control custom-checkbox align-self-center p-2">
              {/* <Button outline color='danger' size='xs' onClick={() => remove(item)}>Delete</Button> */}
              <Button outline color='danger' size='xs' onClick={() => deleteItem(item)}><IntlMessages id={"menus.delete"} /></Button>
            </div>
            <div className="custom-control custom-checkbox align-self-center p-2 pr-4">
              <Button outline color="dark" size='xs' onClick={() => rowClicked(item)}><IntlMessages id={"menus.view"} /></Button>
            </div>

          </div>
        </Card>
      </ContextMenuTrigger>
    </Colxx>

  );
};

export default TodoListItem;
