import React, { Component, Fragment } from "react";
import {
    Label,
    Card,
    Button,
    Row,
    CardBody,
    Form,
    FormGroup,
    Input
} from "reactstrap";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { firestore, storage, functions, app } from 'firebase';
import moment from 'moment'
import { Separator, Colxx } from "../../../components/common/CustomBootstrap";
import ImageUploader from 'react-images-upload';
import Resizer from 'react-image-file-resizer';
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import { auth } from '../../../helpers/Firebase.js';

import Axios from "axios";
import { NotificationManager } from "../../../components/common/react-notifications";

function dateFormatter(timeStamp) {

    return moment(timeStamp).fromNow()
}
function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
class CreateRes extends Component {
    constructor(props) {
        super(props);
        this.onDrop = this.onDrop.bind(this);

        this.state = {
            loading: false,
            admin: false
        };
    }

    componentWillMount() {
        // let getList = functions().httpsCallable('allPaymentList')
        // getList({ from: 1588627371000, to: 1588886571000 }).then(res => {
        //     console.log("resresres", res)
        // }).catch(error => {
        //     console.log("errorerror", error)

        // })

        firestore().collection('res').get().then(res => {
            let allRes = []
            res.forEach(result => {
                let data = result.data()
                data.resKey = result.id
                allRes.push(data)
            })
            this.setState({ allRes, loading: true })
        })
    }
    onDrop(picture) {
        let { appIcons } = this.state
        if (picture.length) {
            Resizer.imageFileResizer(
                picture[0],
                128,
                128,
                'png',
                100,
                0,
                uri => {
                    appIcons["icon-128x128"] = uri
                    this.setState({ appIcons })
                },
                'base64'
            );
            Resizer.imageFileResizer(
                picture[0],
                144,
                144,
                'png',
                100,
                0,
                uri => {
                    appIcons["icon-144x144"] = uri
                    this.setState({ appIcons })

                },
                'base64'
            );
            Resizer.imageFileResizer(
                picture[0],
                152,
                152,
                'png',
                100,
                0,
                uri => {
                    appIcons["icon-152x152"] = uri
                    this.setState({ appIcons })

                },
                'base64'
            );
            Resizer.imageFileResizer(
                picture[0],
                192,
                192,
                'png',
                100,
                0,
                uri => {
                    appIcons["icon-192x192"] = uri
                    this.setState({ appIcons })

                },
                'base64'
            );
            Resizer.imageFileResizer(
                picture[0],
                384,
                384,
                'png',
                100,
                0,
                uri => {
                    appIcons["icon-384x384"] = uri
                    this.setState({ appIcons })

                },
                'base64'
            );
            Resizer.imageFileResizer(
                picture[0],
                72,
                72,
                'png',
                100,
                0,
                uri => {
                    appIcons["icon-72x72"] = uri
                    this.setState({ appIcons })

                },
                'base64'
            );
            Resizer.imageFileResizer(
                picture[0],
                96,
                96,
                'png',
                100,
                0,
                uri => {
                    appIcons["icon-96x96"] = uri
                    this.setState({ appIcons })

                },
                'base64'
            );
            Resizer.imageFileResizer(
                picture[0],
                32,
                32,
                'ico',
                100,
                0,
                uri => {
                    appIcons["favicon"] = uri
                    this.setState({ appIcons })

                },
                'base64'
            );
            Resizer.imageFileResizer(
                picture[0],
                512,
                512,
                'png',
                100,
                0,
                uri => {
                    appIcons["icon-512x512"] = uri
                    this.setState({ appIcons, imgUploaded: true })

                },
                'base64'
            );
        }
    }

    selectRes(res) {

        if (res) {
            firestore().collection('resturant').doc(res.id).get().then(results => {
                let data = results.data()
                let { branches } = data
                let selectedRes = JSON.parse(JSON.stringify(res))
                let { appIcons, settings, subscription } = selectedRes
                let { openHours } = settings

                subscription.paymentMeth = subscription.paymentMeth ? subscription.paymentMeth.map((item, index) => {
                    return ({ key: index, value: item, label: item })
                }) : []
                subscription.deliveryMode = subscription.deliveryMode ? subscription.deliveryMode.map((item, index) => {
                    return ({ key: index, value: item, label: item })
                }) : []
                this.setState({ appIcons, settings, subscription, openHours, res: selectedRes, branches })
            })
            firestore().collection('admins').where('res', '==', res.id).get().then(results => {
                let admins = []
                results.forEach(admin => {
                    let { email, role } = admin.data()
                    admins.push({ email: email, role: role })
                })
                this.setState({ admins })
            })

        } else {
            this.setState({
                appIcons: {}, settings: {
                    color: 'var(--theme-color-1)',
                    counterBtnBg: '#3498db30',
                    counterBtnColor: 'var(--theme-color-1)',
                    secondaryColor: 'var(--theme-color-1)'
                }, subscription: {}, openHours: {}, res: {}, branches: {}, admins: []
            })

        }
    }
    updateRes() {
        let { res, appIcons, settings, subscription, openHours, imgUploaded, admin, branches } = this.state
        res.appIcons = appIcons;
        settings.openHours = openHours
        res.settings = settings
        subscription.paymentMeth = subscription.paymentMeth.map(item => item.value)
        subscription.deliveryMode = subscription.deliveryMode.map(item => item.value)

        res.subscription = subscription
        let _this = this
        let id = res.id
        let newRes = false
        if (!res.id) {
            let id = firestore().collection('res').doc().id;
            newRes = true
            res.id = id
            res.subscription.created = Date.now()
            res.created = Date.now()
        }

        let resKey = res.resKey ? res.resKey : res.startUrl.replace('/', '')
        let newAppIcons = {}
        // return 
        if (imgUploaded) {
            let uploadedIcons = appIcons;
            res.appIcons = {};
            Object.keys(uploadedIcons).map((key, index) => {
                let pic = uploadedIcons[key]
                var storageRef = storage().ref().child('resturant').child(res.id).child("icons").child(key).putString(pic, 'data_url');
                storageRef.on('state_changed', function (snapshot) {
                }, function (error) {
                    // Handle unsuccessful uploads
                }, function () {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    storageRef.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                        newAppIcons[key] = downloadURL
                        if (Object.keys(uploadedIcons).length === Object.keys(newAppIcons).length) {
                            firestore().collection('res').doc(resKey).update({
                                appIcons: newAppIcons,
                                "settings.footerLogo_ar": newAppIcons["icon-128x128"],
                                "settings.headerLogo_ar": newAppIcons["icon-128x128"],
                                "settings.menuLogo": newAppIcons["icon-128x128"],
                                "settings.sideImage": newAppIcons["icon-512x512"],
                            })
                        }
                    })
                })
            })
        }

        firestore().collection('res').doc(resKey).set(res, { merge: true }).then(() => {
            console.log("updatedSuccessfully")
            if (admin && admin.email && admin.pw) {
                app('userCreator').auth().createUserWithEmailAndPassword(admin.email, admin.pw).then(resultUser => {
                    let { uid } = resultUser.user
                    if (newRes) {
                        firestore().collection('resturant').doc(res.id).set({
                            admin: [uid],
                            branches: branches
                        }, { merge: true })
                    } else {
                        firestore().collection('resturant').doc(res.id).update({
                            admin: firestore.FieldValue.arrayUnion(uid),
                            branches: branches
                        })
                    }
                    firestore().collection('admins').doc(uid).set({
                        email: admin.email,
                        role: admin.role.value,
                        res: res.id
                    }).then(() => {
                        NotificationManager.success(
                            "",
                            "Admin Created Successfully",
                            3000,
                            null,
                            null,
                        )
                    })
                    _this.setState({ res: false, admin: false })

                })
            } else {
                firestore().collection('resturant').doc(res.id).set({
                    branches: branches
                }, { merge: true }).then(() => {
                    _this.setState({ res: false, admin: false })

                })
            }
        })
    }

    getBranchesOptions() {
        let { branches } = this.state
        let options = Object.keys(branches).map(item => {
            return ({
                key: item,
                label: branches[item].name,
                value: branches[item].name
            })
        })
        return options
    }


    render() {
        let { loading, allRes, res, appIcons, settings, subscription, openHours, admin, branches, admins } = this.state
        return (
            loading ?
                <Fragment >
                    <div className="mb-2">
                        <h1>
                            Restaurants
                        </h1>
                        <div className="text-zero top-right-button-container">
                            <Button
                                color="primary"
                                size="lg"
                                className="top-right-button"
                                onClick={() => this.selectRes(false)}
                            >Create New
                            </Button>
                        </div>

                    </div>
                    <Separator className="mb-5" />

                    {
                        res ?
                            <Card className="rounded mt-4 ml-4">

                                <Row >
                                    <Colxx xxs="12">
                                        <Card>
                                            <CardBody>
                                                <Form>
                                                    <FormGroup row>

                                                        <Colxx sm={8}>
                                                            <Row className='mb-2'>
                                                                <Colxx sm={6}>
                                                                    <Label for="name">
                                                                        Title
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="name"
                                                                        id="name"
                                                                        placeholder="name"
                                                                        defaultValue={res.title}
                                                                        value={res.title}
                                                                        onChange={event => {
                                                                            res.title = event.target.value
                                                                            this.setState({ res })
                                                                        }}
                                                                    />
                                                                </Colxx>
                                                                <Colxx sm={6}>
                                                                    <Label for="arabic">
                                                                        Description
                                                                    </Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        name="description"
                                                                        id="description"
                                                                        placeholder="Description"
                                                                        defaultValue={res.description}
                                                                        value={res.description}
                                                                        onChange={event => {
                                                                            res.description = event.target.value
                                                                            this.setState({ res })

                                                                        }}
                                                                    />
                                                                </Colxx>
                                                            </Row>
                                                            <Row className='mb-4'>
                                                                <Colxx sm={3}>
                                                                    <Label for="price">
                                                                        URL
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="url"
                                                                        id="url"
                                                                        placeholder="url"
                                                                        defaultValue={res.startUrl}
                                                                        value={res.startUrl}
                                                                        onChange={event => {
                                                                            res.startUrl = event.target.value
                                                                            this.setState({ res })

                                                                        }}
                                                                    />
                                                                </Colxx>
                                                            </Row>
                                                            <Row className='mb-4'>
                                                                <Colxx >
                                                                    <Label for="branches">
                                                                        Branches
                                                                    </Label>
                                                                    <CreatableSelect
                                                                        type="text"
                                                                        name="branches"
                                                                        id="branches"
                                                                        placeholder="branches"
                                                                        options={
                                                                            this.getBranchesOptions()
                                                                        }
                                                                        value={Object.keys(branches).map(item => {
                                                                            return ({
                                                                                key: item,
                                                                                value: branches[item].name,
                                                                                label: branches[item].name
                                                                            })
                                                                        })}
                                                                        isMulti
                                                                        isClearable
                                                                        onChange={event => {
                                                                            let branches = {}
                                                                            if (event) {
                                                                                event.map(item => {
                                                                                    let key = item.key ? item.key : makeid(4);
                                                                                    return branches[key] = { name: item.value }
                                                                                })
                                                                            }
                                                                            this.setState({ branches })
                                                                        }}
                                                                    />
                                                                </Colxx>
                                                            </Row>


                                                        </Colxx>


                                                        <Colxx sm={4}>
                                                            <Row className='justify-content-center mb-4'>
                                                                <ImageUploader
                                                                    className='w-80'
                                                                    withIcon={true}
                                                                    buttonText='Choose images'
                                                                    onChange={this.onDrop}
                                                                    imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                                                    maxFileSize={20971520}
                                                                    withPreview={true}
                                                                    defaultImages={appIcons ? [appIcons["icon-96x96"]] : []}
                                                                />
                                                            </Row>

                                                        </Colxx>
                                                    </FormGroup>
                                                    <div>
                                                        <Separator className='mb-4' />
                                                        <h4 className='mb-4'>Settings:</h4>


                                                        <Row className='mb-4'>
                                                            <Colxx sm={3}>
                                                                <Label for="price">
                                                                    Color
                                                                    </Label>
                                                                <Input
                                                                    type="text"
                                                                    name="color"
                                                                    id="color"
                                                                    placeholder="color"
                                                                    defaultValue={settings ? settings.color : ''}
                                                                    value={settings ? settings.color : ''}
                                                                    onChange={event => {
                                                                        settings.color = event.target.value
                                                                        this.setState({ settings })

                                                                    }}
                                                                />
                                                                <Input
                                                                    type="text"
                                                                    name="secondaryColor"
                                                                    id="secondaryColor"
                                                                    placeholder="Secondary Color"
                                                                    defaultValue={settings ? settings.secondaryColor : ''}
                                                                    value={settings ? settings.secondaryColor : ''}
                                                                    onChange={event => {
                                                                        settings.secondaryColor = event.target.value
                                                                        this.setState({ settings })

                                                                    }}
                                                                />

                                                                <Input
                                                                    type="text"
                                                                    name="counterBtnBg"
                                                                    id="counterBtnBg"
                                                                    placeholder="counterBtnBg"
                                                                    defaultValue={settings ? settings.counterBtnBg : ''}
                                                                    value={settings ? settings.counterBtnBg : ''}
                                                                    onChange={event => {
                                                                        settings.counterBtnBg = event.target.value
                                                                        this.setState({ settings })

                                                                    }}
                                                                />
                                                                <Input
                                                                    type="text"
                                                                    name="counterBtnColor"
                                                                    id="counterBtnColor"
                                                                    placeholder="counterBtnColor"
                                                                    defaultValue={settings ? settings.counterBtnColor : ''}
                                                                    value={settings ? settings.counterBtnColor : ''}
                                                                    onChange={event => {
                                                                        settings.counterBtnColor = event.target.value
                                                                        this.setState({ settings })

                                                                    }}
                                                                />
                                                            </Colxx>
                                                        </Row>
                                                        <Row className='mb-4'>
                                                            <Colxx sm={3}>
                                                                <Label for="price">
                                                                    Closing Hours

                                                            </Label>
                                                                <Input
                                                                    type="number"
                                                                    name="openHoursFrom"
                                                                    id="openHoursFrom"
                                                                    placeholder="From"
                                                                    value={openHours ? openHours.from : ''}
                                                                    onChange={event => {
                                                                        openHours.from = Number(event.target.value)
                                                                        this.setState({ openHours })
                                                                    }}
                                                                />
                                                                <Input
                                                                    type="number"
                                                                    name="openHoursFrommin"
                                                                    id="openHoursFrommin"
                                                                    placeholder="From Min"
                                                                    value={openHours ? openHours.minFrom : ''}
                                                                    onChange={event => {
                                                                        openHours.minFrom = Number(event.target.value)
                                                                        this.setState({ openHours })
                                                                    }}
                                                                />
                                                                <Input
                                                                    type="number"
                                                                    name="openHoursTo"
                                                                    id="openHoursTo"
                                                                    placeholder="To"
                                                                    value={openHours ? openHours.to : ''}
                                                                    onChange={event => {
                                                                        openHours.to = Number(event.target.value)
                                                                        this.setState({ openHours })
                                                                    }}
                                                                />
                                                                <Input
                                                                    type="number"
                                                                    name="openHoursTomin"
                                                                    id="openHoursTomin"
                                                                    placeholder="To Min"
                                                                    value={openHours ? openHours.minTo : ''}
                                                                    onChange={event => {
                                                                        openHours.minTo = Number(event.target.value)
                                                                        this.setState({ openHours })
                                                                    }}
                                                                />
                                                                <Input
                                                                    type="text"
                                                                    name="openHoursTitle"
                                                                    id="openHoursTitle"
                                                                    placeholder="Message En"
                                                                    value={openHours ? openHours.title : ''}
                                                                    onChange={event => {
                                                                        openHours.title = event.target.value
                                                                        this.setState({ openHours })
                                                                    }}
                                                                />
                                                                <Input
                                                                    type="text"
                                                                    name="openHoursTitle_ar"
                                                                    id="openHoursTitle_ar"
                                                                    placeholder="Message AR"
                                                                    value={openHours ? openHours.title_ar : ''}
                                                                    onChange={event => {
                                                                        openHours.title_ar = event.target.value
                                                                        this.setState({ openHours })
                                                                    }}
                                                                />
                                                            </Colxx>

                                                        </Row>
                                                        <Row className='mb-4'>
                                                            <Colxx sm={3}>
                                                                <Label for="price">
                                                                    Language
                                                                    </Label>
                                                                <Select
                                                                    options={[
                                                                        { key: 'ltr', value: 'English', label: 'English' },
                                                                        { key: 'rtl', value: 'Arabic', label: 'Arabic' },

                                                                    ]}
                                                                    type="text"
                                                                    name="language"
                                                                    id="language"
                                                                    placeholder="Language"
                                                                    value={settings && settings.direction ? { key: settings.direction, value: settings.direction === 'ltr' ? 'English' : 'Arabic', label: settings.direction === 'ltr' ? 'English' : 'Arabic' } : {}}
                                                                    onChange={event => {
                                                                        settings.direction = event.key
                                                                        this.setState({ settings })

                                                                    }}

                                                                />
                                                            </Colxx>
                                                        </Row>

                                                    </div>
                                                    <div className='mb-4'>
                                                        <Separator className='mb-4' />
                                                        <h4 className='mb-4'>Subscription:</h4>
                                                        <Row>
                                                            <Colxx sm={3}>

                                                                <Select
                                                                    options={[
                                                                        { key: 'UAE', value: 'UAE', label: 'UAE', code: '971', currency: 'AED' },
                                                                        { key: 'Egypt', value: 'Egypt', label: 'Egypt', code: '20', currency: 'EGP' },

                                                                    ]}
                                                                    type="text"
                                                                    name="country"
                                                                    id="country"
                                                                    placeholder="Country"
                                                                    value={subscription ? { key: subscription.country, value: subscription.country, label: subscription.country } : ''}
                                                                    onChange={event => {
                                                                        subscription.country = event.value
                                                                        subscription.counterCode = event.code
                                                                        subscription.currency = event.currency
                                                                        this.setState({ subscription })
                                                                    }}
                                                                />
                                                                <Input
                                                                    type="text"
                                                                    name="counterCode"
                                                                    id="counterCode"
                                                                    placeholder="Countery Code"
                                                                    value={subscription ? subscription.counterCode : ''}
                                                                    onChange={event => {
                                                                        subscription.counterCode = event.target.value
                                                                        this.setState({ subscription })
                                                                    }}
                                                                />
                                                                <Input
                                                                    type="text"
                                                                    name="currency"
                                                                    id="currency"
                                                                    placeholder="Currency"
                                                                    value={subscription ? subscription.currency : ''}
                                                                    onChange={event => {
                                                                        subscription.currency = event.target.value
                                                                        this.setState({ subscription })
                                                                    }}
                                                                />
                                                                <Input
                                                                    type="text"
                                                                    name="type"
                                                                    id="type"
                                                                    placeholder="Type"
                                                                    value={subscription ? subscription.type : ''}
                                                                    onChange={event => {
                                                                        subscription.type = event.target.value
                                                                        this.setState({ subscription })
                                                                    }}
                                                                />
                                                                <Input
                                                                    type="number"
                                                                    name="vat"
                                                                    id="vat"
                                                                    placeholder="Vat"
                                                                    value={subscription ? subscription.vat : ''}
                                                                    onChange={event => {
                                                                        subscription.vat = Number(event.target.value)
                                                                        this.setState({ subscription })
                                                                    }}
                                                                />
                                                                <Select
                                                                    type="text"
                                                                    name="Payment"
                                                                    id="Payment"
                                                                    placeholder="Payment method"
                                                                    options={[{ key: '1', value: 'cash', label: 'cash' },
                                                                    { key: '2', value: 'card', label: 'card' }]}
                                                                    value={subscription ? subscription.paymentMeth : []}
                                                                    isMulti
                                                                    onChange={event => {
                                                                        subscription.paymentMeth = event
                                                                        this.setState({ subscription })
                                                                    }}
                                                                />
                                                                <Select
                                                                    type="text"
                                                                    name="deliveryMode"
                                                                    id="deliveryMode"
                                                                    placeholder="Delivery Modes"
                                                                    options={[{ key: '1', value: 'pickup', label: 'pickup' },
                                                                    { key: '2', value: 'delivery', label: 'delivery' }]}
                                                                    value={subscription ? subscription.deliveryMode : []}
                                                                    isMulti
                                                                    onChange={event => {
                                                                        subscription.deliveryMode = event
                                                                        this.setState({ subscription })
                                                                    }}
                                                                />

                                                            </Colxx>

                                                        </Row>
                                                    </div>
                                                    {/* <div className='mb-4'>
                                                        <Separator className='mb-4' />
                                                        <h4 className='mb-4'>Store Subscriptions:</h4>
                                                        <Row>
                                                            <Colxx sm={3}>
                                                                {
                                                                    subscription.storeSubscriptions ?
                                                                        Object.keys(subscription.storeSubscriptions).map(key => {
                                                                            let storeItem = subscription.storeSubscriptions[key];
                                                                            return <div key={key}>
                                                                                <Label>{key}</Label>
                                                                                <Label>{storeItem.endDate}</Label>

                                                                            </div>
                                                                        }) : ''
                                                                }

                                                            </Colxx>
                                                        </Row>
                                                    </div> */}

                                                    {
                                                        admins && <div className='mb-4'>
                                                            <Separator className='mb-4' />
                                                            <h4 className='mb-4'>Create Admin:</h4>
                                                            {
                                                                admins.map((item, index) => {
                                                                    return (
                                                                        <Row key={index}>
                                                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '300px', margin: '12px' }}>
                                                                                <div>
                                                                                    <span>Email: </span> {item.email}
                                                                                </div>
                                                                                <div>
                                                                                    <span>Role: </span>{item.role}
                                                                                </div>
                                                                            </div>
                                                                        </Row>
                                                                    )
                                                                })
                                                            }

                                                        </div>
                                                    }
                                                    {
                                                        admin ?
                                                            <div>
                                                                <Separator className='mb-4' />
                                                                <h4 className='mb-4'>Create Admin:</h4>
                                                                <Row>
                                                                    <Colxx sm={3}>
                                                                        <Input
                                                                            type="email"
                                                                            name="email"
                                                                            id="email"
                                                                            placeholder="Email"
                                                                            value={admin ? admin.email : ''}
                                                                            onChange={event => {
                                                                                admin.email = event.target.value
                                                                                this.setState({ admin })
                                                                            }}
                                                                        />
                                                                        <Input
                                                                            type="password"
                                                                            name="password"
                                                                            id="password"
                                                                            placeholder="password"
                                                                            value={admin ? admin.pw : ''}
                                                                            onChange={event => {
                                                                                admin.pw = event.target.value
                                                                                this.setState({ admin })
                                                                            }}
                                                                        />
                                                                        <Select
                                                                            type="text"
                                                                            name="role"
                                                                            id="role"
                                                                            placeholder="role"
                                                                            options={[{ key: '1', value: 'admin', label: 'admin' },
                                                                            { key: '2', value: 'callCenter', label: 'callCenter' }]}
                                                                            value={admin ? admin.role : ''}
                                                                            onChange={event => {
                                                                                admin.role = event
                                                                                this.setState({ admin })
                                                                            }}
                                                                        />
                                                                    </Colxx>
                                                                </Row>
                                                            </div>
                                                            :
                                                            <Button onClick={() => this.setState({ admin: {} })}>Add Admin</Button>
                                                    }


                                                    <FormGroup >
                                                        <div className="mb-4  float-right">
                                                            <Button color='danger' className='m-1' outline onClick={() => this.setState({ res: false })}>Cancel</Button>
                                                            <Button color="primary" className='m-1' onClick={() => this.updateRes()}>
                                                                Save
                                                        </Button>
                                                        </div>
                                                    </FormGroup>
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    </Colxx>
                                </Row>

                            </Card>

                            :
                            allRes.map((item, index) => {
                                let { subscription, appIcons } = item
                                let img = appIcons["icon-96x96"]
                                return (

                                    <Card key={index} className='d-flex flex-row rounded mb-3 '>

                                        <div className="p-2 d-flex flex-grow-1 align-items-center">
                                            <div className='w-10'>
                                                <img style={{ minHeight: '96px' }} src={img} className='m-0' />
                                            </div>
                                            <div className='w-20' onClick={() => this.selectRes(item)}>
                                                <Label className='m-0'>{item.title}</Label>
                                            </div>
                                            <div className='w-10'>
                                                <Label className='m-0'>{subscription.type}</Label>
                                            </div>
                                            <div className='w-10'>
                                                <Label className='m-0'>{subscription.country}</Label>
                                            </div>
                                            <div className='w-10'>
                                                <Label className='m-0'>{subscription.created ? dateFormatter(subscription.created) : ''}</Label>
                                            </div>

                                        </div>
                                    </Card>

                                )
                            })
                    }

                    {

                    }


                </Fragment>
                :
                <div className='loading' />
        )
    }
}
const mapStateToProps = ({ orders }) => {
    return {
        orders
    };
};
export default injectIntl(
    connect(
        mapStateToProps,
        {

        }
    )(CreateRes)
);