import React, { Component } from "react";
import { injectIntl } from "react-intl";
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";
import Select from 'react-select'

import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import {
  setContainerClassnames,
  clickOnMobileMenu,
  logoutUser,
  changeLocale,
  selectBranch,
  getOrderListSuccess,
  getAllBranches,
  setupResParam,
  GetSubscriptionStatus
} from "../../redux/actions";

import {
  menuHiddenBreakpoint,
  searchPath,
  localeOptions,
  isDarkSwitchActive
} from "../../constants/defaultValues";

import { MobileMenuIcon, MenuIcon } from "../../components/svg";
import TopnavEasyAccess from "./Topnav.EasyAccess";
import TopnavNotifications from "./Topnav.Notifications";
import TopnavDarkSwitch from "./Topnav.DarkSwitch";
import audioFile from '../../Smartphone Open.m4a'

import { getDirection, setDirection } from "../../helpers/Utils";
import { auth, firestore } from 'firebase';
const beep = new Audio(audioFile)

class TopNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isInFullScreen: false,
      searchKeyword: ""
    };
  }
  componentWillMount() {
    let _this = this
    let { selectBranch } = this.props
    auth().onAuthStateChanged(user => {
      if (user) {
        let userCollection = firestore().collection('users').doc(user.uid);
        userCollection.set({ 'lastSession.sessionsCount': firestore.FieldValue.increment(1), 'lastSession.date': Date.now() }, { merge: true });
        firestore().collection('users').doc(user.uid).get().then(user => {
          this.setState({ userInfo: user.data() })
        })


        user.getIdTokenResult(true).then(result => {
          let resKey = result.claims.res
          // firestore().collection('customers').doc(resKey).collection('subscriptions').where('status', 'in', ['past_due', 'active']).onSnapshot(snap => {
          //   let data = snap.docs.map(rec => {
          //     let { status, current_period_start, current_period_end, created, items } = rec.data()
          //     let price = items?.[0]?.price
          //     return { status, current_period_start, id: rec.id, current_period_end, created, price_id: price?.id, price }
          //   })
          //   _this.props.GetSubscriptionStatus(data)
          // })
          let branch = result.claims.branch
          let hideDinein = result.claims.hideDinein
          if (resKey) {
            firestore().collection('res').where('id', '==', resKey).onSnapshot(settings => {
              settings.forEach(setting => {
                let data = setting.data()
                localStorage.setItem('resKey', setting.id)
                let { title } = data;
                let logo = data.settings;
                let { menuLogo } = logo;
                let { subscription } = data;
                let { currency, storeSubscriptions } = subscription ? subscription : { currency: "AED", storeSubscriptions: {} }
                let { country } = subscription
                window.currency = currency
                // console.log('storeSubscriptions', storeSubscriptions)
                let loyaltyActivated = storeSubscriptions && storeSubscriptions.loyalty && storeSubscriptions.loyalty.activated;

                this.props.setupResParam({
                  ...subscription,
                  ...storeSubscriptions,
                  loyalty: (loyaltyActivated ? storeSubscriptions?.loyalty : false),
                  jeebly: (storeSubscriptions ? storeSubscriptions.jeebly : false),
                });
                // if (storeSubscriptions && storeSubscriptions.loyalty && storeSubscriptions.loyalty.activated) {
                //   this.props.setupResParam({ currency, country, loyalty: storeSubscriptions.loyalty, jeebly: (storeSubscriptions ? storeSubscriptions.jeebly : false) })
                // } else {
                //   this.props.setupResParam({ currency, country, loyalty: false, jeebly: (storeSubscriptions ? storeSubscriptions.jeebly : false) })
                // }
                localStorage.setItem('resID', setting.id)
                localStorage.setItem('currency', currency)
                localStorage.setItem('country', country)
                this.setState({ headerLogo: menuLogo })
                if (!title) {
                  window.location.href = '/onboarding'
                }
              })
            })
            firestore().collection('resturant').doc(resKey).get().then(resultData => {
              let data = resultData.data()
              let branches = branch ? { [branch]: data.branches?.[branch] } : data.branches
              _this.props.getAllBranches(branches)
              window.branches =branches
              if (branches) {
                let optimizedBranches = Object.keys(branches).map(item => {
                  return { id: item, label: branches[item].name, value: branches[item].name, activeMenu: branches[item].activeMenu }
                });
                _this.setState({ branches: optimizedBranches }, () => {
                  selectBranch(optimizedBranches[0])
                  _this.getOrdersList(resKey, branch, hideDinein)
                })
              }
            })
          }
        })
      }
    })
  }

  getOrdersList(key, branch, hideDinein) {
    let _this = this
    let { loaded, branches } = this.state
    let ref = firestore().collection('resturant').doc(key).collection('orders');
    if (branch) {
      ref = ref.where('branch', 'array-contains', branch)
      let selectedBranche = branches.filter(item => item.id === branch)[0]
      this.props.selectBranch(selectedBranche)
      this.setState({
        branches: [selectedBranche],
        selectedBransh: selectedBranche
      });
    }
    if (hideDinein) {
      ref = ref.where('type', 'in', ['delivery', 'pickup'])
    }

    ref.orderBy('created', 'desc').onSnapshot(function (results) {
      let list = [];
      if (results.empty) {
        beep.play().catch(() => {
          _this.setState({ activateSound: 0, loaded: true })
        })
        _this.props.getOrderListSuccess(list);

      } else {
        results.forEach((item) => {
          let value = item.data();
          value.id = item.id
          list.push(value)
        })
        let newItems = list.filter(item => item.status === 'new');
        if (newItems.length > 0) {
          beep.loop = true;
          beep.play().catch(() => {
            _this.setState({ activateSound: newItems.length, loaded: true })
          })
        } else {
          beep.pause()
          if (!loaded) {
            beep.loop = false;
            beep.play().catch(() => {
              _this.setState({ activateSound: 0, loaded: true })
            })
          }
        }
        _this.props.getOrderListSuccess(list);
      }
    })

  }
  handleChangeLocale = (locale, direction) => {
    this.props.changeLocale(locale);

    const currentDirection = getDirection().direction;
    if (direction !== currentDirection) {
      setDirection(direction);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  };

  isInFullScreen = () => {
    return (
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement &&
        document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement &&
        document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null)
    );
  };
  handleSearchIconClick = e => {
    if (window.innerWidth < menuHiddenBreakpoint) {
      let elem = e.target;
      if (!e.target.classList.contains("search")) {
        if (e.target.parentElement.classList.contains("search")) {
          elem = e.target.parentElement;
        } else if (
          e.target.parentElement.parentElement.classList.contains("search")
        ) {
          elem = e.target.parentElement.parentElement;
        }
      }

      if (elem.classList.contains("mobile-view")) {
        this.search();
        elem.classList.remove("mobile-view");
        this.removeEventsSearch();
      } else {
        elem.classList.add("mobile-view");
        this.addEventsSearch();
      }
    } else {
      this.search();
    }
  };
  addEventsSearch = () => {
    // document.addEventListener("click", this.handleDocumentClickSearch, true);
  };
  removeEventsSearch = () => {
    // document.removeEventListener("click", this.handleDocumentClickSearch, true);
  };

  handleDocumentClickSearch = e => {
    let isSearchClick = false;
    if (
      e.target &&
      e.target.classList &&
      (e.target.classList.contains("navbar") ||
        e.target.classList.contains("simple-icon-magnifier"))
    ) {
      isSearchClick = true;
      if (e.target.classList.contains("simple-icon-magnifier")) {
        this.search();
      }
    } else if (
      e.target.parentElement &&
      e.target.parentElement.classList &&
      e.target.parentElement.classList.contains("search")
    ) {
      isSearchClick = true;
    }

    if (!isSearchClick) {
      const input = document.querySelector(".mobile-view");
      if (input && input.classList) input.classList.remove("mobile-view");
      this.removeEventsSearch();
      this.setState({
        searchKeyword: ""
      });
    }
  };
  handleSearchInputChange = e => {
    this.props.selectBranch(e)
    this.setState({
      selectedBransh: e
    });
  };
  handleSearchInputKeyPress = e => {
    if (e.key === "Enter") {
      this.search();
    }
  };

  search = () => {
    this.props.history.push(searchPath + "/" + this.state.searchKeyword);
    this.setState({
      searchKeyword: ""
    });
  };

  toggleFullScreen = () => {
    const isInFullScreen = this.isInFullScreen();

    var docElm = document.documentElement;
    if (!isInFullScreen) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    this.setState({
      isInFullScreen: !isInFullScreen
    });
  };

  handleLogout = () => {
    this.props.logoutUser(this.props.history);
  };

  menuButtonClick = (e, menuClickCount, containerClassnames) => {
    e.preventDefault();
    setTimeout(() => {
      var event = document.createEvent("HTMLEvents");
      event.initEvent("resize", false, false);
      window.dispatchEvent(event);
    }, 350);
    this.props.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.props.selectedMenuHasSubItems
    );
  };
  mobileMenuButtonClick = (e, containerClassnames) => {
    e.preventDefault();
    this.props.clickOnMobileMenu(containerClassnames);
  };

  render() {

    let { userInfo, branches, headerLogo, loaded, activateSound } = this.state
    const { containerClassnames, menuClickCount, locale, branch, remainigOrders } = this.props;
    const { messages } = this.props.intl;
    return (
      <nav className="navbar fixed-top" style={{ top: remainigOrders ? '43px' : '0', maxHeight: '80px' }}>

        <div className="d-flex align-items-center navbar-left">
          <NavLink
            to="#"
            className="menu-button d-none d-md-block"
            onClick={e =>
              this.menuButtonClick(e, menuClickCount, containerClassnames)
            }
          >
            <MenuIcon />
          </NavLink>
          <NavLink
            to="#"
            className="menu-button-mobile d-xs-block d-sm-block d-md-none"
            onClick={e => this.mobileMenuButtonClick(e, containerClassnames)}
          >
            <MobileMenuIcon />
          </NavLink>

          <div className="search" data-search-path="/app/pages/search">
            <Select
              name="searchKeyword"
              id="searchKeyword"
              placeholder={"Select Branch"}
              options={branches}
              value={branch}
              onChange={e => this.handleSearchInputChange(e)}
              onKeyPress={e => this.handleSearchInputKeyPress(e)}
            />
          </div>

          <div className="d-inline-block">
            <UncontrolledDropdown className="ml-2">
              <DropdownToggle
                caret
                color="light"
                size="sm"
                className="language-button"
              >
                <span className="name">{locale.toUpperCase()}</span>
              </DropdownToggle>
              <DropdownMenu className="mt-3" right>
                {localeOptions.map(l => {
                  return (
                    <DropdownItem
                      onClick={() => this.handleChangeLocale(l.id, l.direction)}
                      key={l.id}
                    >
                      {l.name}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>

        </div>
        <a className="navbar-logo" href="/">
          <span className="logo d-none d-xs-block" style={{ backgroundImage: "url(" + headerLogo + ")" }} />
          <span className="logo-mobile d-block d-xs-none" style={{ backgroundImage: "url(" + headerLogo + ")" }} />
        </a>
        <div className="navbar-right">
          {isDarkSwitchActive && <TopnavDarkSwitch />}

          <div className="header-icons d-inline-block align-middle">

            {/* <TopnavEasyAccess /> */}
            {/* <TopnavNotifications /> */}
            <button
              className="header-icon btn btn-empty d-none d-sm-inline-block"
              type="button"
              id="fullScreenButton"
              onClick={this.toggleFullScreen}
            >
              {this.state.isInFullScreen ? (
                <i className="simple-icon-size-actual d-block" />
              ) : (
                <i className="simple-icon-size-fullscreen d-block" />
              )}
            </button>
          </div>
          <div className="user d-inline-block">
            <UncontrolledDropdown className="dropdown-menu-right">
              <DropdownToggle className="p-0" color="empty">
                <span className="name mr-1 ml-1">{userInfo && userInfo.name && userInfo.name.toUpperCase()}</span>
                <span>
                  {
                    userInfo && userInfo.picture ?
                      <img alt="Profile" src={userInfo.picture} />
                      :
                      <span className='simple-icon-user' />
                  }

                </span>
              </DropdownToggle>
              <DropdownMenu className="mt-3" right>
                {/* <DropdownItem>Account</DropdownItem>
                <DropdownItem divider /> */}
                <DropdownItem onClick={() => this.handleLogout()}>
                  Sign out
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
        {
          loaded && <Modal isOpen={true}>
            <ModalBody>
              {"You have " + activateSound + " new orders"}
            </ModalBody>

            <ModalFooter >
              <Button color='primary' onClick={() => { beep.play(); this.setState({ activateSound: false, loaded: false }) }}>Dismiss</Button>
            </ModalFooter>
          </Modal>
        }
      </nav>
    );
  }
}

const mapStateToProps = ({ menu, settings }) => {
  const { containerClassnames, menuClickCount, selectedMenuHasSubItems } = menu;
  const { locale, branch, type } = settings;
  let remainigOrders;
  if (type === 'free_trial') {
    remainigOrders = settings.remainigOrders
  }
  return {
    containerClassnames,
    menuClickCount,
    selectedMenuHasSubItems,
    locale,
    branch,
    remainigOrders
  };
};
const mapActionsToProps = (dispatch) => ({
  getOrderListSuccess: (updatedList) => dispatch(getOrderListSuccess(updatedList)),
  setContainerClassnames: (items1, items2, items3) => dispatch(setContainerClassnames(items1, items2, items3)),
  clickOnMobileMenu: (mobile) => dispatch(clickOnMobileMenu(mobile)),
  logoutUser: (user) => dispatch(logoutUser(user)),
  changeLocale: (bransh) => dispatch(changeLocale(bransh)),
  selectBranch: (bransh) => dispatch(selectBranch(bransh)),
  getAllBranches: (bransh) => dispatch(getAllBranches(bransh)),
  setupResParam: (param) => dispatch(setupResParam(param)),
  GetSubscriptionStatus: (param) => dispatch(GetSubscriptionStatus(param))
});
export default injectIntl(
  connect(
    mapStateToProps,
    mapActionsToProps
  )(TopNav)
);
