import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import './index.css'
import OnboardModal from './onboardModal/onboardModal'
class Index extends Component {
    state = {
        step: 0
    }
    render() {
        let { step } = this.state
        return (
            <div className='onboard' style={{ height: '100vh', display: 'flex' }}>
                <OnboardModal/>
                <div className='left_section'>
                    <div style={{ fontSize: '22px', fontWeight: '600' }}>Join us now!</div>
                    <div style={{ fontSize: '12px', color: '#cacaca' }}>{`step ${step + 1} of 2`}</div>
                    {
                        step === 0 ?
                            <>
                                <div className='group'>
                                    <div className='row_group'>
                                        <div>Store name</div>
                                        <Input placeholder="enter store name" />
                                    </div>
                                    <div className='row_group'>
                                        <div>Store Link</div>
                                        <Input placeholder="enter store link" />
                                    </div>
                                </div>
                                <div style={{ width: '100%', background: '#cacaca', height: '1px', margin: '10px 0' }} />
                                <div className='group'>
                                    <div className='row_group'>
                                        <div>Name</div>
                                        <Input placeholder="enter store link" />
                                    </div>
                                    <div className='row_group'>
                                        <div>Email</div>
                                        <Input placeholder="enter store link" />
                                    </div>
                                    <div className='row_group'>
                                        <div>Password</div>
                                        <Input placeholder="enter store link" />
                                    </div>
                                </div>

                                <Button onClick={() => this.setState({ step: 1 })} style={{ width: "100%" }}>Next</Button>
                            </>
                            : step === 1 ?
                                <div className='group'>
                                    <div className='row_group'>
                                        <div>Verify</div>
                                        <Input placeholder="Enter verification code" />
                                    </div>
                                </div>
                                : ''
                    }

                </div>



                <div className='right_section' ></div>

            </div>
        )
    }
}

export default Index;